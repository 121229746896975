import React, { useCallback, useEffect, useState } from "react";
import Avatar from "boring-avatars";

// Types
import ProfileIconProps from "./types";

//Styles
import { MediumProfileImage } from "./styles";

const ProfileIcon: React.FC<ProfileIconProps> = ({
	imageURL,
	fName,
	lName,
	size = 36,
	...props
}) => {
	// State
	const [useFallback, setUseFallback] = useState(!imageURL);

	// Helpers
	useEffect(() => {
		setUseFallback(!imageURL);
	}, [imageURL]);

	const onImageFail = useCallback(() => setUseFallback(true), []);

	return !useFallback && imageURL ? (
		<MediumProfileImage
			size={size}
			imageSrcURL={imageURL}
			onImageFail={onImageFail}
		/>
	) : (
		<Avatar
			{...props}
			size={size}
			name={`${fName} ${lName}`}
			variant="marble"
			colors={["#5E9842", "#ac50da", "#E97E42", "#bb3680", "#007CBE"]}
		/>
	);
};

export default ProfileIcon;
