import { animated } from "react-spring";
import styled from "@emotion/styled";
import CCCloseButton from "CCW-Components/CCW-CloseButton";

import { ContainerProps, InnerAlertProps } from "./types";

export const Container = styled.div<ContainerProps>(({ theme, type }) => ({
	position: "relative",
	width: "100%",
	borderRadius: "1.2rem",
	boxShadow: theme.shadow,
	display: "flex",
	flexDirection: "column",
	background: type === "success" ? theme.colors.Green : theme.colors.Red,
}));

export const InnerAlert = styled.div<InnerAlertProps>(
	({ timedAlert, theme }) => ({
		display: "flex",
		alignItems: "center",
		height: "100%",
		padding: "1.2rem 3rem 1.2rem 2rem",
		marginBottom: timedAlert ? "-0.4rem" : "0px",
		"& > strong": {
			display: "flex",
			marginRight: "1.5rem",
		},
		"& > strong > svg > path": {
			fill: theme.colors.White,
		},
	}),
);

export const Text = styled.div(({ theme }) => ({
	color: "white",
	...theme.webFonts.F5,
}));

export const Loader = styled(animated.div)(() => ({
	width: "100%",
	height: "0.4rem",
	background: "white",
	opacity: 0.4,
	borderBottomLeftRadius: "1.2rem",
	borderBottomRightRadius: "1.2rem",
}));

export const CloseButton = styled(CCCloseButton)({
	position: "absolute",
	right: "0.7rem",
	top: "50%",
	transform: "translateY(-50%)",
});
