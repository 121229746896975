import * as Yup from "yup";

export const initValues = {
	password: "",
	confirmPassword: "",
};

export const validation = Yup.object({
	password: Yup.string().required("Required"),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("password")], "Passwords do not match")
		.required("Required"),
});
