import React from "react";

// Styles
import Container from "./styles";

// Types
import type { PropsWithChildren } from "react";

export const VisuallyHidden: React.FC<PropsWithChildren<unknown>> = (props) => {
	return <Container>{props.children}</Container>;
};

export default VisuallyHidden;
