import styled from "@emotion/styled";
import { Theme } from "@emotion/react";

const selectFill = (theme: Theme, status: string) => {
	switch (status) {
		case "Not Started":
			return theme.colors.Red;
		case "In Progress":
			return theme.colors.primary;
		case "Completed":
			return theme.colors.Green;
		case "Abandoned":
			return theme.colors.D3;
		default:
			return "#E94242";
	}
};

const Circle = styled.span<{ status?: string; fill?: string }>(
	({ theme, status, fill }) => ({
		marginRight: "1rem",
		"> svg > circle": {
			fill: status ? selectFill(theme, status) : fill,
		},
	}),
);

export default Circle;
