import { Navigate } from "react-router-dom";

// Types
import PrivateRouteProps from "./types";

function PrivateRoute({ user, children }: PrivateRouteProps) {
	if (!user) {
		return <Navigate to="/login" replace />;
	}

	return children;
}

export default PrivateRoute;
