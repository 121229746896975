export const stateOptions = [
	{
		label: "WA",
		value: "WA",
	},
	{
		label: "NT",
		value: "NT",
	},
	{
		label: "QLD",
		value: "QLD",
	},
	{
		label: "NSW",
		value: "NSW",
	},
	{
		label: "ACT",
		value: "ACT",
	},
	{
		label: "VIC",
		value: "VIC",
	},
	{
		label: "TAS",
		value: "TAS",
	},
	{
		label: "SA",
		value: "SA",
	},
];

export const partnerOptions = [
	{ value: "lga", label: "LGA" },
	{ value: "normal", label: "Normal" },
	{ value: "affiliate", label: "Affiliate" },
];

export const userPermissionOptions = [
	{
		label: "Account Admin",
		value: "Account Admin",
		infoLabel: "Full access",
	},
];

export const planOptions = [
	{ label: "Basic $850", value: "basic" },
	{ label: "Standard $5K", value: "standard" },
	{ label: "Pro $10K", value: "pro" },
];
