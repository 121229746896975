import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const Container = styled.div(({ theme }) => ({
	display: "flex",
	gap: "1rem",
	alignItems: "center",
	background: theme.colors.Blueberry200,
	padding: "0.5rem 1rem",
	overflow: "hidden",
	borderRadius: "1.2rem",
}));

const styles = (theme: Theme) => ({
	padding: "0.5rem",
	lineHeight: 1,
	background: "transparent",
	borderRadius: "0.5rem",
	"&:hover": { background: theme.colors.Blueberry300 },
});

export const StyledButton = styled.button(({ theme }) => ({
	border: "none",
	...styles(theme),
}));

export const StyledLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	color: "inherit",
	...styles(theme),
}));
