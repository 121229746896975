import { useEffect, useState } from "react";

export const useLoadImage = (
	imageSrcURL: string | undefined,
): [image: HTMLImageElement | undefined, error: string | undefined] => {
	// State
	const [error, setError] = useState<string>();
	const [image, setImage] = useState<HTMLImageElement>();
	// Helpers
	useEffect(() => {
		let img: HTMLImageElement;

		const handleOnLoad = () => {
			setImage(img);
			setError(undefined);
		};
		const handleOnError = () => {
			setError("Failed to Load");
			setImage(undefined);
		};

		if (imageSrcURL) {
			img = new Image();
			img.src = imageSrcURL;

			img.addEventListener("load", handleOnLoad);
			img.addEventListener("error", handleOnError);
		} else {
			setError(undefined);
			setImage(undefined);
		}

		return () => {
			img?.removeEventListener("load", handleOnLoad);
			img?.removeEventListener("error", handleOnError);
		};
	}, [imageSrcURL]);

	return [image, error];
};

export default useLoadImage;
