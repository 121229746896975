// CC
import React from "react";
import LogoHeader from "../CCW-LogoHeader";
import Container from "../CCW-Container";

import {
	BackgroundStyle,
	IconWrapper,
	ContainerStyle,
	CardStyle,
} from "./styles";

import { CardFormTemplateProps } from "./types";

const CardFormTemplate: React.FC<CardFormTemplateProps> = ({
	icon,
	iconWidth,
	iconHeight,
	...props
}) => {
	return (
		<BackgroundStyle>
			<Container>
				<IconWrapper>
					<LogoHeader
						customLogo={icon != null ? icon : undefined}
						customWidth={icon != null ? iconWidth : undefined}
						customHeight={icon != null ? iconHeight : undefined}
					/>
				</IconWrapper>
				<ContainerStyle>
					<CardStyle>{props.children}</CardStyle>
				</ContainerStyle>
			</Container>
		</BackgroundStyle>
	);
};

export default CardFormTemplate;
