import { createContext } from "react";

// Types
import type { IntercomContext as IntercomContextType } from "./types";

const IntercomContext = createContext<IntercomContextType | undefined>(
	undefined,
);

export default IntercomContext;
