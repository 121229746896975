import React, { useState } from "react";
import { Formik, Form } from "formik";

// CC
import CardFormTemplate from "CCW-Components/CCW-CardFormTemplate";
import FIGDefault from "CCW-Components/CCW-FIGDefault";

// Hooks
import useAlert from "Hooks/UseAlert";

// Helpers
import isError from "Helpers/TypeHelpers/IsError";
import createResetPasswordEmail from "Helpers/NetworkingHelpers/CreateResetPasswordEmail";

// Assets
import EmailIcon from "Assets/SVG/EmailIcon";

// Styles
import { Card, Button, Link, Message } from "./styles";

// Data
import { initValues, validation } from "./FormLogic";
import FIGDropDownInput from "CCW-Components/CCW-FIGDropDownInputNew";

const DefaultForgotPassword: React.FC = () => {
	// State
	const [success, setSuccess] = useState(false);

	// Hooks
	const [, reportAlert] = useAlert();

	return (
		<CardFormTemplate>
			{!success ? (
				<Card title="Forgot password" subtitle="Request a reset password link.">
					<Formik
						initialValues={initValues}
						validationSchema={validation}
						onSubmit={async (values, { setSubmitting }) => {
							setSubmitting(true);
							try {
								await createResetPasswordEmail(values);
								setSuccess(true);
							} catch (err) {
								setSubmitting(false);
								reportAlert(
									isError(err)
										? err.message
										: "Action failed. Please contact support",
									"error",
								);
							}
						}}>
						{({ isSubmitting }) => (
							<Form>
								<FIGDefault
									id="login-email"
									name="email"
									type="email"
									label="Email Address"
								/>
								<FIGDropDownInput
									name="accountType"
									label="Account type"
									options={[
										{ label: "Business", value: "business" },
										{ label: "School", value: "school" },
										{ label: "Home", value: "home" },
									]}
								/>
								<Button
									variant="primary"
									type="submit"
									disabled={isSubmitting}
									loading={isSubmitting}
									block>
									Submit
								</Button>
							</Form>
						)}
					</Formik>
				</Card>
			) : (
				<Card icon={EmailIcon} title="Check your inbox">
					<Message>
						We have sent instructions to the requested email with details on how
						to reset your password.
						<p>
							If you don&apos;t receive an email in the next few minutes, please
							<Link onClick={() => setSuccess(false)}> click here</Link>.
						</p>
					</Message>
				</Card>
			)}
		</CardFormTemplate>
	);
};

export default DefaultForgotPassword;
