import React from "react";

// Types
import type IconProps from "Types/IconProps";

const DollarIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "dollar-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "16px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.60208 16H8.92708L8.93333 14.5688C11.6146 14.375 13.1521 12.9 13.1583 10.8063C13.1521 8.5875 11.3208 7.575 9.39583 7.11875L8.98333 7.0125L9.00833 3.44375C10.0083 3.61875 10.6521 4.21875 10.7521 5.11875H12.9833C12.9458 3.13125 11.3833 1.68125 9.02083 1.45625L9.02708 0H7.70208L7.69583 1.45625C5.35208 1.69375 3.66458 3.13125 3.67083 5.2C3.67083 7.0375 4.97708 8.09375 7.08333 8.6375L7.64583 8.78125L7.62083 12.5625C6.50208 12.3875 5.69583 11.75 5.61458 10.6188H3.33333C3.42083 12.9625 5.03333 14.3563 7.60833 14.5688L7.60208 16ZM8.94583 12.5625L8.97083 9.13125C10.1083 9.4625 10.8333 9.9 10.8396 10.7875C10.8333 11.7125 10.0896 12.375 8.94583 12.5625ZM7.65833 6.675C6.79583 6.4 6.00833 5.94375 6.02083 5.05625C6.02708 4.25 6.63333 3.625 7.68333 3.44375L7.65833 6.675Z"
				fill={fill != null ? fill : "#466FB6"}
			/>
			<defs>
				<linearGradient
					id="dollarIcon_linear"
					x1="3.33333"
					y1="0"
					x2="13.5452"
					y2="0.250828"
					gradientUnits="userSpaceOnUse">
					<stop />
					<stop offset="1" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default DollarIcon;
