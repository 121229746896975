import styled from "@emotion/styled";

export const LoaderContainer = styled.div({
	width: "100%",
	height: "100%",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
});

export const SVGContainer = styled.div({
	display: "flex",
	justifyContent: "center",
	"& > svg": {
		width: "13rem",
		"@keyframes load-spin-block": {
			"0%": {
				transform: "rotate(0deg)",
			},
			"100%": {
				transform: "rotate(360deg)",
			},
		},
		animation: "load-spin-block 5.0s linear infinite",
	},
});

export const LoaderText = styled.p(({ theme }) => ({
	...theme.webFonts.F5,
	color: theme.colors.D2,
	textAlign: "center",
	margin: "3rem 0 0 0",
}));
