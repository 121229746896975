import React from "react";

// Types
import type IconProps from "Types/IconProps";

/** Customizable svg icon */
const HouseIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "house-icon",
	fill = "#007CBE",
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "64px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 64 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 23.6993C0 22.0147 0.848304 20.4433 2.25672 19.519L7 16.4063V5H15V11.1563L30.9027 0.720117C31.5689 0.282911 32.4311 0.28291 33.0973 0.720115L61.7433 19.519C63.1517 20.4433 64 22.0147 64 23.6993V64L37 64V53C37 52.4477 36.5523 52 36 52H28C27.4477 52 27 52.4477 27 53V64L0 64V23.6993ZM8 53C8 52.4477 8.44772 52 9 52H18C18.5523 52 19 52.4477 19 53V57C19 57.5523 18.5523 58 18 58H9C8.44771 58 8 57.5523 8 57V53ZM46 52C45.4477 52 45 52.4477 45 53V57C45 57.5523 45.4477 58 46 58H55C55.5523 58 56 57.5523 56 57V53C56 52.4477 55.5523 52 55 52H46ZM8 35C8 34.4477 8.44772 34 9 34H18C18.5523 34 19 34.4477 19 35V39C19 39.5523 18.5523 40 18 40H9C8.44771 40 8 39.5523 8 39V35ZM46 34C45.4477 34 45 34.4477 45 35V39C45 39.5523 45.4477 40 46 40H55C55.5523 40 56 39.5523 56 39V35C56 34.4477 55.5523 34 55 34H46Z"
				fill={fill != null ? fill : "#007CBE"}
			/>
		</svg>
	);
};

export default HouseIcon;
