import styled from "@emotion/styled";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

// Styles
export const Container = styled.div<{
	backgroundColor: "primary" | "secondary";
}>(({ theme, backgroundColor }) => ({
	background: theme.colors.White,
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	color: theme.colors.grey500,
	gap: "0.1rem",
	overflow: "hidden",
	borderRadius: "0.8rem",
	[MediaQueries[1]]: {
		...theme.webFonts.F6,
		...theme.webFonts.bold,
		border: `0.1rem solid ${theme.colors.grey200}`,
		padding: "0.8rem 1.6rem",
	},
	"& input[type='radio']": {
		display: "none",
	},
	"& label": {
		lineHeight: 1,
		padding: "0.8rem",
		overflow: "hidden",
		width: "3.2rem",
		height: "3.2rem",
		borderRadius: "50%",
		cursor: "pointer",
		background: theme.colors.grey50,
		transition: "all 0.2s",
	},
	"& input[type='radio']:checked+label": {
		background:
			backgroundColor === "primary"
				? theme.colors.Blue400
				: theme.colors.Grape400,
		color: theme.colors.grey50,
		cursor: "default",
	},
}));

export const Text = styled.span({
	display: "none",
	[MediaQueries[1]]: { display: "inline" },
	marginRight: "0.8rem",
});

export const SelectCircle = styled.button({});
