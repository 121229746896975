import React from "react";

// Types
import IconProps from "Types/IconProps";

export const FilterIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "filter-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "16px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 16 17"
			fill={fill || "currentColor"}
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_4889_8908FILTERIcon)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M2.25 11.5C1.00736 11.5 0 12.5074 0 13.75C0 14.9926 1.00736 16 2.25 16C3.22966 16 4.06309 15.3739 4.37197 14.5H15.1111C15.602 14.5 16 14.1642 16 13.75C16 13.3358 15.602 13 15.1111 13H4.37197C4.06309 12.1261 3.22966 11.5 2.25 11.5ZM2.25 14.75C1.99639 14.75 1.76483 14.6556 1.58854 14.5C1.38094 14.3168 1.25 14.0487 1.25 13.75C1.25 13.4513 1.38094 13.1832 1.58854 13C1.76483 12.8444 1.99639 12.75 2.25 12.75C2.50361 12.75 2.73517 12.8444 2.91146 13C3.11906 13.1832 3.25 13.4513 3.25 13.75C3.25 14.0487 3.11906 14.3168 2.91146 14.5C2.73517 14.6556 2.50361 14.75 2.25 14.75Z"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.75 6C11.7703 6 10.9369 6.62611 10.628 7.5H0.888889C0.397969 7.5 0 7.83579 0 8.25C0 8.66421 0.39797 9 0.888889 9H10.628C10.9369 9.87389 11.7703 10.5 12.75 10.5C13.7297 10.5 14.5631 9.87389 14.872 9H15.1111C15.602 9 16 8.66421 16 8.25C16 7.83579 15.602 7.5 15.1111 7.5H14.872C14.5631 6.62611 13.7297 6 12.75 6ZM12.75 9.25C12.4964 9.25 12.2648 9.15559 12.0885 9C11.8809 8.81676 11.75 8.54867 11.75 8.25C11.75 7.95133 11.8809 7.68324 12.0885 7.5C12.2648 7.34441 12.4964 7.25 12.75 7.25C13.0036 7.25 13.2352 7.34441 13.4115 7.5C13.6191 7.68324 13.75 7.95133 13.75 8.25C13.75 8.54867 13.6191 8.81676 13.4115 9C13.2352 9.15559 13.0036 9.25 12.75 9.25Z"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4.75 0.5C3.77034 0.5 2.93691 1.12611 2.62803 2H0.888889C0.397969 2 0 2.33579 0 2.75C0 3.16421 0.39797 3.5 0.888889 3.5H2.62803C2.93691 4.37389 3.77034 5 4.75 5C5.72966 5 6.56309 4.37389 6.87197 3.5H15.1111C15.602 3.5 16 3.16421 16 2.75C16 2.33579 15.602 2 15.1111 2H6.87197C6.56309 1.12611 5.72966 0.5 4.75 0.5ZM4.75 3.75C4.49639 3.75 4.26483 3.65559 4.08854 3.5C3.88094 3.31676 3.75 3.04867 3.75 2.75C3.75 2.45133 3.88094 2.18324 4.08854 2C4.26483 1.84441 4.49639 1.75 4.75 1.75C5.00361 1.75 5.23517 1.84441 5.41146 2C5.61906 2.18324 5.75 2.45133 5.75 2.75C5.75 3.04867 5.61906 3.31676 5.41146 3.5C5.23517 3.65559 5.00361 3.75 4.75 3.75Z"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4889_8908FILTERIcon">
					<rect
						width="16"
						height="16"
						fill="white"
						transform="translate(0 0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default FilterIcon;
