import React from "react";

// Types
import type IconProps from "Types/IconProps";

export const TilesIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "tiles-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "16px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.5 0C0.671573 0 0 0.671573 0 1.5V5.5C0 6.32843 0.671573 7 1.5 7H5.5C6.32843 7 7 6.32843 7 5.5V1.5C7 0.671573 6.32843 0 5.5 0H1.5ZM10.5 0C9.67157 0 9 0.671573 9 1.5V5.5C9 6.32843 9.67157 7 10.5 7H14.5C15.3284 7 16 6.32843 16 5.5V1.5C16 0.671573 15.3284 0 14.5 0H10.5ZM0 10.5C0 9.67157 0.671573 9 1.5 9H5.5C6.32843 9 7 9.67157 7 10.5V14.5C7 15.3284 6.32843 16 5.5 16H1.5C0.671573 16 0 15.3284 0 14.5V10.5ZM10.5 9C9.67157 9 9 9.67157 9 10.5V14.5C9 15.3284 9.67157 16 10.5 16H14.5C15.3284 16 16 15.3284 16 14.5V10.5C16 9.67157 15.3284 9 14.5 9H10.5Z"
				fill={fill || "currentColor"}
			/>
		</svg>
	);
};

export default TilesIcon;
