import styled from "@emotion/styled";

// Types
import { ColorProps, ErrorProps } from "./types";

export const Container = styled.div(() => ({
	position: "relative",
}));

export const InputBox = styled.div(() => ({
	display: "flex",
	alignItems: "stretch",
	width: "100%",
}));

export const Input = styled.input(() => ({
	visibility: "hidden",
	width: 0,
	height: 0,
}));

export const UploadInputButton = styled.label<ColorProps>(
	({ theme, border, disabled }) => ({
		...theme.webFonts.F6Bold,
		display: "inline-block",
		cursor: "pointer",
		borderRadius: "0.8rem",
		textAlign: "center",
		minHeight: "4rem",
		padding: "1rem 2rem 0.9rem 2rem",
		boxShadow: `inset 0 0 0 0.1rem ${theme.colors.primary}, 0 0 0.8rem 0 ${theme.colors.Black10}, 0 0.1rem 0.3rem 0 ${theme.colors.Black15}`,
		background: border ? theme.colors.White : theme.colors.primary,
		color: border ? theme.colors.primary : theme.colors.White,
		transition: "background 0.2s ease-out, color 0.2s ease-in",
		...(disabled && {
			...theme.webFonts.F6SemiBold,
			cursor: "default",
			background: theme.colors.D4,
			border: theme.colors.D4,
			opacity: 0.5,
			color: theme.colors.D2,
		}),
		"&:hover, :focus": {
			outline: "0 !important",
			boxShadow: `inset 0 0 0 0.2rem ${theme.colors.primary}, 0 0 0.8rem 0 ${theme.colors.Black10}, 0 0.1rem 0.3rem 0 ${theme.colors.Black15}`,
			background: theme.colors.White,
			color: theme.colors.primary,
		},
		"&:active": {
			transform: "translateY(0.2rem) scale(0.98)",
		},
	}),
);

export const FileNameBox = styled.div(() => ({
	position: "relative",
	flexGrow: 1,
}));

export const FileInfo = styled.div(({ theme }) => ({
	...theme.webFonts.F6,
	color: theme.colors.D3,
}));

export const FileName = styled.span<ColorProps>(({ theme, error }) => ({
	position: "absolute",
	top: "50%",
	padding: "0 1.5rem",
	transform: "translateY(-50%)",
	width: "100%",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis",
	color: theme.colors.D3,
	...theme.webFonts.F6,
	background: "white",
	"&:disabled": {
		...theme.webFonts.F6SemiBold,
		cursor: "default",
		background: theme.colors.Black10,
		opacity: 0.7,
		boxShadow: "none",
		color: theme.colors.D2,
	},
	"&:focus": {
		boxShadow: `0 0 0.8rem ${theme.colors.Black05}, 0 0.1rem 0.3rem ${theme.colors.Black10}`,
		border: `0.1rem solid ${
			error != null && error !== "" ? theme.colors.Red : theme.colors.primary
		}`,
		background: "white",
		color: error != null && error !== "" ? theme.colors.Red : theme.colors.D1,
	},
}));

export const Error = styled.span<ErrorProps>(({ theme, error, border }) => ({
	color: theme.colors.Red,
	...theme.webFonts.F6,
	display: error != null && error !== "" ? "auto" : "none",
	left: border === true ? "7px" : "3px",
	bottom: "-2rem",
	wordBreak: "break-word",
}));

export const DragAndDropBox = styled.div(({ theme }) => ({
	...theme.webFonts.F5,
	display: "flex",
	justifyContent: "center",
	gap: "2rem",
	alignItems: "center",
	border: `1px dashed ${theme.colors.D3}`,
	padding: "4rem",
	paddingTop: "5.2rem",
	color: theme.colors.D2,
	background: theme.colors.Black05,
	marginBottom: "3.1rem",
	borderRadius: "0.4rem",
}));

export const DragHint = styled.span(({ theme }) => ({
	...theme.webFonts.F5,
	color: theme.colors.D3,
}));

export const FlexRow = styled.div(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	borderBottom: `1px solid ${theme.colors.Black15}`,
}));

export const ImageBox = styled.div(() => ({
	flexShrink: 0,
	width: "4rem",
	height: "4rem",
	margin: "0.5rem 2rem",
}));

export const MarginLeft = styled.div(() => ({
	marginLeft: "1.6rem",
	paddingRight: "0.5rem",
}));
