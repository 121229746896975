import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// CC
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import CCContainer from "CCW-Components/CCW-Container";
import { DropDownInputConverter as DropDown } from "CCW-Components/CCW-FIGDropDownInputNew";
import PaginationNavBar from "CCW-Components/CCW-PaginationNavBar";
import FactorRow from "CCW-Components/CCW-FactorRow";
import AppError from "CCW-Components/CCW-AppError";
import BlockLoader from "CCW-Components/CCW-BlockLoader";

// Hooks
import useGetEmissionFactors from "Hooks/UseGetEmissionFactors";
import useUtilities from "Hooks/UseUtilities";
import { useTheme } from "@emotion/react";

// Helpers
import swrErrorCheck from "Helpers/FormatHelpers/SWRErrorCheck";
import sentenceCase from "Helpers/StringHelpers/SentenceCase";
import {
	getAvailableMethods,
	getInitialMethod,
	getMethodDropDownOptions,
} from "Helpers/FactorHelpers";
import { isEmissionTypeWithUtility } from "Pages/Measure/Helpers";

// Styles
import {
	FactorsNavBar,
	PageTitle,
	SearchBarContainer,
	SearchBar,
	SearchBarIcon,
	RowContainer,
	BreakdownColumn,
	AddButton,
	FactorBox,
	FactorBreakdown,
	FactorBreakdownContainer,
	FactorConstituent,
} from "./styles";

// Types
import type DefaultEmissionFactorsPageProps from "./types";
import type {
	GeneralMethod,
	PaperMethod,
	FlightMethod,
	ElectricityMethod,
	FuelBasedMethod,
	AccommodationMethod,
} from "Types/EmissionFactors";
import type { State } from "Types/Address";
import { MeasureCategory } from "Types/MeasureCategory";
import UploadCSVForm from "CCW-Components/CCW-UploadCSVForm";
import ModalBanner from "CCW-Components/CCW-ModalBanner";
import postReq from "Helpers/NetworkingHelpers/PostReq";
import useAlert from "Hooks/UseAlert";
import isError from "Helpers/TypeHelpers/IsError";
import getCSVTemplate from "Helpers/NetworkingHelpers/GetCSVTemplate";

export const DefaultEmissionFactorsPage: React.FC<
	DefaultEmissionFactorsPageProps
> = () => {
	// State
	const [selectedMethod, setSelectedMethod] = useState<
		| GeneralMethod
		| PaperMethod
		| FlightMethod
		| ElectricityMethod
		| FuelBasedMethod
		| AccommodationMethod
	>("location");

	const { emissionType = "electricity" } = useParams<{
		emissionType: MeasureCategory;
	}>();

	const searchParams = new URLSearchParams(location.search);

	const [selectedState, setSelectedState] = useState<State | undefined>();
	const [searchQuery, setSearchQuery] = useState<string | undefined>();
	const [templateLinkHRef, setTemplateLinkHRef] = useState<
		string | undefined
	>();

	// Hooks
	const theme = useTheme();
	const navigate = useNavigate();
	const [utilities] = useUtilities(
		selectedState,
		isEmissionTypeWithUtility(emissionType) ? emissionType : undefined,
	);

	const paginationLimit = parseInt(searchParams.get("perPage") || "") || 10;
	const paginationOffset = parseInt(searchParams.get("page") || "") || 1;
	const [
		emissionFactors,
		emissionFactorsError,
		emissionFactorsMutate,
		,
		,
		pagination,
	] = useGetEmissionFactors(emissionType, selectedMethod, selectedState, {
		limit: paginationLimit,
		offset: paginationOffset,
	});
	const [, reportAlert] = useAlert();

	useEffect(() => {
		(async () => {
			if (emissionType) {
				try {
					const templateURL = await getCSVTemplate(
						emissionType,
						"emission-factors",
					);

					if (templateURL) {
						setTemplateLinkHRef(templateURL);
					}
				} catch {
					null;
				}
			}
		})();
	}, [emissionType]);

	// Helpers
	const searchCategoryKeywords = () => {
		switch (selectedMethod) {
			case "location":
				return "postcode";
			case "market":
				return "supplier";
			case "market-average":
				return "country";
			default:
				return "year";
		}
	};

	const isStateIncluded = !(
		emissionType === "paper" ||
		emissionType === "flight" ||
		emissionType === "stationary-fuels" ||
		emissionType === "transport" ||
		selectedMethod === "location-australia" ||
		(emissionType === "accommodation" && selectedMethod === "stars")
	);

	useEffect(() => {
		if (!getAvailableMethods(emissionType)?.includes(selectedMethod)) {
			setSelectedMethod(getInitialMethod(emissionType));
		}
	}, [emissionType, selectedMethod]);

	// Normal state
	return (
		<DefaultPage>
			<CCContainer>
				<PageTitle>Carbon Emission Factors</PageTitle>
				<FactorsNavBar>
					<SearchBarContainer>
						<SearchBarIcon
							width="16px"
							height="16px"
							fill={theme.colors.primary}
						/>
						<SearchBar
							name="search-bar"
							value={searchQuery}
							onFocusCapture={() => setSearchQuery("")}
							onChange={({ currentTarget: { value } }) => {
								setSearchQuery(value);
							}}
							placeholder={`Try searching a ${searchCategoryKeywords()}...`}
						/>
					</SearchBarContainer>
					<DropDown
						name="stream-type"
						value={emissionType}
						plain
						placeholder="Select stream"
						inlineLabel="Stream"
						onSelect={(_name, value) =>
							navigate({
								...location,
								pathname: `/factors/${value}`,
								search: undefined,
								hash: "",
							})
						}
						options={[
							{ label: "Electricity", value: "electricity" },
							{ label: "Gas", value: "gas" },
							{ label: "Water", value: "water" },
							{ label: "LPG", value: "lpg" },
							{ label: "Waste", value: "waste" },
							{ label: "Flight", value: "flight" },
							{ label: "Paper", value: "paper" },
							{ label: "Stationary Fuels", value: "stationary-fuels" },
							{ label: "Transport", value: "transport" },
							{ label: "Accommodation", value: "accommodation" },
						]}
					/>
					<DropDown
						name="method"
						value={selectedMethod}
						plain
						placeholder="Select method"
						inlineLabel="Method"
						onSelect={(_, value) => {
							setSelectedMethod(
								value as
									| GeneralMethod
									| PaperMethod
									| FlightMethod
									| ElectricityMethod
									| FuelBasedMethod
									| AccommodationMethod,
							);
						}}
						options={getMethodDropDownOptions(emissionType)}
					/>
					{isStateIncluded && (
						<>
							<DropDown
								name="state"
								value={selectedState || ""}
								plain
								inlineLabel="State"
								onSelect={(_, value) => {
									setSelectedState(value as State);
								}}
								options={[
									{ label: "ALL", value: "" },
									{ label: "WA", value: "WA" },
									{ label: "NSW", value: "NSW" },
									{ label: "NT", value: "NT" },
									{ label: "QLD", value: "QLD" },
									{ label: "SA", value: "SA" },
									{ label: "TAS", value: "TAS" },
									{ label: "ACT", value: "ACT" },
									{ label: "VIC", value: "VIC" },
								]}
							/>
						</>
					)}
					<AddButton
						variant="primary"
						block={false}
						name="add-factor"
						onClick={() => navigate("#upload")}>
						Upload Factor CSV
					</AddButton>
				</FactorsNavBar>
				{emissionFactorsError ? (
					<AppError error={swrErrorCheck([emissionFactorsError])} />
				) : (!emissionFactorsError && !emissionFactors) || !utilities ? (
					<BlockLoader message="Loading your details..." />
				) : (
					<RowContainer>
						{!!emissionFactors &&
							emissionFactors.map((factor, index) => {
								const availableScopes =
									factor.factors && Object.entries(factor.factors);

								const scopeData = (selection: "title" | "breakdown") =>
									availableScopes?.map((scope, index) => {
										const [
											scopeNumber,
											{
												co2co2e: scopeCarbon = 0,
												n2oco2e: scopeNitrous = 0,
												ch4co2e: scopeMethane = 0,
												total: scopeTotal = 0,
											},
										] = scope;
										const scopeTitle = () => {
											switch (scopeNumber) {
												case "scope1":
													return "Scope One";
												case "scope2":
													return "Scope Two";
												case "scope3":
													return "Scope Three";
												case "total":
													return "Factor Total";
											}
										};

										if (selection === "title") {
											return (
												<FactorBox key={index}>
													<span>{scopeTitle()}</span> {scopeTotal.toFixed(2)}
												</FactorBox>
											);
										} else if (selection === "breakdown") {
											return (
												<BreakdownColumn key={index}>
													<FactorBox>
														<span>{scopeTitle()}</span> {scopeTotal.toFixed(2)}
													</FactorBox>
													<FactorBreakdown>
														<FactorConstituent>
															<span>Carbon: </span>
															{scopeCarbon ? scopeCarbon.toFixed(2) : " -"}
														</FactorConstituent>
														<FactorConstituent>
															<span>Nitrous: </span>
															{scopeNitrous ? scopeNitrous.toFixed(2) : " -"}
														</FactorConstituent>
														<FactorConstituent>
															<span>Methane: </span>
															{scopeMethane ? scopeMethane.toFixed(2) : " -"}
														</FactorConstituent>
														<FactorConstituent>
															<span>Total: </span>
															{scopeTotal ? scopeTotal.toFixed(2) : " -"}
														</FactorConstituent>
													</FactorBreakdown>
												</BreakdownColumn>
											);
										}
									});

								// Filters
								if (factor.method === "location") {
									// State filter
									if (
										selectedState &&
										"state" in factor &&
										factor.state !== selectedState
									) {
										return;
									}
								}

								// Quick search filter

								if (searchQuery) {
									const searchValue = new RegExp(searchQuery, "i"); // Search query converted RegEx with a "non-case-sensitive" flag (i)
									const globalSearchProperties =
										factor?.country?.match(searchValue) ||
										factor?._id?.match(searchValue) ||
										factor?.yearType?.match(searchValue) ||
										factor?.year?.toString().match(searchValue);
									// Location based search
									if (
										emissionType !== "accommodation" &&
										!("location" in factor) && // This checks that the factor doesn't use a broad location (i.e. Metro) such as in Accommodation
										factor?.method === "location" &&
										!globalSearchProperties &&
										!(
											("postcode" in factor &&
												factor?.postcode?.toString().match(searchValue)) ||
											("postcodeAlias" in factor &&
												factor?.postcodeAlias?.match(searchValue)) ||
											("state" in factor && factor?.state?.match(searchValue))
										)
									) {
										return;
									}

									// Market Based search
									if (
										factor.method === "market" &&
										!globalSearchProperties &&
										!utilities
											?.find((utility) => utility._id === factor.utility)
											?.name.match(searchValue)
									) {
										return;
									}

									// Market Average search
									if (
										factor.method === "market-average" &&
										!globalSearchProperties
									) {
										return;
									}
								}

								const rowHeading = (() => {
									switch (factor.method) {
										case "location":
											return (
												("postcode" in factor && factor.postcode) ||
												("postcodeAlias" in factor && factor.postcodeAlias) ||
												("location" in factor &&
													`${sentenceCase(factor.location)} (${
														factor.year
													})`) ||
												""
											);
										case "market":
											return (
												(utilities &&
													utilities.find(
														(utility) => utility._id === factor.utility,
													)?.name) ||
												"Supplier unknown"
											);
										case "stars":
											return `${factor.stars} Stars (${factor.year})`;
										case "fuel":
											return `${sentenceCase(factor.fuelType)} (${
												factor.year
											})`;
										case "distance":
											return `${
												(factor.class === "average" && "Average") ||
												(factor.class === "business" && "Business Class") ||
												(factor.class === "first" && "First Class") ||
												sentenceCase(factor.class)
											} (${factor.year})`;
										case "market-average":
											return `${factor.country} (${factor.state})`;
										default:
											return `${factor.country} (${factor.year})`;
									}
								})();

								return (
									<>
										<div>
											<FactorRow
												key={index}
												headingText={rowHeading}
												rowData={factor}
												index={index}
												actionTagText="Show Factors"
												actionTagPopupContent={
													<FactorBreakdownContainer>
														{scopeData("title")}
													</FactorBreakdownContainer>
												}
												actionTagExpansion={
													<FactorBreakdownContainer>
														{scopeData("breakdown")}
													</FactorBreakdownContainer>
												}
												propertyTags={[
													"method",
													"location",
													"paperType",
													"stars",
													"fuelType",
													"commercial",
													"distance",
													"class",
													"country",
													"state",
													"year",
													"yearType",
												]}
											/>
										</div>
									</>
								);
							})}
						{pagination && (
							<PaginationNavBar
								selectedPage={paginationOffset}
								pageCount={paginationLimit}
							/>
						)}
					</RowContainer>
				)}

				<ModalBanner
					id="upload"
					matchType="hash"
					headline="Upload Factor CSV"
					returnUrl={{ hash: "" }}>
					<UploadCSVForm
						templateLinkHRef={templateLinkHRef}
						onSubmit={async (CSVs: File[]) => {
							try {
								const form = new FormData();
								form.append("csv-upload", CSVs[0]);
								await postReq(
									`${import.meta.env.VITE_BASE_URL}measure/emission-factors`,
									form,
									1,
									undefined,
									true,
									"MULTIPART",
								);
								reportAlert(
									"Factors successfully uploaded and processed",
									"success",
								);
								emissionFactorsMutate();
							} catch (error) {
								reportAlert(
									isError(error) ? error.message : "Had an error, try again",
									"error",
								);
							}
						}}
						onCancel={() => navigate({ hash: "" })}
					/>
				</ModalBanner>
			</CCContainer>
		</DefaultPage>
	);
};

export default DefaultEmissionFactorsPage;
