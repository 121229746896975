import React, { useState } from "react";
import { Formik, Form } from "formik";

// CC
import ButtonLink from "CCW-Components/CCW-ButtonLink";
import FIGPassword from "CCW-Components/CCW-FIGPassword";
import FIGPasswordStrength from "CCW-Components/CCW-FIGPasswordStrength";

// Hooks
import useAlert from "Hooks/UseAlert";

// Helpers
import isError from "Helpers/TypeHelpers/IsError";
import resetPassword from "Helpers/NetworkingHelpers/ResetPassword";

// Styles
import { Card, Button } from "./styles";

// Types
import type ResetPasswordFormProps from "./types";

// Data
import { initValues, validation } from "./FormLogic";

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
	token,
	...props
}) => {
	// State
	const [success, setSuccess] = useState(false);

	// Hooks
	const [, reportAlert] = useAlert();

	if (!success) {
		return (
			<Card
				title="Change password"
				subtitle="Enter you new password below"
				{...props}>
				<Formik
					initialValues={initValues}
					validationSchema={validation}
					onSubmit={async (values, { setSubmitting }) => {
						setSubmitting(true);
						try {
							await resetPassword({ ...values, token });
							setSuccess(true);
						} catch (err) {
							setSubmitting(false);
							reportAlert(
								isError(err)
									? err.message
									: "There was an issue resetting your password. Please contact us at support@climateclever.org",
								"error",
							);
						}
					}}>
					{({ isSubmitting }) => (
						<Form>
							<FIGPasswordStrength
								id="reset-password"
								name="password"
								label="Password"
								type="password"
							/>
							<FIGPassword
								id="reset-confirm-password"
								name="confirmPassword"
								label="Confirm Password"
								type="password"
							/>
							<Button
								variant="primary"
								type="submit"
								loading={isSubmitting}
								block>
								Submit
							</Button>
						</Form>
					)}
				</Formik>
			</Card>
		);
	} else {
		return (
			<Card
				title="Password successfully changed!"
				subtitle="Click in the Login button below and log to your account using your new password.">
				<ButtonLink to="/login" type="button" block>
					Login
				</ButtonLink>
			</Card>
		);
	}
};

export default ResetPasswordForm;
