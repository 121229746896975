import styled from "@emotion/styled";

import mq from "Helpers/WebUIHelpers/Breakpoints";

export const Content = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	"& > svg": {
		transform: "scale(0.5)",
	},
	[mq[1]]: {
		"& > svg": {
			transform: "scale(1)",
		},
	},
}));

export const Text = styled.p(({ theme }) => ({
	...theme.webFonts.F4,
	color: theme.colors.D2,
	textAlign: "center",
	marginTop: "0px",
	[mq[1]]: {
		marginTop: "30px",
		maxWidth: "70%",
	},
}));
