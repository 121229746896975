import React from "react";

// Types
import type IconProps from "Types/IconProps";

/** Customizable svg icon */
const FileIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "file-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "16px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M14 1V9C14 14 11.9 16 7.4 16H3C2.44772 16 2 15.5523 2 15V1C2 0.447715 2.44772 0 3 0H13C13.5523 0 14 0.447715 14 1Z"
				fill={fill != null ? fill : "#AC50DA"}
			/>
			<path
				d="M12 11.6667C11.0061 12.0036 9.33333 11.3333 9.33333 11.3333C9.33333 11.3333 9.8815 13.2979 9.33333 14.3333C8.65237 15.6196 6 16 6 16C10.4183 16 14 12.4183 14 8C14 8 13.5447 11.143 12 11.6667Z"
				fill={fill != null ? fill : "#DC95FF"}
			/>
			<rect x="4" y="2" width="4" height="1.33333" rx="0.666667" fill="white" />
			<rect
				x="4"
				y="4.6665"
				width="8"
				height="1.33333"
				rx="0.666667"
				fill="white"
			/>
			<rect
				x="4"
				y="7.3335"
				width="4.66667"
				height="1.33333"
				rx="0.666667"
				fill="white"
			/>
			<rect
				x="10"
				y="7.3335"
				width="2"
				height="1.33333"
				rx="0.666667"
				fill="white"
			/>
		</svg>
	);
};

export default FileIcon;
