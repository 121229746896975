import { useContext } from "react";

// Context
import AlertContext from "Contexts/AlertContext";

// Types
import type { Alert, ReportAlert, ClearAlert } from "Types/Alert";

/**
 * Value hook
 * Return the current platform alert if there is one
 */
const useAlert = () => {
	const alertContext = useContext(AlertContext);

	if (!alertContext) {
		throw new Error("Alert hook has been used outside of the AlertContext");
	}

	return [
		alertContext.alerts,
		alertContext.reportAlert,
		alertContext.clearAlert,
	] as [Alert[] | undefined, ReportAlert, ClearAlert];
};

export default useAlert;
