import useSWR from "swr";

// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";

// Types
import type {
	APIResponseDeprecated,
	DataHookResponseDeprecated,
} from "Types/JSONApi";
import type { RenewableEnergyTarget } from "Types/RenewableEnergyTargets";

/**
 * Data hook - V1
 * Get the Renewable Energy Targets for all states
 * @param Page is the page number to return
 * @returns a tuple of RenewableEnergyTarget[], Error and SWR's Mutate
 */

const useRenewableEnergyTargets = (
	page: number,
): DataHookResponseDeprecated<RenewableEnergyTarget[]> => {
	const { data, error, mutate } = useSWR<
		APIResponseDeprecated<RenewableEnergyTarget[]>,
		Error
	>(
		[
			`${
				import.meta.env.VITE_BASE_URL
			}measure/emissions/renewable-energy-targets?page=${page}`,
			1,
		],
		getReq,
		{
			shouldRetryOnError: false,
		},
	);

	return [data?.data, error, mutate, data?.pagination];
};

export default useRenewableEnergyTargets;
