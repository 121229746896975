import styled from "@emotion/styled";

// Breakpoints
import mq from "Helpers/WebUIHelpers/Breakpoints";

// CC
import Container from "../CCW-Container";

export const ErrorContainer = styled(Container)(() => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	marginTop: "8rem",
	"@keyframes app-error-slide": {
		"0%": {
			opacity: 0,
			transform: "translateY(0)",
		},
		"80%": {
			opacity: 1,
		},
		"100%": {
			opacity: 1,
			transform: "translateY(3rem)",
		},
	},
	animation: "app-error-slide 0.7s ease forwards",
}));

export const Title = styled.h2(({ theme }) => ({
	...theme.webFonts.h2,
	color: theme.colors.D1,
	textAlign: "center",
}));

export const Text = styled.p(({ theme }) => ({
	...theme.webFonts.F4,
	color: theme.colors.D1,
	textAlign: "center",
	marginTop: "0px",
	[mq[1]]: {
		maxWidth: "67%",
	},
}));

export const ButtonContainer = styled.div(() => ({
	display: "flex",
	justifyContent: "center",
	gap: "2rem",
}));

export const ButtonLink = styled.a(({ theme }) => ({
	...theme.webFonts.F6Bold,
	textDecoration: "none",
	cursor: "pointer",
	borderRadius: "0.8rem",
	padding: "1rem 2rem 1.1rem 2rem",
	background: theme.colors.White,
	boxShadow: `inset 0 0 0 0.1rem ${theme.colors.primary}, 0 0 0.8rem 0 ${theme.colors.Black10}, 0 0.1rem 0.3rem 0 ${theme.colors.Black15}`,
	color: theme.colors.primary,
	transition: "border 0.2s ease-out, padding 0.2s",
	"&:hover,:focus": {
		boxShadow: `inset 0 0 0 0.2rem ${theme.colors.primary}, 0 0 0.8rem 0 ${theme.colors.Black10}, 0 0.1rem 0.3rem 0 ${theme.colors.Black15}`,
	},
	"&:active": {
		background: theme.colors.primary,
		color: theme.colors.White,
		boxShadow: `inset 0 0 0.3rem 0 ${theme.colors.Black20}, inset 0 0.4rem 0.3rem 0 ${theme.colors.Black30}`,
	},
}));
