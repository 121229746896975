import styled from "@emotion/styled";
import { Theme } from "@emotion/react";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

// Types
import type { StyledProps, StyledBorderProps, ColorVariant } from "./types";

const blockStyle = (block: boolean) =>
	block ? { display: "block", width: "100%" } : {};

const boxShadowWithInset = (inset: number, color: string, theme: Theme) => {
	return `inset 0 0 0 ${inset}rem ${color}, 0 0 0.8rem 0 ${theme.colors.Black10}, 0 0.1rem 0.3rem 0 ${theme.colors.Black15}`;
};

const variateColor = (theme: Theme, variation?: ColorVariant) =>
	variation === "blue"
		? theme.colors.Blue400
		: variation === "grape"
		? theme.colors.Grape400
		: theme.colors.Blueberry400;

const Button = styled.button<StyledBorderProps>(
	({ theme: { webFonts, colors }, block, disabled }) => {
		return {
			...blockStyle(block),
			...webFonts.F5Bold,
			outline: "none",
			border: "none",
			cursor: disabled ? "default" : "pointer",
			borderRadius: "0.8rem",
			textAlign: "center",
			minHeight: "4rem",
			padding: "1rem 2rem 1.1rem 2rem",
			boxShadow: `0 0 0.8rem 0 ${colors.Black10}, 0 1px 3px 0 ${colors.Black15}`,
			"&:hover, :focus": !disabled && {
				outline: "0 !important",
			},
			"&:active": !disabled && {
				transform: "translateY(2px) scale(0.98)",
				boxShadow: `inset 0 0 0.3rem 0 ${colors.Black20}, inset 0 0.4rem 0.3rem 0 ${colors.Black30}`,
			},
			[MediaQueries[1]]: {
				...webFonts.F6Bold,
			},
		};
	},
);

export const PrimaryButton = styled(Button)<StyledBorderProps>(
	({ theme, disabled, border, colorVariant, customColor }) => {
		const mainColor = !border
			? customColor?.mainColor || variateColor(theme, colorVariant)
			: theme.colors.White;

		const alternativeColor = border
			? customColor?.mainTextColor ||
			  customColor?.mainColor ||
			  variateColor(theme, colorVariant)
			: theme.colors.White;

		return {
			boxShadow: border
				? boxShadowWithInset(0.1, alternativeColor, theme)
				: theme.shadow,
			background: disabled ? theme.colors.Black10 : mainColor,
			color: disabled
				? theme.colors.D4
				: customColor?.mainTextColor || alternativeColor,
			transition: "background 0.1s ease-out, color 0.1s ease-in",
			"&:hover, :focus": !disabled && {
				background: customColor?.hoverColor || alternativeColor,
				color: customColor?.hoverTextColor || mainColor,
				boxShadow:
					border || customColor
						? "none"
						: boxShadowWithInset(0.2, mainColor, theme),
			},
			"&:active": !disabled && {
				background: customColor?.clickColor || mainColor,
				boxShadow: customColor
					? "none"
					: `inset 0 0 0.3rem 0 ${theme.colors.Black20}, inset 0 0.4rem 0.3rem 0 ${theme.colors.Black30}`,
				color: customColor?.clickTextColor || alternativeColor,
			},
		};
	},
);

export const SecondaryButton = styled(Button)<StyledProps>(
	({ theme, disabled, colorVariant, customColor }) => {
		const color = disabled
			? theme.colors.D4
			: customColor?.mainTextColor ||
			  customColor?.mainColor ||
			  variateColor(theme, colorVariant);

		const mainColor = disabled
			? theme.colors.Black10
			: customColor?.mainColor || theme.colors.White;

		return {
			background: mainColor,
			boxShadow:
				disabled || customColor
					? "none"
					: boxShadowWithInset(0.1, color, theme),
			color,
			transition: "border 0.2s ease-out, padding 0.2s",
			"&:hover,:focus": !disabled && {
				background: customColor ? customColor.hoverColor : mainColor,
				boxShadow: customColor ? "none" : boxShadowWithInset(0.2, color, theme),
			},
			"&:active": !disabled && {
				background: color,
				color: theme.colors.White,
				boxShadow: `inset 0 0 0.3rem 0 ${theme.colors.Black20}, inset 0 0.4rem 0.3rem 0 ${theme.colors.Black30}`,
			},
		};
	},
);

export const WashedButton = styled(Button)<StyledProps>(
	({ theme, disabled, customColor }) => ({
		boxShadow: theme.shadow,
		background: customColor?.mainColor || theme.colors.Black10,
		color: disabled
			? theme.colors.D2
			: customColor?.mainTextColor || theme.colors.D3,
		opacity: disabled ? 0.6 : 1,
		transition: "background 0.2s ease-out, color 0.2s ease-in",
		"&:hover,:focus": !disabled && {
			background: theme.colors.D3,
			color: theme.colors.White,
		},
		"&:active": !disabled && {
			background: theme.colors.Black10,
			color: theme.colors.D3,
			boxShadow: `inset 0 0 0.3rem 0 ${theme.colors.Black20}, inset 0 0.4rem 0.3rem 0 ${theme.colors.Black30}`,
		},
	}),
);

export const DeleteButton = styled(Button)<StyledBorderProps>(
	({ theme, disabled, isPrimary }) => {
		const color = disabled ? theme.colors.D4 : theme.colors.Red;
		return {
			background: disabled
				? theme.colors.Black10
				: isPrimary // if delete button is the primary action, make it solid red
				? color
				: theme.colors.White,
			boxShadow: disabled ? "none" : boxShadowWithInset(0.1, color, theme),
			color: isPrimary ? theme.colors.White : color,
			transition: "boxShadow 0.2s ease-out, padding 0.2s",
			"&:hover,:focus": !disabled && {
				boxShadow: boxShadowWithInset(0.2, color, theme),
			},
			"&:active": !disabled && {
				background: theme.colors.Red,
				color: theme.colors.White,
				boxShadow: `inset 0 0 0.3rem 0 ${theme.colors.Black20}, inset 0 0.4rem 0.3rem 0 ${theme.colors.Black30}`,
			},
		};
	},
);

export const TextWashedLinkButton = styled(Button)<StyledBorderProps>(
	({ theme, disabled }) => ({
		boxShadow: "none",
		background: "transparent",
		color: disabled ? theme.colors.D4 : theme.colors.D3,
		"&:hover, :focus": !disabled && {
			color: theme.colors.D4,
		},
		"&:active": !disabled && {
			transform: "translateY(2px) scale(0.98)",
			boxShadow: "none",
		},
	}),
);

export const ButtonLinkWithIcon = styled(Button)<StyledBorderProps>(
	({ theme, disabled, colorVariant, customColor }) => {
		const color = disabled
			? theme.colors.D4
			: customColor?.mainColor || variateColor(theme, colorVariant);
		return {
			boxShadow: "none",
			background: "transparent",
			color,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			"& > svg": {
				marginRight: "1rem",
				...(!disabled
					? { fill: customColor?.mainColor || "currentcolor" }
					: {}),
			},
			"&:hover, :focus": !disabled && {
				color:
					customColor?.hoverColor ||
					(colorVariant ? theme.colors.Grape600 : theme.colors.Blue600),
			},
			"&:hover svg, :focus svg": !disabled && {
				fill: customColor?.hoverColor || "currentcolor",
			},
			"&:active": !disabled && {
				color: customColor?.clickColor || theme.colors.D3,
				boxShadow: "none",
			},
			"&:active svg": !disabled && {
				fill: customColor?.clickColor || "currentcolor",
			},
		};
	},
);

/** This button can be used for the different holidays adding a prop to change style. Fow now is only used in the login page */
export const HolidayButton = styled(Button)<StyledProps>(
	({ theme, disabled }) => {
		return {
			background: disabled ? theme.colors.Black10 : theme.colors.DarkOrange,
			color: disabled ? theme.colors.D4 : theme.colors.White,
			"&:hover, :focus": !disabled && {
				background: theme.colors.White,
				color: theme.colors.DarkOrange,
				boxShadow: boxShadowWithInset(0.2, theme.colors.DarkOrange, theme),
			},
		};
	},
);
