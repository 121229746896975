import { createContext } from "react";

// Types
import type { AlertContextInterface } from "Types/Alert";

const AlertContext = createContext<AlertContextInterface | undefined>(
	undefined,
);

export default AlertContext;
