import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// CC
import CCButton from "CCW-Components/CCW-CCButton";
import ModalBanner from "CCW-Components/CCW-ModalBanner";
import FIGPassword from "CCW-Components/CCW-FIGPassword";
import FIGPasswordStrength from "CCW-Components/CCW-FIGPasswordStrength";

// Styles
import { ButtonBox, Hint, Container, ErrorBox } from "./styles";
import { Error } from "CCW-Components/CCW-TextInput/styles";

// Types
import type UpdateUserPasswordProps from "./types";
import { useField, useFormikContext } from "formik";

export const UpdateUserPassword: React.FC<UpdateUserPasswordProps> = ({
	error,
	onSubmit,
	...props
}) => {
	// Hooks
	const navigate = useNavigate();
	const location = useLocation();
	const [, oldPasswordMeta, oldPasswordHelpers] = useField("oldPassword");
	const [, passwordMeta, passwordHelpers] = useField("password");
	const [, confirmPasswordMeta, confirmPasswordHelpers] =
		useField("confirmPassword");
	const [errors, setErrors] = useState<string[]>([]);
	const { validateForm } = useFormikContext();

	// Helpers
	const resetValues = async () => {
		// Reset all password values
		oldPasswordHelpers.setValue(oldPasswordMeta.initialValue);
		passwordHelpers.setValue(passwordMeta.initialValue);
		confirmPasswordHelpers.setValue(confirmPasswordMeta.initialValue);
		// Validate the form (this wasn't automatic after the above. Shrug.)
		await validateForm();
		// Set all fields as untouched
		oldPasswordHelpers.setTouched(false);
		passwordHelpers.setTouched(false);
		confirmPasswordHelpers.setTouched(false);
	};

	const closeModal = () => navigate(location.pathname);

	useEffect(() => {
		setErrors(
			[
				error,
				oldPasswordMeta.error,
				passwordMeta.error,
				confirmPasswordMeta.error,
			].filter((error) => !!error) as string[],
		);
	}, [
		error,
		oldPasswordMeta.error,
		passwordMeta.error,
		confirmPasswordMeta.error,
	]);

	return (
		<Container {...props}>
			<CCButton
				border
				variant="primary"
				onClick={() => navigate("#update-password")}>
				Update Password
			</CCButton>
			{errors.map((error) => (
				<ErrorBox key={error}>
					<Error {...{ error }}>{error}</Error>
				</ErrorBox>
			))}
			<ModalBanner
				headline="Change Password"
				id="update-password"
				matchType="hash"
				returnUrl={location.pathname}>
				<FIGPassword
					id="oldPassword"
					name="oldPassword"
					label="Current Password"
					type="password"
					required={false}
				/>
				<FIGPasswordStrength
					id="password"
					name="password"
					label="New Password"
					type="password"
					required={false}
				/>
				<FIGPassword
					id="confirmPassword"
					name="confirmPassword"
					label="Confirm New Password"
					type="password"
					required={false}
				/>
				<Hint>Password must be at least 8 characters</Hint>
				<ButtonBox>
					<CCButton
						disabled={!!(passwordMeta.error || confirmPasswordMeta.error)}
						onClick={async () => {
							await onSubmit();
							closeModal();
						}}
						type="submit">
						Save
					</CCButton>
					<CCButton
						variant="washed"
						onClick={() => {
							resetValues();
							closeModal();
						}}>
						Cancel
					</CCButton>
				</ButtonBox>
			</ModalBanner>
		</Container>
	);
};

export default UpdateUserPassword;
