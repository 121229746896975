import Platform from "Types/Platform";
import * as Yup from "yup";

export const initValues: { email: string; accountType: Platform } = {
	email: "",
	accountType: "" as Platform,
};

export const validation = Yup.object({
	email: Yup.string().email("Invalid email address").required("Required"),
	accountType: Yup.string().required("Please select an account type"),
});
