import type {
	DataHelperResponse,
	JSONApi,
	_Related,
	_Relationships,
	ResourceObject,
	Pagination,
} from "Types/JSONApi";

/**
 * getRelated is a convenience helper to map JSON API relationships to their includes data.
 * DANGER ZONE: You must supply the T generic to tell the function what type you think the
 * function will return. The way I've written the TS here will help you to some degree, but
 * it is not completely type accurate and it will let you make some mistakes, so be careful.
 * @param relationships the relationships object of the JSON API response
 * @param included the relationships object of the JSON API response
 * @returns an object with relationships mapped to includes attributes with _id's included
 * TODO write a test for this
 */
export const getRelated = <
	Related extends Includes extends ResourceObject<infer IncludesAttributes>[]
		? {
				[RelationshipKey in keyof Relationships]: Relationships[RelationshipKey]["data"] extends Array<
					infer E
				>
					? IncludesAttributes[]
					: IncludesAttributes;
		  }
		: never,
	Relationships extends _Relationships,
	Includes extends ResourceObject[],
>(
	relationships: _Relationships | undefined,
	included: ResourceObject[] | undefined,
): Related | Record<string, never> => {
	if (!relationships || !included) {
		return {};
	}
	return Object.fromEntries(
		Object.entries(relationships).map(([name, { data }]) => {
			if (!data) {
				return [];
			} else {
				const mappedRelationshipToIncludes = [data]
					.flat()
					.map(({ type, id }) => {
						const include = included.find(
							(singleInclude) =>
								singleInclude.type === type && singleInclude.id === id,
						);
						return { ...include?.attributes, _id: include?.id };
					});
				return [
					name,
					Array.isArray(data)
						? mappedRelationshipToIncludes
						: mappedRelationshipToIncludes[0],
				];
			}
		}),
	);
};

export const transformAPIResponse = <
	Response extends JSONApi<Attributes, Relationships, Includes, Meta, Related>,
	Related extends
		| _Related<Attributes, Relationships, Includes>
		| undefined = Response extends JSONApi<
		infer InferredAttributes,
		infer InferredRelationships,
		infer InferredIncludes,
		infer InferredMeta,
		infer InferedRelated
	>
		? InferedRelated
		: never,
	/** Shape of the attributes (the core data) */
	Attributes extends
		| Record<string, unknown>
		| Record<string, unknown>[] = Response extends JSONApi<
		infer InferredAttributes,
		infer InferredRelationships,
		infer InferredIncludes,
		infer InferredMeta,
		infer InferedRelated
	>
		? InferredAttributes
		: never,
	/** Shape of the root relationships */
	Relationships extends _Relationships | undefined = Response extends JSONApi<
		infer InferredAttributes,
		infer InferredRelationships,
		infer InferredIncludes,
		infer InferredMeta,
		infer InferedRelated
	>
		? InferredRelationships
		: never,
	/** Shape of the included array */
	Includes extends ResourceObject[] | undefined = Response extends JSONApi<
		infer InferredAttributes,
		infer InferredRelationships,
		infer InferredIncludes,
		infer InferredMeta,
		infer InferedRelated
	>
		? InferredIncludes
		: never,
	/** Shape of the root metadata */
	Meta extends Record<string, unknown> | undefined = Response extends JSONApi<
		infer InferredAttributes,
		infer InferredRelationships,
		infer InferredIncludes,
		infer InferredMeta,
		infer InferedRelated
	>
		? InferredMeta
		: never,
>(
	response: Response | undefined,
	/**
	 * What is the inital related value, before the response arrives?
	 * Must be an empty object or an empty array. The reason for doing this is
	 * to make destructuring work on the component side (if we give back undefined
	 * TS won't let us destructure it)
	 * */
	initialRelatedValue: Attributes extends Array<unknown>
		? []
		: Record<string, undefined>,
): DataHelperResponse<Attributes, Related, Response> => {
	if (!response) {
		return [undefined, initialRelatedValue, undefined, undefined];
	}
	const { data, included } = response;

	const transformedItems = [data]
		.flat()
		.map(({ id, attributes, relationships }) => ({
			primaryDataWithIDIncluded: { ...attributes, _id: id },
			related: getRelated(relationships, included),
		}));

	const paginationParams: Pagination = response.links?.first
		? Object.fromEntries([
				...Object.entries(response.links).map(
					([paginationKey, paginationUrl]) => [
						paginationKey,
						paginationUrl
							? parseInt(
									new URLSearchParams(
										new URL(
											typeof paginationUrl === "string"
												? paginationUrl
												: paginationUrl.href,
										).search,
									).get("page[offset]") || "",
							  )
							: null,
					],
				),
				[
					"limit",
					parseInt(
						new URLSearchParams(new URL(response.links?.first).search).get(
							"page[limit]",
						) || "",
					),
				],
		  ])
		: undefined;

	if (Array.isArray(data)) {
		return [
			transformedItems.map(
				({ primaryDataWithIDIncluded }) => primaryDataWithIDIncluded,
			) as unknown as Attributes, // `as` smell
			transformedItems.map(({ related }) => related) as Related, // `as` smell,
			response,
			paginationParams,
		];
	} else {
		return [
			transformedItems[0].primaryDataWithIDIncluded as unknown as Attributes, // `as` smell
			transformedItems[0].related as Related, // `as` smell,
			response,
			paginationParams,
		];
	}
};

/**
 * Strips out the key of any object
 * Especially useful in mocks where you want to take the _id out of an object
 *
 */
export const stripKey = <O extends Record<string, unknown>, K extends keyof O>(
	objectToStrip: O,
	...keysToStrip: K[]
): Omit<O, K> => {
	return keysToStrip.reduce((strippedObject, currentKeyToStrip) => {
		const { [currentKeyToStrip]: _, ...rest } = strippedObject;
		return rest as Omit<O, K>;
	}, objectToStrip as Omit<O, K>);
};

/**
 * Creates a JSON API object that will output the Principle Data and Related fields supplied once it is
 * run through the transformAPIResponse function.
 */
export const makeMockJSONApi = <
	T extends
		| (Record<string, unknown> & { _id: string })
		| (Record<string, unknown> & { _id: string })[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	R extends _Related<T, _Relationships, ResourceObject<any>[]> | undefined,
>(
	principleData: T,
	related?: R,
): Partial<JSONApi<T>> => {
	const getRelationshipsFromSingleRelated = (singleRelated: {
		[relatedKeys: string]: unknown;
	}) => {
		return Object.fromEntries(
			Object.entries(singleRelated).map(([relatedKey, relatedAttributes]) => [
				relatedKey,
				{
					data: Array.isArray(relatedAttributes)
						? relatedAttributes.map((singleAttributes) => ({
								id: singleAttributes._id,
								type: relatedKey,
						  }))
						: {
								id: (
									relatedAttributes as Record<string, unknown> & { _id: string }
								)._id,
								type: relatedKey,
						  },
				},
			]),
		);
	};

	return {
		v: 1,
		jsonapi: {
			version: "",
		},
		data: (Array.isArray(principleData)
			? principleData.map((singlePrincipleData, index) => ({
					id: singlePrincipleData._id,
					type: "blank", // `type` is required to meet the spec, but the front end doesn't really care about it / use it.
					attributes: stripKey(singlePrincipleData, "_id"),
					...(related &&
						Array.isArray(related) && {
							relationships: getRelationshipsFromSingleRelated(related[index]),
						}),
			  }))
			: {
					id: principleData._id,
					type: "blank", // `type` is required to meet the spec, but the front end doesn't really care about it / use it.
					attributes: stripKey(principleData, "_id"),
					...(related &&
						!Array.isArray(related) && {
							relationships: getRelationshipsFromSingleRelated(related),
						}),
			  }) as unknown as JSONApi<T>["data"],
		...(related && {
			included: [related]?.flat().flatMap((singleRelated) =>
				Object.entries(singleRelated).flatMap(([relationshipKey, attributes]) =>
					Array.isArray(attributes)
						? attributes.map((signleAttributes) => ({
								type: relationshipKey,
								id: signleAttributes._id,
								attributes: stripKey(signleAttributes, "_id"),
						  }))
						: {
								type: relationshipKey,
								id: attributes._id,
								attributes: stripKey(attributes, "_id"),
						  },
				),
			) as JSONApi<T>["included"],
		}),
	};
};
