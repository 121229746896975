import * as Yup from "yup";

// Types
import type { CreateRenewableEnergyTarget } from "Types/RenewableEnergyTargets";

export const initialValues: Partial<CreateRenewableEnergyTarget> = {
	country: "Australia",
	year: "" as unknown as number,
	yearType: "fiscal",
	state: undefined,
	percentage: "" as unknown as number,
};

export const validationSchema = Yup.object().shape(
	{
		country: Yup.string().oneOf(["Australia"]),
		state: Yup.string().required("Please select a state"),
		year: Yup.number()
			.min(2014, "Must be 2014 or later")
			.max(2022, "Cannot be later than 2022")
			.required("Please enter a year"),
		yearType: Yup.string().oneOf(["fiscal", "yearly"]),
		percentage: Yup.number()
			.min(0.01, "Must be greater than 0.01%")
			.max(100, "Can't be over 100%")
			.required("Please enter a percentage"),
	},
	[["method", "postcodeAlias"]],
);
