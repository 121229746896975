// Assets
import * as Icons from "Assets/SVG";

import {
	MeasureCategoryWithIdentifiers,
	MeasureCategoryWithMeters,
	MeasureCategory,
	MeasureCategoryWithUtilities,
} from "Types/MeasureCategory";
import {
	AccommodationEntry,
	ElectricityStreamData,
	EmissionEntry,
	FlightStreamData,
	GasStreamData,
	LPGBottles,
	LPGBulk,
	LPGStreamData,
	PaperStreamData,
	TransportEntry,
	WasteStreamData,
	WaterStreamData,
} from "Types/MeasureEntry";
import IconProps from "Types/IconProps";
import dateFormat from "Helpers/FormatHelpers/DateFormat";

export type CellMaker<E extends EmissionEntry = EmissionEntry> = {
	icon?: React.FC<IconProps>;
	description: string;
	render: (entry: E) => string | JSX.Element;
};

export type Cell = Pick<CellMaker, "description" | "icon"> & {
	value: string | JSX.Element;
};

export const emissionTypes: MeasureCategory[] = [
	"electricity",
	"gas",
	"lpg",
	"water",
	"waste",
	"flight",
	"transport",
	"paper",
	"accommodation",
];

export const emissionTypesWithUtility: MeasureCategoryWithUtilities[] = [
	"electricity",
	"gas",
	"lpg",
	"waste",
	"water",
];

export const emissionTypeDisplayMap: {
	[emissionType in MeasureCategory]: string;
} = {
	electricity: "Electricity",
	gas: "Gas",
	lpg: "LPG",
	water: "Water",
	waste: "Waste",
	flight: "Flight",
	transport: "Transport",
	paper: "Paper",
	"stationary-fuels": "Stationary Fuels",
	accommodation: "Accommodation",
	catering: "Catering",
	equipment: "Equipment",
};

export const isEmissionTypeWithUtility = (
	emissionType: MeasureCategory,
): emissionType is MeasureCategoryWithUtilities =>
	emissionTypesWithUtility.includes(
		emissionType as MeasureCategoryWithUtilities,
	);

export const streamWithIdentifiers: MeasureCategoryWithIdentifiers[] = [
	"electricity",
	"gas",
	"water",
	"lpg",
	"waste",
	"transport",
];

export const streamWithMeters: MeasureCategoryWithMeters[] = [
	"electricity",
	"gas",
	"water",
];

export const isEmissionTypeWithIdentifier = (
	stream: MeasureCategory,
): stream is MeasureCategoryWithIdentifiers => {
	return streamWithIdentifiers.includes(
		stream as MeasureCategoryWithIdentifiers,
	);
};

export const cellMakerOptions = {
	dateFrom: {
		icon: Icons.CalendarIcon,
		description: "From",
		render: (stream: EmissionEntry) =>
			dateFormat(stream.from, { timeZone: "UTC" }),
	},
	dateTo: {
		icon: Icons.CalendarIcon,
		description: "To",
		render: (stream: EmissionEntry) =>
			dateFormat(stream.to, { timeZone: "UTC" }),
	},
	cost: {
		icon: Icons.DollarIcon,
		description: "Cost",
		render: (
			stream:
				| ElectricityStreamData
				| GasStreamData
				| LPGStreamData
				| WaterStreamData
				| WasteStreamData
				| PaperStreamData
				| FlightStreamData
				| AccommodationEntry,
		) =>
			"$" +
			stream.totalCost.toLocaleString("en-AU", {
				maximumFractionDigits: 2,
			}),
	},
	consumption: {
		icon: Icons.BoltIcon,
		description: "Consumption",
		render: (stream: ElectricityStreamData | GasStreamData | WaterStreamData) =>
			stream?.consumption.toLocaleString("en-AU", {
				maximumFractionDigits: 2,
			}) +
			" " +
			stream?.unit,
	},
	grossFootprint: {
		icon: Icons.CarbonFootprintIcon,
		description: "Gross footprint",
		render: (stream: EmissionEntry) =>
			stream.tCO2e.toLocaleString("en-AU", {
				maximumFractionDigits: 2,
			}) +
			" " +
			"t-CO₂e",
	},
	netFootprint: {
		icon: ({ width, height }: IconProps) => (
			<Icons.CarbonFootprintIcon
				width={width}
				height={height}
				fill={"#0093D2"}
			/>
		),
		description: "Net footprint",
		render: (stream: EmissionEntry) =>
			stream.tCO2eNet.toLocaleString("en-AU", {
				maximumFractionDigits: 2,
			}) +
			" " +
			"t-CO₂e",
	},
	abatements: {
		icon: Icons.AbatementsIcon,
		description: "Abatements",
		render: (
			stream:
				| ElectricityStreamData
				| GasStreamData
				| PaperStreamData
				| TransportEntry,
		) =>
			stream?.abatements.toLocaleString("en-AU", {
				maximumFractionDigits: 2,
			}) +
			" " +
			"t-CO₂e",
	},
};

export const electricityCellMakers: CellMaker<ElectricityStreamData>[] = [
	cellMakerOptions.cost,
	cellMakerOptions.consumption,
	cellMakerOptions.grossFootprint,
	cellMakerOptions.abatements,
	cellMakerOptions.netFootprint,
	{
		icon: Icons.SolarIcon,
		description: "Solar exports",
		render: (stream: ElectricityStreamData) =>
			stream.fed ? `${stream.fed?.toLocaleString()} ${stream.unit}` : "none",
	},
];

export const waterCellMakers: CellMaker<WaterStreamData>[] = [
	cellMakerOptions.cost,
	{
		icon: Icons.WaterIcon,
		description: "Consumption",
		render: (stream) =>
			stream?.consumption.toLocaleString("en-AU", {
				maximumFractionDigits: 2,
			}) +
			" " +
			stream?.unit,
	},
	cellMakerOptions.grossFootprint,
	cellMakerOptions.netFootprint,
];

export const wasteCellMakers: CellMaker<WasteStreamData>[] = [
	cellMakerOptions.cost,
	{
		icon: Icons.WeightIcon,
		description: "Weight",
		render: (stream) => stream.totalWeight.toLocaleString("en-AU") + " t",
	},
	cellMakerOptions.grossFootprint,
	cellMakerOptions.netFootprint,
];

export const transportCellMakers: CellMaker<TransportEntry>[] = [
	cellMakerOptions.grossFootprint,
	cellMakerOptions.abatements,
	cellMakerOptions.netFootprint,
];

export const vehicleCellMakers: CellMaker<TransportEntry>[] = [
	{
		icon: Icons.DistanceIcon,
		description: "Distance",
		render: (stream: TransportEntry) =>
			stream.unit === "kms" ? stream.usage + " " + stream.unit : "-",
	},
];

export const fuelCellMakers: CellMaker<TransportEntry>[] = [
	{
		icon: Icons.FuelIcon,
		description: "Fuel",
		render: (stream: TransportEntry) =>
			stream.unit !== "kms" ? stream.usage + " " + stream.unit : "-",
	},
	{
		icon: Icons.DollarIcon,
		description: "Cost",
		render: (stream: TransportEntry) =>
			// This check is only for TS; at this point it must always have totalCost
			"totalCost" in stream
				? "$" +
				  stream?.totalCost?.toLocaleString("en-AU", {
						maximumFractionDigits: 2,
				  })
				: "",
	},
];

export const paperCellMakers: CellMaker<PaperStreamData>[] = [
	{
		icon: ({ width, height }: IconProps) => (
			<Icons.CalendarIcon width={width} height={height} fill="#0093D2" />
		),
		description: "Purchase date",
		render: (stream) => dateFormat(stream.purchase, { timeZone: "UTC" }),
	},
	cellMakerOptions.cost,
	{
		icon: Icons.PaperIcon,
		description: "Reams",
		render: (stream: PaperStreamData) => stream.reams.toLocaleString("en-AU"),
	},
	cellMakerOptions.grossFootprint,
	cellMakerOptions.abatements,
	cellMakerOptions.netFootprint,
];

export const lpgBulkCellMakers: CellMaker<LPGBottles | LPGBulk>[] = [
	{
		icon: Icons.BoxIcon,
		description: "Bulk Supply",
		render: (stream) =>
			"bottles" in stream ? (
				"-"
			) : (
				<Icons.TickIcon width="1.6rem" height="1.6rem" />
			),
	},
];

export const lpgBulkCellMakersCard: CellMaker<LPGBottles | LPGBulk>[] = [
	cellMakerOptions.cost,
	{
		icon: Icons.BoxIcon,
		description: "Bulk Supply",
		render: () => <Icons.TickIcon width="1.6rem" height="1.6rem" />,
	},
	{
		icon: ({ width, height }: IconProps) => (
			<Icons.CalendarIcon width={width} height={height} fill="#0093D2" />
		),
		description: "Purchase date",
		render: (stream) => dateFormat(stream.purchase, { timeZone: "UTC" }),
	},
	cellMakerOptions.grossFootprint,
	cellMakerOptions.netFootprint,
];

export const lpgBottleCellMakers: CellMaker<LPGBottles | LPGBulk>[] = [
	cellMakerOptions.cost,
	{
		icon: Icons.BottleIcon,
		description: "Bottles",
		render: (stream) =>
			"bottles" in stream ? stream.bottles.toLocaleString("en-AU") : "-",
	},
	{
		icon: Icons.WeightIcon,
		description: "Bottle weight",
		render: (stream) =>
			"perBottle" in stream
				? stream.perBottle.toLocaleString("en-AU") + ` ${stream.unit}`
				: "-",
	},
	{
		icon: ({ width, height }: IconProps) => (
			<Icons.CalendarIcon width={width} height={height} fill="#0093D2" />
		),
		description: "Purchase date",
		render: (stream) => dateFormat(stream.purchase, { timeZone: "UTC" }),
	},
	cellMakerOptions.grossFootprint,
	cellMakerOptions.netFootprint,
];

export const gasCellMakers: CellMaker<GasStreamData>[] = [
	cellMakerOptions.cost,
	{
		icon: ({ width, height }: IconProps) => (
			<Icons.GasIcon width={width} height={height} fill={"#E94242"} />
		),
		description: "Consumption",
		render: (stream: GasStreamData) =>
			stream?.consumption.toLocaleString("en-AU") + " " + stream?.unit,
	},
	cellMakerOptions.grossFootprint,
	cellMakerOptions.abatements,
	cellMakerOptions.netFootprint,
];

export const flightCellMakers: CellMaker<FlightStreamData>[] = [
	{
		description: "Departure date",
		render: (stream) =>
			dateFormat(stream.flights[0]?.departureDate, {
				timeZone: "UTC",
			}),
		icon: ({ width, height }: IconProps) => (
			<Icons.CalendarIcon width={width} height={height} fill="#0093D2" />
		),
	},
	cellMakerOptions.cost,
	{
		description: "Number of flights",
		render: (stream) => stream.flights.length.toString(),
		icon: Icons.FlightIcon,
	},
	{
		description: "Distance",
		render: (stream) =>
			stream.distance.toLocaleString("en-AU", {
				maximumFractionDigits: 0,
			}) + " kms",
		icon: Icons.DistanceIcon,
	},
	cellMakerOptions.grossFootprint,
	cellMakerOptions.netFootprint,
	{
		description: "Offset",
		render: (stream) =>
			stream.flights.some((flight) => flight.flightOffset) ? "Yes" : "No",
		icon: Icons.OffsetIcon,
	},
];

export const accommodationCellMakers = (
	layout: "card" | "table",
): CellMaker<AccommodationEntry>[] => {
	const dates: CellMaker<AccommodationEntry>[] = [
		cellMakerOptions.dateFrom,
		cellMakerOptions.dateTo,
	];

	const cardContents: CellMaker<AccommodationEntry>[] = [
		cellMakerOptions.cost,
		{
			description: "Carbon Neutral",
			render: (entry) =>
				entry.neutral ? (
					<Icons.TickIcon width="1.8rem" height="1.8rem" fill="teal" />
				) : (
					"—"
				),
			icon: Icons.OffsetIcon,
		},
		cellMakerOptions.grossFootprint,
		cellMakerOptions.netFootprint,
	];

	const tableContents: CellMaker<AccommodationEntry>[] = [
		{
			description: "Nights",
			render: (entry) => String(entry.nights),
		},
		{
			description: "Rooms",
			render: (entry) => String(entry.rooms),
		},
		{
			description: "Rating",
			render: (entry) =>
				"stars" in entry && entry.stars ? `${entry.stars} star` : "—",
		},
		{
			description: "State",
			render: (entry) => ("state" in entry && entry.state ? entry.state : "—"),
		},
		{
			description: "Region",
			render: (entry) => (entry.location === "country" ? "Regional" : "Metro"),
		},
		...cardContents,
	];

	return [...dates, ...(layout === "card" ? cardContents : tableContents)];
};
