import React from "react";
import { useSpring, animated } from "react-spring";

// Types
import type IconProps from "Types/IconProps";

/** Customizable svg icon */
const ArrowIcon: React.FC<
	IconProps & { direction?: "down" | "up" | "left" | "right" }
> = ({
	width,
	height,
	direction = "down",
	fill,
	className = "direction-arrow",
	id,
	...props
}) => {
	// Helpers
	const arrowDirection = (arrowD?: "down" | "up" | "left" | "right") => {
		switch (arrowD) {
			case "right":
				return "M5.79289 14.2071C5.40237 13.8166 5.40237 13.1834 5.79289 12.7929L10.0858 8.5L5.79289 4.20711C5.40237 3.81658 5.40237 3.18342 5.79289 2.79289C6.18342 2.40237 6.81658 2.40237 7.20711 2.79289L12.2071 7.79289C12.5976 8.18342 12.5976 8.81658 12.2071 9.20711L7.20711 14.2071C6.81658 14.5976 6.18342 14.5976 5.79289 14.2071Z";
			case "left":
				return "M10.7071 2.29289C11.0976 2.68342 11.0976 3.31658 10.7071 3.70711L6.41421 8L10.7071 12.2929C11.0976 12.6834 11.0976 13.3166 10.7071 13.7071C10.3166 14.0976 9.68342 14.0976 9.29289 13.7071L4.29289 8.70711C3.90237 8.31658 3.90237 7.68342 4.29289 7.29289L9.29289 2.29289C9.68342 1.90237 10.3166 1.90237 10.7071 2.29289Z";
			case "up":
				return "M13.7071 10.7071C13.3166 11.0976 12.6834 11.0976 12.2929 10.7071L8 6.41421L3.70711 10.7071C3.31658 11.0976 2.68342 11.0976 2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L7.29289 4.29289C7.68342 3.90237 8.31658 3.90237 8.70711 4.29289L13.7071 9.29289C14.0976 9.68342 14.0976 10.3166 13.7071 10.7071Z";
			default:
				return "M2.29289 5.29289C2.68342 4.90237 3.31658 4.90237 3.70711 5.29289L8 9.58579L12.2929 5.29289C12.6834 4.90237 13.3166 4.90237 13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711L8.70711 11.7071C8.31658 12.0976 7.68342 12.0976 7.29289 11.7071L2.29289 6.70711C1.90237 6.31658 1.90237 5.68342 2.29289 5.29289Z";
		}
	};

	// Hooks
	const animation = useSpring({ d: arrowDirection(direction) });

	return (
		<animated.svg
			{...(width || (!height && !width) ? { width: width ?? "16px" } : {})}
			{...{ id, height, className, ...props }}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<animated.path
				fillRule="evenodd"
				clipRule="evenodd"
				fill={fill != null ? fill : "#888"}
				d={animation.d as unknown as string}
			/>
		</animated.svg>
	);
};

export default ArrowIcon;
