import React from "react";

// Types
import type IconProps from "Types/IconProps";

export const ActionIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "action-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "32px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<defs>
				<linearGradient
					id="action_gradient_one"
					x1="0"
					y1="0"
					x2="27.8489"
					y2="35.277"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#888888" />
					<stop offset="1" stopColor="#76A4C5" />
				</linearGradient>
				<linearGradient
					id="action_gradient_two"
					x1="0"
					y1="0"
					x2="27.8489"
					y2="35.277"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#888888" />
					<stop offset="1" stopColor="#76A4C5" />
				</linearGradient>
			</defs>
			<path
				d="M31.0253 26.324L15.0987 10.3973C14.356 9.65467 13.976 8.624 14.0547 7.57733C14.208 5.59733 13.528 3.56533 12.016 2.04933C10.6427 0.678667 8.848 0 7.052 0C6.376 0 5.69867 0.096 5.04533 0.288L9.272 4.51467C9.73067 6.63333 6.66 9.73867 4.516 9.27067L0.290667 5.044C0.0973333 5.7 0 6.37733 0 7.056C0 8.852 0.68 10.644 2.05067 12.0173C3.564 13.532 5.59733 14.2107 7.57867 14.0573C8.62267 13.9773 9.65467 14.3587 10.396 15.1013L26.324 31.0267C26.948 31.6507 27.7933 32 28.6747 32C30.4987 32 32 30.5227 32 28.6747C32 27.824 31.6747 26.9733 31.0253 26.324ZM28.6667 30C27.9293 30 27.3333 29.4027 27.3333 28.6667C27.3333 27.9307 27.9293 27.3333 28.6667 27.3333C29.404 27.3333 30 27.9307 30 28.6667C30 29.4027 29.404 30 28.6667 30ZM18.0867 9.61467L25.1893 2.524C25.968 1.744 26.9867 1.35333 28.0067 1.35333C29.0253 1.35333 30.0453 1.74267 30.824 2.524C31.6093 3.30667 32 4.33333 32 5.35733C32 6.376 31.612 7.39467 30.836 8.17067L23.7347 15.2613L18.0867 9.61467ZM13.38 21.8547L6.41067 28.768C6.108 29.0747 5.84667 29.4187 5.64533 29.8L5.244 30.5733L2.444 32L1.33333 30.888L2.7 28.0267L3.47333 27.624C3.85467 27.424 4.22133 27.1853 4.52667 26.88L11.496 19.9667L13.38 21.8547Z"
				fill={fill != null ? fill : "url(#action_gradient_one)"}
			/>
			<path
				d="M28.6667 30C27.9293 30 27.3333 29.4027 27.3333 28.6667C27.3333 27.9307 27.9293 27.3333 28.6667 27.3333C29.404 27.3333 30 27.9307 30 28.6667C30 29.4027 29.404 30 28.6667 30Z"
				fill={fill != null ? fill : "url(#action_gradient_two)"}
			/>
		</svg>
	);
};

export default ActionIcon;
