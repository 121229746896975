import styled from "@emotion/styled";
import { Row } from "CCW-Components/CCW-TableStyles/styles";
import CCButtonLink from "CCW-Components/CCW-ButtonLink";

export const ButtonLink = styled(CCButtonLink)(() => ({
	margin: "4rem 0",
	padding: "0 4rem",
}));

export const StyledRow = styled(Row)({
	position: "relative",
	"&:hover": {
		".showOnHover": {
			transform: "scale(1)",
		},
	},
});

export const HoverPosition = styled.div({
	position: "absolute",
	left: 0,
	top: "-15px",
	transform: "translateX(-100%) scale(0)",
	overflow: "hidden",
	transition: "all 0.4s",
});
