import styled from "@emotion/styled";
import type { ColorOption } from "../CarbonReport/types";
import { PaddingOption } from "./types";

const getPadding = (paddingOption: PaddingOption) => {
	if (paddingOption === "full") {
		return "3.6rem 3.2rem 3.2rem 5.6rem";
	} else if (paddingOption === "left") {
		return "3.6rem 0 0 5.6rem";
	} else {
		return "unset";
	}
};

// Styles
export const StaticPDFContainer = styled.div<{
	orientation?: "portrait" | "landscape";
	setPadding?: PaddingOption;
}>(({ orientation = "portrait", setPadding = "full" }) => ({
	width: orientation === "portrait" ? 595 : 842,
	height: orientation === "portrait" ? 842 : 595,
	position: "relative",
	padding: getPadding(setPadding),
}));

export const ColorBar = styled.div(({ theme }) => ({
	position: "absolute",
	top: 0,
	left: -1,
	bottom: 0,
	width: "2.5rem",
	background: theme.gradientsColors.Rotated90,
}));

export const TitleBox = styled.div(() => ({
	marginBottom: "2.2rem",
}));

export const Title = styled.h2<{ color: ColorOption }>(({ theme, color }) => ({
	...theme.webFonts.h2,
	color: color === "grape" ? theme.colors.Grape400 : theme.colors.primary,
	margin: 0,
	whiteSpace: "pre",
}));

export const Subtitle = styled.h4(({ theme }) => ({
	...theme.webFonts.F4,
	...theme.webFonts.italic,
	fontWeight: 600, // Bold (700) is too much, regular (400) is not enough.
	color: theme.colors.D3,
	marginLeft: "1rem",
	margin: 0,
	lineHeight: 1,
}));

export const EntityLogo = styled.img(() => ({
	position: "absolute",
	right: "2rem",
	top: "2rem",
	maxHeight: "5.2rem",
}));

export const SomeBox = styled.div(() => ({
	display: "grid",
	gridTemplateColumns: "3.2rem 1fr",
	gap: "2.4rem",
	paddingLeft: "3.8rem",
}));

export const Content = styled.div(({ theme }) => ({
	...theme.webFonts.F7,
	color: theme.colors.D2,
	height: "100%",
}));

export const PageNumber = styled.div(({ theme }) => ({
	...theme.webFonts.F7,
	color: theme.colors.D4,
	position: "absolute",
	bottom: "1.2rem",
	right: "1.3rem",
}));
