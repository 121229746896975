import React from "react";

// Types
import type IconProps from "Types/IconProps";

export const CompareIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "compare-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "16px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2362:44737)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0.865529 11.9088C0.677113 11.707 0.360718 11.6961 0.158843 11.8845C-0.0430326 12.0729 -0.0539427 12.3893 0.134474 12.5912L3.63447 16.3412C3.72904 16.4425 3.86141 16.5 4 16.5C4.13859 16.5 4.27097 16.4425 4.36553 16.3412L7.86553 12.5912C8.05395 12.3893 8.04304 12.0729 7.84116 11.8845C7.63929 11.6961 7.32289 11.707 7.13447 11.9088L4.5 14.7315L4.5 1C4.5 0.723858 4.27614 0.5 4 0.5C3.72386 0.5 3.5 0.723858 3.5 1L3.5 14.7315L0.865529 11.9088ZM8.86553 5.09116C8.67711 5.29303 8.36072 5.30394 8.15884 5.11553C7.95697 4.92711 7.94606 4.61072 8.13448 4.40884L11.6345 0.658841C11.729 0.557524 11.8614 0.5 12 0.5C12.1386 0.5 12.271 0.557524 12.3655 0.658841L15.8655 4.40884C16.0539 4.61072 16.043 4.92711 15.8412 5.11553C15.6393 5.30394 15.3229 5.29303 15.1345 5.09116L12.5 2.26851V16C12.5 16.2761 12.2761 16.5 12 16.5C11.7239 16.5 11.5 16.2761 11.5 16V2.26851L8.86553 5.09116Z"
					fill={fill || "currentColor"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_2362:44737">
					<rect
						width="16"
						height="16"
						fill="white"
						transform="translate(0 16.5) rotate(-90)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default CompareIcon;
