import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// CC
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import PLink from "CCW-Components/CCW-PLink";
import BlockLoader from "CCW-Components/CCW-BlockLoader";

// Hooks
import useSession from "Hooks/UseSession";
import usePlatform from "Hooks/UsePlatform";
import useValidateReferral from "Hooks/ValidateReferral";

// Local
import LoginHeader from "./Components/LoginHeader";
import LoginForm from "./Components/LoginForm";

// Assets
import { HalloweenIcon, ChristmasIcon } from "Assets/SVG";

// Styles
import { Container, LoginContainer, Card, SignUp } from "./styles";

const LoginPage = ({
	appType = "main",
}: {
	appType?: "main" | "partner" | "admin";
}) => {
	// Hooks
	const [activeUser, sessionError] = useSession();
	const platform = usePlatform();
	const navigate = useNavigate();
	const location = useLocation();

	const referralCode =
		new URLSearchParams(location.search).get("referral-code") ||
		new URLSearchParams(location.search).get("referralCode") ||
		undefined;
	const externalId =
		new URLSearchParams(location.search).get("external-id") ||
		new URLSearchParams(location.search).get("externalId") ||
		undefined;
	const partnerCode =
		new URLSearchParams(location.search).get("partner") || undefined; // Deprecated

	// Hooks
	const [referral, referralError] = useValidateReferral(
		referralCode || partnerCode || "",
	);
	// Navigate if user is already logged in
	useEffect(() => {
		if (activeUser && !sessionError) {
			navigate("/");
		}
	}, [activeUser, navigate, sessionError]);

	// Loading state
	if (
		((referralCode || partnerCode) && !referral && !referralError) ||
		(!activeUser && !sessionError)
	) {
		return <BlockLoader message="loading..." height="100%" />;
	}

	// Main state
	return (
		<DefaultPage defaultPadding={false} onNavigation={true}>
			{import.meta.env.VITE_HOLIDAY === "xmas" && (
				<Helmet>
					<script src="https://app.embed.im/snow.js" defer></script>
				</Helmet>
			)}
			<Container>
				<LoginContainer>
					<>
						<LoginHeader
							referrer={
								referral
									? {
											logoURL: referral.logo,
											bannerURL: referral.banner,
											name: referral.name,
									  }
									: undefined
							}
						/>
						{referralError && !referral && (
							<p className="mt-0 text-center text-base text-warning">
								There is an issue with your referral code.
								<br></br>
								<span className="text-base">
									You can contact us at{" "}
									<PLink to={"mailto:support@climateclever.org"}>
										support@climateclever.org{" "}
									</PLink>{" "}
									or continue to Log In.
								</span>
							</p>
						)}
					</>
					<Card>
						{import.meta.env.VITE_HOLIDAY === "halloween" && (
							<>
								<HalloweenIcon
									type="witchHat"
									width="158"
									height="149"
									id="hat"
								/>
								<HalloweenIcon
									type="bigBat"
									width="125"
									height="79"
									id="bigBat"
								/>
								<HalloweenIcon
									type="smallBat"
									width="85"
									height="43"
									id="smallBat"
								/>
								<HalloweenIcon
									type="pumpkin"
									width="124"
									height="128"
									id="pumpkin"
								/>
							</>
						)}
						{import.meta.env.VITE_HOLIDAY === "xmas" && (
							<>
								<ChristmasIcon type="snow" width="712" height="373" id="snow" />
								<ChristmasIcon
									type="santaHat"
									width="158"
									height="149"
									id="santa"
								/>
								<ChristmasIcon
									type="snowCap"
									width="165"
									height="78"
									id="cap"
								/>
								<ChristmasIcon
									type="snowman"
									width="145"
									height="156"
									id="snowman"
								/>
								<ChristmasIcon
									type="gifts"
									width="109"
									height="109"
									id="gifts"
								/>
							</>
						)}
						<LoginForm
							id="login-form"
							appType={appType}
							referralCode={referralCode && referral?.referralCode}
							partnerCode={partnerCode && referral?.referralCode}
							externalId={externalId}
						/>
					</Card>
					{appType === "main" && (
						<SignUp isHoliday={import.meta.env.VITE_HOLIDAY === "xmas"}>
							{"Don't have an account?"}{" "}
							<PLink
								to={{ ...location, pathname: "/sign-up/1" }}
								isColorVariant>
								Sign Up
							</PLink>
						</SignUp>
					)}
				</LoginContainer>
			</Container>
		</DefaultPage>
	);
};

export default LoginPage;
