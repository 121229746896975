import styled from "@emotion/styled";

// Helpers
import MediaQueries, { minScreenWidth } from "Helpers/WebUIHelpers/Breakpoints";

export const Container = styled.div(() => ({
	width: "100%",
	height: "100vh",
	display: "flex",
}));

export const LoginContainer = styled.div(() => ({
	padding: "0 2rem",
	marginTop: "4rem",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	width: "100%",
	height: "100%",
	[MediaQueries[1]]: {
		marginTop: "0",
	},
}));

export const Card = styled.div(({ theme }) => ({
	marginTop: "4rem",
	position: "relative",
	boxShadow: theme.shadow,
	borderRadius: "1.2rem",
	minWidth: "100%",
	backgroundColor: theme.colors.White,
	"#hat": {
		display: "none",
		[minScreenWidth.small]: {
			display: "initial",
			position: "absolute",
			left: "-79px",
			top: "-97px",
		},
	},
	"#bigBat": {
		position: "absolute",
		scale: "60%",
		top: "-30px",
		right: "-12px",
		[minScreenWidth.small]: {
			scale: "100%",
			right: "-61px",
			top: "-47px",
		},
	},
	"#smallBat": {
		position: "absolute",
		scale: "60%",
		top: "-38px",
		right: "-22px",
		[minScreenWidth.small]: {
			scale: "100%",
			top: "32px",
			right: "-96px",
		},
	},
	"#pumpkin": {
		position: "absolute",
		bottom: "-92px",
		left: "-27px",
		scale: "55%",
		[minScreenWidth.small]: {
			left: "413px",
			scale: "100%",
			bottom: "-42px",
		},
	},
	"#santa": {
		display: "none",
		[minScreenWidth.small]: {
			display: "initial",
			position: "absolute",
			left: "-63px",
			top: "-70px",
		},
	},
	"#snow": {
		display: "none",
		[minScreenWidth.small]: {
			display: "initial",
			position: "absolute",
			right: "-139px",
			top: "24px",
		},
	},
	"#cap": {
		position: "absolute",
		scale: "75%",
		top: "-32px",
		right: "-32px",
		[minScreenWidth.small]: {
			scale: "100%",
			top: "-27px",
			right: "-19px",
		},
	},
	"#snowman": {
		display: "none",
		[minScreenWidth.small]: {
			display: "initial",
			position: "absolute",
			left: "392px",
			bottom: "-68px",
		},
	},
	"#gifts": {
		position: "absolute",
		bottom: "-75px",
		left: "-27px",
		scale: "61%",
		[minScreenWidth.small]: {
			left: "-69px",
			scale: "100%",
			bottom: "-42px",
		},
	},
	[MediaQueries[1]]: {
		minWidth: "44.5rem",
		marginTop: "1rem",
	},
	"#login-form": {
		margin: "2.6rem 1.4rem",
		[MediaQueries[1]]: {
			margin: "4rem 3.8rem",
		},
	},
}));

export const SignUp = styled.p<{ isHoliday: boolean }>(
	({ theme, isHoliday }) => ({
		zIndex: 100,
		color: theme.colors.D3,
		paddingBottom: "2rem",
		...theme.webFonts.F6Bold,
		marginTop: isHoliday ? "2rem" : "3rem",
		[MediaQueries[1]]: {
			marginTop: isHoliday ? "4rem" : "3rem",
		},
	}),
);
