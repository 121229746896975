import { Theme } from "@emotion/react";

const colors: Theme["colors"] = {
	primary: "#007CBE",
	secondary: "#884EB1",
	selected: "linear-gradient(106.07deg, #007CBE 6.37%, #884EB1 84.66%)",

	/* Blacks and Whites */
	grey50: "#FFFFFF",
	grey100: "#F2F2F2",
	grey200: "#DBDBDB",
	grey300: "#BDBDBD",
	grey400: "#9C9C9C",
	grey500: "#767676",
	grey600: "#545454",
	grey700: "#333333",
	grey800: "#0D0D0D",
	D1: "#222222",
	D2: "#4F4F4F",
	D3: "#828282",
	D4: "#BBBBBB",
	D5: "#DDDDDD",
	D6: "#F0F0F0",
	White: "#ffffff",
	Black60: "rgba(0, 0, 0, 0.6)",
	Black50: "rgba(0, 0, 0, 0.5)",
	Black30: "rgba(0, 0, 0, 0.3)",
	Black20: "rgba(0, 0, 0, 0.2)",
	Black15: "rgba(0, 0, 0, 0.15)",
	Black10: "rgba(0, 0, 0, 0.1)",
	Black05: "rgba(0, 0, 0, 0.05)",
	White70: "rgba(255, 255, 255, 0.7)",
	White60: "rgba(255, 255, 255, 0.6)",
	White50: "rgba(255, 255, 255, 0.5)",
	White20: "rgba(255, 255, 255, 0.2)",
	White15: "rgba(255, 255, 255, 0.15)",
	White10: "rgba(255, 255, 255, 0.1)",
	LightBackground: "#efefef",

	/* V6 Theme Colours (Light Only) */
	Blue50: "#8AD6FF",
	Blue100: "#57C5FF",
	Blue200: "#24B3FF",
	Blue300: "#009CF0",
	Blue400: "#007CBE",
	Blue500: "#00679E",
	Blue600: "#005380",
	Blue700: "#003F61",
	Blue800: "#002B42",

	Grape50: "#DECEE9",
	Grape100: "#C9AEDB",
	Grape200: "#B38ECD",
	Grape300: "#9D6EBF",
	Grape400: "#884EB1",
	Grape500: "#724295",
	Grape600: "#5C3578",
	Grape700: "#47295C",
	Grape800: "#311C40",

	Blueberry50: "#F0F2F9",
	Blueberry100: "#C9CEE9",
	Blueberry200: "#A5ACDA",
	Blueberry300: "#818CCA",
	Blueberry400: "#5D6BBB",
	Blueberry500: "#4451A2",
	Blueberry600: "#353F7E",
	Blueberry700: "#222953",
	Blueberry800: "#0F1224",

	/* Random Colours */
	Red: "#E94242",
	DarkBlue: "#466fb5",
	DarkOrange: "#E97E42",
	Teal: "#1fa786",
	Green: "#58B647",
	Lime: "#93C83F",
	Yellow: "#fbb12c",
	Orange: "#E97E42",
	SteelBlue: "#63849c",
	Purple: "#ac50da",
	Tree: "#698e27",
};

export default colors;
