// Helpers
import getReqHeader, { ContentType } from "../GetReqHeader";
import makeError from "../MakeError";

/**
 * Networking function for get requests
 * @param url The url for the request
 * @params
 */
const getReq = async <T>([url, version, voidResponse, contentType = "JSON"]: [
	url: string,
	version?: number,
	voidResponse?: boolean, // Response is void if it does not contain JSON
	contentType?: ContentType,
]): Promise<T> => {
	const requestHeaders = new Headers();
	requestHeaders.set("Content-Type", getReqHeader(contentType));
	version &&
		requestHeaders.set("Accept", `application/vnd.api.v${version}+json`);
	try {
		const response = await fetch(url, {
			method: "GET",
			credentials: "include",
			headers: requestHeaders,
		});
		if (!response.ok) {
			throw makeError(
				response.headers.get("content-type")?.includes("application/json")
					? (await response.json()).error
					: "Unknown Error, please contact support",
			);
		}
		const responseData = voidResponse ? response : await response.json();
		return responseData;
	} catch (error) {
		throw makeError(error);
	}
};

export default getReq;
