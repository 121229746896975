/**
 * Removes values from an object depending on the configuration
 * @param objectToStrip Object to strip
 * @param configuration If set to "all" it will strip out all nullish values and empty arrays and empty
 * objects. If set to "empties", which is the default, it will only strip out empty arrays and empty objects
 * @returns A partial of the object passed in
 */
export const stripEmpty = <T extends Record<string, unknown>>(
	objectToStrip: T,
	...configuration: (
		| "strings" // Empty strings. e.g. ""
		| "zeros" // Zero values. e.g. 0
		| "falsey" // Anything falsey
		| "nullish" // Anything Nullish i.e. undefined and null
		| "objects" // Empty objects i.e. {}
		| "arrays" // Empty arrays i.e. []
		// Should the function strip "empty" values in nested objects
		| "recursive"
		// As though all configuration flags were turned on (except recursive)
		| "all"
	)[]
): Partial<T> =>
	Object.entries(objectToStrip).reduce((acc, [currentKey, currentValue]) => {
		const isAll = configuration.includes("all");

		if ((isAll || configuration.includes("strings")) && currentValue === "") {
			return acc;
		}

		if ((isAll || configuration.includes("zeros")) && currentValue === 0) {
			return acc;
		}

		if ((isAll || configuration.includes("falsey")) && !currentValue) {
			return acc;
		}

		if (
			(isAll || configuration.includes("nullish")) &&
			(currentValue === undefined || currentValue === null)
		) {
			return acc;
		}

		if (
			(isAll || configuration.includes("arrays")) &&
			Array.isArray(currentValue) &&
			currentValue.length === 0
		) {
			return acc;
		}

		// If it's a normal object (array's and null are technically objects, and we're not checking those)
		if (
			typeof currentValue === "object" &&
			currentValue !== null &&
			!Array.isArray(currentValue)
		) {
			if (
				(isAll || configuration.includes("objects")) &&
				Object.keys(currentValue).length === 0
			) {
				return acc;
			} else if (configuration.includes("recursive")) {
				return {
					...acc,
					[currentKey]: stripEmpty(
						currentValue as Record<string, unknown>,
						...configuration,
					),
				};
			}
		}
		return {
			...acc,
			[currentKey]: currentValue,
		};
	}, {});

/**
 * Returns the changes in an object
 * It's essentially removing any keys from the new object that haven't changed,
 * rather than returning the old object with keys that have changed (which is an important distinction)
 */
export const getDiff = <T>(
	oldObject: Partial<T>,
	newObject: Partial<T>,
): Partial<T> => {
	return Object.fromEntries(
		Object.entries(newObject).filter(([newObjectKey, newObjectValue]) => {
			return (
				newObjectValue != null &&
				newObjectValue !== "" &&
				((!Array.isArray(newObjectValue) &&
					oldObject[newObjectKey as keyof T] !== newObjectValue) ||
					(Array.isArray(newObjectValue) &&
						newObjectValue.some(
							(subObject, index) =>
								!!Object.keys(
									getDiff(
										// eslint-disable-next-line @typescript-eslint/no-explicit-any
										(oldObject[newObjectKey as keyof T] as unknown as any[])[
											index
										],
										subObject,
									),
								).length,
						)))
			);
		}),
	) as Partial<T>;
};

export default getDiff;
