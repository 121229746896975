// Helpers
import postReq from "Helpers/NetworkingHelpers/PostReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import {
	JSONApi,
	DataHelperResponse,
	ResourceObject,
	Create,
} from "Types/JSONApi";
import Partner from "Types/Partner";
import Account from "Types/Account";
import { CreatePartnerData } from "Types/Partner";

type Relationships = {
	account: {
		links: { related: string };
		data: { type: "accounts"; id: string };
	};
};

type Related = {
	account: Account;
};

type Included = ResourceObject<Account, undefined, "accounts">[];

type Response = JSONApi<Partner, Relationships, Included, undefined, Related>;

/**
 * @async
 * Creates a new partner
 * @param partnerToCreate The partner data you wish to use in the creation
 * @returns a DataHelperResponse tuple of the new Partner, Related, and the full response
 * @throws Networking error
 */
const createPartner = async (
	partnerToCreate: CreatePartnerData,
): Promise<DataHelperResponse<Partner, Related, Response>> => {
	const requestBody: Create<CreatePartnerData> = {
		data: {
			type: "partners",
			attributes: partnerToCreate,
			relationships: undefined,
			meta: undefined,
		},
	};
	const response = await postReq<
		JSONApi<Partner, Relationships, Included, undefined, Related>
	>(`${import.meta.env.VITE_BASE_URL}executive/partners`, requestBody, 2);

	const [primaryData, related, fullResponse] = transformAPIResponse(
		response,
		{},
	);
	return [primaryData, related, fullResponse];
};

export default createPartner;
