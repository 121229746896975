import React from "react";

// Assets
import GlobeIcon from "Assets/SVG/GlobeIcon";

// Styles
import { Button, Spinner, Text } from "./styles";

// Types
import { ButtonLoaderProps } from "./types";

/** Button component */
const ButtonLoader: React.FC<ButtonLoaderProps> = ({
	block = false,
	disabled = false,
	border = false,
	loading,
	variant,
	type,
	...props
}) => {
	return (
		<Button
			{...{
				type,
				block,
				disabled,
				load: loading,
				variant,
				border,
			}}
			{...props}>
			{loading && (
				<Spinner>
					<GlobeIcon width="32px" height="32px" />
				</Spinner>
			)}
			<Text visible={!loading}>{props.children}</Text>
		</Button>
	);
};

export default ButtonLoader;
