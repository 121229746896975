import React from "react";

// Styles
import { Container, Text } from "./styles";

// Types
import ViewOptionsProps from "./types";

// Assets
import TilesIcon from "Assets/SVG/TilesIcon";
import ListIcon from "Assets/SVG/ListIcon";

export const ViewOptions: React.FC<ViewOptionsProps<string>> = ({
	id,
	selectedOption,
	onChange,
	options = [
		{ value: "tiles", icon: TilesIcon },
		{ value: "table", icon: ListIcon },
	],
	backgroundColor = "primary",
	...props
}) => {
	// Normal state
	return (
		<Container {...props} backgroundColor={backgroundColor}>
			<Text>View:</Text>
			{options.map(({ value, icon: Icon }) => {
				const inputID = `${id}-${value}`;
				return (
					<React.Fragment key={value}>
						<input
							id={inputID}
							type="radio"
							value={value}
							onChange={() => onChange(value)}
							checked={selectedOption === value}
						/>
						<label htmlFor={inputID}>
							<Icon width="16" height="16" />
						</label>
					</React.Fragment>
				);
			})}
		</Container>
	);
};

export default ViewOptions;
