import styled from "@emotion/styled";

// Styles
export const Container = styled.div<{ isReverseOrder?: boolean }>(
	({ isReverseOrder }) => ({
		width: "100%",
		height: "100%",
		display: "flex",
		gap: "0.4rem",
		overflow: "hidden",
		flexFlow: isReverseOrder ? "row-reverse" : "row",
	}),
);

export const PrimaryImage = styled.img({
	objectFit: "cover",
	width: "66%",
	flex: "0 0 auto",
});

export const SecondaryImage = styled.img({
	objectFit: "cover",
	width: "34%",
	flex: "0 1 auto",
});
