import React, { useMemo } from "react";

import { useNavigate, useLocation } from "react-router-dom";

// CC
import PaginationNavBar from "CCW-Components/CCW-PaginationNavBar";
import PageHeader from "CCW-Components/CCW-PageHeader";
import TransitionHeight from "CCW-Components/CCW-TransitionHeight";

// Local CC
import ViewOptions from "./Components/ViewOptions";
import FilterBar from "./Components/FilterBar";
import Actions from "./Components/Actions";

// Hooks
import useBreakpoints from "Hooks/UseBreakpoints";

// Helpers
import { breakpoints } from "Helpers/WebUIHelpers/Breakpoints";

// Styles
import {
	Grid,
	GridAreaHeader,
	GridAreaBanner,
	GridAreaActions,
	GridAreaFilters,
	GridAreaMain,
	HideInMobile,
	HideableDiv,
} from "./styles";

// Types
import { Option } from "./Components/ViewOptions/types";
import { PageControlsProps } from "./types";
import SideNav from "./Components/SideNav";

const PageControls: React.FC<PageControlsProps> = ({
	header,
	banner,
	actions,
	navLinks,
	showViewOptions = true,
	filter,
	pagination,
	children,
	hideSidebar = false,
	className,
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const width = useBreakpoints();
	const searchParams = useMemo(
		() => new URLSearchParams(location.search),
		[location.search],
	);

	const showPagination =
		pagination &&
		((pagination.last && pagination.last > 1) ||
			(pagination.self === 1 && pagination.limit && pagination.limit !== 10));

	const ActionWrapper = breakpoints[0] < width ? TransitionHeight : HideableDiv;

	return (
		<Grid showBanner={!!banner} {...{ className }}>
			{!hideSidebar && (
				<>
					<GridAreaHeader>
						<PageHeader>{header}</PageHeader>
					</GridAreaHeader>
					<GridAreaBanner>{banner}</GridAreaBanner>
					<GridAreaActions>
						{showViewOptions && (
							<HideInMobile>
								<ViewOptions
									id="page-view-options"
									selectedOption={
										(searchParams.get("view") as Option) || "tiles"
									}
									onChange={(nextOption) => {
										searchParams.set("view", nextOption);
										navigate({ ...location, search: searchParams.toString() });
									}}
								/>
							</HideInMobile>
						)}
						<ActionWrapper visible={!!actions}>
							<Actions actions={actions || []} />
						</ActionWrapper>
						<SideNav links={navLinks} />
					</GridAreaActions>
				</>
			)}
			<GridAreaFilters>{filter && <FilterBar {...filter} />}</GridAreaFilters>
			<GridAreaMain>
				{children}
				{!!showPagination && (
					<PaginationNavBar
						selectedPage={pagination?.self || 1}
						pageCount={pagination?.last || 0}
					/>
				)}
			</GridAreaMain>
		</Grid>
	);
};

export default PageControls;
