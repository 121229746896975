import React from "react";
import { animated, config, useTransition } from "react-spring";

import type TransitionHeightArrayProps from "./types";

import useMeasure from "Hooks/UseMeasure";

export const TransitionHeightArray: React.FC<TransitionHeightArrayProps> = ({
	elements,
	springConfig = { delay: 200, config: config.stiff },
}) => {
	// Hooks
	const [heightElement, { height }] = useMeasure<HTMLDivElement>();
	const transitions = useTransition(
		elements.map((element, index) => index),
		{
			from: { height: 0 },
			...(height && { update: { height } }),
			leave: { height: 0 },
			...springConfig,
		},
	);

	return transitions((styles, itemIndex) => (
		<animated.div
			aria-label="transition-height"
			style={{ ...styles, overflow: "hidden" }}>
			<div ref={heightElement}>{elements[itemIndex]}</div>
		</animated.div>
	));
};

export default TransitionHeightArray;
