import amplitude from "amplitude-js";

/**
 * Init tracking this function needs to be called before any other tracking function is called
 */
export const initTracking = () => {
	if (!import.meta.env.VITE_AMPLITUDE) return;
	amplitude.getInstance().init(import.meta.env.VITE_AMPLITUDE, undefined, {
		sameSiteCookie: "Lax",
		includeUtm: true,
	});
};

/**
 * Set the User id to attach events to them
 * @param userId The _id of the User
 * @param properties Any user properties you want to set
 */
export const setUser = (
	userId: string,
	properties?: Record<string, unknown>,
) => {
	if (!import.meta.env.VITE_AMPLITUDE) return;
	amplitude.getInstance().setUserId(userId);
	properties && amplitude.getInstance().setUserProperties(properties);
};

/**
 * Update the User properties
 * @param properties Any user properties you want to set
 */
export const updateUser = (properties: Record<string, unknown>) => {
	if (!import.meta.env.VITE_AMPLITUDE) return;
	if (!amplitude.getInstance()) return;
	amplitude.getInstance().setUserProperties(properties);
};

/**
 * Track an event
 * @param name Name of the event
 * @param properties Optional event properties
 */
export const track = (name: string, properties?: Record<string, unknown>) => {
	if (!import.meta.env.VITE_AMPLITUDE) return;
	if (!amplitude.getInstance()) return;
	properties
		? amplitude.getInstance().logEvent(name, properties)
		: amplitude.getInstance().logEvent(name);
};
