import styled from "@emotion/styled";
import {
	minScreenWidth,
	maxScreenWidth,
} from "Helpers/WebUIHelpers/Breakpoints";
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";
import { NavLink } from "react-router-dom";
import { Theme } from "@emotion/react";

// Styles
export const Relative = styled.div({
	position: "relative",
});

export const ToggleButton = styled.button(({ theme }) => ({
	background: theme.colors.grey50,
	borderRadius: "0.6rem",
	border: `1px solid ${theme.colors.grey200}`,
	padding: 0,
	minWidth: "11rem",
	"& > div": {
		padding: "0.8rem 1.2rem",
	},
	[MediaQueries[1]]: {
		display: "none",
	},
}));

export const Container = styled.nav<{ isShown: boolean }>(
	({ theme, isShown }) => ({
		borderRadius: "0.8rem",
		border: `0.1rem solid ${theme.colors.D4}`,
		overflow: "hidden",
		display: isShown ? "flex" : "none",
		position: "absolute",
		top: "100%",
		zIndex: "1",
		background: theme.colors.grey50,
		boxShadow: theme.shadow,
		// Breakpoint MUST match the breakpoint measure changes layout at
		[MediaQueries[1]]: {
			display: "flex",
			boxShadow: "none",
			position: "static",
		},
	}),
);

export const LinkList = styled.ul(() => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "stretch",
	margin: 0,
	padding: 0,
}));

export const linkFunction = ({ theme }: { theme?: Theme }) => ({
	...theme?.webFonts.F6,
	...theme?.webFonts.bold,
	display: "flex",
	gap: "0.8rem",
	padding: "1.4rem",
	cursor: "pointer",
	color: theme?.colors.D3,
	textDecoration: "none",
	whiteSpace: "nowrap",
	"&:hover": {
		backgroundColor: theme?.colors.Black05,
	},
	"&.active": {
		color: theme?.colors.grey50,
	},
	// Mobile styles
	[maxScreenWidth.small]: {
		"&.active, &.active:hover": { backgroundColor: theme?.colors.Blue400 },
	},
	// Desktop styles
	[minScreenWidth.small]: {
		"&.active, &.active:hover": { backgroundColor: theme?.colors.Grape400 },
	},
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const StyledNavLink = styled(NavLink)(linkFunction);

export const HighlightedLink = styled(StyledNavLink)(({ theme }) => ({
	color: theme?.colors.Grape500,
}));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const StyledFakeNavLink = styled.div(linkFunction);
