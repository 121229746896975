import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";

// CC
import FIGDefault from "CCW-Components/CCW-FIGDefault";
import FormContainer from "Pages/Measure/Components/FormContainer";
import FIGDropDownInput from "CCW-Components/CCW-FIGDropDownInputNew";
import ButtonLoader from "CCW-Components/CCW-ButtonLoader";

// Hooks
import useAlert from "Hooks/UseAlert";

// Helpers
import { emissionTypesWithUtility } from "Pages/Measure/Helpers";
import createUtility from "Helpers/NetworkingHelpers/CreateUtility";
import isError from "Helpers/TypeHelpers/IsError";
import { states } from "Helpers/StringHelpers/Address";

// Types
import type CreateUtilityProps from "./types";

// Form Data
import { validationSchema, initialValues } from "./FormData";

export const CreateUtility: React.FC<CreateUtilityProps> = ({
	onSuccess,
	...props
}) => {
	// Hooks
	const [, reportAlert] = useAlert();
	const navigate = useNavigate();

	// Normal state
	return (
		<div {...props}>
			{" "}
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				enableReinitialize
				onSubmit={async (values, { setSubmitting, resetForm }) => {
					setSubmitting(true);
					try {
						await createUtility(values);
						navigate({
							pathname: "/utilities",
						});
						onSuccess();
						reportAlert("Utility successfully created", "success");
						resetForm();
					} catch (error) {
						isError(error) && reportAlert(error.message, "error");
					} finally {
						setSubmitting(false);
					}
				}}>
				{({ isSubmitting, dirty }) => {
					return (
						<Form>
							<FormContainer>
								<FIGDefault
									id="name"
									name="name"
									label="Name"
									data-testid="name"
									placeholder="Name of the utility"
								/>
								<FIGDropDownInput
									id="emissionType"
									name="emissionType"
									label="Emission Type"
									options={emissionTypesWithUtility.map((option) => {
										return { label: option, value: option };
									})}
								/>
								<FIGDropDownInput
									id="states"
									name="states"
									label="States"
									isMultiple
									options={states.map((option) => {
										return { label: option, value: option };
									})}
								/>
								<ButtonLoader
									name="submit"
									type="submit"
									disabled={isSubmitting || !dirty}
									loading={isSubmitting}>
									Submit
								</ButtonLoader>
							</FormContainer>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default CreateUtility;
