import React from "react";

// Types
import type IconProps from "Types/IconProps";

export const UploadCloudIcon: React.FC<IconProps> = ({
	width,
	height,
	className = "upload-cloud-icon",
	fill,
	id,
	...props
}) => (
	<svg
		{...(width || (!height && !width) ? { width: width ?? "116px" } : {})}
		{...{ className, id, height, ...props }}
		fill="none"
		viewBox="0 0 116 116"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fill={fill || "#828282"}
			d="m87.79 94.522h-16.62c-0.7956 0-1.5587-0.3161-2.1213-0.8787s-0.8787-1.3257-0.8787-2.1213c0-0.7957 0.3161-1.5587 0.8787-2.1213s1.3257-0.8787 2.1213-0.8787h16.62c5.5743-0.079 10.921-2.221 15.009-6.0121 4.087-3.7911 6.624-8.9623 7.122-14.515 0.497-5.5526-1.081-11.092-4.43-15.55-3.348-4.4571-8.2295-7.5151-13.701-8.5834-0.6641-0.1205-1.2682-0.4615-1.7146-0.9677-0.4464-0.5063-0.709-1.1484-0.7454-1.8223-0.3231-8.0809-3.7606-15.723-9.5923-21.326-5.8317-5.6033-13.605-8.7326-21.693-8.7326-8.0874 0-15.861 3.1293-21.693 8.7326-5.8318 5.6032-9.2692 13.246-9.5923 21.326-0.0359 0.6725-0.2971 1.3135-0.7415 1.8195-0.4444 0.5061-1.0462 0.8479-1.7085 0.9705-5.4647 1.065-10.342 4.1148-13.692 8.5618-3.3498 4.447-4.9354 9.9764-4.451 15.523s3.0043 10.717 7.0742 14.516c4.07 3.799 9.402 5.9573 14.969 6.059h16.61c0.7957 0 1.5587 0.3161 2.1213 0.8787s0.8787 1.3256 0.8787 2.1213c0 0.7956-0.3161 1.5587-0.8787 2.1213s-1.3256 0.8787-2.1213 0.8787h-16.61c-6.8702-0.0874-13.478-2.6495-18.612-7.216-5.1336-4.5666-8.4482-10.831-9.3356-17.644-0.88736-6.8132 0.71206-13.718 4.5048-19.447 3.7927-5.7291 9.5243-9.8983 16.143-11.742 0.9426-9.1797 5.2563-17.684 12.107-23.866 6.8503-6.183 15.75-9.6055 24.978-9.6055s18.128 3.4225 24.978 9.6055c6.8503 6.1829 11.164 14.687 12.107 23.866 6.632 1.8301 12.379 5.9942 16.185 11.726 3.805 5.7315 5.412 12.645 4.524 19.467-0.887 6.8222-4.209 13.094-9.354 17.662-5.144 4.5674-11.766 7.1228-18.645 7.1957z"
		/>
		<path
			fill={fill || "#4F4F4F"}
			d="m84.52 74.742c-0.3288 0.3145-0.7173 0.5601-1.1425 0.7222s-0.8785 0.2374-1.3333 0.2215c-0.4548-0.0158-0.9018-0.1225-1.3147-0.3138s-0.7833-0.4633-1.0895-0.7999l-18.19-19.89v52c0.0253 0.469-0.0453 0.939-0.2076 1.38-0.1622 0.441-0.4126 0.845-0.7359 1.186s-0.7127 0.613-1.1445 0.798c-0.4318 0.186-0.8969 0.282-1.367 0.282-0.47 0-0.9351-0.096-1.3669-0.282-0.4318-0.185-0.8212-0.457-1.1446-0.798-0.3233-0.341-0.5737-0.745-0.7359-1.186s-0.2328-0.911-0.2076-1.38v-52l-18.14 19.89c-0.3061 0.3366-0.6766 0.6086-1.0895 0.7999s-0.8599 0.298-1.3147 0.3138c-0.4547 0.0159-0.9081-0.0594-1.3333-0.2215s-0.8137-0.4077-1.1425-0.7222c-0.6779-0.6449-1.0755-1.5302-1.1073-2.4653s0.3048-1.8453 0.9373-2.5347l24.17-26.42c0.3213-0.3495 0.7116-0.6284 1.1462-0.8192 0.4347-0.1908 0.9041-0.2894 1.3788-0.2894s0.9442 0.0986 1.3788 0.2894c0.4347 0.1908 0.825 0.4697 1.1462 0.8192l24.17 26.42c0.6262 0.6958 0.9539 1.6098 0.9128 2.545s-0.448 1.8168-1.1329 2.455z"
		/>
	</svg>
);

export default UploadCloudIcon;
