import styled from "@emotion/styled";

export const Container = styled.div(() => ({
	display: "flex",
	justifyContent: "space-between",
	marginBottom: "0.5rem",
}));

export const Label = styled.label(({ theme }) => ({
	marginBottom: "0px",
	color: theme.colors.D1,
	...theme.webFonts.F6Bold,
}));

export const AssessmentContainer = styled.div(() => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
}));

export const PasswordInfo = styled.span(({ theme }) => ({
	color: theme.colors.D3,
	...theme.webFonts.F6,
	marginRight: "16px",
}));

export const Asterisk = styled.span(({ theme }) => ({
	color: theme.colors.D1,
	...theme.webFonts.F5Bold,
}));
