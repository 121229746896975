import useSWR from "swr";

// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";
import { stripEmpty } from "Helpers/ObjectHelpers";

// Types
import type { JSONApi, DataHookResponse, ResourceObject } from "Types/JSONApi";
import User from "Types/User";
import Account from "Types/Account";

type Relationships = {
	accounts: {
		links: { related: string };
		data: { type: "accounts"; id: string }[];
	};
};

type Related = {
	accounts: Account[];
}[];

type Included = ResourceObject<Account, undefined, "accounts">[];

type Response = JSONApi<User[], Relationships, Included, undefined, Related>;

/**
 * Get ALL the users. For admin use only.
 * @param pagination limits and offsets for pagination
 * @param includeAccounts Should the response include accounts in it's relationships
 * @param filters Optional filters to apply to the dataset
 * @param bypassFetch Optional variable to bypass the fetch (useful if you want to have the hook do nothing, i.e. conditionally fetch)
 * @returns a DataHookResponse tuple of Users, Error, Mutate, Related, the full response and pagination options
 */
const useUsers = (
	includeAccounts?: boolean,
	pagination?: {
		limit: number;
		offset: number;
	},
	filters?: { search?: string; account?: string; id?: string },
	bypassFetch?: boolean,
): DataHookResponse<User[], Related, Response> => {
	const queryString = new URLSearchParams({
		...(includeAccounts ? { include: "account" } : {}),
		...(pagination?.limit && pagination?.offset
			? {
					"page[limit]": pagination.limit.toString(),
					"page[offset]": pagination.offset.toString(),
			  }
			: {}),
		...(filters ? stripEmpty(filters, "nullish") : {}),
	}).toString();

	const { data, error, mutate } = useSWR<
		JSONApi<User[], Relationships, Included, undefined, Related>,
		Error
	>(
		bypassFetch
			? null
			: [
					`${import.meta.env.VITE_BASE_URL}executive/users${
						queryString ? "?" + queryString : ""
					}`,
					1,
			  ],
		getReq,
		{
			shouldRetryOnError: false,
		},
	);

	const [users, related, fullJSONApiResponse, paginationDetails] =
		transformAPIResponse(data, []);

	return [
		users,
		error,
		mutate,
		related,
		fullJSONApiResponse,
		paginationDetails,
	];
};

export default useUsers;
