import React from "react";

// Styles
import { Container, PrimaryImage, SecondaryImage } from "./styles";

// Types
import type HeroImageProps from "./types";

export const HeroImage: React.FC<HeroImageProps> = ({
	primaryImageSRC,
	secondaryImageSRC,
	isReverseOrder,
	...props
}) => {
	// Normal state
	return (
		<Container {...props} isReverseOrder={isReverseOrder}>
			<PrimaryImage src={primaryImageSRC} />
			<SecondaryImage src={secondaryImageSRC} />
		</Container>
	);
};

export default HeroImage;
