// Helpers
import postReq from "Helpers/NetworkingHelpers/PostReq";

// Types
import type { ResetUserPassword } from "Types/User";

/**
 * @async
 * Reset a user's password
 * @param newPasswordDetails The new password, confirmed new password and token
 * @returns void
 */
const resetPassword = async (
	newPasswordDetails: ResetUserPassword,
): Promise<void> => {
	await postReq(
		`${import.meta.env.VITE_BASE_URL}executive/auth/reset-password`,
		newPasswordDetails,
		1,
		undefined,
		true,
	);
	return;
};

export default resetPassword;
