import React, { PropsWithChildren } from "react";

// Styles
import Row from "./styles";

const ProfileHalfInputRow: React.FC<PropsWithChildren<unknown>> = (props) => (
	<Row {...props} />
);

export default ProfileHalfInputRow;
