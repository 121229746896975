import useSWR from "swr";

// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import type { JSONApi, DataHookResponse } from "Types/JSONApi";
import type Referral from "Types/Referrals";

type Response = JSONApi<Referral, undefined, undefined, undefined, undefined>;

/**
 * Validate a referral code
 * @param referralCode The code to validate
 * @returns a DataHookResponse tuple of ReferralResponse, Error, Mutate, Related, and the full response
 */
const useValidateReferral = (
	referralCode?: string,
): DataHookResponse<Referral, undefined, Response> => {
	const { data, error, mutate } = useSWR<
		JSONApi<Referral, undefined, undefined, undefined, undefined>,
		Error
	>(
		referralCode
			? [
					`${
						import.meta.env.VITE_BASE_URL
					}executive/referral-codes/${referralCode}`,
					1,
			  ]
			: null,
		getReq,
		{
			shouldRetryOnError: false,
		},
	);

	const [referralValidation, related, fullJSONApiResponse] =
		transformAPIResponse(data, {});

	return [referralValidation, error, mutate, related, fullJSONApiResponse];
};

export default useValidateReferral;
