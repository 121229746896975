/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Typeguard used to test if an object is, for our purposes, an error
 * Usage: Typically found in a catch block, use to test the caught object with an if statement before attempting to access message or name fields.
 * @param err any object of type any or unknown
 * @returns object is Error (typeguard boolean)
 */
const isError = (object: any | unknown): object is Error => {
	return object?.message !== undefined;
};

export default isError;
