import styled from "@emotion/styled";

// CC
import ButtonLink from "CCW-Components/CCW-ButtonLink";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

// Styles
export const MarginBottom = styled.div(() => ({
	marginBottom: "1.3rem",
	[MediaQueries[1]]: {
		marginBottom: "2.5rem",
	},
}));

export const Content = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	rowGap: "2.3rem",
	marginBottom: "2.5rem",
	[MediaQueries[1]]: {
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "space-between",
		columnGap: "2rem",
		marginBottom: "4rem",
	},
}));

export const ButtonContainer = styled.div<{ isMobileIcon: boolean }>(
	({ isMobileIcon }) => ({
		display: "flex",
		flexDirection: "row",
		gap: "1.5rem",
		justifyContent: isMobileIcon ? "space-between" : "unset",
		flexWrap: "wrap",
		"& > button": {
			flexShrink: "0",
			flexGrow: "1",
			flexBasis: "50%",
			[MediaQueries[1]]: {
				flexBasis: "unset",
			},
		},
	}),
);

export const MainTitle = styled.h2(({ theme }) => ({
	...theme.webFonts.h2,
	color: theme.colors.D1,
	margin: "0 0 1.2rem",
	[MediaQueries[1]]: {
		marginBottom: "1.5rem",
	},
}));

export const MainDescription = styled.span(({ theme }) => ({
	...theme.webFonts.F6,
	color: theme.colors.D2,
}));

export const Button = styled(ButtonLink)<{ isMobileIcon: boolean }>(
	({ isMobileIcon }) => ({
		display: isMobileIcon ? "none" : "block",
		[MediaQueries[1]]: {
			display: "block",
		},
	}),
);

export const IconButton = styled(ButtonLink)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	padding: "1.4rem 1.6rem",
	...theme.webFonts.F6,
	...theme.webFonts.bold,
	color: theme.colors.D2,
	gap: "1.2rem",
	"& > svg > path, g > path": {
		fill: theme.colors.primary,
	},
	[MediaQueries[0]]: {
		minWidth: "13.2rem",
	},
	[MediaQueries[1]]: {
		display: "none",
	},
	"&:hover, :focus": {
		background: theme.colors.primary,
		color: theme.colors.White,
		"& > svg > path, g > path": {
			fill: theme.colors.White,
		},
	},
}));

export const FlexChild = styled.div({
	flex: "1 1 50%",
});
