import React from "react";
import { useParams } from "react-router-dom";

// Styles
import { Container, ChartLink } from "./styles";

// Types
import ChartSelectorProps from "./types";

const ChartSelector: React.FC<ChartSelectorProps> = ({ charts = [] }) => {
	// Hooks
	const params = useParams();

	return (
		<Container>
			{charts.map(({ name, ref }, index) => (
				<ChartLink
					key={`${name}-${index}`}
					to={`/track/${ref}`}
					selected={ref === params?.chartId}>
					{name}
				</ChartLink>
			))}
		</Container>
	);
};

export default ChartSelector;
