import * as Yup from "yup";

export const initValues = {
	oldPassword: "",
	password: "",
	confirmPassword: "",
	image: "",
};

export const passwordValidation = Yup.object({
	password: Yup.string().min(8, "Password must be at least 8 characters"),
	oldPassword: Yup.string().when("newPassword", {
		is: (value: string) => !!value,
		then: (schema) => schema.required("Please enter your old password"),
	}),
	confirmPassword: Yup.string().when("password", {
		is: (value: string) => !!value,
		then: (schema) =>
			schema
				.oneOf([Yup.ref("password")], "Passwords do not match")
				.required("Please confirm your new password"),
	}),
});

export const validation = passwordValidation.shape({
	fName: Yup.string()
		.required("Please enter a first name")
		.max(15, "Must be 15 characters or less"),
	lName: Yup.string()
		.required("Please enter a first name")
		.max(20, "Must be 20 characters or less"),
	email: Yup.string().email("Invalid email address"),
	image: Yup.mixed(),
	salesCalls: Yup.boolean(),
	newsletter: Yup.boolean(),
	marketingEmails: Yup.boolean(),
});
