import React from "react";

// CC
import CardFormTemplate from "CCW-Components/CCW-CardFormTemplate";
import ButtonLink from "CCW-Components/CCW-ButtonLink";

// Local CC
import ResetPasswordForm from "../ResetPasswordForm";

// Styles
import { Card } from "./styles";

const DefaultResetPasswordPage: React.FC = () => {
	// Hooks
	const searchParams: URLSearchParams = new URLSearchParams(location.search);
	const token = searchParams.get("token");

	if (token == null || token == "") {
		return (
			<CardFormTemplate>
				<Card
					title="There was an error with your request"
					subtitle="Please click below to reset your password.">
					<ButtonLink to="/set-reset-password" type="button" block>
						Reset password
					</ButtonLink>
				</Card>
			</CardFormTemplate>
		);
	}

	return (
		<CardFormTemplate>
			<ResetPasswordForm token={token} />
		</CardFormTemplate>
	);
};

export default DefaultResetPasswordPage;
