import styled from "@emotion/styled";

// Styles
export const Container = styled.div(() => ({}));

export const Group = styled.div(({ theme }) => ({
	...theme.webFonts.F7,
	padding: "1rem 0",
}));

export const GroupName = styled.div(({ theme }) => ({
	...theme.webFonts.bold,
	color: theme.colors.grey500,
	marginBottom: "1rem",
}));

export const Options = styled.div(({ theme }) => ({
	display: "flex",
	flexWrap: "wrap",
	columnGap: "0.4rem",
	rowGap: "0.6rem",
}));

export const Option = styled.button<{
	isSelected: boolean;
	isAddButton?: boolean;
}>(({ theme, isSelected, isAddButton }) => ({
	border: "none",

	transition: "all 0.3s ease",
	color: isSelected
		? theme.colors.grey50
		: isAddButton
		? theme.colors.Blue400
		: theme.colors.grey600,
	background: isSelected
		? theme.colors.Blue400
		: isAddButton
		? theme.colors.grey50
		: theme.colors.grey100,
	padding: "0.6rem 1rem",
	borderRadius: "1.5rem",
	cursor: "pointer",
	whiteSpace: "break-spaces",
	...(isAddButton && { ...theme.webFonts.bold }),
	"&:hover": {
		boxShadow: theme.shadow,
	},
}));
