import React from "react";

// Styles
import {
	FormatMessage,
	Table,
	ColumnItemHeading,
	ColumnItemValue,
	Instructions,
	Row,
} from "./styles";

// Types
import type CSVRequirementsProps from "./types";

export const CSVRequirements: React.FC<CSVRequirementsProps> = ({
	requirements,
}) => {
	return (
		<>
			<FormatMessage>
				Please format the CSV file with the following fields
				<p>
					(
					{requirements.map(({ header }, index) =>
						index === requirements.length - 1 ? `${header}` : `${header}, `,
					)}
					):
				</p>
			</FormatMessage>
			<Table>
				<tbody>
					<Row>
						{requirements.map(({ header }, index) => (
							<ColumnItemHeading key={`heading ${index} - ${header}`}>
								{header}
							</ColumnItemHeading>
						))}
					</Row>
					<Row>
						{requirements.map(({ instruction }, index) => (
							<ColumnItemValue key={`value ${index} - ${instruction}`}>
								{instruction}
							</ColumnItemValue>
						))}
					</Row>
				</tbody>
			</Table>
			{requirements.map(
				({ additionalInstruction }, index) =>
					additionalInstruction && (
						<Instructions key={`requirement ${index} instructions`}>
							{`*${additionalInstruction}`}
						</Instructions>
					),
			)}
		</>
	);
};

export default CSVRequirements;
