import * as Yup from "yup";

// Types

export const initialValues = {
	name: "",
	states: [],
	emissionType: "",
};

export const validationSchema = Yup.object().shape({
	name: Yup.string().required("Name of the provider is required"),
	emissionType: Yup.string()
		.oneOf(["electricity", "water", "waste", "gas", "lpg"])
		.required("Select an emission type"),
	states: Yup.array(Yup.string().required("At least one state is required")),
});
