import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";

// CC
import TextInputNumber from "CCW-Components/CCW-TextInputNumber";
import Button from "CCW-Components/CCW-CCButton";
import ButtonLoader from "CCW-Components/CCW-ButtonLoader";
import FIGDefault from "CCW-Components/CCW-FIGDefault";
import FIGDropDown from "CCW-Components/CCW-FIGDropDownInputNew";
import CheckBox from "CCW-Components/CCW-CheckBoxFormik";
import InputLabel from "CCW-Components/CCW-InputLabel";
import FIGFileInput from "CCW-Components/CCW-FIGFileInput";
import CustomButtonLink from "CCW-Components/CCW-CustomButtonLink";
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import Container from "CCW-Components/CCW-Container";

// Hooks
import useAlert from "Hooks/UseAlert";

// Helpers
import createPartner from "Helpers/NetworkingHelpers/CreatePartner";
import replacePartnerImage from "Helpers/NetworkingHelpers/ReplacePartnerImage";
import isError from "Helpers/TypeHelpers/IsError";
import { stripEmpty } from "Helpers/ObjectHelpers";

// Assets
import ArrowIcon from "Assets/SVG/ArrowIcon";

// Styles
import {
	FormContainer,
	HeaderContainer,
	Header,
	BackButton,
	InfoCard,
	SectionTitle,
	Row,
	PostcodeSection,
	PostcodeList,
	CheckBoxRow,
} from "./styles";

// Types
import type { CreatePartnerFormProps } from "./types";
import { CreatePartnerData } from "Types/Partner";

// Form data
import { initValues, validation } from "./FormLogic";
import { stateOptions, partnerOptions, planOptions } from "./Data";

export const CreatePartnerForm: React.FC<CreatePartnerFormProps> = () => {
	// States
	const [postcode, setPostcode] = useState<number | undefined>();

	// Hooks
	const [, reportAlert] = useAlert();
	const navigate = useNavigate();

	// Actions
	const handleInputPostcode = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPostcode(Number(event.target.value));
	};

	const addPostcode = (postcodes?: number[], postcode?: number) => {
		if (postcodes == null) {
			postcodes = [];
		}
		postcode != null && postcodes != null && postcodes.push(postcode);
		setPostcode(undefined);
		return postcodes;
	};

	return (
		<DefaultPage onNavigation={true} defaultPadding={true}>
			<Container>
				<Formik
					initialValues={initValues}
					validationSchema={validation}
					onSubmit={async (values, { setSubmitting, resetForm }) => {
						setSubmitting(true);
						try {
							const { banner, logo, homeDiscount, ...partnerValues } = values;
							const [newPartner] = await createPartner(
								stripEmpty(partnerValues, "all") as CreatePartnerData,
							);
							if (newPartner?._id) {
								await Promise.all([
									replacePartnerImage(newPartner?._id, logo, "logo"),
									replacePartnerImage(newPartner._id, banner, "banner"),
								]);
								reportAlert("Partner has been successfully created", "success");
								values.postcodes = [];
								resetForm();
								navigate("/partners");
							} else {
								throw new Error(
									"Partner creation failed. Please contact support",
								);
							}
						} catch (err) {
							reportAlert(
								isError(err)
									? err.message
									: "Partner creation failed. Please contact support",
								"error",
							);
						} finally {
							setSubmitting(false);
						}
					}}
					enableReinitialize={true}>
					{({ values, isSubmitting, setFieldValue, errors }) => {
						if (values.type === "lga") {
							!values.businessDiscount &&
								setFieldValue("businessDiscount", true);
							!values.schoolDiscount && setFieldValue("schoolDiscount", true);
						} else {
							!!values.state && setFieldValue("state", "");
							!!values.postcodes?.length && setFieldValue("postcodes", []);
						}

						return (
							<FormContainer>
								<React.Fragment>
									<HeaderContainer>
										<CustomButtonLink
											to={"/partners"}
											button={BackButton}
											type="submit">
											<ArrowIcon width="12px" height="12px" direction="left" />
											Back to partners
										</CustomButtonLink>
										<Header>Add Partner</Header>
									</HeaderContainer>
									<InfoCard>
										<SectionTitle>Partner details</SectionTitle>
										<Row>
											<FIGDefault
												id="partnerName"
												name="name"
												type="text"
												label="Name"
											/>
											<FIGDropDown
												id="partnerType"
												name="type"
												label="Type"
												options={partnerOptions}
											/>
										</Row>
										{values.type === "lga" ? (
											<Row>
												<FIGDropDown
													id="partnerState"
													name="state"
													label="State"
													options={stateOptions}
												/>
												<PostcodeSection>
													<InputLabel
														text="Postcodes"
														htmlFor="partnerPostcode"
													/>
													<Row>
														<TextInputNumber
															onChange={handleInputPostcode}
															min={100}
															max={9999}
															step="int"
															id="partnerPostcode"
															name="postcodes"
															value={postcode?.toString() ?? ""}
														/>
														<Button
															variant="linkWashed"
															type="button"
															onClick={() =>
																addPostcode(values.postcodes, postcode)
															}>
															{values.postcodes != null &&
															values.postcodes.length > 0
																? "Add another postcode"
																: "Add postcode"}
														</Button>
													</Row>
													{values.postcodes != null &&
													values.postcodes.length > 0 ? (
														<Row>
															<p>Postcode list:</p>
															<PostcodeList>
																{values.postcodes.map(
																	(postcode: number, index: number) => (
																		<li key={`${postcode}${index}`}>
																			{postcode}
																			{values.postcodes != null &&
																			values.postcodes.length > 1
																				? "|"
																				: null}
																		</li>
																	),
																)}
															</PostcodeList>
														</Row>
													) : null}
												</PostcodeSection>
											</Row>
										) : null}
										<CheckBoxRow>
											<CheckBox
												label="Does the partner have access to the ClimateClever API?"
												id="api"
												name="api"
											/>
										</CheckBoxRow>
										{values.type !== "lga" ? (
											<React.Fragment>
												<p>Choose all the relevant discounts:</p>
												<CheckBoxRow>
													<CheckBox
														label="School discount"
														id="School-discount"
														name="schoolDiscount"
													/>
													<CheckBox
														label="Business discount"
														id="Business-discount"
														name="businessDiscount"
													/>
													<CheckBox
														label="Homes discount"
														id="homeDiscount"
														name="homeDiscount"
														checked
														disabled
													/>
												</CheckBoxRow>
											</React.Fragment>
										) : null}
									</InfoCard>
									<InfoCard>
										<SectionTitle>User details</SectionTitle>
										<Row>
											<FIGDefault
												id="fName"
												name="fName"
												type="text"
												label="First Name"
											/>
											<FIGDefault
												id="lName"
												name="lName"
												type="text"
												label="Last Name"
											/>
										</Row>
										<Row>
											<FIGDefault
												id="email"
												name="email"
												type="email"
												label="Email"
											/>
										</Row>
									</InfoCard>
									<InfoCard>
										<SectionTitle>Payment</SectionTitle>
										<Row>
											<FIGDropDown
												id="partnerPlan"
												name="tier"
												label="Tier"
												options={planOptions}
											/>
										</Row>
									</InfoCard>
									<InfoCard>
										<SectionTitle>Images</SectionTitle>
										<Row>
											<FIGFileInput
												id="upload-logo"
												name="logo"
												label="Upload Logo (.png only / Logo must be a circle with white background)"
												accept="image/png"
											/>
										</Row>
										<Row>
											<FIGFileInput
												id="upload-banner"
												name="banner"
												label="Upload Banner (.png only)"
												accept="image/png"
											/>
										</Row>
									</InfoCard>
									<ButtonLoader type="submit" loading={isSubmitting}>
										Add partner
									</ButtonLoader>
								</React.Fragment>
							</FormContainer>
						);
					}}
				</Formik>
			</Container>
		</DefaultPage>
	);
};

export default CreatePartnerForm;
