import React from "react";
import styled from "@emotion/styled";

// Types
import type IconProps from "Types/IconProps";

export const StyledSVG = styled.svg<{ opened: boolean }>(
	({ theme, opened }) => ({
		"& .line": {
			fill: "none",
			stroke: opened ? theme.colors.primary : "currentColor",
			strokeWidth: "6",
			transition:
				"stoke 400ms ease, stroke-dasharray 400ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 400ms cubic-bezier(0.4, 0, 0.2, 1)",
		},
		"& .line1": {
			strokeDasharray: "60 207",
			strokeWidth: "6",
		},
		"& .line2": {
			strokeDasharray: "60 60",
			strokeWidth: "6",
		},
		"& .line3": {
			strokeDasharray: "60 207",
			strokeWidth: "6",
		},
		...(opened && {
			"& .line1": {
				strokeDasharray: "90 207",
				strokeDashoffset: "-134",
				strokeWidth: "6",
			},
			"& .line2": {
				strokeDasharray: "1 60",
				strokeDashoffset: "-30",
				strokeWidth: "6",
			},
			"& .line3": {
				strokeDasharray: "90 207",
				strokeDashoffset: "-134",
				strokeWidth: "6",
			},
		}),
	}),
);

const BurgerIcon: React.FC<IconProps & { opened?: boolean }> = ({
	width,
	height,
	id,
	className = "burger-icon",
	fill,
	opened = false,
	...props
}) => {
	return (
		<StyledSVG
			fill={fill || "currentColor"}
			{...(width || (!height && !width) ? { width: width ?? "100px" } : {})}
			{...{ id, height, opened, ...props }}
			viewBox="0 0 100 100">
			<path
				className="line line1"
				d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
			/>
			<path className="line line2" d="M 20,50 H 80" />
			<path
				className="line line3"
				d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
			/>
		</StyledSVG>
	);
};

export default BurgerIcon;
