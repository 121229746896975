import styled from "@emotion/styled";

// CC
import Button from "CCW-Components/CCW-CCButton";

// Helpers
import mediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Content = styled.div(() => ({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	[mediaQueries[0]]: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
}));

export const Title = styled.p(({ theme }) => ({
	...theme.webFonts.F6SemiBold,
	color: theme.colors.D3,
}));

export const Cancel = styled(Button)(() => ({
	marginRight: "24px",
}));

export const Actions = styled.div(() => ({
	width: "100%",
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	"& > button": {
		flex: "1 1 0px",
		maxWidth: "45%",
	},
	[mediaQueries[0]]: {
		width: "auto",
	},
}));
