import React from "react";

// Types
import IconProps from "Types/IconProps";

export const ChevronLeftIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "chevron-left-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "7px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 7 12"
			fill={fill || "currentColor"}
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.70711 0.292893C7.09763 0.683417 7.09763 1.31658 6.70711 1.70711L2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.097631 6.31658 -0.097631 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893Z"
			/>
		</svg>
	);
};

export default ChevronLeftIcon;
