import React, { PropsWithChildren, useState } from "react";

// Context
import Context from "../IntercomContext";

// Types
import type { IntercomContext } from "../IntercomContext/types";

const IntercomContextProvider: React.FC<PropsWithChildren> = (props) => {
	const [userHash, setUserHash] = useState<IntercomContext>({
		hash: undefined,
		updateHash: (nextHash) => {
			setUserHash((oldContext) => ({
				...oldContext,
				hash: nextHash,
			}));
		},
	});

	return <Context.Provider value={userHash}>{props.children}</Context.Provider>;
};

export default IntercomContextProvider;
