import React from "react";

// Helpers
import { groupBy } from "Helpers/ArrayHelpers";

// Styles
import { Container, Group, GroupName, Options, Option } from "./styles";

// Types
import type { TagPickerProps, Option as OptionType } from "./types";

export const TagPicker: React.FC<TagPickerProps> = ({
	options,
	displayNames,
	onChange,
	disableDeselect = false,
	...props
}) => {
	// Hooks
	const groups = groupBy(options, (option: OptionType) => option.group);

	// Normal state
	return (
		<Container {...props}>
			{Object.entries(groups).map(([groupName, options]) => (
				<Group key={groupName}>
					{displayNames?.[groupName] && (
						<GroupName>{displayNames[groupName]}</GroupName>
					)}
					<Options>
						{options.map(({ display, group, value, isSelected }) => (
							<Option
								isAddButton={value === "create"}
								type="button"
								key={value}
								isSelected={isSelected}
								style={
									disableDeselect && isSelected
										? { cursor: "auto", pointerEvents: "none" }
										: {}
								}
								onClick={() => {
									onChange({
										display,
										value,
										group,
										isSelected: disableDeselect ? true : !isSelected,
									});
								}}>
								{display}
							</Option>
						))}
					</Options>
				</Group>
			))}
		</Container>
	);
};

export default TagPicker;
