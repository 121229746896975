import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// CC
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import AppError from "CCW-Components/CCW-AppError";
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import CCContainer from "CCW-Components/CCW-Container";
import { DropDownInputConverter as DropDown } from "CCW-Components/CCW-FIGDropDownInputNew";
import FactorRow from "CCW-Components/CCW-FactorRow";
import PaginationNavBar from "CCW-Components/CCW-PaginationNavBar";

// Hooks
import useRenewableEnergyTargets from "Hooks/UseRenewableEnergyTargets";

// Styles
import { PageTitle, TargetsNavBar, AddButton, RowContainer } from "./styles";

// Types
import type DefaultRETargetsPageProps from "./types";
import type { State } from "Types/Address";

export const DefaultRETargetsPage: React.FC<DefaultRETargetsPageProps> = () => {
	// State
	const [selectedState, setSelectedState] = useState<State | undefined>();
	const [selectedYear, setSelectedYear] = useState<number | undefined>();
	const [selectedPage, setSelectedPage] = useState<number>(1);

	// Hooks
	const [targets, targetsError, , pagination] =
		useRenewableEnergyTargets(selectedPage);
	const navigate = useNavigate();

	// Helpers
	const years = targets && targets.map((target) => target.year);
	const includedYears = [...Array.from(new Set(years))];

	// Error state
	if (targetsError) {
		return <AppError error={targetsError} />;
	}

	// Loading state
	if (!targets) {
		return <BlockLoader message="Loading targets..." />;
	}

	// Normal state
	return (
		<DefaultPage>
			<CCContainer>
				<PageTitle>Renewable Energy Targets</PageTitle>
				<TargetsNavBar>
					<DropDown
						name="state"
						plain
						placeholder="Select state"
						value={selectedState}
						inlineLabel={"State"}
						onSelect={(_name, value) =>
							setSelectedState(value.length ? (value as State) : undefined)
						}
						options={[
							{ label: "ALL", value: "" },
							{ label: "WA", value: "WA" },
							{ label: "NSW", value: "NSW" },
							{ label: "NT", value: "NT" },
							{ label: "QLD", value: "QLD" },
							{ label: "SA", value: "SA" },
							{ label: "TAS", value: "TAS" },
							{ label: "ACT", value: "ACT" },
							{ label: "VIC", value: "VIC" },
						]}
					/>
					<DropDown
						name="year"
						plain
						placeholder="Select year"
						value={selectedYear?.toString()}
						inlineLabel="Year"
						onSelect={(_name, value) =>
							setSelectedYear(value.length ? parseInt(value) : undefined)
						}
						options={[
							{ label: "ALL", value: "" },
							...includedYears.sort().map((year) => {
								return { label: `${year}`, value: `${year}` };
							}),
						]}
					/>
					<AddButton onClick={() => navigate("./create")}>
						Add new target
					</AddButton>
				</TargetsNavBar>
				<RowContainer>
					{targets &&
						targets.map((target, index) => {
							if (
								(selectedState && selectedState !== target.state) ||
								(selectedYear && selectedYear !== target.year)
							) {
								return;
							}
							return (
								<FactorRow
									isShortHeading={true}
									key={index}
									rowData={target}
									headingText={target.state}
									index={index}
									propertyTags={["year", "yearType"]}
									actionTagText={`${target.percentage.toFixed(2)}%`}
									actionTagPopupContent={
										<div>Target: {target.percentage}%</div>
									}
								/>
							);
						})}
				</RowContainer>
				{pagination && (
					<PaginationNavBar
						selectedPage={selectedPage}
						actionButtonText="+ Add new target"
						actionOnClick={() => navigate("./create")}
						pageCount={pagination.pageCount}
					/>
				)}
			</CCContainer>
		</DefaultPage>
	);
};

export default DefaultRETargetsPage;
