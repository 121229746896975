import styled from "@emotion/styled";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Row = styled.div(() => ({
	[MediaQueries[0]]: {
		display: "flex",
		flexDirection: "row",
		"> *": {
			width: "calc(50% - 15px)",
		},
	},
}));
export default Row;
