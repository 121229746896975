import useSWR from "swr";

// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import type { JSONApi, DataHookResponse } from "Types/JSONApi";
import type { State } from "Types/Address";
import type { MeasureCategoryWithUtilities } from "Types/MeasureCategory";
import type Utility from "Types/Utility";

type Response = JSONApi<Utility[], undefined, undefined, undefined, undefined>;

/**
 * Get the Utilities
 * @param emissionType The emission source type (i.e., electricity)
 * @param state The state the provider operates in
 * @returns a DataHookResponse tuple of Utilities, Error, Mutate, Related, the full response and pagination options
 */

const useUtilities = (
	state?: State,
	emissionType?: MeasureCategoryWithUtilities,
): DataHookResponse<Utility[], undefined, Response> => {
	const queryString = new URLSearchParams({
		...(emissionType ? { emissionType } : {}),
		...(state ? { state } : {}),
	}).toString();
	const { data, error, mutate } = useSWR<
		JSONApi<Utility[], undefined, undefined, undefined, undefined>,
		Error
	>(
		[
			`${import.meta.env.VITE_BASE_URL}measure/utilities${
				queryString ? "?" + queryString : ""
			}`,
			2,
			false,
			"JSONAPI",
		],
		getReq,
		{
			shouldRetryOnError: false,
		},
	);

	const [utilities, related, fullJSONApiResponse] = transformAPIResponse(
		data,
		[],
	);
	return [utilities, error, mutate, related, fullJSONApiResponse];
};

export default useUtilities;
