import React from "react";

// Assets
import CrossIcon from "Assets/SVG/CrossIcon";

// Styles
import Button from "./styles";

// Types
import type CloseButtonProps from "./types";

export const CloseButton: React.FC<CloseButtonProps> = ({
	useWhiteColorFill,
	...props
}) => {
	return (
		<Button
			aria-label="close"
			type="button"
			{...props}
			{...{ useWhiteColorFill }}>
			<CrossIcon width="1.225rem" height="1.225rem" />
		</Button>
	);
};

export default CloseButton;
