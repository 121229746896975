import styled from "@emotion/styled";

import TextInput from "../CCW-TextInput";

export const Container = styled.div(() => ({
	position: "relative",
}));

export const StyledTextInput = styled(TextInput)(() => ({
	paddingRight: "5rem",
}));
