import styled from "@emotion/styled";

// CC
import CloseButtonLink from "../CCW-CloseButtonLinkNew";

// Helpers
import mediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const ModalContent = styled.div<{ isSimpleDesign: boolean }>(
	({ theme, isSimpleDesign }) => ({
		background: theme.colors.White,
		borderRadius: "1.2rem",
		width: "100%",
		margin: "auto",
		[mediaQueries[1]]: {
			width: isSimpleDesign ? "65rem" : "47.6rem",
		},
	}),
);

export const Header = styled.div<{ isSimpleDesign: boolean }>(
	({ theme, isSimpleDesign }) => ({
		position: "relative",
		padding: isSimpleDesign ? "3rem 4rem 0" : "3rem 4rem",
		display: "flex",
		flexDirection: "column",
		borderBottom: !isSimpleDesign ? `0.1rem solid ${theme.colors.D4}` : "",
	}),
);

export const CloseButton = styled(CloseButtonLink)<{ isHidden?: boolean }>(
	({ isHidden }) => ({
		position: "absolute",
		top: "1rem",
		right: "1rem",
		visibility: isHidden ? "hidden" : "visible",
	}),
);

export const Headline = styled.h1<{ isSimpleDesign: boolean }>(
	({ theme, isSimpleDesign }) => ({
		...theme.webFonts.h2,
		color: isSimpleDesign ? theme.colors.D1 : theme.colors.primary,
		textAlign: "center",
		margin: "0.175rem",
	}),
);

export const SubHeadline = styled.h2(({ theme }) => ({
	...theme.webFonts.F6,
	...theme.webFonts.italic,
	color: theme.colors.D2,
	textAlign: "center",
	paddingTop: "0.7rem",
	margin: "0",
}));

export const ContentContainer = styled.div<{ isSimpleDesign: boolean }>(
	({ theme, isSimpleDesign }) => ({
		background: isSimpleDesign ? theme.colors.White : theme.colors.Black05,
		padding: "3.8rem",
		paddingTop: "3.5rem",
		borderRadius: "0 0 1.2rem 1.2rem ",
	}),
);
