import styled from "@emotion/styled";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

// Styles

export const Container = styled.div<{ progress?: number }>(
	({ progress, theme }) => ({
		...(progress && { position: "fixed", inset: 0 }),
		display: "flex",
		flexDirection: "column",
		background: theme.colors.Blueberry50,
	}),
);

export const MarginBottom = styled.div(() => ({
	marginBottom: "1.3rem",
	[MediaQueries[1]]: {
		marginBottom: "2.5rem",
	},
}));

export const Header = styled.div(() => ({
	position: "sticky",
	top: 0,
	zIndex: 1,
	display: "flex",
	flexDirection: "column",
	alignItems: "stretch",
	width: "100vw",
}));

export const LogoBar = styled.div<{
	justifyContent: "space-between" | "center";
}>(({ justifyContent }) => ({
	display: "flex",
	justifyContent,
	alignItems: "center",
	padding: "0 1.6rem",
	[MediaQueries[1]]: {
		padding: "0 4rem",
	},
}));

export const CCLogoBox = styled.div(() => ({
	padding: "2.5rem 0",
	[MediaQueries[1]]: {
		padding: "5rem 0",
	},
}));

export const PlatformBar = styled.div<{ isHalloween?: boolean }>(
	({ theme, isHalloween }) => ({
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: "2rem",
		padding: "1.5rem",
		background: isHalloween ? theme.colors.DarkOrange : theme.colors.selected,
		[MediaQueries[0]]: {
			width: "100vw",
		},
		[MediaQueries[1]]: {
			padding: "2.2rem 0",
		},
	}),
);

export const PlatformTitle = styled.h3(({ theme }) => ({
	...theme.webFonts.h3,
	margin: 0,
	color: theme.colors.White,
	textTransform: "capitalize",
}));

export const PartnerImage = styled.img({
	maxWidth: "30rem",
	padding: "2rem",
	maxHeight: "14rem",
});

export const PartnerName = styled.h2(({ theme }) => ({
	...theme.webFonts.h2,
	color: theme.colors.Black60,
	margin: 0,
	lineHeight: 0,
}));

export const Main = styled.div(() => ({
	flex: "1 1 auto",
	position: "relative",
	overflowY: "scroll",
}));

export const ContentBox = styled.div(() => ({
	width: "100%",
	position: "relative",
	paddingTop: "2.5rem",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	[MediaQueries[1]]: {
		paddingTop: "4rem",
	},
}));

export const Content = styled.div(() => ({
	width: "100%",
	position: "relative",
}));

export const ProgressBarBox = styled.div(({ theme }) => ({
	background: theme.colors.White,
	height: "5.6rem",
	[MediaQueries[1]]: {
		height: "7.6rem",
	},
}));
