import styled from "@emotion/styled";

export const CardContainer = styled.div(({ theme }) => ({
	backgroundColor: theme.colors.White,
	padding: "50px",
	boxShadow: theme.shadow,
	borderRadius: "12px",
}));

export const CardHeader = styled.div(() => ({
	display: "flex",
	alignItems: "center",
	"& > svg": {
		paddingRight: "15px",
	},
}));

export const CardTitle = styled.h1(({ theme }) => ({
	margin: "0px",
	...theme.webFonts.h2,
}));

export const CardSubtitle = styled.h2(({ theme }) => ({
	color: theme.colors.D3,
	margin: "8px 0px 30px 0px",
	padding: "0px",
	...theme.webFonts.F5,
}));
