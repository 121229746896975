import styled from "@emotion/styled";

const breakpoints = [576, 768, 992, 1200];

const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);

export const BackgroundStyle = styled.div({
	background:
		"linear-gradient(0deg, #eeeeee, #eeeeee), linear-gradient(55.38deg, #eeeeee 39.52%, #f2fbed 100%), linear-gradient(55.38deg, #eeeeee 39.52%, #eaf9e3 100%)",
	minHeight: "100vh",
	height: "100%",
	paddingBottom: "100px",
});

export const IconWrapper = styled.div({
	display: "none",
	paddingTop: "0px",
	[mq[1]]: {
		paddingTop: "100px",
		display: "flex",
		justifyContent: "center",
	},
});

export const ContainerStyle = styled.div({
	display: "flex",
	justifyContent: "center",
	[mq[2]]: {
		padding: "0px 15px",
	},
});

export const CardStyle = styled.div({
	margin: "15px 0px 0px 0px",
	width: "100%",
	[mq[1]]: {
		margin: "60px 0px 0px 0px",
	},
	[mq[2]]: {
		width: "540px",
	},
});
