import useSWR from "swr";

// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";
import { stripEmpty } from "Helpers/ObjectHelpers";

// Types
import type { JSONApi, DataHookResponse, ResourceObject } from "Types/JSONApi";
import Partner from "Types/Partner";
import Account from "Types/Account";

type Relationships = {
	account: {
		links: { related: string };
		data: { type: "account"; id: string };
	};
};

type Related = {
	account: Account;
}[];

type Included = ResourceObject<Account, undefined, "account">[];

type Response = JSONApi<Partner[], Relationships, Included, undefined, Related>;

/**
 * Get all the entities on the ClimateClever system
 * @param includeAccounts Should the hook include the accounts in the Related?
 * @param pagination Optional pagination parameters
 * @param filters Optional filter parameters
 * @returns a DataHookResponse tuple of Entities, Error, Mutate, Related accounts as an array, the full response and pagination options
 */
const useEntities = (
	includeAccounts: boolean,
	pagination?: {
		limit: number;
		offset: number;
	},
	filters?: {
		account?: string;
		search?: string;
		state?: string;
		lga?: string;
	},
	bypassFetch?: boolean,
): DataHookResponse<Partner[], Related, Response> => {
	const queryString = new URLSearchParams({
		...(includeAccounts ? { include: "account" } : {}),
		...(pagination?.limit && pagination?.offset
			? {
					"page[limit]": pagination.limit.toString(),
					"page[offset]": pagination.offset.toString(),
			  }
			: {}),
		...(filters ? stripEmpty(filters, "nullish") : {}),
	}).toString();

	const { data, error, mutate } = useSWR<
		JSONApi<Partner[], Relationships, Included, undefined, Related>,
		Error
	>(
		bypassFetch
			? null
			: [
					`${import.meta.env.VITE_BASE_URL}executive/entities${
						queryString ? "?" + queryString : ""
					}`,
					1,
			  ],
		getReq,
		{
			shouldRetryOnError: false,
		},
	);

	const [entities, related, fullJSONApiResponse, paginationDetails] =
		transformAPIResponse(data, []);

	return [
		entities,
		error,
		mutate,
		related,
		fullJSONApiResponse,
		paginationDetails,
	];
};

export default useEntities;
