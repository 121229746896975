import React from "react";

// Types
import type IconProps from "Types/IconProps";

const EmailIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "email-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "160px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 160 120"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				className="primary-fill"
				d="M61.28 115.26C52.6533 118.307 44.48 120 36.9933 120C16.5333 120 0 108.087 0 87.8C0 78.8133 3.04667 71.06 8.84 65.1867C15.5067 58.52 24.2733 55.26 35.5133 55.26C52.4 55.26 64.4333 66.1333 64.4333 81.28C64.4333 88.0933 61.9733 94.3267 57.5467 98.7467C53.7867 102.513 48.8467 104.693 44 104.693C41.1667 104.693 38.8533 103.607 37.76 101.867C37.4 101.287 37.3267 100.92 37.1067 99.62C34.3533 102.807 31.4533 104.187 27.3933 104.187C20.22 104.187 15.6533 98.8267 15.6533 90.56C15.6533 78.3133 23.6933 68.1667 33.3333 68.1667C37.3933 68.1667 39.42 69.18 41.38 72.2267L42.1 69.6933H50.6667C50.2333 71.14 48.9267 75.56 48.5667 77.16C43.86 94.5733 43.9333 93.8533 43.9333 95.4267C43.9333 98.4067 48.04 97.2267 50.38 95.3533C53.9333 92.6 56.2533 87.2333 56.2533 81.6533C56.2533 74.9867 53.1333 69.1867 47.92 65.9267C44.66 63.9733 40.0867 62.88 34.9467 62.88C19.6533 62.88 8.92667 73.0267 8.92667 87.38C8.92667 110.353 33.1 115.793 58 107.353L61.28 115.26ZM38.6333 80.0467C38.6333 77.0733 36.9667 75.0467 34.4267 75.0467C31.6733 75.0467 29.1333 76.8533 27.3267 80.2667C25.5867 83.5267 24.4267 87.8 24.4267 91.14C24.4267 94.98 25.8733 97.0067 28.6333 97.0067C31.3067 97.0067 33.8467 94.9067 35.7267 91.2067C37.4733 87.8 38.6333 83.38 38.6333 80.0467Z"
				fill={fill != null ? fill : "#FBB12C"}
			/>
			<path
				className="primary-fill"
				d="M79.1736 97.0667C74.8669 99.7667 70.4269 102.167 65.9336 104.193L69.2136 112.087C73.7136 110.067 78.1669 107.7 82.4936 105.047L79.1736 97.0667Z"
				fill={fill != null ? fill : "#FBB12C"}
			/>
			<path
				className="secondary-fill"
				d="M100 81.0133C96.0667 84.8867 90.9134 88.82 86.3867 92.1467L89.7534 100.24C92.9934 97.9333 97.0001 95.1867 100 92.6V81.0133Z"
				fill={fill != null ? fill : "#FBB12C"}
			/>
			<path
				className="secondary-fill"
				d="M160.001 0L138.701 78.1067L109.841 52.4067L137.534 23.1733L100.414 48.8333L74.7939 42.6067L160.001 0ZM106.747 59.1733V86.6667L118.314 69.48L106.747 59.1733Z"
				fill={fill != null ? fill : "#007CBE"}
			/>
		</svg>
	);
};

export default EmailIcon;
