import React from "react";
import { SVGGradient } from "../SVGGradient";

// Types
import type IconProps from "Types/IconProps";

const TickIcon: React.FC<IconProps & { circle?: boolean }> = ({
	width,
	height,
	id,
	className = "tick-icon",
	fill,
	circle,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "32px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 32 32"
			fill={fill === "gradient" ? "url(#svgGradient)" : fill || "currentColor"}
			xmlns="http://www.w3.org/2000/svg">
			{circle ? (
				<>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM22.1846 9.05724C22.6586 8.44007 23.5529 8.31677 24.182 8.78184C24.8111 9.24691 24.9368 10.1242 24.4627 10.7414L14.6617 23.5013C14.5945 23.6002 14.5132 23.6923 14.418 23.7747C13.8275 24.2862 12.9262 24.2311 12.4049 23.6518L7.60704 18.3198C7.08573 17.7404 7.14186 16.8562 7.73239 16.3448C8.32293 15.8334 9.22425 15.8884 9.74556 16.4678L13.3854 20.5128L22.1846 9.05724Z"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M24.182 8.78184C23.5529 8.31677 22.6586 8.44007 22.1846 9.05724L13.3854 20.5128L9.74556 16.4678C9.22425 15.8884 8.32293 15.8334 7.73239 16.3448C7.14186 16.8562 7.08573 17.7404 7.60704 18.3198L12.4049 23.6518C12.9262 24.2311 13.8275 24.2862 14.418 23.7747C14.5132 23.6923 14.5945 23.6002 14.6617 23.5013L24.4627 10.7414C24.9368 10.1242 24.8111 9.24691 24.182 8.78184Z"
						fill="white"
					/>
				</>
			) : (
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M29.0912 4.45094C28.0846 3.70682 26.6538 3.90411 25.8953 4.89159L11.8166 23.2205L5.9929 16.7484C5.15881 15.8215 3.71669 15.7334 2.77183 16.5516C1.82697 17.3699 1.73717 18.7847 2.57126 19.7117L10.2478 28.2428C11.0819 29.1698 12.524 29.2578 13.4688 28.4396C13.6211 28.3077 13.7512 28.1604 13.8587 28.002L29.5403 7.58627C30.2988 6.59879 30.0977 5.19506 29.0912 4.45094Z"
				/>
			)}
			{fill === "gradient" && <SVGGradient />}
		</svg>
	);
};

export default TickIcon;
