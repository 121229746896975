import React from "react";

// Types
import type IconProps from "Types/IconProps";

export const ReportIcon: React.FC<IconProps> = ({
	width,
	height,
	className = "report-icon",
	id,
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "24px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 24 24"
			fill={fill || "currentColor"}
			xmlns="http://www.w3.org/2000/svg">
			<path d="M15.1268 7.50067C14.1248 7.50067 13.4986 6.87879 13.4986 5.88305V0H4.66835C4.14292 0 3.77029 0.145291 3.501 0.37137C3.1622 0.656942 3 1.13102 3 1.66835C3 3.11374 3 4.44329 3 5.88868V22.3179C3 23.3982 3.60121 24 4.68024 24H19.3277C20.3924 24 20.9992 23.3969 20.9992 22.3317V7.50067H15.1268ZM8.98262 20.9908H6.01104V16.52H8.98262V20.9908ZM13.4829 20.9908H10.5107V13.5196H13.4829V20.9908ZM17.98 20.9959H15.0166V10.5167H17.98V20.9959Z" />
			<path d="M14.9991 0V6.00016H20.9992L14.9991 0Z" />
		</svg>
	);
};

export default ReportIcon;
