import React from "react";

// Types
import type IconProps from "Types/IconProps";

const UserIcon: React.FC<IconProps> = ({
	width,
	height,
	className = "user-icon",
	fill,
	id,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "24px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0 17.3333C0 14.8786 3.32318 12 5.77778 12C5.77778 12 9.51491 14 12 14C14.4851 14 18.2222 12 18.2222 12C20.6768 12 24 14.8786 24 17.3333V18.2222C24 19.2039 24 23.9999 21 23.9999H3C0 23.9999 0 19.2039 0 18.2222V17.3333Z"
				fill={fill != null ? fill : "#1FA786"}
			/>
			<path
				d="M18 6C18 9.31369 15.3137 12 12 12C8.68629 12 6 9.31369 6 6C6 2.68631 8.68629 0 12 0C15.3137 0 18 2.68631 18 6Z"
				fill={fill != null ? fill : "#E7D0C6"}
			/>
		</svg>
	);
};

export default UserIcon;
