import useSWR from "swr";

// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";
import { stripEmpty } from "Helpers/ObjectHelpers";

// Types
import type { JSONApi, DataHookResponse, ResourceObject } from "Types/JSONApi";
import Partner from "Types/Partner";
import { AccountType } from "Types/Account";
import { Tier } from "Types/Tiers";
import Subscription from "Types/Subscription";

type Relationships = {
	subscription: {
		links: { related: string };
		data: { type: "subscription"; id: string };
	};
};

type Related = {
	subscription: Subscription;
}[];

type Included = ResourceObject<Subscription, undefined, "subscription">[];

type Response = JSONApi<Partner[], Relationships, Included, undefined, Related>;

/**
 * Get all the accounts on the ClimateClever system
 * @param includeSubscriptions Should the hook include the accounts in the Related?
 * @param pagination Optional pagination parameters
 * @param filters Optional filter parameters
 * @returns a DataHookResponse tuple of Accounts, Error, Mutate, Related accounts as an array, the full response and pagination options
 */
const useAccounts = (
	includeSubscriptions: boolean,
	pagination?: {
		limit: number;
		offset: number;
	},
	filters?: {
		"account-type"?: AccountType;
		search?: string;
		partner?: string;
		tier?: Tier;
		id?: string;
	},
	bypassFetch?: boolean,
): DataHookResponse<Partner[], Related, Response> => {
	const queryString = new URLSearchParams({
		...(includeSubscriptions ? { include: "subscriptions" } : {}),
		...(pagination?.limit && pagination?.offset
			? {
					"page[limit]": pagination.limit.toString(),
					"page[offset]": pagination.offset.toString(),
			  }
			: {}),
		...(filters ? stripEmpty(filters, "nullish") : {}),
	}).toString();

	const { data, error, mutate } = useSWR<
		JSONApi<Partner[], Relationships, Included, undefined, Related>,
		Error
	>(
		bypassFetch
			? null
			: [
					`${import.meta.env.VITE_BASE_URL}executive/accounts${
						queryString ? "?" + queryString : ""
					}`,
					1,
			  ],
		getReq,
		{
			shouldRetryOnError: false,
		},
	);

	const [accounts, related, fullJSONApiResponse, paginationDetails] =
		transformAPIResponse(data, []);

	return [
		accounts,
		error,
		mutate,
		related,
		fullJSONApiResponse,
		paginationDetails,
	];
};

export default useAccounts;
