import React from "react";
import ReactMarkdown from "react-markdown";

// Styles
import { Container } from "./styles";

// Types
import type MarkdownToStyledTextProps from "./types";

export const MarkdownToStyledText: React.FC<MarkdownToStyledTextProps> = ({
	children,
	...props
}) => {
	return (
		<Container {...props}>
			<ReactMarkdown>{children}</ReactMarkdown>
		</Container>
	);
};

export default MarkdownToStyledText;
