import React from "react";
import { useField } from "formik";

// Components
import TextInput from "../CCW-TextInput";
import FormGroup from "../CCW-FormGroup";

// Types
import { FIGDefaultProps } from "./types";

// Styles
import { LabelContainer, InputLabel, SubLabel } from "./styles";
import MaxCharacterHint from "../CCW-MaxCharacterHint";

const FIGDefault: React.FC<FIGDefaultProps> = ({
	label,
	subLabel,
	required,
	name,
	characterLimit,
	className,
	...props
}) => {
	const [field, meta] = useField(name);

	const charactersRemaining = characterLimit
		? characterLimit - (field.value?.length || 0)
		: undefined;

	const error = meta.touched && meta.error ? meta.error : undefined;

	return (
		<FormGroup className={className}>
			<LabelContainer showSubLabel={!!subLabel}>
				<InputLabel
					showSubLabel={!!subLabel}
					htmlFor={props.id}
					text={label}
					required={required}
				/>
				{subLabel && <SubLabel>{subLabel}</SubLabel>}
			</LabelContainer>
			<TextInput {...field} {...props} error={error} />
			{characterLimit && !error && (
				<MaxCharacterHint charactersRemaining={charactersRemaining as number} />
			)}
		</FormGroup>
	);
};

export default FIGDefault;
