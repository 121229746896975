import { State } from "Types/Address";
import { PaidTier } from "Types/Tiers";
import * as Yup from "yup";
import { InitValues } from "../types";
/**
 * Initial values for the Formik form
 */
export const initValues: InitValues = {
	name: "",
	type: "lga",
	state: "" as State,
	postcodes: [],
	schoolDiscount: false,
	businessDiscount: false,
	homeDiscount: true,
	tier: "" as PaidTier,
	api: false,

	fName: "",
	lName: "",
	email: "",

	logo: "",
	banner: "",
};

/**
 * Array of validation objects for the Formik form
 */
export const validation = Yup.object({
	name: Yup.string().min(2).required("Please enter the partner name"),
	type: Yup.string()
		.oneOf(["lga", "normal", "affiliate"])
		.required("Please pick one"),
	state: Yup.string().when(["type"], {
		is: "lga",
		then: (schema) => schema.required("Please select a state"),
	}),
	postcodes: Yup.array().when(["type"], {
		is: "lga",
		then: (schema) =>
			schema.of(Yup.number()).required("Please enter at least one postcode"),
	}),
	schoolDiscount: Yup.bool().oneOf([true, false]),
	businessDiscount: Yup.bool().oneOf([true, false]),
	tier: Yup.string().required("Please select a tier"),
	api: Yup.bool().oneOf([true, false]),

	fName: Yup.string()
		.min(2)
		.max(25, "Maximum of 25 characters")
		.matches(
			/^(?=[^A-Za-z]*[A-Za-z])[ -~]*$/,
			"Please enter a valid first name",
		)
		.required("Please enter your first name"),
	lName: Yup.string()
		.min(1)
		.max(25, "Maximum of 25 characters")
		.matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*$/, "Please enter a valid last name")
		.required("Please enter your last name"),
	email: Yup.string()
		.email("Please enter a valid email address")
		.required("Please enter a valid email address"),

	logo: Yup.mixed().required("Logo is required"),
	banner: Yup.mixed().required("Banner is required"),
});
