import React from "react";
import { Route, Routes } from "react-router-dom";

// Local
import DefaultRETargetsPage from "./Components/DefaultRETargetsPage";
import CreateNewRETarget from "./Components/CreateNewRETarget";

const EmissionFactorsPage = () => {
	return (
		<>
			<Routes>
				<Route path="/" element={<DefaultRETargetsPage />} />
			</Routes>
			<Routes>
				<Route path="/create" element={<CreateNewRETarget />} />
			</Routes>
		</>
	);
};

export default EmissionFactorsPage;
