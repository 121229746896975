import styled from "@emotion/styled";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Container = styled.div(() => ({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
}));

export const Welcome = styled.p<{ isHoliday?: boolean }>(
	({ theme, isHoliday }) => ({
		display: "none",
		[MediaQueries[1]]: {
			...theme.webFonts.F5,
			margin: isHoliday ? "0 0 13.3rem" : "0 0 6rem",
			display: "block",
			color: theme.colors.D3,
			textAlign: "center",
		},
		"& > .start": {
			color: theme.colors.primary,
		},
		"& > .end": {
			color: theme.colors.Green,
		},
	}),
);
