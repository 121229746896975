import styled from "@emotion/styled";

const Container = styled.div(() => ({
	clipPath: "inset(100%)",
	clip: "rect(1px, 1px, 1px, 1px)",
	height: "1px",
	overflow: "hidden",
	position: "absolute",
	whiteSpace: "nowrap",
	width: "1px",
}));

export default Container;
