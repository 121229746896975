import styled from "@emotion/styled";
import { Form } from "formik";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const HeaderContainer = styled.div(() => ({
	[MediaQueries[1]]: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		margin: "5rem 0",
	},
}));

export const Header = styled.h1(({ theme }) => ({
	...theme.webFonts.h1,
	margin: "0",
	color: theme.colors.primary,
}));

export const BackButton = styled.button(({ theme }) => ({
	width: "40%",
	paddingLeft: "0",
	color: theme.colors.primary,
	...theme.webFonts.F5Bold,
	background: "transparent",
	cursor: "pointer",
	textAlign: "left",
	border: "none",
	"& > svg": {
		marginRight: "1.3rem",
	},
	"&:hover, :focus": {
		color: theme.colors.D1,
		"& > svg > path": {
			fill: theme.colors.D1,
		},
	},
	"&:active": {
		transform: "translateY(0.2rem) scale(0.98)",
	},
}));

export const FormContainer = styled(Form)(() => ({
	width: "100%",
	[MediaQueries[1]]: {
		display: "flex",
		flexDirection: "column",
		padding: "0 0 6.5rem 0",
		overflow: "auto",
		zIndex: 0,
	},
}));

export const InfoCard = styled.div(({ theme }) => ({
	width: "100%",
	marginBottom: "2rem",
	...theme.webFonts.h5,
}));

export const CheckBoxRow = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	marginBottom: "1rem",
	"& > div:not(:last-child)": {
		marginBottom: "1rem",
	},
	[MediaQueries[1]]: {
		flexDirection: "row",
		"& > div:not(:last-child)": {
			margin: "0 4rem 0 0",
		},
	},
}));

export const SectionTitle = styled.h3(({ theme }) => ({
	...theme.webFonts.h3,
	color: theme.colors.D1,
	margin: "0 0 3rem 0",
}));

export const Row = styled.div(() => ({
	[MediaQueries[1]]: {
		display: "flex",
		marginBottom: "1rem",
		"& > div": {
			width: "100%",
		},
		"& > div:not(:last-child)": {
			marginRight: "3rem",
		},
	},
}));

export const PostcodeSection = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	"& > div:nth-of-type(2) > div ": {
		marginRight: "2rem",
	},
	"& > div:nth-of-type(2) > button": {
		color: theme.colors.Green,
		paddingLeft: "0",
		[MediaQueries[1]]: {
			width: "30rem",
		},
	},
}));

export const PostcodeList = styled.ul(({ theme }) => ({
	padding: "0",
	display: "flex",
	flexWrap: "wrap",
	"& > p": {
		margin: "0",
	},
	"& > li": {
		padding: "0 0.5rem",
		listStyle: "none",
		...theme.webFonts.F5,
	},
}));
