import { config } from "react-spring";
import React, { useEffect, useState } from "react";
import TransitionOpacity from "../CCW-TransitionOpacity";

// Styles
import { ImageElement, ImageBox } from "./styles";

// Types
import type ProfileImageProps from "./types";

export const ProfileImage: React.FC<ProfileImageProps> = ({
	imageSrcURL,
	className,
	onImageFail,
	onImageLoaded,
}) => {
	// State
	const [showImage, setShowImage] = useState(false);

	// Helpers
	useEffect(() => {
		const handleOnLoad = () => {
			setShowImage(true);
			onImageLoaded && onImageLoaded();
		};
		const handleOnError = () => {
			onImageFail && onImageFail();
		};

		const img = new Image();
		img.src = imageSrcURL;

		img.addEventListener("load", handleOnLoad);
		img.addEventListener("error", handleOnError);

		return () => {
			img.removeEventListener("load", handleOnLoad);
			img.removeEventListener("error", handleOnError);
			setShowImage(false);
		};
	}, [imageSrcURL, onImageFail, onImageLoaded]);

	return (
		<div {...{ className }}>
			<TransitionOpacity
				visible={showImage}
				springConfig={{ config: config.molasses }}>
				<ImageBox>
					<ImageElement src={imageSrcURL} />
				</ImageBox>
			</TransitionOpacity>
		</div>
	);
};

export default ProfileImage;
