import styled from "@emotion/styled";

const Button = styled.button<{ useWhiteColorFill?: boolean }>(
	({ theme, useWhiteColorFill }) => ({
		cursor: "pointer",
		background: useWhiteColorFill ? "transparent" : theme.colors.White,
		width: "fit-content",
		height: "fit-content",
		padding: "0.588rem",
		borderRadius: "50%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		border: "none",
		"& > svg": {
			padding: "0",
			zIndex: 1,
			"& > path": {
				fill: useWhiteColorFill ? theme.colors.White : theme.colors.secondary,
			},
		},
		"&:hover,:focus": {
			background: useWhiteColorFill ? theme.colors.White : "transparent",
			boxShadow: useWhiteColorFill
				? "none"
				: `inset 0 0 0 0.1rem ${theme.colors.grey400}`,
			"> svg > path": {
				fill: theme.colors.grey400,
			},
		},
		"&:active": {
			background: useWhiteColorFill
				? theme.colors.White
				: theme.colors.secondary,
			boxShadow: useWhiteColorFill
				? `inset 0 0 0 0.1rem ${theme.colors.White}`
				: "none",
			"> svg > path": {
				fill: useWhiteColorFill ? theme.colors.secondary : theme.colors.White,
			},
		},
	}),
);

export default Button;
