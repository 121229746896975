import styled from "@emotion/styled";
import Select from "react-select";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

// Types

// Styles
export const StyledSelect = styled(Select)<{
	inlineLabel?: string;
	isIconOnly?: boolean;
	isMenuRightAlign?: boolean;
}>(({ theme, inlineLabel, isIconOnly, isMenuRightAlign }) => ({
	...theme.webFonts.F6,
	...theme.webFonts.bold,
	color: theme.colors.D3,
	".select__control": {
		borderRadius: "0.8rem",
		border: `0.1rem solid ${theme.colors.D5}`,
		paddingLeft: "2%",
		minHeight: "4rem",
		...(isIconOnly && {
			boxShadow: "none",
		}),
	},
	"&.plain .select__control": {
		border: "none",
	},
	"&.plain .select__indicator-separator": {
		visibility: "hidden",
	},
	".select__placeholder": {
		color: theme.colors.D4,
	},
	".select__multi-value__label": {
		...theme.webFonts.F7,
		...theme.webFonts.bold,
	},
	".select__dropdown-indicator": {
		transition: "all 150ms",
		transform: "rotate(0deg)",
	},
	".select__control--menu-is-open ": {
		"& .select__dropdown-indicator": {
			transform: "rotate(180deg)",
		},
	},
	".select__menu": {
		zIndex: 10,
		...(isIconOnly && {
			minWidth: "max-content",
		}),
		...(isMenuRightAlign && {
			right: 0,
		}),
	},
	".select__menu-list": {
		position: "relative",
		borderRadius: "0.8rem",
		boxShadow: theme.shadow,
	},
	".select__option": {
		paddingLeft: "1.5rem",
		borderBottom: `0.1rem solid ${theme.colors.D5}`,
		[MediaQueries[1]]: {
			paddingLeft: "3rem",
		},
	},
	".select__option:last-of-type": {
		borderBottom: "none",
	},
	".select__option--is-focused": {
		backgroundColor: theme.colors.D5,
	},
	".select__single-value": {
		minWidth: "12rem",
		color: theme.colors.grey500,
	},
	...(isIconOnly && {
		// Hide the UI
		".select__indicators": {
			display: "none",
		},
		".select__value-container": {
			width: 0,
			overflow: "hidden",
			height: 0,
			padding: 0,
		},
	}),
	...(inlineLabel
		? {
				".select__single-value:before": {
					content: `'${inlineLabel}: '`,
				},
		  }
		: {}),
})) as typeof Select;

export const Error = styled.span(({ theme }) => ({
	...theme.webFonts.F6,
	color: theme.colors.Red,
	marginTop: "1rem",
}));

export const Label = styled.label(({ theme }) => ({
	cursor: "pointer",
	color: theme.colors.grey500,
	"&:hover": { color: theme.colors.grey800 },
}));
