import React from "react";

// Local CC
import DefaultReportsPage from "./Components/DefaultReportsPage";

const ReportsAdminPage = () => {
	return <DefaultReportsPage />;
};

export default ReportsAdminPage;
