import styled from "@emotion/styled";

export const Position = styled.div(() => ({
	position: "fixed",
	left: "0",
	right: "0",
	top: "3rem",
	zIndex: 99999999,
}));

export const AlertBox = styled.div({
	display: "flex",
	flexDirection: "column",
	gap: "1rem",
});
