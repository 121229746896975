import React, { Suspense } from "react";
import { Route, BrowserRouter, Navigate } from "react-router-dom";

// CC
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import PrivateRoute from "CCW-Components/CCW-PrivateRoute";
import FullPageAlert from "CCW-Components/CCW-FullPageAlert";
import TransitionRoutes from "CCW-Components/CCW-TransitionRoutes";
import FormSubHeading from "CCW-Components/CCW-FormSubHeading/styles";
import { Heading } from "Pages/Settings/Components/DefaultSettingsPage/styles";
import { MarginBottom } from "CCW-Components/CCW-SectionHeader/styles";
import Container from "CCW-Components/CCW-Container";
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import NavBar from "CCW-Components/CCW-NavBar";

// Hooks
import useSession from "Hooks/UseSession";

// Pages
import AdminDashboard from "Pages/AdminDashboard";
import LoginPage from "Pages/Login";
import ForgotPasswordPage from "Pages/ForgotPassword";
import ResetPasswordPage from "Pages/ResetPassword";
import Missing404Page from "Pages/Missing404";
import ConfirmEmailChange from "Pages/ConfirmEmailChange";
import AdminTrackPage from "Pages/Track/admin-track";
import ReportsAdminPage from "Pages/ReportsAdmin";
import EmissionFactors from "Pages/EmissionFactors";
import RenewableEnergyTargets from "Pages/RenewableEnergyTargets";
import ProfilePage from "Pages/Settings/Components/ProfilePage";
import CreatePartnerForm from "Pages/PartnersAdmin/Components/CreatePartnerForm";
import UtilitiesAdmin from "Pages/UtilitiesAdmin/Components/DefaultUtilityPage";

// Helpers
import lazyWithRetries from "Helpers/LazyWithRetries";

// Assets
import {
	SettingsIcon,
	ActionIcon,
	PartnerIcon,
	ReportIcon,
	AnalyticsIcon,
	WindmillIcon,
	PolicyIcon,
	MeasureIcon,
} from "Assets/SVG";

// Lazy loaded
const ActionsAdminPage = lazyWithRetries(() => import("Pages/ActionsAdmin"));

function AdminApp() {
	// Hooks
	const [user, sessionError] = useSession();

	// Loading State
	if (!user && !sessionError) {
		return (
			<div
				style={{
					width: "100%",
					height: "100vh",
				}}>
				<BlockLoader message="Loading page........." />
			</div>
		);
	}

	return (
		<BrowserRouter>
			{!sessionError && user ? (
				<NavBar
					menuItems={[
						{
							to: "/partners/addPartner",
							title: "Add partner",
							icon: PartnerIcon,
						},
						{ to: "/factors/electricity", title: "Factors", icon: PolicyIcon },
						{
							to: "/targets",
							title: "Renewable Energy Targets",
							icon: WindmillIcon,
						},
						{ to: "/utilities", title: "Utilities", icon: MeasureIcon },
						{ to: "/settings", title: "Settings", icon: SettingsIcon },
					]}
					navItems={[
						{ to: "/action", title: "Action", icon: ActionIcon },
						{ to: "/reports", title: "Reports", icon: ReportIcon },
						{ to: "/track", title: "Track", icon: AnalyticsIcon },
					]}
					accountName="Admin Panel"
				/>
			) : null}
			<main>
				<FullPageAlert />
				<Suspense
					fallback={
						<div
							style={{
								width: "100%",
								height: "100vh",
							}}>
							<BlockLoader message="Loading page....." />
						</div>
					}>
					<TransitionRoutes isWithSentry={true}>
						<Route
							path="/action/*"
							element={
								<PrivateRoute user={user}>
									<ActionsAdminPage />
								</PrivateRoute>
							}
						/>
						<Route
							path="/"
							element={<Navigate to="/dashboard/users" replace />}
						/>
						<Route
							path="/dashboard/:type"
							element={
								<PrivateRoute user={user}>
									<AdminDashboard />
								</PrivateRoute>
							}
						/>
						<Route
							path="/factors/:emissionType/*"
							element={
								<PrivateRoute user={user}>
									<EmissionFactors />
								</PrivateRoute>
							}
						/>
						<Route
							path="/utilities/*"
							element={
								<PrivateRoute user={user}>
									<UtilitiesAdmin />
								</PrivateRoute>
							}
						/>
						<Route
							path="/targets/*"
							element={
								<PrivateRoute user={user}>
									<RenewableEnergyTargets />
								</PrivateRoute>
							}
						/>
						<Route
							path="partners/addPartner"
							element={
								<PrivateRoute user={user}>
									<CreatePartnerForm />
								</PrivateRoute>
							}
						/>
						<Route
							path="/reports/*"
							element={
								<PrivateRoute user={user}>
									<ReportsAdminPage />
								</PrivateRoute>
							}
						/>
						{user && (
							<Route
								path="/settings/*"
								element={
									<PrivateRoute user={user}>
										<DefaultPage onNavigation={true} defaultPadding={false}>
											<Container>
												<MarginBottom>
													<Heading>Account Settings</Heading>
													<FormSubHeading>
														Manage your personal details
													</FormSubHeading>
												</MarginBottom>
												<ProfilePage />
											</Container>
										</DefaultPage>
									</PrivateRoute>
								}
							/>
						)}
						{["/track", "/track/:chartId"].map((route) => (
							<Route
								key={route}
								path={route}
								element={
									<PrivateRoute user={user}>
										<AdminTrackPage />
									</PrivateRoute>
								}
							/>
						))}
						<Route
							path="/set-reset-password"
							element={<ForgotPasswordPage />}
						/>
						<Route path="/password-reset" element={<ResetPasswordPage />} />
						<Route path="/login" element={<LoginPage appType="admin" />} />
						<Route
							path="/verify-update"
							element={
								<PrivateRoute user={user}>
									<ConfirmEmailChange />
								</PrivateRoute>
							}
						/>
						<Route element={<Missing404Page />} />
					</TransitionRoutes>
				</Suspense>
			</main>
		</BrowserRouter>
	);
}

export default AdminApp;
