import React from "react";

import CardTemplateProps from "./types";

import { CardContainer, CardHeader, CardTitle, CardSubtitle } from "./styles";

const CardTemplate: React.FC<CardTemplateProps> = ({
	icon: Icon,
	title,
	subtitle,
	children,
	...props
}) => {
	return (
		<CardContainer {...props}>
			<CardHeader>
				{Icon && <Icon width="64px" height="64px" />}
				<CardTitle>{title}</CardTitle>
			</CardHeader>
			<CardSubtitle>{subtitle}</CardSubtitle>
			{children}
		</CardContainer>
	);
};

export default CardTemplate;
