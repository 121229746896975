import React from "react";

// Types
import type IconProps from "Types/IconProps";

const PolicyIcon: React.FC<IconProps> = ({
	width,
	height,
	className = "policy-icon",
	id,
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "48px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 48 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.13287 0C1.39726 0 0 1.39726 0 3.13287V60.8671C0 62.6027 1.39726 64 3.13287 64H44.3077C46.0434 64 47.4406 62.6027 47.4406 60.8671V3.13287C47.4406 1.39726 46.0434 0 44.3077 0H3.13287ZM2.68527 3.62253H44.7553V60.3773H2.68527V3.62253Z"
				fill={fill != null ? fill : "#888888"}
			/>
			<path
				d="M37.82 24.8769H9.62418C9.06803 24.8769 8.61719 25.3278 8.61719 25.8839V26.8909C8.61719 27.447 9.06803 27.8979 9.62418 27.8979H37.82C38.3761 27.8979 38.827 27.447 38.827 26.8909V25.8839C38.827 25.3278 38.3761 24.8769 37.82 24.8769Z"
				fill={fill != null ? fill : "#888888"}
			/>
			<path
				d="M37.82 34.1077H9.62418C9.06803 34.1077 8.61719 34.5585 8.61719 35.1147V36.1217C8.61719 36.6778 9.06803 37.1287 9.62418 37.1287H37.82C38.3761 37.1287 38.827 36.6778 38.827 36.1217V35.1147C38.827 34.5585 38.3761 34.1077 37.82 34.1077Z"
				fill={fill != null ? fill : "#888888"}
			/>
			<path
				d="M37.8175 48.5692H22.6007C22.0446 48.5692 21.5938 49.0201 21.5938 49.5762V50.5832C21.5938 51.1394 22.0446 51.5902 22.6007 51.5902H37.8175C38.3737 51.5902 38.8245 51.1394 38.8245 50.5832V49.5762C38.8245 49.0201 38.3737 48.5692 37.8175 48.5692Z"
				fill={fill != null ? fill : "#888888"}
			/>
			<path
				d="M8.44531 3.65V16.7951L13.1085 12.132L17.8602 16.8836V3.65H8.44531Z"
				fill={fill != null ? fill : "#FBB12C"}
			/>
		</svg>
	);
};

export default PolicyIcon;
