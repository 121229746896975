import styled from "@emotion/styled";

// Styles
export const Container = styled.div(() => ({
	position: "relative",
}));

export const ButtonBox = styled.div(() => ({
	display: "flex",
	justifyContent: "end",
	gap: "1.5rem",
	marginTop: "4.4rem",
}));

export const Hint = styled.span(({ theme }) => ({
	...theme.webFonts.F6,
	color: theme.colors.D1,
	fontStyle: "italic",
}));

export const ErrorBox = styled.div(() => ({
	height: "2rem",
	display: "block",
	position: "relative",
	top: "-2rem",
	zIndex: -1,
}));
