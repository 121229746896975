import styled from "@emotion/styled";

// Types
import { ErrorProps } from "./types";

export const Container = styled.div(() => ({
	position: "relative",
}));

export const CheckLabel = styled.label(({ theme }) => ({
	position: "relative",
	...theme.webFonts.F6,
	color: theme.colors.grey700,
	cursor: "pointer",
	display: "flex",
	alignItems: "center",
}));

export const CheckInput = styled.input(({ theme, disabled }) => ({
	position: "absolute",
	opacity: 0,
	flexShrink: 0,
	zIndex: -1 /* Put the input behind the label so it doesn't overlay text */,
	"&: focus ~ span": {
		boxShadow: `0 0 0 2px ${
			disabled ? theme.colors.grey500 : theme.colors.primary
		}`,
	},
	"&: checked ~ span": {
		"> span": {
			background: disabled ? theme.colors.grey500 : theme.colors.primary,
		},
	},
	"&: active ~ span": {
		"> span": {
			opacity: 0.8,
			background: disabled ? theme.colors.grey500 : theme.colors.primary,
			width: "90%",
			height: "90%",
			margin: "1px",
		},
	},
}));

export const Box = styled.span(({ theme }) => ({
	flexShrink: 0,
	width: "20px",
	height: "20px",
	borderRadius: "2px",
	border: `2px solid ${theme.colors.primary}`,
	marginRight: "10px",
	display: "flex",
	justifyContent: "center",
}));

export const BoxFill = styled.span(({ theme }) => ({
	display: "flex",
	width: "90%",
	height: "90%",
	margin: "1px",
	borderRadius: "2px",
	background: theme.colors.White,
}));

export const Error = styled.span<ErrorProps>(({ theme, error }) => ({
	color: theme.colors.Red,
	...theme.webFonts.F6,
	display: error != null && error !== "" ? "inline" : "none",
	whiteSpace: "nowrap",
	overflow: "hidden",
	position: "absolute",
	bottom: -20,
	left: 0,
}));
