import React from "react";

// Assets
import DollarIcon from "Assets/SVG/DollarIcon";

// Styles
import { Container, Input } from "./styles";

// Types
import type { TextInputNumberProps } from "./types";

/** Base input field */
const TextInputNumber: React.FC<TextInputNumberProps> = ({
	id,
	name,
	error,
	step,
	dollar = false,
	...props
}) => (
	<Container>
		<Input
			id={id || name}
			name={name}
			type="number"
			step={step === "int" ? 1 : 0.01}
			error={error}
			{...props}
			dollar={dollar}
		/>
		{dollar && (
			<span>
				<DollarIcon width="14px" height="14px" />
			</span>
		)}
	</Container>
);

export default TextInputNumber;
