import React from "react";

// Types
import type IconProps from "Types/IconProps";

const RoomIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "room-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "32px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M25.5214 2.65054L14.1451 0.0252432C13.8086 -0.0420721 13.472 0.0252433 13.2027 0.227189C12.9335 0.429135 12.7988 0.765711 12.7988 1.10229V2.61688H7.1107C6.50486 2.61688 6 3.12175 6 3.72758V27.1533V28.264C6 28.8698 6.50486 29.3747 7.1107 29.3747H12.7652V30.8893C12.7652 31.2259 12.9335 31.5288 13.1691 31.7644C13.371 31.9327 13.6066 32 13.8422 32C13.9095 32 14.0105 32 14.0778 31.9663L25.3083 29.3747L25.4541 29.341C25.959 29.2401 26.2956 28.7689 26.2956 28.264V27.1533V3.72758C26.3629 3.18906 26.0263 2.75151 25.5214 2.65054ZM12.7652 27.1533H8.2214V4.80463H12.7652V27.1533ZM16.6695 17.157C16.03 17.157 15.4915 16.6185 15.4915 15.979C15.4915 15.3395 16.03 14.8009 16.6695 14.8009C17.309 14.8009 17.8475 15.3395 17.8475 15.979C17.8475 16.6185 17.309 17.157 16.6695 17.157Z"
				fill={fill != null ? fill : "#828282"}
			/>
			<path
				d="M6 28.264V27.1533H0V29.3747H7.1107C6.50486 29.3747 6 28.8698 6 28.264Z"
				fill={fill != null ? fill : "#828282"}
			/>
			<path
				d="M26.2956 28.264C26.2956 28.7689 25.959 29.2401 25.4541 29.341L25.3083 29.3747H32V27.1533H26.2956V28.264Z"
				fill={fill != null ? fill : "#828282"}
			/>
		</svg>
	);
};

export default RoomIcon;
