import React from "react";
import { Form, Formik } from "formik";

// CC
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import AppError from "CCW-Components/CCW-AppError";
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import CCButton from "CCW-Components/CCW-CCButton";

// Hooks
import useGetMarkdownEntry from "Hooks/UseGetMarkdownEntry";
import useAlert from "Hooks/UseAlert";
import useConfetti from "Hooks/UseConfetti";

// Helpers
import createMarkdownEntry from "Helpers/NetworkingHelpers/CreateMarkdownEntry";
import isError from "Helpers/TypeHelpers/IsError";
import swrErrorCheck from "Helpers/FormatHelpers/SWRErrorCheck";

// Local CC
import MarkdownItem from "../MarkdownItem";
import MarkdownToStyledText from "../../../Reports/Components/MarkdownToStyledText";
import HeroImage from "../../../Reports/Components/HeroImage";
import Page from "../../../Reports/Components/Page";

// Styles
import { Title, Container, ButtonRow, ButtonLoader } from "./styles";

// Types
import type { MarkdownEntry, MarkdownEntryName } from "Types/Markdown";
import { MutateDeprecated } from "Types/JSONApi";

// Data
import { InitialValues, validationSchema } from "./FormData";

// Assets
import WorldIconGradient from "Assets/SVG/WorldIconGradient";

const ReportForm: React.FC = () => {
	// Hooks
	const [terms, termsError, termsMutate] = useGetMarkdownEntry("terms");
	const [appendix, appendixError, appendixMutate] =
		useGetMarkdownEntry("appendix");
	const [climate, climateError, climateMutate] = useGetMarkdownEntry("climate");
	const [, reportAlert] = useAlert();
	const [popConfetti] = useConfetti();

	if (terms === undefined || appendix === undefined || climate === undefined) {
		return <BlockLoader />;
	}

	// Error state
	if (termsError || appendixError || climateError) {
		return (
			<DefaultPage>
				<AppError
					error={swrErrorCheck([termsError, appendixError, climateError])}
				/>
			</DefaultPage>
		);
	}

	const sections: {
		[sectionName in "terms" | "appendix" | "climate"]: {
			entry?: MarkdownEntry;
			mutate: MutateDeprecated<MarkdownEntry | undefined>;
			characterLimit?: number;
		};
	} = {
		climate: {
			entry: climate,
			mutate: climateMutate,
		},
		terms: {
			entry: terms,
			mutate: termsMutate,
		},
		appendix: {
			entry: appendix,
			mutate: appendixMutate,
		},
	};

	return (
		<>
			<Formik
				initialValues={InitialValues(
					terms?.markdown,
					appendix?.markdown,
					climate?.markdown,
				)}
				{...{ validationSchema }}
				onSubmit={async (values, { setSubmitting, resetForm }) => {
					setSubmitting(true);
					try {
						const req = (
							key: MarkdownEntryName,
							value: string,
							id?: string,
						) => {
							const data = id
								? { markdown: value, currentId: id }
								: { markdown: value };
							return createMarkdownEntry(key, data);
						};

						const requests = (Object.keys(sections) as MarkdownEntryName[])
							.filter(
								(sectionName) =>
									values[sectionName] &&
									sections[sectionName]?.entry?.markdown !==
										values[sectionName],
							)
							.map((sectionName) =>
								req(
									sectionName,
									values[sectionName],
									sections[sectionName]?.entry?._id,
								),
							);

						const newMarkdowns = await Promise.all(requests);

						newMarkdowns.forEach(
							(newMarkdown) =>
								newMarkdown?.name && sections[newMarkdown?.name].mutate(),
						);

						setSubmitting(false);
						resetForm({ values });
						reportAlert("Wooohooooo! Report Text Updated!!!", "success");
						popConfetti();
					} catch (err) {
						setSubmitting(false);
						reportAlert(
							isError(err) ? err.message : "Buuuuu! Action failed.",
							"error",
						);
					}
				}}>
				{({ isSubmitting, resetForm, dirty }) => (
					<Form>
						<Container>
							<Title>Report Text</Title>
							<div>
								<p>
									<strong>Instructions:</strong> Start writing on the left side
									the content for the reports using the table fields to
									personalize the markdown (title, bold, list, quotes...). Check
									the final text (without the markdown) on the right hand side.
									Split up pages by using three dashes in a row
									(&quot;---&quot;). Preview of what the pages look like is
									available below the inputs (only updates on save)
								</p>
								{Object.entries(sections).map(
									([sectionName, { characterLimit }], index) => {
										return (
											<MarkdownItem
												key={sectionName}
												even={index % 2 === 0}
												type={sectionName}
												characterLimit={characterLimit}
											/>
										);
									},
								)}
							</div>
							<ButtonRow>
								<ButtonLoader
									type="submit"
									loading={isSubmitting}
									name="Submit"
									disabled={!dirty}>
									Save
								</ButtonLoader>
								<CCButton
									onClick={() => resetForm()}
									variant="washed"
									name="Cancel"
									disabled={!dirty}>
									Cancel
								</CCButton>
							</ButtonRow>
						</Container>
					</Form>
				)}
			</Formik>
			<div>
				{sections.climate.entry?.markdown
					.split("---")
					.map((climateSection, index) => (
						<Page
							key={"climateSection-" + (index + 1)}
							id={"climateSection-" + (index + 1)}
							isPDF={true}
							titleDetails={{ content: "Why?", color: "blue" }}
							icon={WorldIconGradient}>
							<MarkdownToStyledText>{climateSection}</MarkdownToStyledText>
							{index === 0 && (
								<div
									style={{
										position: "absolute",
										borderRadius: "0 10rem 0 0",
										overflow: "hidden",
										bottom: 0,
										left: "2.4rem",
										height: "22rem",
										width: "44.5rem",
									}}>
									<HeroImage
										primaryImageSRC={
											"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg=="
										}
										secondaryImageSRC={
											"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg=="
										}
									/>
								</div>
							)}
						</Page>
					))}
				{sections.terms.entry?.markdown
					.split("---")
					.map((termsSection, index) => (
						<Page
							key={"termsSection-" + (index + 1)}
							id={"termsSection-" + (index + 1)}
							isPDF={true}
							titleDetails={{ content: "Terminology?", color: "blue" }}
							icon={WorldIconGradient}>
							<MarkdownToStyledText>{termsSection}</MarkdownToStyledText>
						</Page>
					))}
				{sections.appendix.entry?.markdown
					.split("---")
					.map((appendixSection, index) => (
						<Page
							key={"appendixSection-" + (index + 1)}
							id={"appendixSection-" + (index + 1)}
							isPDF={true}
							titleDetails={{ content: "Appendix", color: "blue" }}
							icon={WorldIconGradient}>
							<MarkdownToStyledText>{appendixSection}</MarkdownToStyledText>
						</Page>
					))}
			</div>
		</>
	);
};

export default ReportForm;
