import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";

// CC
import CCButton from "../CCW-CCButton";
import MessageDialog from "CCW-Components/CCW-MessageDialog";

// Assets
import { WarningIcon, AppErrorIcon } from "Assets/SVG";

// Styles
import {
	ErrorContainer,
	Title,
	Text,
	ButtonContainer,
	ButtonLink,
} from "./styles";

// Types
import AppErrorProps from "./types";
import useSession from "Hooks/UseSession";

const AppError: React.FC<AppErrorProps> = ({
	style = "page",
	title = "Something went wrong",
	subTitle = "Oops, either there is a network error or a bug slipped past our developers. We'll get on to squishing it right away!",
	message = "Oops, something went wrong",
	error,
	outsideLink,
}) => {
	const hasInternet = window.navigator.onLine;
	const navigate = useNavigate();
	const [user] = useSession();
	const [isReported, setIsReported] = useState(false);

	useEffect(() => {
		if (!isReported && error) {
			const invalidSession =
				error.message.includes("Session is no longer valid") ||
				error.message.includes("No valid session being sent");

			if (!invalidSession) {
				Sentry.captureException(error);
				setIsReported(true);
			}
		}
	}, [error, isReported, user]);

	const errorTitle = !hasInternet
		? "It Looks like you have no Internet Connection."
		: title;

	const errorSubTitle = !hasInternet
		? "Please check your Internet Connection and try again."
		: subTitle;

	return style === "page" ? (
		<ErrorContainer>
			<AppErrorIcon width="180px" height="135px"></AppErrorIcon>
			<Title>{errorTitle}</Title>
			<Text> {errorSubTitle}</Text>
			<ButtonContainer>
				<CCButton
					onClick={() => navigate(-1)}
					name="error-go-back-button"
					type="button"
					variant="secondary"
					colorVariant={"grape"}>
					Go back
				</CCButton>
				{!!outsideLink && (
					<ButtonLink target="_blank" href={outsideLink.to} rel="noreferrer">
						{outsideLink.title}
					</ButtonLink>
				)}
			</ButtonContainer>
		</ErrorContainer>
	) : (
		<MessageDialog
			icon={WarningIcon}
			highlightClass="text-warning"
			outlineClass="outline-warning"
			title="Error!"
			message={message}
			hasOutline
		/>
	);
};

export default AppError;
