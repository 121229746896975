import styled from "@emotion/styled";

import { ContainerProps } from "./types";

const Container = styled.div(({ defaultPadding }: ContainerProps) => ({
	paddingBottom: defaultPadding ? "10rem" : "0px",
	height: defaultPadding ? "auto" : "100%",
}));

export default Container;
