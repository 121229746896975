import React from "react";

// Types
import type IconProps from "Types/IconProps";

const EyeIcon: React.FC<IconProps & { onClick?: () => void }> = ({
	width,
	height,
	id,
	className = "eye-icon",
	fill,
	onClick,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "24px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 24 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M15 8C15 9.654 13.654 11 12 11C10.346 11 9 9.654 9 8C9 6.346 10.346 5 12 5C13.654 5 15 6.346 15 8ZM23.101 6.22587C23.7048 7.02712 23.731 8.08987 23.178 8.92701C21.5966 11.321 17.7697 16 12.015 16C6.72478 16 2.70759 11.4133 0.959424 8.99879C0.327896 8.12657 0.336718 6.97662 0.999764 6.12811C2.70941 3.94025 6.54739 0 12.015 0C17.6567 0 21.4687 4.05998 23.101 6.22587ZM17 8C17 5.243 14.757 3 12 3C9.243 3 7 5.243 7 8C7 10.757 9.243 13 12 13C14.757 13 17 10.757 17 8Z"
				fill={fill != null ? fill : "#828282"}
			/>
		</svg>
	);
};

export default EyeIcon;
