// Helpers
import putReq from "Helpers/NetworkingHelpers/PutReq";

/**
 * @async
 * Replaces the logo/banner of the specific partner and returns success. It must be png format
 * @param partnerId Partner id needed to upload the logo / banner
 * @param type Type of image being uploaded ("logo" or "banner")
 * @param data Data needed to upload the logo / banner
 * @throws Networking error
 */
const replacePartnerImage = async (
	partnerId: string,
	data: string | Blob | File,
	type: "logo" | "banner",
): Promise<void> => {
	const form = new FormData();
	form.append("image", data);
	return await putReq(
		`${import.meta.env.VITE_BASE_URL}executive/partners/${partnerId}/${type}`,
		form,
		true,
		1,
	);
};

export default replacePartnerImage;
