// Helpers
import postReq from "../PostReq";

/**
 * Request a resend of the "verify email" link to a new, unverified user
 * @param userID The user's ID
 * @returns void
 */
const resendVerifyEmail = async (userID: string): Promise<void> => {
	await postReq(
		`${import.meta.env.VITE_BASE_URL}executive/users/${userID}/verify-email`,
		{},
		1,
		undefined,
		true,
	);
	return;
};

export default resendVerifyEmail;
