import useSWR from "swr";

// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import type { JSONApi, DataHookResponse } from "Types/JSONApi";
import type Chart from "Types/APIChart";

type Meta = {
	/** Auth token for the MongoDB charts */
	token: string;
	/** The base URL for calling the charts from MongoDB */
	baseURL: string;
};

type Response = JSONApi<Chart[], undefined, undefined, Meta, undefined>;

/**
 * Get the API charts
 * @returns a DataHookResponse tuple of Charts, Error, Mutate, Related, the full response and pagination options
 */
const useAPICharts = (): DataHookResponse<Chart[], undefined, Response> => {
	const { data, error, mutate } = useSWR<
		JSONApi<Chart[], undefined, undefined, Meta, undefined>,
		Error
	>([`${import.meta.env.VITE_BASE_URL}executive/charts`, 2], getReq, {
		shouldRetryOnError: false,
	});

	const [charts, related, fullJSONApiResponse] = transformAPIResponse(data, []);
	return [charts, error, mutate, related, fullJSONApiResponse];
};

export default useAPICharts;
