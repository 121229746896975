import React from "react";
import { useField } from "formik";

// CC
import CheckBox from "../CCW-CheckBox";

// Types
import CheckBoxFormikProps from "./types";

export const CheckBoxFormik: React.FC<CheckBoxFormikProps> = ({ ...props }) => {
	const [field, meta] = useField({ ...props, type: "checkbox" });

	return (
		<CheckBox
			error={meta.touched && meta.error ? meta.error : undefined}
			{...field}
			{...props}
		/>
	);
};

export default CheckBoxFormik;
