import React from "react";

// Styles
import { OriginalLink, VariantLink } from "./styles";

// Types
import PLinkProps from "./types";

/** Base input field */
const PLink: React.FC<PLinkProps> = ({ to, isColorVariant, ...props }) => {
	return isColorVariant ? (
		<VariantLink to={to} {...props} />
	) : (
		<OriginalLink to={to} {...props} />
	);
};

export default PLink;
