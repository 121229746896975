import React, { ReactNode } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

// CC
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import AppError from "CCW-Components/CCW-AppError";
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import Container from "CCW-Components/CCW-Container";
import ButtonLink from "CCW-Components/CCW-ButtonLink";
import PageControls from "CCW-Components/CCW-PageControls";
import PageHeader from "CCW-Components/CCW-PageHeader";

// Local Components

// Hooks
import useUtilities from "Hooks/UseUtilities";

// Helpers
import { emissionTypesWithUtility } from "Pages/Measure/Helpers";
import { states } from "Helpers/StringHelpers/Address";

// Local CC
import CreateUtility from "../CreateUtility";

// Assets
import { TickIcon } from "Assets/SVG";
import { CrossIcon } from "Assets/SVG";

// Styles
import {
	Cell,
	HeaderCell,
	Table,
	TopRow,
	OverflowScroll,
	Row,
} from "CCW-Components/CCW-TableStyles/styles";

// Types
import type DefaultUtilityPageProps from "./types";
import type { State } from "Types/Address";
import type { MeasureCategoryWithUtilities } from "Types/MeasureCategory";
import type FilterBarProps from "CCW-Components/CCW-PageControls/Components/FilterBar/types";

export const DefaultUtilityPage: React.FC<DefaultUtilityPageProps> = () => {
	const location = useLocation();
	const searchParams: URLSearchParams = new URLSearchParams(location.search);
	const stateParams = searchParams.get("state") as State;
	const emissionTypeParams = searchParams.get(
		"emissionType",
	) as MeasureCategoryWithUtilities;

	const [utilities, utilitiesError, utilitiesMutate] = useUtilities(
		stateParams || undefined,
		emissionTypeParams || undefined,
	);

	const onSuccess = () => {
		utilitiesMutate();
	};

	const filterOptions: FilterBarProps | undefined = {
		displayNames: {
			emissionType: "EmissionType",
			state: "State",
		},
		options: [
			...states.map((state) => ({
				display: state,
				group: "state",
				isSelected: stateParams === state,
				value: state,
			})),
			...emissionTypesWithUtility.map((emissionType) => ({
				display: emissionType,
				group: "emissionType",
				isSelected: emissionTypeParams === emissionType,
				value: emissionType,
			})),
		],
	};
	const hiddenColumns: string[] = ["_id", "createdAt"];

	const columns = Array.from(
		utilities?.reduce((columns, utility) => {
			Object.keys(utility).forEach((key) => columns.add(key));
			return columns;
		}, new Set<string>()) || [],
	).filter((column) => !hiddenColumns.includes(column));

	// Error state
	if (utilitiesError) {
		return <AppError error={utilitiesError} />;
	}

	// Loading state
	if (!utilities) {
		return <BlockLoader message="Loading utilities..." />;
	}

	const getBooleanIcon = (booleanToCheck: boolean) =>
		booleanToCheck ? (
			<TickIcon width="20px" height="20px" fill="green" />
		) : (
			<CrossIcon width="10px" height="10px" fill="red" />
		);

	const formatCell = (cell: unknown): ReactNode => {
		switch (typeof cell) {
			case "boolean":
				return getBooleanIcon(cell);
			case "string":
				return Date.parse(cell)
					? new Date(cell).toLocaleDateString()
					: cell.startsWith("https://")
					? getBooleanIcon(true)
					: cell;
			case "number":
				return cell;
			case "object":
				return JSON.stringify(cell);
			default:
				return (cell as ReactNode) || "-";
		}
	};

	return (
		<DefaultPage id="utilities-page" onNavigation={true}>
			<Container id="utilities-container">
				<PageHeader>Utility Providers</PageHeader>
				<Routes>
					<Route
						path="/"
						element={
							<div className="pt-10">
								<ButtonLink to="/utilities/create">
									Create new utility provider
								</ButtonLink>
								<PageControls
									className="pt-0"
									hideSidebar
									header={"Utility"}
									showViewOptions={false}
									showSortDirection={false}
									showSearch={false}
									navLinks={[]}
									filter={{
										...filterOptions,
										showSearch: false,
										showSortDirection: false,
									}}>
									{!columns.length ? (
										<TopRow>
											<HeaderCell>No results found</HeaderCell>
										</TopRow>
									) : (
										<OverflowScroll>
											<Table border={0}>
												<thead tabIndex={1}>
													<TopRow>
														<HeaderCell />
														{columns?.map((column, index) => (
															<HeaderCell
																key={column + index}
																className="capitalize">
																{column === "billFetchSupported"
																	? "Bill Fetch"
																	: column}
															</HeaderCell>
														))}
													</TopRow>
												</thead>
												<tbody>
													{utilities?.map((utility, index) => {
														return (
															<Row
																aria-label={`Utility ${index + 1}`}
																tabIndex={2}
																id={utility?._id as string}
																key={utility?._id as string}>
																<Cell
																	key={index}
																	style={{ position: "relative" }}></Cell>
																{columns.map((column, index) => {
																	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
																	// @ts-ignore
																	const cell = formatCell(utility[column]);
																	return (
																		<Cell key={index} className="capitalize">
																			{cell}
																		</Cell>
																	);
																})}
															</Row>
														);
													})}
												</tbody>
											</Table>
										</OverflowScroll>
									)}
								</PageControls>
							</div>
						}
					/>
					<Route
						path="/create"
						element={<CreateUtility {...{ onSuccess }} />}
					/>
				</Routes>
			</Container>
		</DefaultPage>
	);
};

export default DefaultUtilityPage;
