import React from "react";

// CC or Bit
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import ButtonLink from "CCW-Components/CCW-ButtonLink";
import Container from "CCW-Components/CCW-Container";

// Assets
import MissingIcon from "Assets/SVG/MissingIcon";

// Styles
import { Content, Text } from "./styles";

const DefaultMissing404: React.FC = () => {
	return (
		<DefaultPage defaultPadding={false}>
			<Container>
				<Content>
					<MissingIcon width="450" height="204" />
					<Text>
						{
							"Where in the “world” are you? We couldn’t find the page you were looking for, it may be lost or broken."
						}
					</Text>
					<ButtonLink to="/" name="404-go-button" type="button">
						Go to home page
					</ButtonLink>
				</Content>
			</Container>
		</DefaultPage>
	);
};

export default DefaultMissing404;
