import React from "react";
import { useSpring } from "react-spring";

// CC
import VisuallyHidden from "../CCW-VisuallyHidden";

// Helpers
import useReduceMotion from "Hooks/UseReduceMotion";

// Assets
import WarningIcon from "Assets/SVG/WarningIcon";
import TickIcon from "Assets/SVG/TickIcon";

// Styles
import { Container, InnerAlert, Text, Loader, CloseButton } from "./styles";

// Types
import { AlertProps } from "./types";

export const Alert: React.FC<AlertProps> = ({
	type = "error",
	message,
	duration,
	onFinish,
	...props
}) => {
	// Hooks
	const [styles, animationAPI] = useSpring(() => ({
		width: "0%",
		from: { width: "100%" },
		config: {
			duration: duration,
			tension: 125,
			friction: 20,
			precision: 0.1,
		},
		onRest: () => onFinish && onFinish(),
	}));
	const reduceMotion = useReduceMotion();

	const iconAriaText = (type: "error" | "success") => {
		switch (type) {
			case "success":
				return "Success:";
			default:
				return "Error:";
		}
	};

	const chooseIcon = (type: "error" | "success") => {
		switch (type) {
			case "success":
				return <TickIcon width="24px" height="24px" />;
			default:
				return <WarningIcon width="24px" height="24px" />;
		}
	};

	return (
		<Container
			type={type}
			{...props}
			role="status"
			aria-live="polite"
			onMouseEnter={() => animationAPI.pause()}
			onMouseLeave={() => animationAPI.resume()}>
			<InnerAlert timedAlert={!!duration}>
				<strong>
					{chooseIcon(type)}
					<VisuallyHidden>
						<span>{iconAriaText(type)}</span>
					</VisuallyHidden>
				</strong>
				<Text>{message}</Text>
			</InnerAlert>
			<CloseButton useWhiteColorFill onClick={() => onFinish && onFinish()} />
			{!reduceMotion && duration && <Loader style={styles} />}
		</Container>
	);
};

export default Alert;
