import React, { useState } from "react";
import { useField } from "formik";

// CC
import UploadCloudIcon from "Assets/SVG/UploadCloudIcon";
import ProfileImage from "CCW-Components/CCW-ProfileImage";
import TransitionHeightArray from "../CCW-TransitionHeightArray";

// Types
import type { FileProps } from "./types";

// Helpers
import addMagnitudeSuffix from "Helpers/NumberHelpers/AddMagnitudeSuffix";

// Styles
import {
	Container,
	InputBox,
	Input,
	UploadInputButton,
	FileNameBox,
	FileName,
	Error,
	DragAndDropBox,
	DragHint,
	FlexRow,
	ImageBox,
	MarginLeft,
	FileInfo,
} from "./styles";
import { CloseButton } from "../CCW-CloseButton";

/** Base input field */
const FileInput: React.FC<FileProps> = ({
	id,
	name,
	className,
	accept,
	border = false,
	disabled = false,
	multiple = false,
	...props
}) => {
	// Formik
	const [, meta, helpers] = useField<File[] | undefined>(name);
	const [killInput, setKillInput] = useState(false);

	// Helpers
	const showPreview = (file: File | null): boolean => {
		return !!file?.type?.includes("image");
	};

	const handleChange = (
		event: React.DragEvent<HTMLElement> & React.ChangeEvent<HTMLInputElement>,
	) => {
		const newSelection = Array.from(
			event.dataTransfer?.files || event.currentTarget?.files,
		);

		const combinedFiles = multiple
			? newSelection
			: [...(meta.value || []), ...newSelection].filter(
					// Removes duplicates
					(file, index, allFiles) =>
						index ===
						allFiles.findIndex(
							(fileToCheck) =>
								fileToCheck.name + fileToCheck.size === file.name + file.size, // The (rough) test for equality
						),
			  );

		event.stopPropagation();
		event.preventDefault();
		helpers.setValue(combinedFiles);
		helpers.setTouched(true);
		// This KillInput removed and replaces the input in the DOM so we can make additional selections
		setKillInput(true);
		setTimeout(() => {
			setKillInput(false);
		}, 1);
	};

	return (
		<Container id="upload-file-container" {...props}>
			<DragAndDropBox
				onDrop={handleChange}
				onDragOver={(e) => e.preventDefault()}
				onDragEnter={(e) => e.preventDefault()}>
				<UploadCloudIcon width="68" height="68" />
				<DragHint>Drop file here or</DragHint>
				<UploadInputButton
					className={className}
					htmlFor={name}
					border={border}
					error={meta.error}
					disabled={disabled}>
					Choose File
				</UploadInputButton>
			</DragAndDropBox>
			<InputBox>
				{!killInput && (
					<Input
						accept={accept}
						id={id || name}
						name={name}
						type="file"
						onChange={handleChange}
						multiple={multiple}
						disabled={disabled}
					/>
				)}
			</InputBox>
			<TransitionHeightArray
				elements={(meta.value || []).map((file, index) => (
					<FlexRow key={index}>
						<FileNameBox>
							<FileName>{file.name || ""}</FileName>
						</FileNameBox>
						<ImageBox>
							{showPreview(file) && (
								<ProfileImage imageSrcURL={URL.createObjectURL(file)} />
							)}
						</ImageBox>
						<FileInfo>{addMagnitudeSuffix(file.size, true)}B</FileInfo>
						<MarginLeft>
							<CloseButton
								onClick={() =>
									helpers.setValue(
										(meta.value || []).filter(
											(_, indexToCheck) => indexToCheck !== index,
										),
									)
								}
							/>
						</MarginLeft>
					</FlexRow>
				))}
			/>
			<Error error={meta.error}>{meta.error}</Error>
		</Container>
	);
};

export default FileInput;
