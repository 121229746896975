import styled from "@emotion/styled";

// CC
import CustomButton from "CCW-Components/CCW-CustomButtonLink";

export const CustomButtonLink = styled(CustomButton)(() => ({
	display: "flex",
}));

export const BackButton = styled.button<{ linkColor: boolean }>(
	({ theme, linkColor }) => ({
		paddingLeft: "0",
		color: theme.colors.Blueberry400,
		...theme.webFonts.F6Bold,
		background: "transparent",
		cursor: "pointer",
		border: "none",
		textAlign: "left",
		display: "flex",
		alignItems: "center",
		"& > svg": {
			marginRight: "1.3rem",
			"& > path": {
				fill: theme.colors.Blueberry400,
			},
		},
		"&:hover, :focus": {
			color: theme.colors.Blueberry600,
			"& > svg > path": {
				fill: theme.colors.Blueberry600,
			},
		},
		"&:active": {
			transform: "translateY(2px) scale(0.98)",
		},
	}),
);
