import styled from "@emotion/styled";
import { Theme } from "@emotion/react";

// Types
import { ColorProps, ErrorProps } from "./types";

const addBorder = (theme: Theme, error?: string, border?: boolean) => {
	if (error != null && error !== "") return `1px solid ${theme.colors.Red}`;
	else if (border === false) return "none";
	else return `1px solid ${theme.colors.Black20}`;
};

export const Container = styled.div(() => ({
	position: "relative",
	width: "100%",
	height: "4rem",
	padding: "0 0.2rem",
}));

export const UploadInput = styled.input<ColorProps>(
	({ theme, border, color, error }) => ({
		width: "100%",
		height: "4rem",
		color: theme.colors.D3,
		...theme.webFonts.F5,
		background: "white",
		padding: "0.5rem 0.5rem 1rem 0.5rem",
		border: addBorder(theme, error, border),
		borderRadius: "0.5rem",
		"&:disabled": {
			...theme.webFonts.F5SemiBold,
			cursor: "default",
			background: theme.colors.Black10,
			opacity: 0.7,
			boxShadow: "none",
			color: theme.colors.D2,
		},
		"&:focus": {
			boxShadow: theme.shadow,
			border: `1px solid ${
				error != null && error !== "" ? theme.colors.Red : theme.colors.primary
			}`,
			background: "white",
			color: error != null && error !== "" ? theme.colors.Red : theme.colors.D1,
		},
		"::-webkit-file-upload-button ": {
			color: theme.colors.White,
			border: color
				? `1px solid ${color}`
				: `1px solid ${theme.colors.primary}`,
			...theme.webFonts.F5,
			background: color ? color : theme.colors.primary,
			borderRadius: "0.5rem",
			boxShadow: theme.shadow,
			padding: "0.4rem 0.8rem 0.3rem 0.8rem",
			marginRight: "2rem",
			"&:hover, :focus": {
				boxShadow: theme.shadow,
				cursor: "pointer",
			},
			"&:active": {
				transform: "translateY(2px) scale(0.98)",
				boxShadow: "none",
			},
		},
	}),
);

export const Error = styled.span<ErrorProps>(({ theme, error, border }) => ({
	color: theme.colors.Red,
	...theme.webFonts.F6,
	display: error != null && error !== "" ? "auto" : "none",
	position: "absolute",
	left: border === true ? "7px" : "3px",
	bottom: "-2rem",
	whiteSpace: "nowrap",
	overflow: "hidden",
}));
