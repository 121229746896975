import React from "react";

// Types
import { FormGroupProps } from "./types";

// Styles
import { Group } from "./styles";

/** Custom div for wrapping form groups to create universal styling */
const FormGroup: React.FC<FormGroupProps> = (props) => {
	return <Group {...props} />;
};

export default FormGroup;
