import React from "react";
import { useField } from "formik";

// Components
import TextInputPassword from "CCW-Components/CCW-TextInputPassword";
import InputLinkLabel from "CCW-Components/CCW-InputLinkLabel";
import FormGroup from "CCW-Components/CCW-FormGroup";

// Types
import { FIGLoginPasswordProps } from "./types";

const FIGLoginPassword: React.FC<FIGLoginPasswordProps> = ({
	label,
	required,
	name,
	className,
	linkText,
	link,
	...props
}) => {
	const [field, meta] = useField({ name });

	return (
		<FormGroup className={className}>
			<InputLinkLabel
				htmlFor={props.id}
				text={label}
				required={required}
				link={link}
				linkText={linkText}
			/>
			<TextInputPassword
				{...field}
				{...props}
				error={meta.touched && meta.error ? meta.error : undefined}
			/>
		</FormGroup>
	);
};

export default FIGLoginPassword;
