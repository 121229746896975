import styled from "@emotion/styled";

import { MainBarProps, InnerBarProps } from "./types";

export const MainBar = styled.div<MainBarProps>(
	({ theme, height, color, isRoundedEdges }) => ({
		width: "100%",
		height: height || "10px",
		margin: "auto 0",
		padding: "0",
		// position: "relative",
		overflow: "hidden",
		background: color || theme.colors.Black10,
		borderRadius: isRoundedEdges ? "12px" : "0px",
	}),
);

export const InnerBar = styled.div<InnerBarProps>(
	({ theme, color, progress, isRoundedEdges }) => ({
		background: color != null ? color : theme.colors.D3,
		borderRadius: isRoundedEdges ? "10px" : "0px",
		width: progress != null ? `${progress}%` : "0px",
		height: "100%",
		margin: "auto 0",
		padding: "0",
		// position: "relative",
		overflow: "hidden",
		transition: "width 1s",
		WebkitTransition: "width 1s",
	}),
);
