import styled from "@emotion/styled";
import { StyledProps } from "./types";

export const Container = styled.div(() => ({
	position: "relative",
	width: "100%",
	height: "4rem",
}));

export const Input = styled.input<StyledProps>(({ theme, error }) => ({
	width: "100%",
	height: "4rem",
	padding: "0.6rem 1.5rem",
	borderRadius: "0.5rem",
	background: "white",
	border: `1px solid ${
		error != null && error !== "" ? theme.colors.Red : theme.colors.grey300
	}`,
	...theme.webFonts.F6,
	color: error != null && error !== "" ? theme.colors.Red : theme.colors.D1,
	outline: "none",
	"&[readonly]": {
		background: theme.colors.Black10,
		border: `1px solid ${
			error != null && error !== "" ? theme.colors.Red : theme.colors.Black10
		}`,
		color:
			error != null && error !== "" ? theme.colors.Red : theme.colors.Black50,
	},
	"&:disabled": {
		...theme.webFonts.F6SemiBold,
		cursor: "default",
		background: theme.colors.Black10,
		opacity: 0.7,
		border: `1px solid ${
			error != null && error !== "" ? theme.colors.Red : theme.colors.Black10
		}`,
		color: error != null && error !== "" ? theme.colors.Red : theme.colors.D1,
	},
	"&:placeholder": {
		color: theme.colors.D3,
	},
	"&:focus": {
		boxShadow: theme.shadow,
		border: `1px solid ${
			error != null && error !== "" ? theme.colors.Red : theme.colors.primary
		}`,
		background: "white",
		color: error != null && error !== "" ? theme.colors.Red : theme.colors.D1,
	},
}));

export const Error = styled.span<StyledProps>(({ theme, error }) => ({
	color: theme.colors.Red,
	...theme.webFonts.F6,
	display: error != null && error !== "" ? "auto" : "none",
	position: "absolute",
	left: "0px",
	bottom: "-2rem",
	whiteSpace: "nowrap",
	overflow: "hidden",
}));
