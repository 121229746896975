import styled from "@emotion/styled";

// CC
import CardTemplate from "CCW-Components/CCW-CardTemplate";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Card = styled(CardTemplate)(() => ({
	padding: "25px",
	[MediaQueries[0]]: {
		padding: "50px",
	},
}));

export const Message = styled.h2(({ theme }) => ({
	color: theme.colors.D2,
	margin: "12px 0px 30px 0px",
	padding: "0px",
	...theme.webFonts.F4,
}));
