import getReqHeader, { ContentType } from "../GetReqHeader";
import makeError from "../MakeError";

/**
 * Networking function for patch requests
 * @param url The url for the request
 * @param reqBody Data to be send in the request body
 * @type T represents the shape the returned data key in an API response ({v: '1', data: THIS})
 */
const patchReq = async <T>(
	url: string,
	reqBody: Record<string, unknown> | Record<string, unknown>[],
	version?: number,
	contentType: ContentType = "JSON",
): Promise<T> => {
	const requestHeaders = new Headers();
	requestHeaders.set("Content-Type", getReqHeader(contentType));
	version != null &&
		requestHeaders.set("Accept", `application/vnd.api.v${version}+json`);
	try {
		const response = await fetch(url, {
			method: "PATCH",
			credentials: "include",
			headers: requestHeaders,
			body: JSON.stringify(reqBody),
		});
		const responseData = await response.json();
		if (!response.ok) {
			throw makeError(responseData.error);
		}
		return responseData;
	} catch (error) {
		throw makeError(error);
	}
};

export default patchReq;
