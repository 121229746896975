import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// CC
import CardFormTemplate from "CCW-Components/CCW-CardFormTemplate";
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import ButtonLink from "CCW-Components/CCW-ButtonLink";

// Hooks
import useUpdateEmailConfirmForAU from "Hooks/UseUpdateEmailConfirmForAU";

// Helpers
import isError from "Helpers/TypeHelpers/IsError";

// Styles
import { Card, Message } from "./styles";

// Types
import type Alert from "Types/Alert";

export const DefaultConfirmEmailChangePage = () => {
	// State
	const [alert, setAlert] = useState<Omit<Alert, "id">>();

	// Hooks
	const location = useLocation();
	const updateEmailConfirm = useUpdateEmailConfirmForAU();

	// Helpers
	useEffect(() => {
		const validateToken = async (search: string) => {
			try {
				const url = new URLSearchParams(search);
				const token = url.get("token");

				if (token == null) {
					setAlert({
						message:
							"No validation link found, try pasting the link directly from your confirm update email",
						type: "error",
					});
					return;
				}
				await updateEmailConfirm(token);
				setAlert({ message: "Your email has been updated", type: "success" });
			} catch (err) {
				setAlert({
					message: isError(err)
						? err.message
						: "Action failed. Please contact support",
					type: "error",
				});
			}
		};
		validateToken(location.search);
	}, [location.search, updateEmailConfirm]);

	return (
		<CardFormTemplate>
			{alert != null ? (
				<Card title={alert.type === "success" ? "Success" : "Error"}>
					<Message>{alert.message}</Message>

					<ButtonLink block type="button" to="/settings/account">
						Profile Settings
					</ButtonLink>
				</Card>
			) : (
				<Card>
					<BlockLoader message="Validating email change request" />
				</Card>
			)}
		</CardFormTemplate>
	);
};

export default DefaultConfirmEmailChangePage;
