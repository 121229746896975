import { AddressNew, State } from "Types/Address";

export const states: State[] = [
	"ACT",
	"NSW",
	"NT",
	"QLD",
	"SA",
	"TAS",
	"VIC",
	"WA",
];

export const addressToString = (address: AddressNew) =>
	`${"street" in address ? address.street : ""}, ${address.state} ${
		address.postcode
	}`;
