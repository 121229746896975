import styled from "@emotion/styled";

export const Container = styled.div(({ theme }) => ({
	"#login-button": {
		margin: "3rem 0 1rem",
	},
}));

export const ResentMessage = styled.p(({ theme }) => ({
	...theme.webFonts.F6,
	color: theme.colors.primary,
	"& > span": {
		...theme.webFonts.bold,
	},
}));

export default Container;
