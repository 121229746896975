import styled from "@emotion/styled";
import CCInputLabel from "../CCW-InputLabel";

// Types
import { LabelProps } from "./types";

export const LabelContainer = styled.div<LabelProps>(({ showSubLabel }) => ({
	marginBottom: showSubLabel ? "1rem" : 0,
}));

export const InputLabel = styled(CCInputLabel)<LabelProps>(
	({ showSubLabel }) => ({
		marginBottom: showSubLabel ? 0 : "default",
	}),
);

export const SubLabel = styled.span(({ theme }) => ({
	...theme.webFonts.F6,
	...theme.webFonts.italic,
	color: theme.colors.D2,
}));
