import React from "react";
import { useField } from "formik";

// Components
import FileInput from "../CCW-FileInput";
import InputLabel from "../CCW-InputLabel";
import FormGroup from "../CCW-FormGroup";

// Types
import FIGFileInputProps from "./types";

const FIGFileInput: React.FC<FIGFileInputProps> = ({
	label,
	required,
	name,
	className,
	...props
}) => {
	const [field, meta, helpers] = useField(name);

	// Actions
	const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
		helpers.setValue(event?.currentTarget?.files?.[0]);
	};

	return (
		<FormGroup className={className}>
			<InputLabel htmlFor={props.id} text={label} required={required} />
			<FileInput
				{...field}
				{...props}
				onChange={handleFileSelect}
				error={meta.touched && meta.error ? meta.error : undefined}
			/>
		</FormGroup>
	);
};

export default FIGFileInput;
