import React from "react";
import { Routes, useLocation } from "react-router-dom";
import { animated, config, useTransition } from "react-spring";
import * as Sentry from "@sentry/react";

// Types
import type TransitionRoutesProps from "./types";
import useReduceMotion from "Hooks/UseReduceMotion";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const TransitionRoutes: React.FC<TransitionRoutesProps> = ({
	isWithSentry = false,
	children,
	configuration = "onlyAnimateOnRootRouteChanges",
}) => {
	const location = useLocation();
	const reduceMotion = useReduceMotion();

	const RoutesComponent = isWithSentry ? SentryRoutes : Routes;

	const transitions = useTransition(location, {
		from: {
			transform: `translate3d(0, ${!reduceMotion ? -5 : 0}px, 0)`,
			opacity: 0,
		},
		enter: [
			{
				transform: "translate3d(0, 0px, 0)",
				opacity: 1,
			},
			{
				transform: "none",
				opacity: 1,
			},
		],
		config: config.default,
		key: (location: Location) =>
			// React spring only animates when the key changes
			configuration === "onlyAnimateOnRootRouteChanges"
				? location.pathname.split("/")[1] || ""
				: location.pathname,
	});

	return transitions((animationStyles, location) => (
		<animated.div
			style={{
				...animationStyles,
				position: "relative",
			}}>
			<RoutesComponent location={location}>{children}</RoutesComponent>
		</animated.div>
	));
};

export default TransitionRoutes;
