import React from "react";

// Types
import type IconProps from "Types/IconProps";

/** Customizable svg icon */
export const AnalyticsIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "analytics-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "32px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.8602 2.66984C26.6302 2.62205 27.3431 3.11781 27.6367 3.90519L31.8631 15.2385C32.244 16.2599 31.8029 17.4288 30.8779 17.8494C29.9529 18.2699 28.8943 17.7829 28.5134 16.7615L26.2716 10.7499L22.1834 26.5495C21.9743 27.3574 21.3302 27.9349 20.5711 27.9949C19.812 28.0549 19.1 27.5846 18.7888 26.8178L14.5633 16.4044L11.4221 24.8547C11.1872 25.4868 10.6762 25.9427 10.0672 26.0637C9.45813 26.1846 8.8353 25.9539 8.41557 25.4518L5.66544 22.1621L3.19405 25.3871C2.54788 26.2304 1.40499 26.3355 0.64134 25.622C-0.122312 24.9085 -0.217553 23.6466 0.428615 22.8034L4.2237 17.851C4.55904 17.4134 5.04831 17.1557 5.56733 17.1433C6.08635 17.131 6.58524 17.3651 6.93736 17.7863L9.14993 20.433L12.8147 10.5739C13.0897 9.83411 13.7374 9.3463 14.4616 9.33361C15.1858 9.32091 15.8472 9.78577 16.1433 10.5156L20.0643 20.1785L24.2203 4.11725C24.4321 3.29849 25.0903 2.71763 25.8602 2.66984Z"
				fill={fill != null ? fill : "rgba(0, 0, 0, 0.5)"}
			/>
		</svg>
	);
};

export default AnalyticsIcon;
