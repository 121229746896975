import React from "react";
import { Link, useMatch } from "react-router-dom";

// Styles
import { LinkContainer, Text, Item } from "./styles";

// Types
import { NavButtonProps } from "./types";

// Assets
import { HalloweenIcon, ChristmasIcon } from "Assets/SVG";

const NavButton: React.FC<NavButtonProps> = ({
	title,
	to,
	activeOnlyWhenExact = false,
	icon: Icon,
}) => {
	// Hooks
	const match = useMatch({
		path: to,
		end: activeOnlyWhenExact,
	});

	return (
		<Item>
			<LinkContainer selected={!!match}>
				<Link to={to}>
					{Icon && <Icon width="24px" height="24px" />}
					<Text>{title}</Text>
				</Link>
			</LinkContainer>
			{!!match && import.meta.env.VITE_HOLIDAY === "halloween" && (
				<HalloweenIcon type="claw" width="50px" height="29px" id="halloween" />
			)}
			{!!match && import.meta.env.VITE_HOLIDAY === "xmas" && (
				<ChristmasIcon type="snowNavBar" width="85px" height="89px" id="xmas" />
			)}
		</Item>
	);
};

export default NavButton;
