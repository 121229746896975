// Types
import User from "Types/User";
import Account, { PartnerAccount } from "Types/Account";

export const commsForUser = (
	type: "boot" | "update",
	user: User,
	account: Account | PartnerAccount,
	hash?: string,
) => {
	if (import.meta.env.VITE_COMMS_FLAG === "off") return;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore: Unreachable code error
	window.Intercom(type, {
		app_id: "wh901oj1",
		user_id: user._id,
		email: user.email,
		name: `${user.fName} ${user.lName}`,
		webV: import.meta.env.VITE_VERSION as string,
		firstName: user.fName,
		lastName: user.lName,
		account: account._id,
		...("name" in account ? { accountName: account.name } : {}),
		tier: account.tier,
		permission: account.permission,
		accountType: account.accountType,
		appType: "web",
		user_hash: hash,
	});
};

export const updateCommsSessionForUser = (
	id: string,
	data?: { [key: string]: string | number | boolean },
) => {
	if (import.meta.env.VITE_COMMS_FLAG === "off") return;
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore: Unreachable code error
	window.Intercom("update", { user_id: id, ...data });
};

export const refreshCommsSession = () => {
	if (import.meta.env.VITE_COMMS_FLAG === "off") return;
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore: Unreachable code error
	window.Intercom("update");
};

export const closeCommsSession = () => {
	if (import.meta.env.VITE_COMMS_FLAG === "off") return;
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore: Unreachable code error
	window.Intercom("shutdown");
};
