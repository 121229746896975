import React, { PropsWithChildren } from "react";

// Styles
import Column from "./styles";

const ProfileColumn: React.FC<PropsWithChildren<unknown>> = (props) => (
	<Column {...props} />
);

export default ProfileColumn;
