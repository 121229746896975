import React from "react";

// Assets
import ArrowIcon from "Assets/SVG/ArrowIcon";

// Styles
import { CustomButtonLink, BackButton } from "./styles";

// Types
import type BacklinkProps from "./types";
import { useNavigate } from "react-router-dom";

export const Backlink: React.FC<BacklinkProps> = ({
	to,
	linkColor,
	className,
	...props
}) => {
	const navigate = useNavigate();
	return (
		<CustomButtonLink
			className={className}
			to={to}
			button={BackButton}
			type="button"
			{...(to === "back" ? { onClick: () => navigate(-1) } : { to })}
			{...{ linkColor }}>
			<ArrowIcon width="12px" height="12px" direction="left" />
			{props.children}
		</CustomButtonLink>
	);
};

export default Backlink;
