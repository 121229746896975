import styled from "@emotion/styled";

// CC
import CardTemplate from "CCW-Components/CCW-CardTemplate";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Card = styled(CardTemplate)(() => ({
	padding: "2.5rem",
	[MediaQueries[0]]: {
		padding: "5rem",
	},
}));
