import React from "react";
import { NavLink } from "react-router-dom";
import { useSWRConfig } from "swr";

// CC
import TransitionHeight from "../CCW-TransitionHeight";

// Hooks
import useSession from "Hooks/UseSession";

// Helpers
import logout from "Helpers/NetworkingHelpers/Logout";

// Assets
import SwitchSiteIcon from "Assets/SVG/SwitchSiteIcon";
import SettingsIcon from "Assets/SVG/SettingsIcon";
import BookIcon from "Assets/SVG/BookIcon";
import RoomIcon from "Assets/SVG/RoomIcon";

// Styles
import {
	Menu,
	MenuContainer,
	Column,
	EntityContainer,
	EntityName,
	SiteName,
	MenuButton,
	SmallProfileImage,
	Padding,
	LinkStyles,
	Divider,
} from "./styles";

// Types
import { NavBarMenuProps } from "./types";

// Helpers
import { track } from "Helpers/TrackingHelpers/Events";
import { closeCommsSession } from "Helpers/CommsHelpers";

const NavBarMenu: React.FC<NavBarMenuProps> = ({
	accountName,
	entityName,
	accountImage,
	isOpen,
	closeMenu,
	isMultipleSites,
	menuItems = [
		{ to: "/settings", title: "Settings", icon: SettingsIcon },
		{ to: "/resources", title: "Resources", icon: BookIcon },
	],
}) => {
	//Hooks
	const [, , sessionMutate] = useSession();
	const { cache } = useSWRConfig();

	const items = [
		...menuItems,
		...(isMultipleSites
			? [
					{
						to: "/sites",
						title: "Manage Sites",
						icon: SwitchSiteIcon,
					},
			  ]
			: []),
	];

	// Actions
	const logoutUser = async () => {
		localStorage.removeItem(
			"climateclever_hidePromotionalUpgradeForTheCurrentSession",
		);
		logout && (await logout());
		sessionMutate(undefined);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		cache.clear();
		closeCommsSession();
	};

	return (
		<TransitionHeight visible={isOpen}>
			<Padding>
				<Menu id="cc-nav-menu">
					<MenuContainer>
						<EntityContainer>
							{accountImage && <SmallProfileImage imageSrcURL={accountImage} />}
							<Column>
								{(!accountName || accountName !== entityName) && (
									<EntityName>{accountName ?? "home"}</EntityName>
								)}
								{entityName && <SiteName>{entityName}</SiteName>}
							</Column>
						</EntityContainer>
						{items.map(({ title, to, icon: Icon }) => (
							<React.Fragment key={title}>
								{((title === "View demo" && items.length > 4) ||
									title === "Settings") && <Divider />}
								<LinkStyles isLoud={title.includes("Upgrade")} key={title}>
									{title === "View demo" ? (
										<a
											target="_blank"
											rel="noreferrer"
											onClick={closeMenu}
											href={to}>
											<MenuButton>
												{Icon && (
													<Icon fill="#828282" width={"20"} height={"20"} />
												)}
												<span>{title}</span>
											</MenuButton>
										</a>
									) : (
										<NavLink
											to={to}
											onClick={() => {
												title.includes("Upgrade") &&
													track("Clicked Upgrade Now", {
														location: "Hamburger Menu",
													});
												closeMenu();
											}}>
											<MenuButton>
												{Icon && (
													<Icon fill="#828282" width={"20"} height={"20"} />
												)}
												<span>{title}</span>
											</MenuButton>
										</NavLink>
									)}
								</LinkStyles>
							</React.Fragment>
						))}
						<LinkStyles>
							<MenuButton onClick={() => logoutUser()}>
								<RoomIcon fill="#828282" width={"20"} height={"20"} />
								<span>Logout</span>
							</MenuButton>
						</LinkStyles>
					</MenuContainer>
				</Menu>
			</Padding>
		</TransitionHeight>
	);
};

export default NavBarMenu;
