// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";
import { track } from "Helpers/TrackingHelpers/Events";

// Types
import type MeasureCategory from "Types/MeasureCategory";

type Response = {
	v: string;
	jsonapi: {
		version: string;
	};
	meta: { template: string };
};

/**
 * @async
 * Gets a template to upload csv
 * @param emissionType the emission type you want to fetch a template for
 * @param dataType the type of data you want for the template (entries or identifiers)
 * @param entityID The entityID you want to track this action to
 * @returns a URL
 * @throws Networking error
 */
const getCSVTemplate = async (
	emissionType: MeasureCategory,
	dataType: "emission-entries" | "emission-identifiers" | "emission-factors",
): Promise<string | undefined> => {
	const queryString = new URLSearchParams({
		...(dataType ? { dataType } : {}),
		...(emissionType ? { emissionType } : {}),
	}).toString();

	const data: Response = await getReq([
		`${import.meta.env.VITE_BASE_URL}measure/csv-templates${
			queryString ? "?" + queryString : ""
		}`,
		1,
		false,
		"JSONAPI",
	]);

	track("Downloaded CSV Template URL", {
		dataType,
		emissionType,
	});

	return data?.meta.template;
};

export default getCSVTemplate;
