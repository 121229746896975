import styled from "@emotion/styled";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Container = styled.div(() => ({
	padding: "1.6rem 0",
	[MediaQueries[1]]: {
		padding: "2rem 0.1rem",
	},
}));
export default Container;
