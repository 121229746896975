import React from "react";

// CC or Bit
import InputLabel from "../CCW-InputLabel";
import PLink from "../CCW-PLink";

// Styles
import Container from "./styles";

// Types
import { InputLinkLabelProps } from "./types";

const InputLinkLabel: React.FC<InputLinkLabelProps> = ({
	text,
	htmlFor,
	linkText,
	link,
}) => {
	return (
		<Container>
			<InputLabel htmlFor={htmlFor} required={false} text={text} />
			<PLink to={link}>{linkText}</PLink>
		</Container>
	);
};

export default InputLinkLabel;
