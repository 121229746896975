import React, { PropsWithChildren } from "react";

// CC
import CCContainer from "CCW-Components/CCW-Container";

// Styles
import {
	StaticPDFContainer,
	TitleBox,
	Title,
	Subtitle,
	EntityLogo,
	ColorBar,
	SomeBox,
	Content,
	PageNumber,
} from "./styles";

// Types
import type PageProps from "./types";

export const Page: React.FC<PropsWithChildren<PageProps>> = ({
	titleDetails,
	subtitle,
	isPDF,
	isShowColorBar = true,
	icon: Icon,
	pageNumberText,
	logo,
	orientation = "portrait",
	setPadding = "full",
	children,
	id,
	...props
}) => {
	const Container = isPDF ? StaticPDFContainer : CCContainer;
	return (
		<Container
			id={id}
			setPadding={setPadding}
			orientation={orientation}
			{...props}>
			{!!titleDetails?.content && (
				<TitleBox>
					<Title color={titleDetails.color}>{titleDetails?.content}</Title>
					{!!subtitle && <Subtitle>{subtitle}</Subtitle>}
				</TitleBox>
			)}
			{isPDF && (
				<>
					{isShowColorBar && <ColorBar />}
					{!!logo && <EntityLogo src={logo} />}
					{pageNumberText && <PageNumber>{pageNumberText}</PageNumber>}
				</>
			)}

			{Icon ? (
				<SomeBox>
					<Icon width={"32"} height={"32"} />
					<Content>{children}</Content>
				</SomeBox>
			) : (
				<Content>{children}</Content>
			)}
		</Container>
	);
};

export default Page;
