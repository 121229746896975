import React from "react";
import { useTheme } from "@emotion/react";

// Types
import type IconProps from "Types/IconProps";

const DownloadArrow: React.FC<
	IconProps & { circle?: boolean; circleFill?: string }
> = ({
	width,
	height,
	id,
	className = "download-arrow-icon",
	fill,
	circle,
	circleFill,
	...props
}) => {
	const theme = useTheme();

	// NOTE: The fill gets changed for hover/active states in some places in the app. Check the styling on the parent.

	const handleFill = () => {
		if (circle && !fill) return theme.colors.White;
		else if ((circle && fill) || fill) return fill;
		else return theme.colors.D1;
	};

	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "32px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 32 32"
			fill={handleFill()}
			xmlns="http://www.w3.org/2000/svg">
			<circle
				cx="16"
				cy="16"
				r="16"
				fill={circleFill || (circle && theme.colors.primary) || "none"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.75812 15.3501C10.0862 15.0379 10.6003 15.0559 10.9065 15.3905L15.1875 20.0688V7.82871C15.1875 7.37103 15.5513 7 16 7C16.4487 7 16.8125 7.37103 16.8125 7.82871V20.0688L21.0935 15.3905C21.3997 15.0559 21.9138 15.0379 22.2419 15.3501C22.5699 15.6624 22.5877 16.1868 22.2815 16.5214L16.594 22.7367C16.4403 22.9047 16.2252 23 16 23C15.7748 23 15.5597 22.9047 15.406 22.7367L9.71852 16.5214C9.41234 16.1868 9.43007 15.6624 9.75812 15.3501Z"
				fill={handleFill()}
			/>
			<rect
				x="9.5"
				y="24.65"
				width="1.65"
				height="13"
				rx="0.825"
				transform="rotate(-90 9.5 24.65)"
			/>
		</svg>
	);
};

export default DownloadArrow;
