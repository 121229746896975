import React from "react";

// Types
import type IconProps from "Types/IconProps";

const SettingsIcon: React.FC<IconProps> = ({
	width,
	height,
	className = "settings-icon",
	id,
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "24px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 24 24"
			fill={fill || "currentColor"}
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.3162 0.948685C13.1274 0.38214 12.5972 0 12 0C11.4028 0 10.8726 0.38214 10.6838 0.948685L10.1617 2.51504C9.97831 3.06512 9.56526 3.50855 9.02955 3.73041L8.2527 4.05213C7.71709 4.27395 7.11158 4.25245 6.59305 3.99319L5.11628 3.25484C4.58211 2.98776 3.93697 3.09248 3.51469 3.51479C3.09243 3.93709 2.98776 4.5822 3.25484 5.11634L3.9932 6.59299C4.25249 7.11155 4.27399 7.71711 4.05213 8.25276L3.73036 9.02965C3.50849 9.56533 3.06507 9.97837 2.51501 10.1617L0.948689 10.6838C0.382143 10.8726 0 11.4028 0 12C0 12.5972 0.382143 13.1274 0.948689 13.3162L2.51503 13.8383C3.06508 14.0216 3.50849 14.4347 3.73037 14.9703L4.05218 15.7472C4.27404 16.2829 4.25255 16.8885 3.99327 17.407L3.25485 18.8839C2.98777 19.418 3.09248 20.0631 3.5148 20.4854C3.93706 20.9076 4.5821 21.0123 5.11619 20.7453L6.59321 20.0068C7.11175 19.7475 7.71727 19.7261 8.25288 19.9479L9.02959 20.2696C9.56527 20.4914 9.97831 20.9349 10.1617 21.4849L10.6838 23.0513C10.8726 23.6179 11.4028 24 12 24C12.5972 24 13.1274 23.6179 13.3162 23.0513L13.8383 21.485C14.0216 20.9349 14.4347 20.4915 14.9704 20.2696L15.7473 19.9479C16.2829 19.726 16.8884 19.7475 17.4069 20.0068L18.8839 20.7452C19.418 21.0123 20.063 20.9076 20.4853 20.4854C20.9075 20.0631 21.0122 19.418 20.7452 18.8839L20.0067 17.407C19.7474 16.8885 19.726 16.2829 19.9478 15.7472L20.2696 14.9703C20.4915 14.4347 20.9349 14.0216 21.485 13.8383L23.0513 13.3162C23.6179 13.1274 24 12.5972 24 12C24 11.4028 23.6179 10.8726 23.0513 10.6838L21.485 10.1617C20.9349 9.97837 20.4915 9.56533 20.2696 9.02965L19.9479 8.25276C19.726 7.71711 19.7475 7.11155 20.0068 6.59299L20.7452 5.11634C21.0122 4.5822 20.9076 3.93709 20.4853 3.51479C20.063 3.09248 19.4179 2.98776 18.8837 3.25484L17.407 3.99319C16.8884 4.25245 16.2829 4.27395 15.7473 4.05213L14.9704 3.73041C14.4347 3.50855 14.0217 3.06512 13.8383 2.51504L13.3162 0.948685ZM12 16.5C14.4853 16.5 16.5 14.4854 16.5 12C16.5 9.51465 14.4853 7.5 12 7.5C9.51471 7.5 7.5 9.51465 7.5 12C7.5 14.4854 9.51471 16.5 12 16.5Z"
			/>
		</svg>
	);
};

export default SettingsIcon;
