import React from "react";
import { useTheme } from "@emotion/react";

// CC
import ProgressBar from "CCW-Components/CCW-ProgressBar";

// Hooks
import useBreakpoints from "Hooks/UseBreakpoints";
import usePlatform from "Hooks/UsePlatform";
import useLoadImage from "Hooks/UseLoadImage";

// Helpers
import { breakpoints } from "Helpers/WebUIHelpers/Breakpoints";
import sentenceCase from "Helpers/StringHelpers/SentenceCase";

// Assets
import LogoLong from "Assets/SVG/CCDarkLogo";
import LogoShort from "Assets/SVG/Logo";

import {
	Container,
	PlatformBar,
	PlatformTitle,
	Header,
	CCLogoBox,
	LogoBar,
	PartnerImage,
	PartnerName,
	Main,
	ContentBox,
	Content,
	ProgressBarBox,
} from "./styles";

// Types
import type LayoutProps from "./types";
import Platform from "Types/Platform";

export const Layout: React.FC<LayoutProps> = ({
	referrerImages,
	referrerName,
	children,
	progress,
	...props
}) => {
	// Hooks
	const isMobile = useBreakpoints() < breakpoints[1];
	const platform = usePlatform();
	const theme = useTheme();
	const [partnerBanner, partnerBannerError] = useLoadImage(
		referrerImages?.bannerURL,
	);
	const [partnerLogo, partnerLogoError] = useLoadImage(
		((!partnerBanner || partnerBannerError) && referrerImages?.logoURL) || "",
	);

	// Helpers
	const getTitle = (platform: Platform) => {
		switch (platform) {
			case "partner":
				return "ClimateClever Partners";
			case "admin":
				return "Admin Panel";
			default:
				return sentenceCase(platform);
		}
	};

	return (
		<Container {...{ progress }} {...props}>
			<Header role="banner">
				<LogoBar justifyContent={referrerImages ? "space-between" : "center"}>
					<CCLogoBox>
						{referrerImages && isMobile ? (
							<LogoShort
								isClassicLogo
								width={"44"}
								height={"31"}
								title="ClimateClever Logo"
							/>
						) : (
							<LogoLong
								width={isMobile ? "272" : "299"}
								height={isMobile ? "28" : "31"}
								title="ClimateClever Logo"
							/>
						)}
					</CCLogoBox>
					{partnerBanner || partnerLogo ? (
						<PartnerImage
							src={partnerBanner?.src || partnerLogo?.src}
							alt="Partner image"
						/>
					) : (
						((!partnerBanner && !partnerLogo) ||
							(partnerBannerError && partnerLogoError)) &&
						referrerName && <PartnerName>{referrerName}</PartnerName>
					)}
				</LogoBar>
				<PlatformBar
					isHalloween={
						import.meta.env.VITE_HOLIDAY === "halloween" && !progress
					}>
					<PlatformTitle>{!progress ? "Login" : "Sign Up"}</PlatformTitle>
				</PlatformBar>
			</Header>
			<Main>
				<ContentBox>
					<Content>{children}</Content>
				</ContentBox>
			</Main>
			{!!progress && (
				<ProgressBarBox>
					<ProgressBar
						now={progress}
						height={"4px"}
						backgroundColor={"transparent"}
						color={theme.colors.primary}
					/>
				</ProgressBarBox>
			)}
		</Container>
	);
};

export default Layout;
