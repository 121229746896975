// Helpers
import postReq from "Helpers/NetworkingHelpers/PostReq";
import { closeCommsSession } from "Helpers/CommsHelpers";

/**
 * @async
 * Logs out a user from the platform
 * @returns void
 */

const logout = async (): Promise<void> => {
	await postReq(
		`${import.meta.env.VITE_BASE_URL}executive/auth/logout`,
		{},
		1,
		undefined,
		true,
	);
	closeCommsSession();
	return;
};

export default logout;
