import isError from "Helpers/TypeHelpers/IsError";

/**
 * Turns an unknown input (that could be an error or an error message) into an Error
 * @param potentialError an unknown error that might be an Error or a message string or undefined
 * @returns Error
 */
const makeError = (potentialError: unknown): Error => {
	return isError(potentialError)
		? potentialError
		: new Error(
				typeof potentialError === "string"
					? potentialError
					: "Unknown error, please try again later",
		  );
};

export default makeError;
