// Helpers
import postReq from "../PostReq";
import { track } from "../../TrackingHelpers/Events";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import Platform from "Types/Platform";
import { JSONApi, DataHelperResponse, ResourceObject } from "Types/JSONApi";
import User from "Types/User";
import Account from "Types/Account";

export type LoginDetails = {
	email: string;
	password: string;
	platform: Platform;
	referralCode?: string;
	externalId?: string;
};

type LoginRelationships = {
	accounts: {
		links: { related: string };
		data: { type: "accounts"; id: string }[];
	};
};

type LoginIncludes = ResourceObject<Account, undefined, "accounts">[];

type LoginResponse = JSONApi<
	User,
	LoginRelationships,
	LoginIncludes,
	{ intercom: string }
>;

/**
 * @async
 * Logs in a user, auto assigns cookies
 * @param credentials.email Email of the user
 * @param credentials.password Password of the user
 * @param credentials.platform Platform to log in on
 * @throws Networking error
 */
const login = async (credentials: {
	email: string;
	password: string;
	platform: Platform;
	referralCode?: string;
	externalId?: string;
}): Promise<
	DataHelperResponse<User, { accounts: Account[] }, LoginResponse>
> => {
	const response = await postReq<LoginResponse>(
		`${import.meta.env.VITE_BASE_URL}executive/auth/login`,
		credentials,
		4,
	);

	track("Login", { platform: credentials.platform });

	return transformAPIResponse(response, {});
};

export default login;
