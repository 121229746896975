import React from "react";

// CC
import ButtonLoader from "CCW-Components/CCW-ButtonLoader";
import ActionAlert from "CCW-Components/CCW-ActionAlert";
import Portal from "CCW-Components/CCW-Portal";

// Styles
import { Content, Title, Cancel, Actions } from "./styles";

// Types
import { SavedAlertProps } from "./types";

const SavedAlert: React.FC<SavedAlertProps> = ({
	message,
	loading,
	show,
	submitDisabled,
	onCancel,
	onSubmit,
	...props
}) => {
	return (
		<Portal>
			<div
				role={show ? "status" : undefined}
				aria-live={show ? "polite" : undefined}
				{...props}>
				<ActionAlert show={show}>
					<Content>
						<Title>{message}</Title>
						<Actions>
							<Cancel variant="washed" type="button" onClick={onCancel}>
								Cancel
							</Cancel>
							<ButtonLoader
								disabled={submitDisabled}
								variant="primary"
								onClick={onSubmit}
								loading={loading}>
								Save
							</ButtonLoader>
						</Actions>
					</Content>
				</ActionAlert>
			</div>
		</Portal>
	);
};

export default SavedAlert;
