import useSWR from "swr";

// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";
import { stripEmpty } from "Helpers/ObjectHelpers";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import type { DataHookResponse } from "Types/JSONApi";
import type {
	GeneralMethod,
	PaperMethod,
	FlightMethod,
	EmissionFactor,
	ElectricityMethod,
	FuelBasedMethod,
	AccommodationMethod,
} from "Types/EmissionFactors";
import { MeasureCategory } from "Types/MeasureCategory";
import { JSONApi } from "Types/JSONApi";
import { State } from "Types/Address";

type Response = JSONApi<
	EmissionFactor[],
	undefined,
	undefined,
	undefined,
	undefined
>;

/**
 * Get the emission factors
 * @returns a DataHookResponse tuple of emission factors, Error, Mutate, the full response and pagination options
 */
const useGetEmissionFactors = (
	emissionType?: MeasureCategory,
	method?:
		| GeneralMethod
		| PaperMethod
		| FlightMethod
		| ElectricityMethod
		| FuelBasedMethod
		| AccommodationMethod,
	state?: State,
	pagination?: {
		/** Results per page === limit */
		limit: number;
		/** Current page === offset */
		offset: number;
	},
): DataHookResponse<EmissionFactor[], undefined, Response> => {
	const queryString = new URLSearchParams({
		...(pagination?.limit &&
			pagination?.offset && {
				"page[limit]": pagination.limit.toString(),
				"page[offset]": pagination.offset.toString(),
			}),
		...stripEmpty(
			{ "emission-type": emissionType, method, state },
			"nullish",
			"strings",
		),
	}).toString();

	const { data, error, mutate } = useSWR<
		JSONApi<EmissionFactor[], undefined, undefined, undefined, undefined>,
		Error
	>(
		[
			`${import.meta.env.VITE_BASE_URL}measure/emission-factors${
				queryString ? "?" + queryString : ""
			}`,
			1,
			false,
			"JSONAPI",
		],
		getReq,
		{
			shouldRetryOnError: true,
		},
	);

	const [factors, , fullJSONApiResponse, paginationDetails] =
		transformAPIResponse(data, []);

	return [
		factors,
		error,
		mutate,
		undefined,
		fullJSONApiResponse,
		paginationDetails,
	];
};

export default useGetEmissionFactors;
