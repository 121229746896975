import { useCallback } from "react";

// Hooks
import useSession from "Hooks/UseSession";

// Helpers
import patchReq from "Helpers/NetworkingHelpers/PatchReq";

/**
 * Function hook - V2
 * Hook for generating a function for confirm a Users email update
 */
const useUpdateEmailConfirmForAU = () => {
	// Hooks
	const [activeUser] = useSession();

	/**
	 * Confirm the email update
	 * @param token Token from the confirm email
	 * @throws Networking error
	 */
	const updateEmailForAU = useCallback(
		async (token: string) => {
			if (!activeUser) throw new Error("No active session, please login");
			await patchReq(
				`${import.meta.env.VITE_BASE_URL}executive/users/${
					activeUser._id
				}/update-email`,
				{ token },
			);
		},
		[activeUser],
	);

	return updateEmailForAU;
};

export default useUpdateEmailConfirmForAU;
