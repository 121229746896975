import React from "react";

// Assets
import Logo from "Assets/SVG/Logo";

// Styles
import { LogoHeaderContainer } from "./styles";

// Types
import { LogoHeaderProps } from "./types";

const LogoHeader: React.FC<LogoHeaderProps> = ({
	customLogo: CustomLogo,
	customHeight = "72",
	customWidth = "84.74",
	...props
}) => {
	return (
		<LogoHeaderContainer {...props}>
			{CustomLogo != null ? (
				<CustomLogo width={customWidth} height={customHeight} />
			) : (
				<Logo width="84.74" height="72" />
			)}
		</LogoHeaderContainer>
	);
};

export default LogoHeader;
