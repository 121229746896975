import styled from "@emotion/styled";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

// Styles
export const FormatMessage = styled.h3(({ theme }) => ({
	...theme.webFonts.F6Bold,
	"& > p": {
		...theme.webFonts.F6,
		...theme.webFonts.italic,
		marginTop: "0.3rem",
	},
}));

export const Table = styled.table(({ theme }) => ({
	border: `0.1rem solid ${theme.colors.secondary}`,
	borderSpacing: "0",
}));

export const Row = styled.tr(({ theme }) => ({
	[MediaQueries[1]]: {
		...theme.webFonts.F7,
		"& > th:not(:last-of-type)": {
			borderRight: `0.1rem solid ${theme.colors.White}`,
		},
		"& > td:not(:last-of-type)": {
			borderRight: `0.1rem solid ${theme.colors.secondary}`,
		},
	},
}));

export const ColumnItemHeading = styled.th(({ theme }) => ({
	...theme.webFonts.bold,
	background: theme.colors.secondary,
	color: theme.colors.White,
	padding: "1.1rem",
	textAlign: "left",
}));

export const ColumnItemValue = styled.td(({ theme }) => ({
	color: theme.colors.D3,
	padding: "1.6rem 1.1rem",
}));

export const Instructions = styled.p(({ theme }) => ({
	...theme.webFonts.F6,
	...theme.webFonts.italic,
}));
