import styled from "@emotion/styled";

export const Container = styled.div({
	width: "100%",
	height: "100vh",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
});

export const Heading = styled.h3(({ theme }) => ({
	...theme.webFonts.h3,
	color: theme.colors.D1,
}));
