// Types
import { MeasureCategory } from "Types/MeasureCategory";
import type ScopeNames from "Types/Scope";
import type { FactorConstituent, FactorMethod } from "Types/EmissionFactors";
import sentenceCase from "Helpers/StringHelpers/SentenceCase";

type FactorBreakdown = {
	[key in ScopeNames | "total"]?: FactorConstituent[];
};

// Get Factor Helpers
// Takes STREAM TYPE as an argument, returns the included scopes for that stream
export const getFactorScopes = (
	streamType: MeasureCategory,
	method: FactorMethod,
): (ScopeNames | "total")[] => {
	switch (streamType) {
		case "electricity":
			return ["scope2", "scope3", "total"];
		case "gas":
			return ["scope1", "scope3"];
		case "water":
			return ["scope3"];
		case "waste":
			return ["scope3"];
		case "lpg":
			return ["scope1", "scope3"];
		case "paper":
			return ["scope3"];
		case "flight":
			return ["scope3"];
		case "stationary-fuels":
			return ["scope1", "scope3"];
		case "transport":
			return ["scope1", "scope3"];
		case "accommodation":
			return ["scope3"];
		default:
			return ["scope1", "scope2", "scope3", "total"];
	}
};

// Get Full Factor Breakdown
// Takes a stream type and returns a full factor breakdown, including scopes and each scope's constituents
export const getFullFactorBreakdown = (
	streamType: MeasureCategory,
	method: FactorMethod,
): FactorBreakdown => {
	const scopes = getFactorScopes(streamType, method);

	return scopes.reduce((allScopes, currentScope) => {
		const allConstituents = ["total", "co2co2e", "n2oco2e", "ch4co2e"];
		const totalOnly = ["total"];

		switch (streamType) {
			// Total Only (across all scopes)
			case "electricity":
			case "accommodation":
			case "water":
				return { [currentScope]: totalOnly, ...allScopes };

			// Mixed Constituents across scopes
			case "lpg":
			case "gas":
			case "stationary-fuels":
			case "transport":
				return {
					scope1: allConstituents,
					scope3: totalOnly,
				};

			case "waste":
				return { [currentScope]: ["total", "ch4co2e"] };

			// General (all constituents across all scopes)
			default:
				return {
					[currentScope]: allConstituents,
					...allScopes,
				};
		}
	}, {});
};

// Get the available methods for each emission type
export const getAvailableMethods = (
	emissionType: MeasureCategory,
): FactorMethod[] | undefined => {
	switch (emissionType) {
		case "electricity":
			return ["location-australia", "market", "market-average", "location"];
		case "gas":
		case "lpg":
		case "water":
		case "waste":
			return ["location", "market", "market-average"];
		case "accommodation":
			return ["location", "stars"];
		case "transport":
		case "stationary-fuels":
			return ["fuel"];
		case "paper":
			return ["average-data-method"];
		case "flight":
			return ["distance"];
		case "catering":
			return []; // TODO: Set available methods when Catering is available
		case "equipment":
			return []; // TODO: Set available methods when Equipment is available
	}
};

// Get a default method for an emission type
export const getInitialMethod = (
	emissionType: MeasureCategory,
): FactorMethod => {
	switch (emissionType) {
		case "electricity":
		case "waste":
		case "water":
		case "lpg":
		case "gas":
			return "location";
		case "accommodation":
			return "stars";
		case "flight":
			return "distance";
		case "paper":
			return "average-data-method";
		case "transport":
		case "stationary-fuels":
			return "fuel";
		default:
			return "location";
	}
};

// Get the METHOD DropDown Options for a given emission type
export const getMethodDropDownOptions = (emissionType: MeasureCategory) => {
	const getMethodLabels = (method: FactorMethod) => {
		switch (method) {
			case "average-data-method":
				return "Average Data";
			case "location-australia":
				return "Location Aus";
			case "market-average":
				return "Market Average";
			default:
				return sentenceCase(method);
		}
	};

	return (
		getAvailableMethods(emissionType)?.reduce(
			(availableOptions, currentOption) => {
				const label = getMethodLabels(currentOption);
				return [
					...((!!availableOptions[0].label && availableOptions) || []),
					{ label, value: currentOption },
				];
			},
			[{ label: "", value: "" }],
		) || [{ label: "-", value: "-" }]
	);
};
