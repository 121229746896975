import React from "react";
import { useField } from "formik";

// Components
import TextInputPassword from "../CCW-TextInputPassword";
import PasswordLabel from "../CCW-PasswordLabel";
import FormGroup from "../CCW-FormGroup";

// Types
import { FIGPasswordStrengthProps } from "./types";

const FIGPasswordStrength: React.FC<FIGPasswordStrengthProps> = ({
	label = "Password",
	required,
	name,
	className,
	...props
}) => {
	const [field, meta] = useField({ name });

	return (
		<FormGroup className={className}>
			<PasswordLabel
				htmlFor={props.id}
				text={label}
				required={required}
				password={field.value}
				{...field}
			/>
			<TextInputPassword
				{...field}
				{...props}
				error={(meta.touched && meta.error) || ""}
			/>
		</FormGroup>
	);
};

export default FIGPasswordStrength;
