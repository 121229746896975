import styled from "@emotion/styled";

// CC
import CardTemplate from "CCW-Components/CCW-CardTemplate";
import ButtonLoader from "CCW-Components/CCW-ButtonLoader";

// Helpers
import breakpoints from "Helpers/WebUIHelpers/Breakpoints";

export const Card = styled(CardTemplate)(() => ({
	padding: "2.5rem",
	[breakpoints[0]]: {
		padding: "3rem 5rem",
	},
}));

export const Button = styled(ButtonLoader)(() => ({
	marginTop: "3rem",
}));

export const Message = styled.h2(({ theme }) => ({
	color: theme.colors.D2,
	margin: "1.2rem 0 0 0",
	padding: "0",
	...theme.webFonts.F4,
}));

export const Link = styled.button(({ theme }) => ({
	border: "none",
	background: "transparent",
	color: theme.colors.secondary,
	...theme.webFonts.F4,
	cursor: "pointer",
	"&: hover, focus": {
		opacity: 0.5,
	},
	"&: visited": { color: theme.colors.DarkBlue },
}));
