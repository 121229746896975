import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Styles
import {
	DropDownInput,
	Flex,
	MiniFlex,
	SortContainer,
	FilterButton,
	Position,
	Relative,
	SelectedFilterOptions,
} from "./styles";
import { Option } from "CCW-Components/CCW-TagPicker/styles";

// Assets
import { FilterIcon, CompareIcon, CrossIcon, SearchIcon } from "Assets/SVG";

// Types
import type FilterBarProps from "./types";
import TagPicker from "CCW-Components/CCW-TagPicker";
import TransitionHeight from "CCW-Components/CCW-TransitionHeight";
import useBreakpoints from "Hooks/UseBreakpoints";
import { breakpoints } from "Helpers/WebUIHelpers/Breakpoints";
import TextInput from "CCW-Components/CCW-TextInput";
import useDebouncer from "Hooks/UseDebouncer";

const FilterBar: React.FC<FilterBarProps> = ({
	options,
	displayNames,
	showSortDirection = true,
	showSearch = true,
}) => {
	// Hooks
	const navigate = useNavigate();
	const location = useLocation();
	const [isFilterOpen, setIsFilterOpen] = useState(false);
	const currentBreakpointWidth = useBreakpoints();
	const debounce = useDebouncer(300);

	const updateSearchParams = (
		[newKey, newValue]: [string, string | number],
		options?: { replaceKey?: boolean },
	) => {
		if (options?.replaceKey) {
			searchParams.delete(newKey);
		}
		let isKeyValueAlreadyInParams = false;
		const nextSearchParams: URLSearchParams = new URLSearchParams();
		searchParams.forEach((value, key) => {
			if (newKey === key && newValue === value) {
				isKeyValueAlreadyInParams = true;
			} else {
				nextSearchParams.append(key, value);
			}
		});

		if (!isKeyValueAlreadyInParams) {
			// TODO Delete the true once the backend can take multiple params
			// eslint-disable-next-line no-constant-condition
			if (options?.replaceKey || true) {
				nextSearchParams.delete(newKey);
			}
			nextSearchParams.append(
				newKey,
				typeof newValue === "number" ? newValue.toString() : newValue,
			);
		}
		navigate({ search: nextSearchParams.toString() });
	};

	const searchParams: URLSearchParams = new URLSearchParams(location.search);

	const filterGroupNames: string[] = [
		...new Set(options?.map((option) => option.group)),
	];

	const isFilterActive = [...searchParams.entries()].some(([group]) =>
		filterGroupNames.includes(group),
	);

	const isMobile = currentBreakpointWidth < breakpoints[1];
	return (
		<>
			<Flex>
				{!!showSearch && (
					<MiniFlex>
						<label htmlFor="search">
							<SearchIcon width="1.6rem" />
						</label>
						<TextInput
							name={"search"}
							onChange={({ target: { value: nextSearch } }) =>
								debounce(() =>
									updateSearchParams(["search", nextSearch], {
										replaceKey: true,
									}),
								)
							}
						/>
					</MiniFlex>
				)}
				{!!showSortDirection && (
					<SortContainer>
						<DropDownInput
							plain
							isIconOnly={isMobile}
							isMenuRightAlign={isMobile}
							placeholder="Sort"
							name="sort-dates-dropdown"
							value={searchParams.get("sort") || "recent"}
							onSelect={(_, value: string) =>
								updateSearchParams(["sort", value], { replaceKey: true })
							}
							options={[
								{ label: "Newest first", value: "recent" },
								{ label: "Oldest first", value: "oldest" },
							]}
							icon={CompareIcon}
							isSearchable={false}
						/>
					</SortContainer>
				)}
				{!!options?.length && displayNames && (
					<Relative
						onBlur={({ currentTarget, relatedTarget }) =>
							!currentTarget.contains(relatedTarget) && setIsFilterOpen(false)
						}>
						<FilterButton
							isActive={isFilterActive}
							onClick={() => setIsFilterOpen((oldValue) => !oldValue)}>
							<FilterIcon width="1.6rem" height="1.6rem" />
							<span>Filter</span>
						</FilterButton>
						{isFilterOpen && (
							<Position tabIndex={0}>
								<TagPicker
									options={options}
									displayNames={displayNames}
									onChange={({ group, value }) =>
										updateSearchParams([group, value])
									}
								/>
							</Position>
						)}
					</Relative>
				)}
			</Flex>
			<TransitionHeight visible={isFilterActive}>
				<SelectedFilterOptions>
					{[...searchParams.entries()]
						.filter(([group]) => filterGroupNames.includes(group))
						.map(([group, value]) => (
							<Option
								key={group + value}
								isSelected
								onClick={() => updateSearchParams([group, value])}>
								{
									options?.find(
										(filterOption) =>
											filterOption.group === group &&
											`${filterOption.value}` === value,
									)?.display
								}
								{"  "}
								<CrossIcon width={"10"} height={"10"} fill={"white"} />
							</Option>
						))}
				</SelectedFilterOptions>
			</TransitionHeight>
		</>
	);
};

export default FilterBar;
