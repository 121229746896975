import React, { useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";

// CC
import FIGDefault from "CCW-Components/CCW-FIGDefault";
import ModalBanner from "CCW-Components/CCW-ModalBanner";

// Hooks
import useAlert from "Hooks/UseAlert";
import useSession from "Hooks/UseSession";

// Helpers
import isError from "Helpers/TypeHelpers/IsError";
import updateUser from "Helpers/NetworkingHelpers/UpdateUser";
import uploadUserImage from "Helpers/NetworkingHelpers/ReplaceUserImage";
import { getDiff } from "Helpers/ObjectHelpers";

// Local Components
import SaveAlert from "../SaveAlert";
import UpdateUserPassword from "../UpdateUserPassword";
import UpdateProfileImage from "../UpdateProfileImage";
import ProfileDoubleInputRow from "../ProfileDoubleInputRow";
import ProfileHalfInputRow from "../ProfileHalfInputRow";
import ProfileColumn from "../ProfileColumn";

// Styles
import {
	FormContainer,
	DeleteButton,
	Message,
	ProfileMultipleInputRowWithGap,
} from "./styles";
import FormSection from "../FormSection/styles";

// Types
import type { UpdateUser } from "./types";

// Form data
import { initValues, validation } from "./FormLogic";
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import PLink from "CCW-Components/CCW-PLink";
import FormHeading from "CCW-Components/CCW-FormHeading/styles";
import FormSubHeading from "CCW-Components/CCW-FormSubHeading/styles";
import CheckBoxFormik from "CCW-Components/CCW-CheckBoxFormik";

const ProfilePage: React.FC = ({ ...props }) => {
	// State
	const [passwordError, setPasswordError] = useState("");

	// Hooks
	const [, reportAlert] = useAlert();
	const navigate = useNavigate();
	const [user, , sessionMutate, { activeAccount }] = useSession();

	// Helpers
	const formHasErrors = (errors: { [key: string]: string }) =>
		Object.keys(errors)?.length > 0;

	// Loading state
	if (!user) {
		return <BlockLoader message="Loading..." />;
	}

	return (
		<FormContainer {...props}>
			<Formik
				initialValues={{ ...user, ...initValues }}
				validationSchema={validation}
				onSubmit={async (values, { setSubmitting, resetForm }) => {
					setSubmitting(true);
					try {
						const { image, ...updatedValues } = values;
						const newValues = getDiff<UpdateUser>(user, updatedValues);
						const [detailsResponse, imageResponse] = await Promise.allSettled([
							Object.keys(newValues).length &&
								updateUser(user._id, {
									...newValues,
									...(updatedValues.password && {
										password: updatedValues.password,
										confirmPassword: updatedValues.confirmPassword,
									}),
								}),
							image &&
								uploadUserImage(user._id, (image as unknown as File[])[0]),
						]);

						[
							detailsResponse as PromiseRejectedResult, // Had to type-cast these as typescript would yell otherwise
							imageResponse as PromiseRejectedResult,
						]
							.filter((response) => response.status === "rejected")
							.forEach((response: PromiseRejectedResult) => {
								throw response?.reason;
							});

						if (detailsResponse.status === "rejected") {
							setPasswordError(detailsResponse?.reason?.message);
						}
						sessionMutate();
						resetForm();
						values.email && values.email !== user.email
							? reportAlert(
									"Please check your inbox to verify your email",
									"success",
							  )
							: reportAlert("Your details have been updated", "success");
					} catch (err) {
						reportAlert(
							isError(err)
								? err.message
								: "Action failed. Please contact support",
							"error",
						);
					} finally {
						setSubmitting(false);
					}
				}}
				enableReinitialize={true}>
				{({ isSubmitting, submitForm, resetForm, errors, dirty }) => (
					<Form>
						<FormSection>
							<ProfileColumn>
								<ProfileDoubleInputRow>
									<FIGDefault
										id="usersFName"
										name="fName"
										type="text"
										label="First Name"
										required={false}
									/>
									<FIGDefault
										id="usersLName"
										name="lName"
										type="text"
										label="Last Name"
										required={false}
									/>
								</ProfileDoubleInputRow>
								<ProfileHalfInputRow>
									<FIGDefault
										id="email"
										name="email"
										type="email"
										label="Email"
										required={false}
									/>
								</ProfileHalfInputRow>
							</ProfileColumn>
						</FormSection>
						<FormSection defaultTopMargin>
							<FormHeading>Password</FormHeading>
							<FormSubHeading>Change your password</FormSubHeading>

							<ProfileColumn>
								<ProfileHalfInputRow>
									<UpdateUserPassword
										error={passwordError}
										onSubmit={submitForm}
									/>
								</ProfileHalfInputRow>
							</ProfileColumn>
						</FormSection>
						<FormSection defaultTopMargin>
							<FormHeading>Profile Image</FormHeading>
							<FormSubHeading>Upload profile image here</FormSubHeading>
							<ProfileColumn>
								<ProfileHalfInputRow>
									<UpdateProfileImage
										fieldName="image"
										placeholderImageSrc={user?.img}
										onSubmit={submitForm}
									/>
								</ProfileHalfInputRow>
							</ProfileColumn>
						</FormSection>
						<FormSection defaultTopMargin>
							<FormHeading>Marketing</FormHeading>
							<FormSubHeading>
								Do you want to receive occasional emails from Climate Clever?
							</FormSubHeading>
							<ProfileColumn>
								<ProfileMultipleInputRowWithGap>
									<CheckBoxFormik
										id={"salesCalls"}
										name={"salesCalls"}
										label={"Receive sales phone calls"}
									/>
									<CheckBoxFormik
										id={"newsletter"}
										name={"newsletter"}
										label={"Receive newsletter"}
									/>
									<CheckBoxFormik
										id={"marketingEmails"}
										name={"marketingEmails"}
										label={"Receive marketing emails"}
									/>
								</ProfileMultipleInputRowWithGap>
							</ProfileColumn>
						</FormSection>
						<SaveAlert
							show={dirty}
							loading={isSubmitting}
							submitDisabled={formHasErrors(errors)}
							message="Looks like you made some changes to your account!"
							onCancel={() => resetForm()}
							onSubmit={() => submitForm()}
						/>
					</Form>
				)}
			</Formik>
			{activeAccount?.tier === "free" && (
				<FormSection defaultTopMargin>
					<FormHeading>Delete your user account</FormHeading>
					<FormSubHeading>
						Do you want to delete your user account?
					</FormSubHeading>
					<ProfileColumn>
						<ProfileHalfInputRow>
							<DeleteButton
								loading={false}
								type="button"
								variant="delete"
								onClick={() => navigate("#delete-account")}>
								Delete account
							</DeleteButton>
						</ProfileHalfInputRow>
					</ProfileColumn>
				</FormSection>
			)}
			<ModalBanner
				headline="Delete account"
				id="delete-account"
				matchType="hash"
				returnUrl={location.pathname}>
				{" "}
				<Message>
					Deleting your account will delete all your data and your login
					details.{" "}
				</Message>
				<Message>
					If you are sure you want to proceed, please send us a message through
					the intercom or email us at{" "}
					<PLink to={"mailto:support@climateclever.org"}>
						support@climateclever.org
					</PLink>
					.{" "}
					<span>
						Thank you for sharing your sustainability journey with us.
					</span>
				</Message>
			</ModalBanner>
		</FormContainer>
	);
};

export default ProfilePage;
