import styled from "@emotion/styled";

export const Container = styled.div<{ isError?: boolean }>(
	({ theme, isError }) => ({
		...theme.webFonts.F7,
		...theme.webFonts.light,
		color: isError ? theme.colors.Red : theme.colors.D3,
		position: "absolute",
		top: "100%",
		right: "0",
	}),
);
