import useSWR from "swr";

// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";

// Types
import type {
	APIResponseDeprecated,
	DataHookResponseDeprecated,
} from "Types/JSONApi";
import type { MarkdownEntry, MarkdownEntryName } from "Types/Markdown";

/**
 * Get Markdown entries by name
 * @param name the name of the markdown you want to get
 * @returns a tuple of MarkdownEntry, Error and SWR's Mutate
 */
const useGetMarkdownEntry = (
	name: MarkdownEntryName,
): DataHookResponseDeprecated<MarkdownEntry | undefined> => {
	const { data, error, mutate } = useSWR<
		APIResponseDeprecated<MarkdownEntry | undefined>,
		Error
	>([`${import.meta.env.VITE_BASE_URL}measure/markdowns/${name}`, 1], getReq);

	return [data?.data, error, mutate];
};

export default useGetMarkdownEntry;
