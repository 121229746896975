import styled from "@emotion/styled";

// CC
import FIGMarkdown from "CCW-Components/CCW-FIGMarkdown";
import CCButton from "CCW-Components/CCW-CCButton";

const color = (even?: boolean) => (even ? "#FF1493" : "#0000ff");

export const Section = styled.div(() => ({
	padding: "2rem",
}));

export const MarkdownInput = styled(FIGMarkdown)<{ show: boolean }>(
	({ show }) => ({
		display: show ? "block" : "none",
	}),
);

export const Button = styled(CCButton)<{ even?: boolean }>(
	({ even, theme }) => ({
		textTransform: "capitalize",
		marginBottom: "2rem",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: color(even),
		boxShadow: `inset 0 0 0 0.1rem ${color(even)}, 0 0 0.8rem 0 ${
			theme.colors.Black10
		}, 0 0.1rem 0.3rem 0 ${theme.colors.Black15}`,
		"& > span": {
			paddingRight: "0.5rem",
		},
		"& > svg > path": {
			fill: color(even),
		},
		"&:hover, :focus": {
			background: color(even),
			color: theme.colors.White,
			boxShadow: "none",
			"& > svg > path": {
				fill: theme.colors.White,
			},
		},
	}),
);
