import styled from "@emotion/styled";

// Helpers
import MediaQueries, { minScreenWidth } from "Helpers/WebUIHelpers/Breakpoints";

const mobileBreakpoint = MediaQueries[1];

export const Grid = styled.div<{ showBanner: boolean }>(({ showBanner }) => ({
	display: "grid",
	justifyItems: "start",
	gridTemplateColumns: "auto auto",
	gridTemplateRows: "auto",
	rowGap: "1.2rem",
	gridTemplateAreas: `
		"header header"
		"banner banner"
		"actions filters"
		"main main"
	`,
	[mobileBreakpoint]: {
		gridTemplateColumns: "auto 1fr",
		gridTemplateRows: "max-content max-content min-content 1fr",
		gap: "3rem",
		gridTemplateAreas: `
                "header banner"
                "header filters"
                "actions main"
				`,
	},
}));

export const GridAreaHeader = styled.div(({ theme }) => ({
	gridArea: "header",
}));

export const GridAreaBanner = styled.div(({ theme }) => ({
	gridArea: "banner",
	justifySelf: "stretch",
	marginLeft: "1rem",
}));

export const GridAreaActions = styled.div(({ theme }) => ({
	gridArea: "actions",
	display: "flex",
	gap: "2rem",
	alignItems: "start",
	[MediaQueries[1]]: {
		flexDirection: "column",
	},
}));

export const GridAreaFilters = styled.div(({ theme }) => ({
	gridArea: "filters",
	justifySelf: "end",
}));

export const GridAreaMain = styled.div<{ isTableView?: boolean }>({
	gridArea: "main",
	placeSelf: "stretch",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	gap: "1.5rem",
	overflowX: "hidden", // Important to keep this so that the table will scroll
	padding: "0.2rem", // Important so that box shadows don't get cut off
	[minScreenWidth.medium]: {
		overflowY: "auto",
	},
});

export const Text = styled.h4(({ theme }) => ({
	...theme.webFonts.F6SemiBold,
	margin: "2rem auto",
	color: theme.colors.D2,
	textAlign: "center",
	"& a": {
		color: theme.colors.primary,
	},
	[MediaQueries[1]]: {
		...theme.webFonts.F5SemiBold,
	},
}));

export const HideInMobile = styled.div({
	display: "none",
	[mobileBreakpoint]: {
		display: "block",
		width: "100%",
	},
});

export const HideableDiv = styled.div<{ visible?: boolean }>(({ visible }) => ({
	display: visible ? "block" : "none",
}));
