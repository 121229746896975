import React, { PropsWithChildren, useState } from "react";

// Context
import AlertContext from "../AlertContext";

// Types
import type { AlertContextInterface } from "Types/Alert";

const AlertContextProvider: React.FC<PropsWithChildren> = (props) => {
	const [appAlert, setAppAlert] = useState<AlertContextInterface>({
		alerts: [],
		reportAlert: (message, type, error) => {
			const id = Math.random().toString(36).slice(-6); // Close enough to unique id
			setAppAlert((oldAppAlert) => ({
				...oldAppAlert,
				alerts: [...(oldAppAlert.alerts || []), { message, type, id }],
			}));
			if (type === "success") {
				// eslint-disable-next-line no-console
				console.log("ALERT:", { message, type });
			} else {
				// eslint-disable-next-line no-console
				console.error("ALERT:", { message, type, error });
			}
			return id;
		},
		clearAlert: (idToClear: string) => {
			setAppAlert((oldAppAlert) => ({
				...oldAppAlert,
				alerts: oldAppAlert.alerts?.filter((alert) => alert.id !== idToClear),
			}));
		},
	});

	return (
		<AlertContext.Provider value={appAlert}>
			{props.children}
		</AlertContext.Provider>
	);
};

export default AlertContextProvider;
