import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

// CC
import NavButton from "../CCW-NavButton";
import ProfileIcon from "../CCW-ProfileIcon";
import NavBarMenu from "../CCW-NavBarMenu";
import Container from "../CCW-Container";

// Hooks
import useBreakpoints from "Hooks/UseBreakpoints";
import useSession from "Hooks/UseSession";

// Helpers
import { breakpoints } from "Helpers/WebUIHelpers/Breakpoints";
import { track } from "Helpers/TrackingHelpers/Events";

// Assets
import {
	ActionIcon,
	ReportIcon,
	AnalyticsIcon,
	MeasureIcon,
	ArrowIcon,
	OffsetIcon,
	BurgerIcon,
	ArrowUpDownIcon,
	Logo,
} from "Assets/SVG";

// Styles
import {
	NavBarContainer,
	NavList,
	Bar,
	MenuContainer,
	MenuButton,
	HoverStyles,
	Position,
	UpgradeButton,
	UpgradeButtonContainer,
} from "./styles";

// Types
import { NavBarProps, LinksProps, NavItem } from "./types";
import Account, { PartnerAccount } from "Types/Account";

const Links: React.FC<LinksProps> = ({ navItems, isShowUpgrade }) => {
	return (
		<NavList>
			{navItems.map((navItem) => {
				return (
					<li key={navItem.title}>
						<NavButton
							title={navItem.title}
							to={navItem.to}
							icon={navItem.icon}
						/>
					</li>
				);
			})}
			{isShowUpgrade && (
				<li>
					<UpgradeButtonContainer>
						<UpgradeButton
							variant="secondary"
							onClick={() =>
								track("Clicked Upgrade Now", { location: "Nav Bar" })
							}
							to={"/settings/upgrade-plan"}>
							<span>Upgrade Now</span>
						</UpgradeButton>
					</UpgradeButtonContainer>
				</li>
			)}
		</NavList>
	);
};

const NavBar: React.FC<NavBarProps> = ({
	accountName,
	entityName,
	className,
	menuItems,
	profileCompleted,
	isMultipleSites,
	navItems = [
		{
			to: profileCompleted
				? "/measure/electricity"
				: "/measure/electricity?modal=more-info",
			title: "Measure",
			icon: MeasureIcon,
		},
		{
			to: profileCompleted ? "/action" : "/action?modal=more-info",
			title: "Action",
			icon: ActionIcon,
		},
		{
			to: profileCompleted ? "/track" : "/track?modal=more-info",
			title: "Track",
			icon: AnalyticsIcon,
		},
		{
			to: "/offsets",
			title: "Offsets",
			icon: OffsetIcon,
		},
		{
			to: "/reports",
			title: "Reports",
			icon: ReportIcon,
		},
	] as NavItem[],
}) => {
	// State
	const [isOpen, setIsOpen] = useState(false);

	// Hooks
	const containerRef = useRef<HTMLDivElement>(null);
	const clientWidth = useBreakpoints();
	const [user, , , { activeAccount: account, activePartner: partner }] =
		useSession<Account | PartnerAccount>();

	const accountType = account?.accountType;

	// Helpers
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				containerRef.current &&
				!containerRef.current.contains(event.target as HTMLDivElement)
			) {
				isOpen && setIsOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isOpen]);

	const filteredNavItems = navItems.filter(
		(navItem) => !(accountType === "home" && navItem.title === "Reports"),
	);

	const isMobile = clientWidth < breakpoints[2];
	const isShowUpgrade =
		(!account?.tier || account?.tier === "free") &&
		accountType &&
		accountType !== "partner";

	const mobileMenuItems = [
		...filteredNavItems,
		...(menuItems?.length
			? [
					...menuItems.slice(0, menuItems?.length - 1),
					...(isShowUpgrade
						? [
								{
									title: "Upgrade Plan",
									to: "/settings/upgrade-plan",
									icon: ArrowUpDownIcon,
								},
						  ]
						: []),
					menuItems[menuItems.length - 1],
			  ]
			: []),
	];

	return user ? (
		<NavBarContainer id="cc-nav-bar" ref={containerRef} className={className}>
			<Container>
				<Bar>
					<Link to={"/"}>
						<HoverStyles>
							<Logo width={"37.66px"} height={"32px"} />
						</HoverStyles>
					</Link>
					{!isMobile && (
						<nav>
							<Links
								navItems={filteredNavItems}
								isShowUpgrade={isShowUpgrade}
							/>
						</nav>
					)}
					<MenuContainer>
						<MenuButton
							name="profile-icon-button"
							type="button"
							onClick={() => setIsOpen((oldIsOpen) => !oldIsOpen)}>
							{isMobile ? (
								<BurgerIcon opened={isOpen} width={"32"} height={"32"} />
							) : (
								<>
									<ProfileIcon
										imageURL={user.img}
										fName={user.fName}
										lName={user.lName}
									/>
									<ArrowIcon
										width="16px"
										height="16px"
										direction={isOpen ? "up" : "down"}
									/>
								</>
							)}
						</MenuButton>
						<Position>
							<NavBarMenu
								closeMenu={() => setIsOpen(false)}
								{...{
									isOpen,
									fName: user.fName,
									lName: user.lName,
									accountName,
									entityName,
									accountImage:
										partner?.logo ||
										(account && "img" in account ? account?.img : undefined),
									menuItems: isMobile
										? (mobileMenuItems as NavItem[])
										: menuItems,
									isMultipleSites,
								}}
							/>
						</Position>
					</MenuContainer>
				</Bar>
			</Container>
		</NavBarContainer>
	) : null;
};

export default NavBar;
