import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useField } from "formik";

// CC
import ButtonLink from "CCW-Components/CCW-ButtonLink";
import FileInput from "CCW-Components/CCW-FileInputNew";
import ModalBanner from "CCW-Components/CCW-ModalBanner";
import CCButton from "CCW-Components/CCW-CCButton";
import ProfileImage from "CCW-Components/CCW-ProfileImage";

// Types
import type UpdateProfileImageProps from "./types";

// Styles
import { ButtonBox, FlexColumn, ImageBox } from "./styles";

export const UpdateProfileImage: React.FC<UpdateProfileImageProps> = ({
	fieldName,
	modalHeading,
	placeholderImageSrc,
	onSubmit,
}) => {
	// Hooks
	const location = useLocation();
	const navigate = useNavigate();
	const [, meta, helpers] = useField<File[] | undefined>(fieldName);

	// Normal state
	return (
		<>
			<FlexColumn>
				{placeholderImageSrc && (
					<ImageBox marginBottom>
						<ProfileImage
							imageSrcURL={`${placeholderImageSrc}?w=34px&h=34px`}
						/>
					</ImageBox>
				)}
				<ButtonLink to="#upload-profile-image">Upload Image</ButtonLink>
			</FlexColumn>
			<ModalBanner
				headline={modalHeading ? modalHeading : "Upload Profile Image"}
				subheading={"Image must be a .jpeg file"}
				id="upload-profile-image"
				matchType="hash"
				returnUrl={location.pathname}
				isSimpleDesign={true}>
				<FileInput name={fieldName} accept={"image/jpeg"} />
				<ButtonBox>
					<CCButton
						onClick={async () => {
							onSubmit && (await onSubmit());
							navigate(location.pathname);
						}}>
						Save
					</CCButton>
					<CCButton
						variant="washed"
						onClick={() => {
							helpers.setValue(meta.initialValue);
							navigate(location.pathname);
						}}>
						Cancel
					</CCButton>
				</ButtonBox>
			</ModalBanner>
		</>
	);
};

export default UpdateProfileImage;
