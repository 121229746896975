/**
 * Turns a a number into a string separating 1000s by a comma
 * Ensures that formatting is persistent on all platforms as there is an issue on Android
 * @param {Number} number The number to turn into a string
 * @returns {String} The string version of the number
 */
const toCommaSeparatedString = (number: number) => {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Round a number by x amount of places
 * Ensures that formatting is persistent on all platforms as there is an issue on Android
 * @param {number} number => the number you want to round
 * @param {number} places => how many places you want to round the number by
 */
const numberRounder = (number: number, places: number) => {
	const rounded = +(Math.round(Number(number + "e+" + places)) + "e-" + places);
	return isNaN(rounded) ? 0 : rounded;
};

/**
 * Format a number to be displayed to a user in a readable consistent format
 * @param number The number you want to format
 * @param places How many places it should round to, standard is two places
 * @returns The number
 */
export const formatNumToNumber = (number: number, places = 2) => {
	if (number === 0) return 0;
	if (number >= 1) {
		return numberRounder(number, places);
	} else if (number < 0) {
		if (number > -0.01) return -0.01;
		return numberRounder(number, places);
	} else {
		return number < 0.01 ? 0.01 : numberRounder(number, places);
	}
};

/**
 * Format a number to be displayed to a user in a readable consistent format
 * @param number The number you want to format
 * @param places How many places it should round to, standard is two places
 * @returns The number as string with commas
 */
export const formatNumToString = (number: number, places = 2) => {
	if (number === 0) return "0";
	if (number >= 1) {
		return toCommaSeparatedString(numberRounder(number, places));
	} else if (number < 0) {
		if (number > -0.01) return "-0.01";
		return toCommaSeparatedString(numberRounder(number, places));
	} else {
		return number < 0.01
			? "0.01"
			: toCommaSeparatedString(numberRounder(number, places));
	}
};
