import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// CC
import FIGDefault from "CCW-Components/CCW-FIGDefault";
import ButtonLoader from "CCW-Components/CCW-ButtonLoader";

// Hooks
import useAlert from "Hooks/UseAlert";
import useSession from "Hooks/UseSession";
import useResendVerifyEmail from "Helpers/NetworkingHelpers/ResendVerifyEmail";
import useIntercomHash from "Hooks/UseIntercomHash";

// Helpers
import login, { LoginDetails } from "Helpers/NetworkingHelpers/Login";
import { setUser } from "Helpers/TrackingHelpers/Events";
import isError from "Helpers/TypeHelpers/IsError";

// CC Local
import FIGLoginPassword from "../FIGLoginPassword";

// Styles
import { Container, ResentMessage } from "./styles";

// Types
import LoginFormProps from "./types";
import Platform from "Types/Platform";
import FIGDropDownInput from "CCW-Components/CCW-FIGDropDownInputNew";

/** Login form */
const LoginForm: React.FC<LoginFormProps> = ({
	referralCode,
	partnerCode,
	externalId,
	appType,
	...props
}) => {
	// Hooks
	const [, reportAlert] = useAlert();
	const [activeUser, , sessionMutate] = useSession();
	const navigate = useNavigate();
	const [isUnverifiedUser, setIsUnverifiedUser] = useState<boolean>(false);
	const [emailEntered, setEmailEntered] = useState<string>("");
	const [verificationEmailSent, setVerificationEmailSent] =
		useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [, updateHash] = useIntercomHash();
	const requestResendVerifyEmail = useResendVerifyEmail;

	// Helpers
	const handleResendEmail = async () => {
		if (activeUser) {
			try {
				setIsLoading(true);
				await requestResendVerifyEmail(activeUser?._id);
				setVerificationEmailSent(true);
			} catch (error) {
				reportAlert(
					isError(error)
						? error?.message
						: "Could not resend email. Please contact support.",
					"error",
				);
			} finally {
				setIsLoading(false);
			}
		}
	};

	return (
		<Container {...props}>
			<Formik
				initialValues={
					{
						email: "",
						password: "",
						platform:
							appType && appType !== "main" ? appType : ("" as Platform),
						externalId,
						referralCode: referralCode || partnerCode,
					} satisfies LoginDetails
				}
				validationSchema={Yup.object({
					email: Yup.string()
						.email("Invalid email address")
						.required("Required"),
					password: Yup.string().required("Required"),
					platform: Yup.string()
						.oneOf(["home", "school", "business", "admin", "partner"])
						.required(),
				})}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					setIsUnverifiedUser(false);
					emailEntered !== values.email && setVerificationEmailSent(false);
					setEmailEntered(values.email);
					try {
						const [newUser, { accounts }, fullLoginResponse] = await login(
							values,
						);

						await sessionMutate();
						if (fullLoginResponse?.meta?.intercom && updateHash) {
							updateHash(fullLoginResponse?.meta?.intercom);
						}
						newUser &&
							setUser(newUser._id, {
								platform: values.platform,
								account: accounts?.[0]._id,
							});

						navigate("/");
					} catch (err) {
						setSubmitting(false);
						isError(err) &&
						err.message ===
							"Please validate your email address before signing in, you should have received an email"
							? setIsUnverifiedUser(true)
							: setIsUnverifiedUser(false);
						reportAlert(
							isError(err)
								? err.message
								: "Action failed. Please contact support",
							"error",
						);
					}
				}}>
				{({ isSubmitting, values }) => {
					return (
						<Form>
							<FIGDefault
								id="login-email"
								name="email"
								type="email"
								label="Email Address"
								required={false}
							/>
							<FIGLoginPassword
								id="login-password"
								label="Password"
								name="password"
								linkText="Forgot password?"
								link="/set-reset-password"
							/>
							{appType === "main" && (
								<FIGDropDownInput
									name="platform"
									label="Platform"
									options={[
										{ label: "Business", value: "business" },
										{ label: "School", value: "school" },
										{ label: "Home", value: "home" },
									]}
								/>
							)}
							<ButtonLoader
								variant={
									import.meta.env.VITE_HOLIDAY === "halloween"
										? "holiday"
										: "primary"
								}
								colorVariant={
									import.meta.env.VITE_HOLIDAY !== "halloween"
										? "blueberry"
										: undefined
								}
								id="login-button"
								type="submit"
								disabled={isSubmitting}
								loading={isSubmitting}
								block>
								Log In
							</ButtonLoader>
							{isUnverifiedUser && !verificationEmailSent && !isSubmitting && (
								<ButtonLoader
									onClick={handleResendEmail}
									variant="secondary"
									id="resend-validation-button"
									type="button"
									disabled={isLoading}
									loading={isLoading}
									block>
									Resend Confirmation Email
								</ButtonLoader>
							)}
							{isUnverifiedUser && verificationEmailSent && (
								<ResentMessage>
									Verification email sent to: <br />
									<span>{emailEntered}</span> <br />
									Please confirm your email to continue.
								</ResentMessage>
							)}
						</Form>
					);
				}}
			</Formik>
		</Container>
	);
};

export default LoginForm;
