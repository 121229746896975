import React, { SyntheticEvent } from "react";

// Hooks
import { useLocation, useNavigate } from "react-router-dom";

// CC
import Portal from "CCW-Components/CCW-Portal";

// Styles
import { Backdrop } from "./styles";

// Types
import type { Location } from "react-router-dom";
import type ModalNewProps from "./types";
import type { MatchType } from "Types/Modal";

export const ModalNew: React.FC<ModalNewProps> = ({
	id,
	matchType,
	returnUrl,
	className,
	children,
	onClose,
}) => {
	// Hooks
	const location = useLocation();
	const navigate = useNavigate();

	// Helpers
	const checkId = (
		id: string,
		matchType: MatchType,
		location: Location,
	): boolean => {
		return (
			matchType === "none" ||
			(matchType === "query"
				? location.search.includes(`modal=${id}`)
				: location.hash === `#${id}`)
		);
	};

	// Actions
	const closeModal = (eventToPrevent: SyntheticEvent | null) => {
		eventToPrevent?.preventDefault();
		returnUrl && navigate(returnUrl);
		onClose && onClose();
	};

	return (
		<Portal>
			<Backdrop
				className={className}
				visible={checkId(id, matchType, location)}
				onClick={(event) => closeModal(event)}
				onKeyDown={(event) => event.key === "Escape" && closeModal(event)}
				role="dialog">
				<div
					onClick={(e) => e.stopPropagation()}
					onKeyDown={(e) => e.stopPropagation()}>
					{children}
				</div>
			</Backdrop>
		</Portal>
	);
};

export default ModalNew;
