import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom";
import React from "react";

/**
 * This is a helper function that will be used to create a breadcrumb and performance tracing for Sentry
 * @returns Sentry integration
 */
const makeBrowserTracing = () =>
	new BrowserTracing({
		routingInstrumentation: Sentry.reactRouterV6Instrumentation(
			React.useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		),
	});

export default makeBrowserTracing;
