import "./index.css";

import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const baseFont = {
	fontFamily: "Source Sans Pro, arial, Sans-Serif",
	fontStyle: "normal",
	fontWeight: 400,
	lineHeight: "130%",
};

const baseBoldFont = {
	...baseFont,
	fontWeight: 700,
};

const baseSemiBoldFont = {
	...baseFont,
	fontWeight: 600,
};

const baseLightFont = {
	...baseFont,
	fontWeight: 300,
};

const webFonts = {
	light: {
		fontWeight: 300,
	},
	bold: {
		fontWeight: 700,
	},
	italic: {
		fontStyle: "italic",
	},
	h1: {
		...baseBoldFont,
		fontSize: "4rem",
		[MediaQueries[1]]: {
			fontSize: "4.8rem",
		},
	},
	h2: {
		...baseBoldFont,
		fontSize: "3.2rem",
		[MediaQueries[1]]: {
			fontSize: "4rem",
		},
	},
	h3: {
		...baseBoldFont,
		fontSize: "2.6rem",
		[MediaQueries[1]]: {
			fontSize: "3.2rem",
		},
	},
	h4: {
		...baseBoldFont,
		fontSize: "2rem",
		[MediaQueries[1]]: {
			fontSize: "2.6rem",
		},
	},
	h5: {
		...baseBoldFont,
		fontSize: "1.6rem",
		[MediaQueries[1]]: {
			fontSize: "2rem",
		},
	},
	h6: {
		...baseBoldFont,
		fontSize: "1.4rem",
		[MediaQueries[1]]: {
			fontSize: "1.6rem",
		},
	},
	h7: {
		...baseBoldFont,
		fontSize: "1.2rem",
		[MediaQueries[1]]: {
			fontSize: "1.4rem",
		},
	},
	F1: {
		...baseFont,
		fontSize: "4rem",
		[MediaQueries[1]]: {
			fontSize: "4.8rem",
		},
	},
	F1Bold: {
		...baseBoldFont,
		fontSize: "4rem",
		[MediaQueries[1]]: {
			fontSize: "4.8rem",
		},
	},
	F1Light: {
		...baseLightFont,
		fontSize: "4rem",
		[MediaQueries[1]]: {
			fontSize: "4.8rem",
		},
	},
	F2: {
		...baseFont,
		fontSize: "3.2rem",
		[MediaQueries[1]]: {
			fontSize: "4rem",
		},
	},
	F2Bold: {
		...baseBoldFont,
		fontSize: "3.2rem",
		[MediaQueries[1]]: {
			fontSize: "4rem",
		},
	},
	F2Light: {
		...baseLightFont,
		fontSize: "3.2rem",
		[MediaQueries[1]]: {
			fontSize: "4rem",
		},
	},
	F3: {
		...baseFont,
		fontSize: "2.6rem",
		[MediaQueries[1]]: {
			fontSize: "3.2rem",
		},
	},
	F3Bold: {
		...baseBoldFont,
		fontSize: "2.6rem",
		[MediaQueries[1]]: {
			fontSize: "3.2rem",
		},
	},
	F3Light: {
		...baseLightFont,
		fontSize: "2.6rem",
		[MediaQueries[1]]: {
			fontSize: "3.2rem",
		},
	},
	F4: {
		...baseFont,
		fontSize: "2rem",
		[MediaQueries[1]]: {
			fontSize: "2.6rem",
		},
	},
	F4SemiBold: {
		...baseSemiBoldFont,
		fontSize: "2rem",
		[MediaQueries[1]]: {
			fontSize: "2.6rem",
		},
	},
	F4Bold: {
		...baseBoldFont,
		fontSize: "2rem",
		[MediaQueries[1]]: {
			fontSize: "2.6rem",
		},
	},
	F4Light: {
		...baseLightFont,
		fontSize: "2rem",
		[MediaQueries[1]]: {
			fontSize: "2.6rem",
		},
	},
	F5: {
		...baseFont,
		fontSize: "1.6rem",
		[MediaQueries[1]]: {
			fontSize: "2rem",
		},
	},
	F5SemiBold: {
		...baseSemiBoldFont,
		fontSize: "1.6rem",
		[MediaQueries[1]]: {
			fontSize: "2rem",
		},
	},
	F5Bold: {
		...baseBoldFont,
		fontSize: "1.6rem",
		[MediaQueries[1]]: {
			fontSize: "2rem",
		},
	},
	F5Light: {
		...baseLightFont,
		fontSize: "1.6rem",
		[MediaQueries[1]]: {
			fontSize: "2rem",
		},
	},
	F6: {
		...baseFont,
		fontSize: "1.4rem",
		[MediaQueries[1]]: {
			fontSize: "1.6rem",
		},
	},
	F6SemiBold: {
		...baseSemiBoldFont,
		fontSize: "1.4rem",
		[MediaQueries[1]]: {
			fontSize: "1.6rem",
		},
	},
	F6Bold: {
		...baseBoldFont,
		fontSize: "1.4rem",
		[MediaQueries[1]]: {
			fontSize: "1.6rem",
		},
	},
	F6Light: {
		...baseLightFont,
		fontSize: "1.4rem",
		[MediaQueries[1]]: {
			fontSize: "1.6rem",
		},
	},
	F7: {
		...baseFont,
		fontSize: "1.2rem",
		[MediaQueries[1]]: {
			fontSize: "1.4rem",
		},
	},
	F7Bold: {
		...baseBoldFont,
		fontSize: "1.2rem",
		[MediaQueries[1]]: {
			fontSize: "1.4rem",
		},
	},
	F7Light: {
		...baseLightFont,
		fontSize: "1.4rem",
		[MediaQueries[1]]: {
			fontSize: "1.6rem",
		},
	},
};

export default webFonts;

import "./index.css";
