import React from "react";

// Assets
import CrossIcon from "Assets/SVG/CrossIcon";

// Styles
import CloseButton from "./styles";

// Types
import type CloseButtonLinkProps from "./types";

export const CloseButtonLink: React.FC<CloseButtonLinkProps> = ({
	to,
	className,
}) => {
	return (
		<CloseButton className={className} to={to} type="button" role="button">
			<CrossIcon width="1.6rem" height="1.6rem" />
		</CloseButton>
	);
};

export default CloseButtonLink;
