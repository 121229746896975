import styled from "@emotion/styled";

// CC
import CCTextInput from "CCW-Components/CCW-TextInput";
import CCButton from "CCW-Components/CCW-CCButton";

// Assets
import SearchIcon from "Assets/SVG/SearchIcon";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

// Styles
import { DataBox } from "CCW-Components/CCW-FactorRow/styles";

export const PageTitle = styled.h2(({ theme }) => ({
	...theme.webFonts.h2,
	color: theme.colors.D2,
}));

export const FactorsNavBar = styled.div(() => ({
	display: "flex",
	gap: "1rem",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	marginBottom: "3rem",
	flexDirection: "column",
	flexWrap: "wrap",
	[MediaQueries[1]]: {
		flexDirection: "row",
		alignItems: "center",
		gap: "2rem",
	},
}));

export const SearchBarContainer = styled.div(() => ({
	display: "flex",
	alignItems: "center",
	alignSelf: "stretch",
}));

export const SearchBarIcon = styled(SearchIcon)(() => ({
	marginRight: "-2.8rem",
	marginLeft: "1.2rem",
	zIndex: 2,
}));

export const SearchBar = styled(CCTextInput)(({ theme }) => ({
	minWidth: "24rem",
	textIndent: "2.4rem",
	"::placeholder": {
		color: theme.colors.D2,
		opacity: 0.6,
	},
	"&:hover::placeholder": {
		transition: "0.3s ease",
		transform: "translateY(-80%)",
		opacity: 0,
	},
}));

export const RowContainer = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	gap: "1rem",
}));

export const FactorBreakdownContainer = styled.div(() => ({
	display: "flex",
	gap: "1rem",
}));

export const BreakdownColumn = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
}));

export const FactorBox = styled(DataBox)(({ theme }) => ({
	display: "flex",
	background: theme.gradientsColors.Rotated90,
	color: theme.colors.White,
	gap: "1rem",
}));

export const AddButton = styled(CCButton)(({ theme }) => ({
	...theme.webFonts.F6Bold,
	whiteSpace: "nowrap",
	padding: "0 2rem",
	marginLeft: "auto",
}));

export const FactorBreakdown = styled.div(({ theme }) => ({
	...theme.webFonts.F6,
	background: theme.colors.Black05,
	borderRadius: "0.3rem",
	display: "flex",
	flexDirection: "column",
	gap: "0.4rem",
	marginTop: "0.4rem",
	padding: "0.6rem",
	cursor: "default",
}));

export const FactorConstituent = styled.div(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	"& > span": {
		...theme.webFonts.bold,
	},
}));
