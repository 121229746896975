import useSWR from "swr";

// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import type { JSONApi, DataHookResponse, ResourceObject } from "Types/JSONApi";
import type User from "Types/User";
import type Entity from "Types/Entity";
import type { Partner } from "Types/Partner";
import type { Account, PartnerAccount } from "Types/Account";
import { UserPermission } from "Types/User";

type SessionRelationships = {
	accounts: {
		links: { related: string };
		data: { type: "accounts"; id: string }[];
	};
	activeAccount: {
		links: { related: string };
		data: { type: "accounts"; id: string };
	};
	activeEntity: {
		links: { related: string };
		data: { type: "entities"; id: string };
	};
	activePartner: {
		links: { related: string };
		data: { type: "partners"; id: string };
	};
};

type SessionRelated<
	AccountType extends Account | PartnerAccount,
	EntityType extends Entity = Entity,
> = {
	accounts: AccountType[];
	activeAccount: AccountType;
	activeEntity: EntityType;
	activePartner: Partner;
};

type SessionIncludes<
	AccountType extends Account | PartnerAccount,
	EntityType extends Entity = Entity,
> = (
	| ResourceObject<
			AccountType,
			{
				subscription: {
					links: { related: string };
					data: { type: "subscriptions"; id: string };
				};
			},
			"accounts"
	  >
	| ResourceObject<EntityType, undefined, "entities">
	| ResourceObject<Partner, undefined, "partners">
)[];

type DataMeta = {
	permissions: { account: string; permission: UserPermission }[];
};

type SessionResponse<
	AccountType extends Account | PartnerAccount,
	EntityType extends Entity = Entity,
> = JSONApi<
	User,
	SessionRelationships,
	SessionIncludes<AccountType, EntityType>,
	undefined,
	SessionRelated<AccountType, EntityType>,
	DataMeta
>;

/**
 * Data hook - V2
 * Get the current session informaton
 * @returns a DataHookResponse tuple of User, Error, Mutate, Related, and the full response
 */

const useSession = <
	AccountType extends Account | PartnerAccount = Account,
	EntityType extends Entity = Entity,
>(
	includes: ("accounts" | "entities" | "subscriptions")[] = [
		"accounts",
		"entities",
	],
): DataHookResponse<
	User,
	SessionRelated<AccountType, EntityType>,
	SessionResponse<AccountType, EntityType>
> => {
	const includesQueryString = new URLSearchParams(
		includes.length ? includes.map((include) => ["include", include]) : {},
	).toString();
	const { data, error, mutate } = useSWR<
		JSONApi<
			User,
			SessionRelationships,
			SessionIncludes<AccountType, EntityType>,
			undefined,
			SessionRelated<AccountType, EntityType>,
			DataMeta
		>,
		Error
	>(
		[
			`${import.meta.env.VITE_BASE_URL}executive/auth/session${
				includesQueryString ? "?" + includesQueryString : ""
			}`,
			2,
		],
		getReq,
		{
			shouldRetryOnError: false,
		},
	);
	const [user, related, fullJSONApiResponse] = transformAPIResponse(data, {});
	return [user, error, mutate, related, fullJSONApiResponse];
};

export default useSession;
