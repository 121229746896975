import styled from "@emotion/styled";

// CC
import CCButtonLoader from "CCW-Components/CCW-ButtonLoader";

// Helpers
import mediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const FormContainer = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
}));

export const CurrentEmailLabel = styled.div(({ theme }) => ({
	...theme.webFonts.F6Bold,
	color: theme.colors.D1,
}));

export const CurrentEmailAddress = styled.div(({ theme }) => ({
	...theme.webFonts.F6,
	color: theme.colors.D3,
	marginBottom: "1.7rem",
}));

export const DeleteButton = styled(CCButtonLoader)(() => ({
	width: "100%",
	[mediaQueries[1]]: {
		width: "auto",
		alignSelf: "end",
	},
}));

export const Message = styled.p(({ theme }) => ({
	...theme.webFonts.F5,
	marginTop: "0",
	"& > a": {
		...theme.webFonts.F5,
	},
	"& > span": {
		display: "block",
		textAlign: "center",
		marginTop: "2rem",
		color: theme.colors.primary,
		...theme.webFonts.bold,
	},
}));

export const ProfileMultipleInputRowWithGap = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	gap: "1.5rem",
	[mediaQueries[2]]: {
		flexDirection: "row",
		gap: "3rem",
	},
}));
