import React from "react";
import { useField } from "formik";

// Components
import InputLabel from "../CCW-InputLabel";
import TextInputPassword from "../CCW-TextInputPassword";
import FormGroup from "../CCW-FormGroup";

// Types
import { FIGPasswordProps } from "./types";

const FIGPassword: React.FC<FIGPasswordProps> = ({
	label,
	required,
	name,
	className,
	...props
}) => {
	const [field, meta] = useField({ name });

	return (
		<FormGroup className={className}>
			<InputLabel htmlFor={props.id} text={label} required={required} />
			<TextInputPassword
				{...field}
				{...props}
				error={(meta.touched && meta.error) || ""}
			/>
		</FormGroup>
	);
};

export default FIGPassword;
