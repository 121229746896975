import { useLocation, useNavigate } from "react-router-dom";

// Styles
import {
	Container,
	PageNavButton,
	ActionButton,
	PagePicker,
	PageNumber,
	FlexColumn,
	PerPageOptions,
	PerPageOption,
	PerPageBox,
} from "./styles";

// Types
import type PaginationNavBarProps from "./types";

// Assets
import { ChevronLeftIcon, ChevronRightIcon } from "Assets/SVG";

export const PaginationNavBar: React.FC<PaginationNavBarProps> = ({
	selectedPage,
	pageCount,
	actionButtonText,
	actionOnClick,
	...props
}) => {
	// Hooks
	const location = useLocation();
	const navigate = useNavigate();

	const numberOfPagesToShow = 6;
	const pageOptions: Set<number | "lower-ellipsis" | "upper-ellipsis"> =
		new Set(
			Array(pageCount)
				.fill(null)
				.map((_, i) => i + 1)
				.map((pageNumber) => {
					const distanceToCurrentPage = Math.abs(selectedPage - pageNumber);
					if (
						pageNumber === 1 ||
						pageNumber === pageCount ||
						distanceToCurrentPage <
							numberOfPagesToShow -
								Math.min(
									selectedPage - 2,
									pageCount - selectedPage - 1,
									numberOfPagesToShow / 2,
								) // Increase allowable gap when closer to the edge
					) {
						return pageNumber;
					} else if (pageNumber - selectedPage < 0) {
						return "lower-ellipsis";
					} else {
						return "upper-ellipsis";
					}
				}),
		);
	// Normal state

	const searchParams = new URLSearchParams(location.search);

	const handleSearchParamsChange = (
		nextKey: "page" | "perPage",
		nextValue: number,
	) => {
		if (nextKey === "perPage") {
			searchParams.delete("page");
		}
		searchParams.set(nextKey, nextValue.toString());
		navigate({ search: searchParams.toString() });
	};

	const perPageOptions = [10, 20, 40];

	const currentPerPageSelection =
		parseInt(searchParams.get("perPage") || "") || perPageOptions[0];

	return (
		<>
			<FlexColumn>
				<PerPageBox>
					<PerPageOptions>
						{perPageOptions.map((perPageOption) => (
							<PerPageOption
								key={perPageOption}
								isActive={perPageOption === currentPerPageSelection}
								onClick={() =>
									handleSearchParamsChange("perPage", perPageOption)
								}>
								{perPageOption}
							</PerPageOption>
						))}
					</PerPageOptions>
					<span> per page</span>
				</PerPageBox>
				<Container {...props}>
					<PageNavButton
						variant="secondary"
						disabled={selectedPage === 1}
						block={false}
						name="prev-page"
						onClick={() => handleSearchParamsChange("page", selectedPage - 1)}>
						<ChevronLeftIcon height="12" width="12" />
					</PageNavButton>
					<PagePicker>
						{[...pageOptions].map((pageNumber, index) => {
							const isNumber = typeof pageNumber === "number";
							const isClickable = isNumber && pageNumber !== selectedPage;
							return (
								<PageNumber
									key={index}
									isActive={pageNumber === selectedPage}
									isClickable={isClickable}
									onClick={() =>
										isClickable && handleSearchParamsChange("page", pageNumber)
									}>
									{isNumber ? pageNumber : "⋯"}
								</PageNumber>
							);
						})}
					</PagePicker>
					<PageNavButton
						variant="secondary"
						block={false}
						name="next-page"
						disabled={selectedPage === pageCount}
						onClick={() => handleSearchParamsChange("page", selectedPage + 1)}>
						<ChevronRightIcon height="12" width="12" />
					</PageNavButton>
				</Container>
			</FlexColumn>
			{!!actionButtonText && !!actionOnClick && (
				<ActionButton onClick={actionOnClick}>{actionButtonText}</ActionButton>
			)}
		</>
	);
};

export default PaginationNavBar;
