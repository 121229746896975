import React from "react";
import { useTheme } from "@emotion/react";

// Types
import type IconProps from "Types/IconProps";

export const WindmillIcon: React.FC<IconProps> = ({
	width,
	height,
	className = "windmill-icon",
	fill,
	id,
	...props
}) => {
	// Hooks
	const theme = useTheme();

	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "42px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 42 50"
			preserveAspectRatio="xMidYMid meet"
			x="0"
			y="0"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M20.3018 23.7491L21.7724 23.7491L21.7724 46.7654C21.7724 47.0968 21.5037 47.3654 21.1724 47.3654L20.9018 47.3654C20.5704 47.3654 20.3018 47.0968 20.3018 46.7654L20.3018 23.7491Z"
				fill={fill ? fill : theme.colors.primary}
			/>
			<path
				d="M10.1533 24.3462C14.7742 21.6791 19.3075 20.8808 20.2786 22.5631C21.2498 24.2457 18.2912 27.7717 13.6702 30.439C9.04943 33.1063 3.54493 32.2221 3.54493 32.2221C3.54493 32.2221 5.53253 27.0136 10.1533 24.3462Z"
				fill={fill ? fill : theme.colors.primary}
			/>
			<path
				d="M28.5528 30.4838C23.9324 27.8156 20.9745 24.2889 21.9458 22.6066C22.9174 20.9244 27.4504 21.7237 32.0709 24.3918C36.6911 27.0599 38.6777 32.2689 38.6777 32.2689C38.6777 32.2689 33.1731 33.1522 28.5528 30.4838Z"
				fill={fill ? fill : theme.colors.primary}
			/>
			<path
				d="M24.5816 11.4802C24.5814 16.8159 23.0061 21.1407 21.0635 21.1406C19.1208 21.1404 17.5465 16.8149 17.547 11.4796C17.5475 6.14428 21.0653 1.8193 21.0653 1.8193C21.0653 1.8193 24.5824 6.14477 24.5816 11.4802Z"
				fill={fill ? fill : theme.colors.primary}
			/>
			<path
				d="M23.259 19.0285C22.6572 20.3503 21.8937 21.1409 21.0633 21.1407L21.0652 1.8195C21.0652 1.8195 24.5823 6.14498 24.5816 11.4804C24.5817 11.4862 24.5816 11.4923 24.5817 11.4983C24.5816 11.4989 24.5816 11.4989 24.5816 11.4995C24.5817 11.5053 24.5816 11.5116 24.5817 11.5174C24.5805 12.2447 24.5503 12.9528 24.4938 13.6337C24.3651 15.1856 24.1 16.5959 23.7356 17.7639C23.5915 18.2251 23.432 18.6487 23.259 19.0285Z"
				fill="black"
				fillOpacity="0.05"
			/>
			<path
				d="M38.6776 32.2685C38.6776 32.2685 33.9895 33.0208 29.6349 31.0396C29.2709 30.874 28.909 30.6893 28.5527 30.4834C28.5444 30.4788 28.5364 30.4741 28.5282 30.4693C28.5273 30.4689 28.5263 30.4682 28.5255 30.4677C28.5105 30.4591 28.4961 30.4507 28.4812 30.442C28.4713 30.4362 28.4611 30.4303 28.4512 30.4245C28.4462 30.4216 28.441 30.4186 28.4358 30.4156C28.424 30.4087 28.4123 30.4019 28.4006 30.3951C28.3976 30.3933 28.3944 30.3914 28.3912 30.3895C27.9624 30.1379 27.5486 29.8791 27.1511 29.6152C24.2104 27.6623 22.1819 25.434 21.8194 23.829C21.7429 23.4903 21.7405 23.1793 21.8194 22.9046C21.8373 22.8423 21.8594 22.7817 21.8857 22.7235L21.888 22.7184C21.9053 22.6803 21.9246 22.6426 21.9457 22.6062L38.6776 32.2685Z"
				fill="black"
				fillOpacity="0.05"
			/>
			<path
				d="M20.2784 22.563C19.3075 20.8808 14.7742 21.6791 10.1533 24.3462C5.53253 27.0136 3.54493 32.2221 3.54493 32.2221L20.3666 22.7803C20.3402 22.7065 20.3176 22.6312 20.2784 22.563Z"
				fill="black"
				fillOpacity="0.05"
			/>
			<path
				d="M23.6817 22.0424C23.6815 23.5207 22.4832 24.719 21.0047 24.7188C19.5266 24.7187 18.3282 23.5201 18.3284 22.0418C18.3285 20.5637 19.5271 19.3653 21.0054 19.3655C22.4837 19.3656 23.6819 20.564 23.6817 22.0424Z"
				fill={fill ? fill : theme.colors.primary}
			/>
			<path
				d="M22.462 22.0427C22.462 22.8475 21.8093 23.4999 21.0046 23.4996C20.1998 23.4996 19.5474 22.8472 19.5474 22.0423C19.5477 21.2377 20.2 20.5852 21.0048 20.5852C21.8096 20.5854 22.4622 21.2379 22.462 22.0427Z"
				fill="white"
			/>
		</svg>
	);
};

export default WindmillIcon;
