import React from "react";

// CC
import ModalNew from "../CCW-ModalNew";

// Styles
import {
	ModalContent,
	Header,
	Headline,
	SubHeadline,
	CloseButton,
	ContentContainer,
} from "./styles";

// Types
import type { ModalBannerProps } from "./types";

export const ModalBanner: React.FC<ModalBannerProps> = ({
	id,
	matchType,
	returnUrl,
	headline,
	subheading,
	className,
	isSimpleDesign = false,
	children,
}) => (
	<ModalNew {...{ id, matchType, returnUrl }}>
		<ModalContent {...{ isSimpleDesign }} {...{ className }}>
			<Header {...{ isSimpleDesign }}>
				<CloseButton to={returnUrl || ""} isHidden={!returnUrl} />
				<Headline {...{ isSimpleDesign }}>{headline}</Headline>
				{subheading && <SubHeadline>{subheading}</SubHeadline>}
			</Header>
			<ContentContainer {...{ isSimpleDesign }}>{children}</ContentContainer>
		</ModalContent>
	</ModalNew>
);

export default ModalBanner;
