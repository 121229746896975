import React, { useState } from "react";
import { useLocation } from "react-router-dom";

// Styles
import {
	Container,
	StyledNavLink,
	LinkList,
	Relative,
	ToggleButton,
	StyledFakeNavLink,
	HighlightedLink,
} from "./styles";

// Types
import type SideNavProps from "./types";

export const SideNav: React.FC<SideNavProps> = ({ links, ...props }) => {
	// Note that is shown only applies for mobile; it's always shown in desktop
	const [isShown, setIsShown] = useState(false);

	const location = useLocation();

	const activeLink = links.find(({ link }) =>
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		location.pathname.includes((link.pathname || link).replace("./", "")),
	);

	// Normal state
	return (
		<Relative
			onBlur={({ currentTarget, relatedTarget }) =>
				!currentTarget.contains(relatedTarget) && setIsShown(false)
			}>
			<div tabIndex={0}>
				{activeLink && (
					<ToggleButton onClick={() => setIsShown((oldIsShown) => !oldIsShown)}>
						<StyledFakeNavLink>
							{!!activeLink.icon && (
								<activeLink.icon
									width={"20"}
									height={"20"}
									fill={"currentColor"}
								/>
							)}
							<span>{activeLink.description}</span>
						</StyledFakeNavLink>
					</ToggleButton>
				)}
				<Container isShown={isShown} {...props}>
					<LinkList>
						{links.map(({ description, icon: Icon, link, highlighted }) => (
							<li key={description}>
								{highlighted ? (
									<HighlightedLink to={link} onClick={() => setIsShown(false)}>
										{!!Icon && (
											<Icon width={"20"} height={"20"} fill={"currentColor"} />
										)}
										<span>{description}</span>
									</HighlightedLink>
								) : (
									<StyledNavLink to={link} onClick={() => setIsShown(false)}>
										{!!Icon && (
											<Icon width={"20"} height={"20"} fill={"currentColor"} />
										)}
										<span>{description}</span>
									</StyledNavLink>
								)}
							</li>
						))}
					</LinkList>
				</Container>
			</div>
		</Relative>
	);
};

export default SideNav;
