import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

// CC
import Backlink from "CCW-Components/CCW-Backlink";

// Hooks
import useAlert from "Hooks/UseAlert";

// Helpers
import createRETarget from "Helpers/NetworkingHelpers/CreateRenewableEnergyTarget";
import isError from "Helpers/TypeHelpers/IsError";

// Styles
import {
	Container,
	PageTitle,
	FormContainer,
	DropDownFormik,
	FormElementContainer,
	InputLabel,
	TextInput,
	ToggleContainer,
	ToggleButton,
	SubmitButton,
} from "./styles";

// Types
import type CreateNewRETargetPageProps from "./types";
import type { CreateRenewableEnergyTarget } from "Types/RenewableEnergyTargets";
import type { YearType } from "Types/Account";

// Form Data
import { initialValues, validationSchema } from "./FormData";

export const CreateNewRETarget: React.FC<CreateNewRETargetPageProps> = () => {
	// Hooks
	const [yearType, setYearType] = useState<YearType>("fiscal");
	const [, reportAlert] = useAlert();
	const navigate = useNavigate();

	// Normal state
	return (
		<Container>
			<div>
				<Backlink to="/targets">Back to Targets</Backlink>
				<PageTitle>Create New Renewable Energy Target</PageTitle>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={async (values, { setSubmitting, resetForm }) => {
						try {
							setSubmitting(true);
							const createdTarget = await createRETarget(
								values as CreateRenewableEnergyTarget,
							);
							createdTarget &&
								reportAlert("Target created successfully", "success");

							createdTarget && resetForm();
							navigate("../");
						} catch (error) {
							isError(error) && reportAlert(error.message, "error");
						} finally {
							setSubmitting(false);
						}
					}}>
					{({ getFieldHelpers, isSubmitting, isValid, dirty }) => {
						return (
							<Form>
								<FormContainer>
									<FormElementContainer gridArea="country">
										<InputLabel isDisabled={true}>Country</InputLabel>
										<DropDownFormik
											name="country"
											placeholder="Select country"
											options={[{ label: "Australia", value: "Australia" }]}
											isDisabled
										/>
									</FormElementContainer>
									<FormElementContainer gridArea="state">
										<InputLabel>State</InputLabel>
										<DropDownFormik
											name="state"
											placeholder="Select state"
											options={[
												{ label: "WA", value: "WA" },
												{ label: "NSW", value: "NSW" },
												{ label: "NT", value: "NT" },
												{ label: "QLD", value: "QLD" },
												{ label: "SA", value: "SA" },
												{ label: "TAS", value: "TAS" },
												{ label: "VIC", value: "VIC" },
												{ label: "ACT", value: "ACT" },
											]}
										/>
									</FormElementContainer>

									<ToggleContainer gridArea="yeartype">
										<ToggleButton
											id="fiscal"
											selected={yearType === "fiscal"}
											onClick={() => {
												getFieldHelpers("yearType").setValue("fiscal");
												setYearType("fiscal");
											}}>
											Fiscal Year
										</ToggleButton>
										<ToggleButton
											id="yearly"
											selected={yearType === "yearly"}
											onClick={() => {
												getFieldHelpers("yearType").setValue("yearly");
												setYearType("yearly");
											}}>
											Calendar Year
										</ToggleButton>
									</ToggleContainer>
									<FormElementContainer style={{ gridArea: "year" }}>
										<InputLabel>Year</InputLabel>
										<TextInput id="year" name="year" />
									</FormElementContainer>
									<FormElementContainer style={{ gridArea: "percentage" }}>
										<InputLabel>Target %</InputLabel>
										<TextInput id="percentage" name="percentage" />
									</FormElementContainer>
									<SubmitButton
										gridArea="submission"
										name="submit"
										type="submit"
										loading={isSubmitting}
										disabled={!dirty || !isValid || isSubmitting}>
										Submit
									</SubmitButton>
								</FormContainer>
							</Form>
						);
					}}
				</Formik>
			</div>
		</Container>
	);
};

export default CreateNewRETarget;
