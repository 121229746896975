import styled from "@emotion/styled";

// CC
import ButtonLoad from "CCW-Components/CCW-ButtonLoader";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Row = styled.div(() => ({
	display: "flex",
	"& > div": {
		width: "100%",
	},
	gap: "3rem",
}));

export const Title = styled.h1(({ theme }) => ({
	color: theme.colors.White,
	...theme.webFonts.h2,
	margin: "0",
	padding: "1.5rem",
	background: "#FF1493",
	backgroundImage:
		"linear-gradient(319deg, #FF1493 0%, #0000ff 37%, #ff8c00 100%)",
	borderRadius: "1.8rem 1.8rem 0 0",
	textAlign: "center",
}));

export const Container = styled.div(({ theme }) => ({
	border: "0.2rem solid #FF1493",
	borderRadius: "2rem",
	backgroundColor: theme.colors.White,
	"& > div": {
		padding: "2rem",
		"& > p": {
			...theme.webFonts.F5,
			margin: "0 0 3rem 0",
		},
	},
}));

export const ButtonRow = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	gap: "3rem",
	[MediaQueries[1]]: {
		flexDirection: "row",
		justifyContent: "center",
	},
}));

export const ButtonLoader = styled(ButtonLoad)<{ disabled: boolean }>(
	({ theme, disabled }) => ({
		background: disabled ? theme.colors.D4 : "#ff1493",
		color: theme.colors.White,
		"&:hover, :focus": !disabled && {
			color: "#ff1493",
			background: theme.colors.White,
			boxShadow: `inset 0 0 0 0.2rem #ff1493, 0 0 0.8rem 0 ${theme.colors.Black10}, 0 0.1rem 0.3rem 0 ${theme.colors.Black15}`,
		},
	}),
);
