import React, { useState, useEffect } from "react";
import { useTheme } from "@emotion/react";

// CC
import ProgressBar from "../CCW-ProgressBar";

// Styles
import {
	Container,
	Label,
	Asterisk,
	AssessmentContainer,
	PasswordInfo,
} from "./styles";

// Types
import PasswordLabelProps from "./types";

const PasswordLabel: React.FC<PasswordLabelProps> = ({
	text,
	password,
	required = false,
	...props
}) => {
	// Theme
	const theme = useTheme();

	// State
	const [strength, setStrength] = useState({
		label: "Weak",
		percentage: 25,
		color: theme.colors.Red,
	});

	// Enums
	enum StrengthOption {
		Strong,
		Ok,
		Weak,
	}

	useEffect(() => {
		const getPasswordStrength = (
			password: string | undefined,
		): StrengthOption =>
			password && password.length >= 8
				? StrengthOption.Strong
				: password && password.length >= 5
				? StrengthOption.Ok
				: StrengthOption.Weak;

		const strength = getPasswordStrength(password);

		switch (strength) {
			case StrengthOption.Strong: {
				setStrength({
					label: "Strong",
					percentage: 100,
					color: theme.colors.Green,
				});
				return;
			}
			case StrengthOption.Ok: {
				setStrength({
					label: "Almost...",
					percentage: 75,
					color: theme.colors.Yellow,
				});
				return;
			}
			case StrengthOption.Weak:
			default:
				setStrength({
					label: "Weak",
					percentage: 25,
					color: theme.colors.Red,
				});
		}
	}, [
		password,
		StrengthOption.Weak,
		StrengthOption.Ok,
		StrengthOption.Strong,
		theme.colors.Red,
		theme.colors.Yellow,
		theme.colors.Green,
	]);

	return (
		<Container>
			<Label {...props}>{text}</Label>
			<AssessmentContainer>
				<PasswordInfo>{strength.label}</PasswordInfo>
				<ProgressBar
					color={strength.color}
					now={strength.percentage}
					style={{ width: "100px", marginRight: "10px" }}
					isRoundedEdges={true}
				/>
				{required ? <Asterisk>*</Asterisk> : null}
			</AssessmentContainer>
		</Container>
	);
};

export default PasswordLabel;
