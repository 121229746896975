import React, { PropsWithChildren } from "react";

// Styles
import Container from "./styles";

const FormContainer: React.FC<PropsWithChildren<unknown>> = (props) => (
	<Container {...props} />
);

export default FormContainer;
