// Helpers
import postReq from "../PostReq";

// Types
import type {
	CreateRenewableEnergyTarget,
	RenewableEnergyTarget,
} from "Types/RenewableEnergyTargets";
import { APIResponseDeprecated } from "Types/JSONApi";

/**
 * @async
 * Create a new Renewable Energy Target
 * @param newRETargetToCreate Form values needed to create the Renewable Energy Target
 * @throws Networking error
 */

const createRenewableEnergyTarget = async (
	newRETargetToCreate: CreateRenewableEnergyTarget,
): Promise<RenewableEnergyTarget | undefined> => {
	const { data: returnData } = await postReq<
		APIResponseDeprecated<RenewableEnergyTarget>
	>(
		`${
			import.meta.env.VITE_BASE_URL
		}measure/emissions/renewable-energy-targets`,
		newRETargetToCreate,
		1,
	);
	return returnData;
};
export default createRenewableEnergyTarget;
