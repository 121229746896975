/**
 * Check a collection of SWR isError object and return the first that is not null
 * @param errors Collection of error object
 * @return An error message
 */
const swrErrorCheck = (errors: (Error | undefined)[]) => {
	const error = errors.find((error) => error != null);
	return error != null
		? error
		: new Error(
				"Unknown network error, contact support and quote code: 11452 and what section of the app your on",
		  );
};

export default swrErrorCheck;
