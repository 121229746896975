import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const Container = styled.div({
	display: "flex",
	flexWrap: "wrap",
	gap: "1.5rem",
});

export const ChartLink = styled(Link)<{ selected: boolean }>(
	({ selected, theme }) => ({
		...(selected ? theme.webFonts.F7Bold : theme.webFonts.F7),
		color: selected ? theme.colors.primary : theme.colors.D2,
		textDecoration: "none",
		padding: "0.5rem 0.7rem 0.7rem",
		"&: hover, focus": {
			background: theme.colors.primary,
			color: theme.colors.White,
		},
		"&: visited": { color: theme.colors.D2 },
	}),
);

export default Container;
