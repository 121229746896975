import styled from "@emotion/styled";

// CC
import CCDropDownFormik from "CCW-Components/CCW-FIGDropDownInputNew";
import FIGDefault from "CCW-Components/CCW-FIGDefault";
import CCButton from "CCW-Components/CCW-CCButton";
import ButtonLoader from "CCW-Components/CCW-ButtonLoader";

// Styles
export const Container = styled.div(() => ({
	position: "relative",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "3rem 0 10rem",
}));

export const PageTitle = styled.h2(({ theme }) => ({
	...theme.webFonts.h2,
	color: theme.colors.D2,
	alignSelf: "flex-start",
	paddingBottom: "4rem",
}));

export const FormContainer = styled.div(() => ({
	display: "grid",
	gap: "3rem 2rem",
	maxWidth: "68rem",
	gridTemplateColumns: "1fr 1fr 1fr 1fr",
	gridTemplateRows: "auto",
	gridTemplateAreas: `"country country state state"
    "yeartype yeartype year percentage"
        "... ... ... ..."
        ". . . submission"`,
	alignItems: "flex-end",
	justifyContent: "flex-end",
}));

export const DropDownFormik = styled(CCDropDownFormik)<{ gridArea?: string }>(
	({ gridArea }) => ({
		gridArea: gridArea || "unset",
		background: "white !important",
		position: "relative",
		"& .error": {
			position: "absolute",
			left: "0px",
			bottom: "-2rem",
			whiteSpace: "nowrap",
			overflow: "hidden",
		},
	}),
);
export const FormElementContainer = styled.div<{ gridArea?: string }>(
	({ gridArea }) => ({
		gridArea: gridArea || "unset",
		display: "flex",
		flexDirection: "column",
	}),
);

export const InputLabel = styled.label<{ isDisabled?: boolean }>(
	({ theme, isDisabled }) => ({
		position: "relative",
		...theme.webFonts.F7,
		color: theme.colors.D2,
		margin: isDisabled ? "-0.3rem 0 -0.7rem 1rem" : "0 0 -1rem 1rem",
		backgroundColor: theme.colors.White,
		borderRadius: "0.4rem",
		padding: isDisabled ? "0.2rem 0.6rem 0.2rem 2.2rem" : "0.2rem 0.6rem",
		alignSelf: "flex-start",
		zIndex: 1,
		boxShadow: isDisabled ? `0 0 0.8rem ${theme.colors.Black10}` : "none",
		"::after": {
			display: isDisabled ? "block" : "none",
			content: "''",
			backgroundColor: theme.colors.D4,
			borderRadius: "0.2rem",
			width: "1rem",
			height: "0.7rem",
			position: "absolute",
			top: "1rem",
			left: "0.5rem",
		},
		"::before": {
			display: isDisabled ? "block" : "none",
			content: "''",
			position: "absolute",
			border: `2px solid ${theme.colors.D4}`,
			top: "0.5rem",
			left: "0.6rem",
			width: "0.4rem",
			height: "0.8rem",
			borderRadius: "35px 35px 0.3rem 0.3rem",
		},
		"& > span": {
			color: theme.colors.D4,
		},
	}),
);

export const TextInput = styled(FIGDefault)<{
	gridArea?: string;
	disabled?: boolean;
	required?: boolean;
}>(({ theme, gridArea, disabled, required }) => ({
	gridArea: gridArea || "unset",
	margin: "-0.8rem 0 0",
	"& > div > input": {
		backgroundColor: disabled ? theme.colors.D4 : "white",
		borderBottom: required ? `2px solid ${theme.colors.Blue500}` : "default",
		paddingTop: "0.8rem",
	},
	"::placeholder": {
		color: disabled ? theme.colors.D3 : theme.colors.D4,
	},
	"& > div > div > span": {
		// Hide that horrible required star
		display: "none",
	},
}));

export const ToggleContainer = styled.div<{
	gridArea?: string;
}>(({ theme, gridArea }) => ({
	gridArea: gridArea || "unset",
	display: "flex",
	alignItems: "flex-end",
	gap: "0.4rem",
	padding: "0.4rem",
	margin: "-0.6rem -0.4rem -0.4rem -0.4rem",
	backgroundColor: theme.colors.Black05,
	borderRadius: "1.2rem",
}));

export const ToggleButton = styled(CCButton)<{
	selected?: boolean;
	disabled?: boolean;
}>(({ theme, selected, disabled }) => ({
	flexBasis: "50%",
	flexGrow: 1,
	userSelect: "none",
	pointerEvents: selected || disabled ? "none" : "initial",
	cursor: disabled ? "default" : "pointer",
	padding: "0",
	...theme.webFonts.F7Bold,
	color: selected ? "white" : disabled ? theme.colors.D4 : theme.colors.D3,
	backgroundColor: selected
		? theme.colors.primary
		: disabled
		? theme.colors.White20
		: "white",
	border: disabled
		? "none"
		: `1px solid ${selected ? theme.colors.primary : theme.colors.D3}`,
	":active, :focus": {
		color: selected ? "white" : theme.colors.primary,
		backgroundColor: selected ? theme.colors.primary : "white",
		borderWidth: "2px",
	},
	transition: "backgroundColor 0s",
}));

export const SubmitButton = styled(ButtonLoader)<{
	gridArea?: string;
}>(({ gridArea }) => ({
	gridArea: gridArea ? gridArea : "auto",
}));
