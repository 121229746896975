import React, { PropsWithChildren } from "react";

// Styles
import { Container } from "./styles";

export const PageHeader: React.FC<PropsWithChildren> = ({
	children,
	...props
}) => {
	return <Container {...props}>{children}</Container>;
};

export default PageHeader;
