import styled from "@emotion/styled";

export const Backdrop = styled.div<{ visible: boolean }>(
	({ theme, visible }) => ({
		position: "fixed",
		opacity: visible ? 1 : 0,
		visibility: visible ? "visible" : "hidden",
		userSelect: visible ? "inherit" : "none",
		pointerEvents: visible ? "inherit" : "none",
		transition: "opacity 400ms ease-out, visibility 400ms ease-out",
		inset: 0,
		zIndex: 10000,
		background: theme.colors.Black50,
		backdropFilter: "blur(5px)",
		overflow: "auto",
		width: "100%",
		display: "flex",
		"& > div": {
			margin: "auto", // Important to keep this and not use justify / align items
			minHeight: "0",
			maxWidth: "100%",
		},
	}),
);
