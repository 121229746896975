import React, { useState } from "react";

// Helpers
import sentenceCase from "Helpers/StringHelpers/SentenceCase";

// Styles
import {
	Container,
	Row,
	RowContent,
	RowHeading,
	RowHeadingText,
	DataBox,
	ActionTag,
	ActionTagPopup,
	ActionTagPopupContainer,
	PopupButtonContainer,
} from "./styles";

// Types
import type FactorRowProps from "./types";
import type {
	GeneralMethod,
	PaperMethod,
	FlightMethod,
	FuelBasedMethod,
	AccommodationMethod,
	ElectricityMethod,
	FlightFactor,
	FlightClass,
} from "Types/EmissionFactors";

export const FactorRow: React.FC<FactorRowProps> = ({
	rowData,
	propertyTags,
	actionTagText,
	actionTagPopupContent,
	actionTagExpansion,
	headingText,
	index,
	isShortHeading,
}) => {
	// Hooks
	const [showPopupNumber, setShowPopupNumber] = useState<number | undefined>();
	const [showPopupExpansion, setShowPopupExpansion] = useState(false);

	// Helpers
	const formatProperty = (valueToFormat: unknown): string | number => {
		if (typeof valueToFormat === "string") {
			return sentenceCase(valueToFormat);
		} else if (typeof valueToFormat === "number") {
			return valueToFormat;
		} else {
			return "";
		}
	};

	// Normal state
	return (
		<Container>
			{!!Object.keys(rowData).length && (
				<Row key={index}>
					<RowHeading shortHeading={isShortHeading}>
						<RowHeadingText isUpdated={!!rowData?.replacedBy}>
							{headingText}
						</RowHeadingText>
					</RowHeading>
					<RowContent>
						{actionTagPopupContent || actionTagExpansion ? (
							<ActionTagPopupContainer
								onMouseOver={() => setShowPopupNumber(index)}
								onClick={() =>
									actionTagExpansion
										? setShowPopupExpansion(!showPopupExpansion)
										: null
								}
								onMouseLeave={() => {
									setShowPopupNumber(undefined);
									setShowPopupExpansion(false);
								}}>
								{actionTagPopupContent && (
									<ActionTagPopup showPopup={showPopupNumber === index}>
										<PopupButtonContainer>
											{!showPopupExpansion && actionTagPopupContent}
											{!!actionTagExpansion &&
												showPopupExpansion &&
												showPopupNumber === index &&
												actionTagExpansion}
										</PopupButtonContainer>
									</ActionTagPopup>
								)}

								<ActionTag isUpdated={!!rowData?.replacedBy}>
									<span>
										{rowData?.replacedBy ? "Outdated Factors" : actionTagText}
									</span>
								</ActionTag>
							</ActionTagPopupContainer>
						) : (
							<ActionTag>
								<span>{actionTagText}</span>
							</ActionTag>
						)}
						{!!rowData?.replacedBy && (
							<>
								<DataBox>🔒 Locked</DataBox>
								<DataBox>Replaced</DataBox>
							</>
						)}
						{!!rowData?.reason && !rowData?.replacedBy && (
							<DataBox isUpdated={true}>Updated</DataBox>
						)}
						{propertyTags.map((property, index) => {
							const methodName = (() => {
								switch (
									rowData[property] as
										| GeneralMethod
										| PaperMethod
										| FlightMethod
										| FuelBasedMethod
										| AccommodationMethod
										| ElectricityMethod
								) {
									case "market-average":
										return "Market Average";
									case "average-data-method":
										return "Average Data";
									case "location-australia":
										return "Location-Australia";
									case "fuel":
										return "Fuel Factor";
									case "stars":
										return "Star Rating based";
									case "distance":
									case "market":
									case "location":
										return `${rowData[property]} based`;
									default:
										return `${rowData[property]}`;
								}
							})();
							if (property === "method") {
								return (
									<DataBox key={index}>{sentenceCase(`${methodName}`)}</DataBox>
								);
							}

							const formatFlightDistance = (() => {
								if (property === ("distance" as keyof FlightFactor)) {
									switch (rowData[property] as 0 | 700 | 3700) {
										case 700:
											return "Under 700km";
										case 3700:
											return "700 - 3700km";
										case 0:
											return "Over 3700km";
									}
								}
								if (property === ("class" as keyof FlightFactor)) {
									switch (rowData[property] as FlightClass) {
										case "average":
											return "Average Passenger";
										case "first":
										case "business":
											return "Business Class";
										default:
											return sentenceCase(`${rowData[property]}`);
									}
								}
							})();

							return rowData[property] ||
								(property === "distance" && rowData[property] === 0) ? ( // Because flights over 3700km return as distance category zero (not falsey)...
								<DataBox key={index}>
									{property === "commercial"
										? "Commercial"
										: property === "distance" || property === "class"
										? formatFlightDistance
										: property === "stars"
										? `${rowData[property]} Star`
										: formatProperty(rowData[property])}
								</DataBox>
							) : null;
						})}
					</RowContent>
				</Row>
			)}
		</Container>
	);
};

export default FactorRow;
