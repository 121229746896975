/**
 * groupByKey is similar to groupBy, but you pass it a key to groupBy rather than a function
 * Another important difference is that groupByKey will remove the key from each array item
 * @param keyToGroupBy key that array items should be grouped by
 * @param arrayToProcess array to split into groups
 * @returns an object with the array split into different keys
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GroupedArraysByKey<T extends Record<K, any>, K extends keyof T> = {
	[key in T[K]]: Omit<T, K>[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupByKey = <T extends Record<K, any>, K extends keyof T>(
	keyToGroupBy: K,
	arrayToProcess: T[] | undefined = [],
): GroupedArraysByKey<T, K> => {
	return arrayToProcess.reduce((accumulatedGroups, arrayItem) => {
		const stringToGroupBy = arrayItem[keyToGroupBy];
		const { [keyToGroupBy]: _, ...arrayItemWithDroppedKey } = arrayItem;
		return {
			...accumulatedGroups,
			[stringToGroupBy]: [
				...(accumulatedGroups[stringToGroupBy] || []),
				arrayItemWithDroppedKey,
			],
		};
	}, {} as GroupedArraysByKey<T, K>);
};

/**
 * groupBy is a home grown version of https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/groupBy
 * The params match the following format arrayToProcess.groupBy(functionToGroupBy)
 * @param functionToGroupBy function you would have passed to the native groupBy
 * @param arrayToProcess Array you would have dot notationed on in the native groupBy
 * @returns an object with the array split into different keys
 */

type GroupedArrays<T> = { [key: string]: T[] };

export const groupBy = <T, S extends string>(
	arrayToProcess: T[] | undefined = [],
	functionToGroupBy: (arrayItem: T) => S,
): GroupedArrays<T> => {
	return arrayToProcess.reduce((accumulatedGroups, arrayItem) => {
		const stringToGroupBy = functionToGroupBy(arrayItem);
		return {
			...accumulatedGroups,
			[stringToGroupBy]: [
				...(accumulatedGroups[stringToGroupBy] || []),
				arrayItem,
			],
		};
	}, {} as GroupedArrays<T>);
};

/**
 * Splits an array into buckets
 * @param items The array to split
 * @param bucketSizes The sizes of each bucket to use. The first index will be for the first bucket,
 * second for the second and so on till it gets to the last size, which it will use for all remaining buckets
 * @returns The same array but with the items split into different arrays (buckets) based on the bucket sizes
 */
export const arrayToBuckets = <T>(items: T[], bucketSizes: number[]): T[][] => {
	let buckets = [] as T[][];
	const mutableBucketSizes = [...bucketSizes];
	const mutableItems = [...items];
	while (mutableItems.length) {
		const bucketSize =
			mutableBucketSizes.splice(0, 1)[0] || bucketSizes[bucketSizes.length - 1];
		buckets = [...buckets, mutableItems.splice(0, bucketSize)];
	}
	return buckets;
};
