import styled from "@emotion/styled";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

// CC
import ButtonLink from "../CCW-ButtonLink";

export const NavBarContainer = styled.div(({ theme }) => ({
	position: "sticky",
	top: 0,
	left: 0,
	right: 0,
	background: "white",
	boxShadow: theme.shadow,
	zIndex: 9999,
}));

export const HoverStyles = styled.div({
	transition: "transform 0.2s ease",
	"&:hover": { transform: "scale(1.02)" },
	"&:active": { transform: "scale(0.98)" },
});

export const NavList = styled.ul(() => ({
	listStyleType: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	padding: "0",
	width: "100%",
	gap: "3.5rem",
	[MediaQueries[3]]: {
		gap: "5.5rem",
	},
}));

export const Bar = styled.div(() => ({
	display: "flex",
	alignItems: "center",
	width: "100%",
	height: "64px",
	justifyContent: "space-between",
}));

export const MenuContainer = styled.div(() => ({
	position: "relative",
	zIndex: 1000,
	height: "100%",
	display: "flex",
	alignItems: "center",
}));

export const MenuButton = styled.button(({ theme }) => ({
	padding: 0,
	border: "none",
	background: "transparent",
	display: "flex",
	alignItems: "center",
	columnGap: "0.4rem",
	"& > svg > path": {
		fill: theme.colors.secondary,
	},
	cursor: "pointer",
}));

export const Position = styled.div({
	position: "absolute",
	right: "-2rem",
	top: "100%",
});

export const UpgradeButtonContainer = styled.div(({ theme }) => ({
	position: "relative",
	overflow: "hidden",
	borderRadius: "1rem",
	padding: "2px",
	transition: "all 0.2s ease",
	background: theme.gradientsColors.Rotated90,

	":hover": {
		transform: "scale(1.05)",
		background: theme.gradientsColors.Rotated90,
	},
	":active, :focus": {
		transform: "scale(0.98)",
	},
}));

export const UpgradeButton = styled(ButtonLink)(({ theme }) => ({
	boxShadow: "none",
	background: "white",
	minHeight: "3rem",
	padding: "0.6rem 2rem 0.8rem",
	"& > span": {
		backgroundColor: theme.colors.primary,
		backgroundImage: theme.gradientsColors.Rotated180,
		backgroundSize: "100%",
		WebkitBackgroundClip: "text",
		WebkitTextFillColor: "transparent",
	},
	":focus": {
		transform: "none",
		boxShadow: "none",
	},
	":hover, :active": {
		transform: "none",
		boxShadow: "none",
		background: theme.colors.White,
	},
	":before, :after": {
		content: "''",
		width: "1rem",
		position: "absolute",
		top: 0,
		left: "-40%",
		height: "150%",
		transform: "skew(20deg)",
		opacity: 0.6,
		background: theme.gradientsColors.Rotated90,
		margin: "-2rem 0",
		transition: "all ease-in-out 0.8s",
		animation: "slow-shimmer 20s 7",
		"@keyframes slow-shimmer": {
			"60%": {
				transform: "skew(20deg) translate(-4rem) scaleX(1)",
			},
			"65%": {
				transform: "skew(25deg) translate(27rem) scaleX(1.5)",
			},
			"70%": {
				transform: "skew(25deg) translate(27rem) scaleX(1.5)",
			},
			"75%": {
				transform: "skew(20deg) translate(-4rem) scaleX(1)",
			},
		},
	},
	":after": {
		animationDelay: "0.1s",
		width: "3rem",
	},
}));
