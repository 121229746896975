import styled from "@emotion/styled";

// Styles
export const OverflowScroll = styled.div({
	maxWidth: "100%",
	overflowX: "auto",
});

export const Table = styled.table(({ theme: { colors } }) => ({
	tableLayout: "auto",
	borderSpacing: 0,
	width: "100%",
	backgroundColor: colors.Blueberry400,
	borderRadius: "0.8rem 0.8rem 0 0",
	overflow: "hidden",
	// Zebra striping
	"& tr:nth-of-type(even)": {
		backgroundColor: colors.D6,
	},
	// Last cell
	"& td:last-of-type": {
		padding: 0,
		width: 0,
		"& .edit-button:focus-visible": {
			outline: `2px solid ${colors.Grape400}`,
			borderRadius: "0.4rem",
			backgroundColor: colors.Grape50,
		},
	},
	"& tbody": {
		background: colors.White,
	},
	// Row hover effects (for edit button)
	"& tbody tr:hover, tbody tr:focus": {
		backgroundColor: colors.Grape50,
		color: colors.D1,
		outline: `2px solid ${colors.Grape400}`,
		outlineOffset: "-2px",
		borderRadius: "0.4rem",
	},
}));

export const HeaderCell = styled.th(({ theme: { colors, webFonts } }) => ({
	...webFonts.F7Bold,
	color: colors.White,
	textAlign: "left",
	padding: "1rem 0.6rem 1rem 1.2rem",
	height: "5rem",
	cursor: "auto",
}));

export const Row = styled.tr(({ theme: { colors, webFonts } }) => ({
	...webFonts.F7,
	color: colors.D3,
	cursor: "pointer",
}));

export const TopRow = styled(Row)(({ theme: { colors } }) => ({
	backgroundColor: colors.Blueberry400,
}));

export const Cell = styled.td(() => ({
	whiteSpace: "nowrap",
	minHeight: "5rem",
	padding: "1rem 0.6rem 1rem 1.2rem", // Last cell padding is set on the parent Table
}));
