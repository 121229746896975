import React, { useState } from "react";
import { useTheme } from "@emotion/react";

// Assets
import EyeIcon from "Assets/SVG/EyeIcon";

// Styles
import { Container, StyledTextInput } from "./styles";

// Types
import { TextInputPasswordProps } from "./types";

const TextInputPassword: React.FC<TextInputPasswordProps> = ({
	error,
	className,
	id,
	name,
	...props
}) => {
	// State
	const [isShowPassword, setIsShowPassword] = useState(false);

	// Hooks
	const theme = useTheme();

	return (
		<Container>
			<StyledTextInput
				id={id || name}
				name={name}
				className={className != null ? className : "text-input-password"}
				error={error}
				{...props}
				type={isShowPassword ? "text" : "password"}
			/>
			<div
				className="absolute top-5 right-4 z-50 cursor-pointer"
				onClick={() => setIsShowPassword(!isShowPassword)}>
				<EyeIcon width="24px" height="16px" fill={theme.colors.D3} />
			</div>
		</Container>
	);
};

export default TextInputPassword;
