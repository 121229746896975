import React, { useState } from "react";

// Assets
import ArrowIcon from "Assets/SVG/ArrowIcon";

// Styles
import { Section, MarkdownInput, Button } from "./styles";

// Types
import type MarkdownItemProps from "./types";

const MarkdownItem: React.FC<MarkdownItemProps> = ({
	type,
	even,
	characterLimit,
}) => {
	// State
	const [isOpen, setIsOpen] = useState(true);

	return (
		<Section key={type} {...{ even, isOpen }}>
			<Button
				onClick={() => setIsOpen(isOpen ? false : true)}
				block
				variant="secondary"
				{...{ even }}>
				<span>{type}</span>
				<ArrowIcon
					width="1.5rem"
					height="1.5rem"
					direction={isOpen ? "up" : "down"}
				/>
			</Button>
			<MarkdownInput
				show={isOpen}
				id={type}
				name={type}
				characterLimit={characterLimit}
				label={`${type.toUpperCase()}:`}
			/>
		</Section>
	);
};

export default MarkdownItem;
