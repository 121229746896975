import React, { PropsWithChildren } from "react";
import { ThemeProvider } from "@emotion/react";
import colors from "Theme/Styles/Colors";
import categoryColors from "Theme/Styles/CategoryColors";
import gradientsColors from "Theme/Styles/GradientColors";
import webFonts from "Theme/Styles/WebFonts";
import shadow from "Theme/Styles/Shadow";

// If you're looking for types, you can find them in the emotion.d.ts file
const Theme: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<ThemeProvider
			theme={{
				colors,
				categoryColors,
				gradientsColors,
				webFonts,
				shadow,
			}}>
			{children}
		</ThemeProvider>
	);
};

export default Theme;
