import React from "react";
import InputLabelProps from "./types";

// Styles
import { LabelContainer, Label, Required } from "./styles";

/** A label for input that shows the input is required */
const InputLabel: React.FC<InputLabelProps> = ({
	defaultMargin = true,
	text,
	required = true,
	style,
	className,
	...props
}) => (
	<LabelContainer
		defaultMargin={defaultMargin}
		style={style}
		className={className}>
		<Label {...props}>{text}</Label>
		{required ? <Required>*</Required> : null}
	</LabelContainer>
);

export default InputLabel;
