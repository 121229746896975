import styled from "@emotion/styled";

// Styles
export const Container = styled.div(({ theme }) => ({
	"& > h1": {
		...theme.webFonts.F6,
		...theme.webFonts.bold,
		color: theme.colors.D1,
	},
	"& > *:first-of-type": {
		marginTop: 0,
	},
	"& > h2": {
		...theme.webFonts.F7,
		...theme.webFonts.bold,
		color: theme.colors.D2,
	},
	"& > p, span, li": {
		...theme.webFonts.F7,
		color: theme.colors.D2,
		margin: "0 0 2rem 0.8rem",
	},
}));
