import React, { PropsWithChildren, Suspense } from "react";

const iconNames = [
	"FuelIcon",
	"CateringIcon",
	"EquipmentIcon",
	"AbandonedIcon",
	"AccommodationIcon",
	"BurgerIcon",
	"DownloadArrowIcon",
	"StudentIcon",
	"AbatementsIcon",
	"BusinessIcon",
	"DropletIcon",
	"ActionIcon",
	"CCDarkLogo",
	"HomeIcon",
	"OtherIcon",
	"SunIcon2",
	"CCLightLogo",
	"ElectricityIcon",
	"AlertBellIcon",
	"CalendarIcon",
	"EmailIcon",
	"HouseIcon",
	"PaperIcon",
	"TeacherIcon",
	"AnalyticsIcon",
	"CarIcon",
	"EyeIcon",
	"PartnerIcon",
	"FaceIcon",
	"PenIcon",
	"TickIcon",
	"AppErrorIcon",
	"CarbonFootprintIcon",
	"PlanIcon",
	"CarbonIcon",
	"CardIcon",
	"LPGIcon",
	"PlusIcon",
	"LaunchIcon",
	"PolicyIcon",
	"TreeIcon",
	"ArrowUpDownIcon",
	"ChangeIcon",
	"PostponedIcon",
	"TreesIcon",
	"CircleArrowIcon",
	"FileIcon",
	"FlightIcon",
	"LearningIcon",
	"RecyclingBinIcon",
	"UploadCloudIcon",
	"ClockIcon",
	"FlowerIcon",
	"UsageIcon",
	"AustraliaIcon",
	"CloudIcon",
	"LightBulbIcon",
	"ReportIcon",
	"UserIcon",
	"CloudShareIcon",
	"LinkIcon",
	"RoomIcon",
	"LoadingSunIcon",
	"SchoolIcon",
	"WarningIcon",
	"CommunityIcon",
	"Logo",
	"SearchIcon",
	"WasteIcon",
	"CompareIcon",
	"FunIcon",
	"MeasureIcon",
	"SettingsIcon",
	"WaterIcon",
	"CompletedIcon",
	"MissingIcon",
	"ShareIcon",
	"BoltIcon",
	"GasIcon",
	"WindmillIcon",
	"CostIcon",
	"GlobeIcon",
	"MoveArrowIcon",
	"CrossIcon",
	"NoCostIcon",
	"SolarIcon",
	"NotSupportedIcon",
	"SolarPanelIcon",
	"NotepadIcon",
	"DistanceIcon",
	"StarIcon",
	"BureaucraticIcon",
	"DollarIcon",
	"OffsetIcon",
	"OffsetGradientIcon",
	"StatusCircleIcon",
	"GiftIcon",
	"OutdoorsIcon",
	"WorldIcon",
	"SocialMediaIcon",
	"OnboardingIcon",
	"BottleIcon",
	"BoxIcon",
	"WeightIcon",
	"PlayIcon",
	"EditIcon",
	"ChevronLeftIcon",
	"ChevronRightIcon",
	"FilterIcon",
	"AubreyIcon",
	"ClipboardIcon",
	"HalloweenIcon",
	"SharehubIcon",
	"TransportIcon",
	"TiersIcon",
	"SparklesIcon",
	"UpgradeIcon",
	"PaywallSharehubIcon",
	"PaywallReportIcon",
	"CertificateIcon",
	"ChristmasIcon",
	"ArrowIcon",
	"BookIcon",
	"CompletionReportToDo",
	"CompletionReportDone",
	"EmptyBoxIcon",
	"KeyIcon",
];
const IconComponents = iconNames
	.sort()
	.map((name) => React.lazy(() => import(`./${name}/index.tsx`)));

const IconContainer: React.FC<PropsWithChildren> = (props) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				width: "150px",
				height: "150px",
				alignItems: "center",
				textAlign: "center",
				fontFamily: "helvetica",
				color: "#444",
			}}>
			{props.children}
		</div>
	);
};

const smIcons = ["facebook", "twitter", "instagram", "linkedin"];

const hwIcons = ["claw", "pumpkin", "witchHat", "smallBat"];

const xmasIcons = [
	"santaHat",
	"snowCap",
	"snowman",
	"gifts",
	"snow",
	"snowNavBar",
];

const tierIcons = ["basic", "standard", "pro"];

export const Icons = () => {
	return (
		<div style={{ display: "flex", flexWrap: "wrap" }}>
			{IconComponents.map((Icon, index) =>
				index ===
					iconNames.findIndex((iconName) => iconName === "SocialMediaIcon") ||
				index ===
					iconNames.findIndex((iconName) => iconName === "HalloweenIcon") ||
				index ===
					iconNames.findIndex((iconName) => iconName === "ChristmasIcon") ||
				index ===
					iconNames.findIndex((iconName) => iconName === "TiersIcon") ? (
					<IconContainer key={iconNames[index]}>
						<Suspense fallback={<div>Loading...</div>}>
							<div
								style={{
									display: "flex",
									gap: "0.2rem",
									flexWrap: "wrap",
									alignItems: "center",
									justifyContent: "center",
									width: "8rem",
								}}>
								{iconNames[index] === "SocialMediaIcon"
									? smIcons.map((icon, index) => (
											<Icon
												key={`${index})${icon}`}
												width={"3.2rem"}
												height={"3.2rem"}
												type={icon}
												fill="teal"
											/>
									  ))
									: iconNames[index] === "TiersIcon"
									? tierIcons.map((icon, index) => (
											<Icon
												key={`${index})${icon}`}
												width={"3.2rem"}
												height={"3.2rem"}
												tier={icon}
											/>
									  ))
									: iconNames[index] === "HalloweenIcon"
									? hwIcons.map((icon, index) => (
											<Icon
												key={`${index})${icon}`}
												width={"3.2rem"}
												height={"3.2rem"}
												type={icon}
											/>
									  ))
									: xmasIcons.map((icon, index) => (
											<Icon
												key={`${index})${icon}`}
												width={"3.2rem"}
												height={"3.2rem"}
												type={icon}
											/>
									  ))}
							</div>
						</Suspense>
						<h2>{iconNames[index]} (multiple types)</h2>
					</IconContainer>
				) : (
					<IconContainer key={iconNames[index]}>
						<Suspense fallback={<div>Loading...</div>}>
							<Icon width="64" height="64" />
						</Suspense>
						<h2>{iconNames[index]}</h2>
					</IconContainer>
				),
			)}
		</div>
	);
};

export default Icons;

export { default as FuelIcon } from "./FuelIcon";
export { default as CateringIcon } from "./CateringIcon";
export { default as EquipmentIcon } from "./EquipmentIcon";
export { default as AbandonedIcon } from "./AbandonedIcon";
export { default as AccommodationIcon } from "./AccommodationIcon";
export { default as BurgerIcon } from "./BurgerIcon";
export { default as DownloadArrowIcon } from "./DownloadArrowIcon";
export { default as StudentIcon } from "./StudentIcon";
export { default as AbatementsIcon } from "./AbatementsIcon";
export { default as BusinessIcon } from "./BusinessIcon";
export { default as DropletIcon } from "./DropletIcon";
export { default as ActionIcon } from "./ActionIcon";
export { default as CCDarkLogo } from "./CCDarkLogo";
export { default as HomeIcon } from "./HomeIcon";
export { default as OtherIcon } from "./OtherIcon";
export { default as SunIcon2 } from "./SunIcon2";
export { default as CCLightLogo } from "./CCLightLogo";
export { default as ElectricityIcon } from "./ElectricityIcon";
export { default as AlertBellIcon } from "./AlertBellIcon";
export { default as CalendarIcon } from "./CalendarIcon";
export { default as EmailIcon } from "./EmailIcon";
export { default as HouseIcon } from "./HouseIcon";
export { default as PaperIcon } from "./PaperIcon";
export { default as TeacherIcon } from "./TeacherIcon";
export { default as AnalyticsIcon } from "./AnalyticsIcon";
export { default as CarIcon } from "./CarIcon";
export { default as EyeIcon } from "./EyeIcon";
export { default as PartnerIcon } from "./PartnerIcon";
export { default as FaceIcon } from "./FaceIcon";
export { default as PenIcon } from "./PenIcon";
export { default as TickIcon } from "./TickIcon";
export { default as AppErrorIcon } from "./AppErrorIcon";
export { default as CarbonFootprintIcon } from "./CarbonFootprintIcon";
export { default as PlanIcon } from "./PlanIcon";
export { default as CarbonIcon } from "./CarbonIcon";
export { default as CardIcon } from "./CardIcon";
export { default as LPGIcon } from "./LPGIcon";
export { default as PlusIcon } from "./PlusIcon";
export { default as LaunchIcon } from "./LaunchIcon";
export { default as PolicyIcon } from "./PolicyIcon";
export { default as TreeIcon } from "./TreeIcon";
export { default as ArrowUpDownIcon } from "./ArrowUpDownIcon";
export { default as ChangeIcon } from "./ChangeIcon";
export { default as PostponedIcon } from "./PostponedIcon";
export { default as TreesIcon } from "./TreesIcon";
export { default as CircleArrowIcon } from "./CircleArrowIcon";
export { default as FileIcon } from "./FileIcon";
export { default as FlightIcon } from "./FlightIcon";
export { default as LearningIcon } from "./LearningIcon";
export { default as RecyclingBinIcon } from "./RecyclingBinIcon";
export { default as UploadCloudIcon } from "./UploadCloudIcon";
export { default as ClockIcon } from "./ClockIcon";
export { default as FlowerIcon } from "./FlowerIcon";
export { default as UsageIcon } from "./UsageIcon";
export { default as AustraliaIcon } from "./AustraliaIcon";
export { default as CloudIcon } from "./CloudIcon";
export { default as LightBulbIcon } from "./LightBulbIcon";
export { default as ReportIcon } from "./ReportIcon";
export { default as UserIcon } from "./UserIcon";
export { default as CloudShareIcon } from "./CloudShareIcon";
export { default as LinkIcon } from "./LinkIcon";
export { default as RoomIcon } from "./RoomIcon";
export { default as LoadingSunIcon } from "./LoadingSunIcon";
export { default as SchoolIcon } from "./SchoolIcon";
export { default as WarningIcon } from "./WarningIcon";
export { default as CommunityIcon } from "./CommunityIcon";
export { default as Logo } from "./Logo";
export { default as SearchIcon } from "./SearchIcon";
export { default as WasteIcon } from "./WasteIcon";
export { default as CompareIcon } from "./CompareIcon";
export { default as FunIcon } from "./FunIcon";
export { default as MeasureIcon } from "./MeasureIcon";
export { default as SettingsIcon } from "./SettingsIcon";
export { default as WaterIcon } from "./WaterIcon";
export { default as CompletedIcon } from "./CompletedIcon";
export { default as MissingIcon } from "./MissingIcon";
export { default as ShareIcon } from "./ShareIcon";
export { default as BoltIcon } from "./BoltIcon";
export { default as GasIcon } from "./GasIcon";
export { default as WindmillIcon } from "./WindmillIcon";
export { default as CostIcon } from "./CostIcon";
export { default as GlobeIcon } from "./GlobeIcon";
export { default as MoveArrowIcon } from "./MoveArrowIcon";
export { default as CrossIcon } from "./CrossIcon";
export { default as NoCostIcon } from "./NoCostIcon";
export { default as SolarIcon } from "./SolarIcon";
export { default as NotSupportedIcon } from "./NotSupportedIcon";
export { default as SolarPanelIcon } from "./SolarPanelIcon";
export { default as NotepadIcon } from "./NotepadIcon";
export { default as DistanceIcon } from "./DistanceIcon";
export { default as StarIcon } from "./StarIcon";
export { default as BureaucraticIcon } from "./BureaucraticIcon";
export { default as DollarIcon } from "./DollarIcon";
export { default as OffsetIcon } from "./OffsetIcon";
export { default as OffsetGradientIcon } from "./OffsetGradientIcon";
export { default as StatusCircleIcon } from "./StatusCircleIcon";
export { default as GiftIcon } from "./GiftIcon";
export { default as OutdoorsIcon } from "./OutdoorsIcon";
export { default as WorldIcon } from "./WorldIcon";
export { default as SocialMediaIcon } from "./SocialMediaIcon";
export { default as OnboardingIcon } from "./OnboardingIcon";
export { default as BottleIcon } from "./BottleIcon";
export { default as BoxIcon } from "./BoxIcon";
export { default as WeightIcon } from "./WeightIcon";
export { default as PlayIcon } from "./PlayIcon";
export { default as EditIcon } from "./EditIcon";
export { default as AubreyIcon } from "./AubreyIcon";
export { default as ChevronLeftIcon } from "./ChevronLeftIcon";
export { default as ChevronRightIcon } from "./ChevronRightIcon";
export { default as FilterIcon } from "./FilterIcon";
export { default as ClipboardIcon } from "./ClipboardIcon";
export { default as HalloweenIcon } from "./HalloweenIcon";
export { default as SharehubIcon } from "./SharehubIcon";
export { default as TransportIcon } from "./TransportIcon";
export { default as SparklesIcon } from "./SparklesIcon";
export { default as UpgradeIcon } from "./UpgradeIcon";
export { default as PaywallSharehubIcon } from "./PaywallSharehubIcon";
export { default as PaywallReportIcon } from "./PaywallReportIcon";
export { default as CertificateIcon } from "./CertificateIcon";
export { default as ChristmasIcon } from "./ChristmasIcon";
export { default as ArrowIcon } from "./ArrowIcon";
export { default as BookIcon } from "./BookIcon";
export { default as CompletionReportToDo } from "./CompletionReportToDo";
export { default as CompletionReportDone } from "./CompletionReportDone";
export { default as EmptyBoxIcon } from "./EmptyBoxIcon";
export { default as KeyIcon } from "./KeyIcon";
