import makeError from "../MakeError";

/**
 * Networking function for PUT requests that use multipart/form-data
 * @param url The url for the request
 * @param reqBody Data to be send in the request body
 */
const putReq = async <T>(
	url: string,
	reqBody: RequestInit["body"],
	isMultiPart?: boolean,
	version?: number,
	headers?: { name: string; value: string }[],
): Promise<T> => {
	try {
		let requestHeaders: Headers | undefined;
		if (!isMultiPart) {
			requestHeaders = new Headers();
			requestHeaders.set("Content-Type", "application/json");
			version &&
				requestHeaders.set("Accept", `application/vnd.api.v${version}+json`);
			headers?.forEach((header) =>
				requestHeaders?.set(header.name, header.value),
			);
		}
		const response = await fetch(url, {
			method: "PUT",
			credentials: "include",
			body: reqBody,
			...(requestHeaders
				? {
						headers: requestHeaders,
				  }
				: {}),
		});

		const responseData = isMultiPart ? response : await response.json();
		if (!response.ok) {
			throw makeError(responseData.error);
		}
		return responseData;
	} catch (error) {
		throw makeError(error);
	}
};

export default putReq;
