import React from "react";

// Local
import DefaultConfirmEmailChangePage from "./Components/DefaultConfirmEmailChangePage";

const VerifyEmailPage = () => {
	return <DefaultConfirmEmailChangePage />;
};

export default VerifyEmailPage;
