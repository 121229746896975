import styled from "@emotion/styled";

export const Page = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	padding: "5rem 0",
}));

export const Section = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	height: "100%",
}));

export const ButtonContainer = styled.div(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	"& > p": {
		...theme.webFonts.F4SemiBold,
		margin: "0 2rem",
	},
}));
