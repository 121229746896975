import styled from "@emotion/styled";
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";
import { Link } from "react-router-dom";

// Styles
export const FullBox = styled.div(() => ({
	position: "relative",
	minWidth: "calc(50% - 15px)",
	width: "100%",
	[MediaQueries[0]]: {
		width: "fit-content",
	},
}));

export const ImagePreview = styled(Link)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	gap: "2rem",
	border: `1px solid ${theme.colors.D5}`,
	borderRadius: "0.8rem",
	padding: "2rem",
	textDecoration: "none",
	[MediaQueries[3]]: {
		padding: "2rem 3rem",
		gap: "3rem",
	},
}));

export const ImageBox = styled.div<{ marginBottom?: boolean }>(
	({ marginBottom = false }) => ({
		width: "12rem",
		marginBottom: marginBottom ? "2rem" : "0",
	}),
);

export const ImageDetails = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
}));

export const ImageName = styled.p(({ theme }) => ({
	...theme.webFonts.F5,
	color: theme.colors.D2,
	margin: "0",
	maxWidth: "15ch",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis",
	[MediaQueries[3]]: {
		maxWidth: "22ch",
	},
}));

export const ImageSize = styled.p(({ theme }) => ({
	...theme.webFonts.F6,
	color: theme.colors.D4,
	margin: "0",
}));

export const CloseBox = styled.div(({ theme }) => ({
	position: "absolute",
	top: "1.5rem",
	right: "1.5rem",
	boxShadow: theme.shadow,
	borderRadius: "50%",
}));

export const ButtonBox = styled.div(() => ({
	display: "flex",
	justifyContent: "end",
	gap: "1.5rem",
	marginTop: "4.4rem",
}));

export const FlexColumn = styled.div({
	display: "flex",
	flexDirection: "column",
	gap: "1rem",
	alignItems: "start",
});
