import styled from "@emotion/styled";

// CC
import TextInput from "../CCW-TextInput";

// Types
import type { StyledProps } from "./types";

export const Container = styled.div(({ theme }) => ({
	position: "relative",
	"& > span:first-of-type": {
		position: "absolute",
		left: "9px",
		bottom: "12px",
		"> svg > path": {
			fill: theme.colors.D3,
		},
	},
}));

export const Input = styled(TextInput)<StyledProps>(({ dollar }) => ({
	padding: dollar ? "0.6rem 2.3rem" : "0.6rem 1.5rem",
}));
