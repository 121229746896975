import useSession from "Hooks/UseSession";

// Types
import Platform from "Types/Platform";

/**
 * Value hook
 * Return the current platform
 */
const usePlatform = (): Platform => {
	const [, , , { activeAccount }] = useSession();

	return activeAccount?.accountType as Platform;
};

export default usePlatform;
