import React, { PropsWithChildren } from "react";
import { useField } from "formik";
import { MultiValue, SingleValue } from "react-select";

// Components
import InputLabel from "../CCW-InputLabel";
import FormGroup from "../CCW-FormGroup";

// Styles
import { StyledSelect, Error, Label } from "./styles";

// Types
import { FIGDropDownInputProps, SelectOption } from "./types";
import IconProps from "Types/IconProps";

const getValuesFromSelection = <T,>(
	selection: MultiValue<SelectOption<T>> | SingleValue<SelectOption<T>>,
): T[] | T | undefined => {
	if (!selection) {
		return undefined;
	} else if ("value" in selection) {
		return selection.value;
	} else {
		return selection.map((selectedOption) => selectedOption.value);
	}
};

/**
 * @deprecated Please use react-select's Select component instead
 * This helper component is to convert the "old" API for dropdowns to the new react-select
 * this saves a massive, repetitive refactor of all the places the old dropdown was called.
 * It's essentially just passing on the props with renaming and finding the right option to display
 */
export const DropDownInputConverter = ({
	id,
	name,
	options,
	onSelect,
	inlineLabel,
	value,
	disabled,
	placeholder,
	isMultiple,
	plain,
	className,
	error,
	handleSearch,
	isIconOnly,
	isMenuRightAlign,
	icon: Icon,
	isSearchable = true,
}: {
	id?: string;
	name: string;
	options: { label: string; value: string }[];
	inlineLabel?: string;
	value?: string;
	disabled?: boolean;
	placeholder?: string;
	plain?: boolean;
	className?: string;
	error?: string;
	handleSearch?: (searchValue: string) => void;
	isIconOnly?: boolean;
	isMenuRightAlign?: boolean;
	icon?: React.FC<IconProps>;
	isSearchable?: boolean;
} & (
	| {
			isMultiple: true;
			onSelect: (name: string, value: string[]) => void;
	  }
	| {
			isMultiple?: false | undefined;
			onSelect: (name: string, value: string) => void;
	  }
)) => {
	return (
		<>
			{Icon && (
				<Label htmlFor={id || name}>
					<Icon height="16" width="16" />
				</Label>
			)}
			<StyledSelect
				className={(plain ? "plain" : "") + " " + className}
				inputId={id || name}
				isDisabled={disabled}
				options={options}
				placeholder={placeholder}
				openMenuOnClick={true}
				openMenuOnFocus={true}
				onChange={(selectedOption) => {
					const values = getValuesFromSelection(selectedOption);
					if (isMultiple) {
						onSelect(name, values as string[]);
					} else {
						onSelect(name, values as string);
					}
				}}
				onInputChange={(newSearch: string) => {
					handleSearch && handleSearch(newSearch);
					return newSearch;
				}}
				value={options.find((option) => option.value === value) || null}
				classNamePrefix="select"
				isSearchable={isSearchable}
				// This is a hack to stop filtering (always true) when not filtering locally
				filterOption={!handleSearch ? undefined : () => true}
				isMulti={isMultiple}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				inlineLabel={inlineLabel}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				isIconOnly={isIconOnly}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				isMenuRightAlign={isMenuRightAlign}
			/>
			{error && <Error>{error}</Error>}
		</>
	);
};

const FIGDropDownInput = <T,>({
	name,
	id,
	label,
	required,
	options,
	isPlain,
	isMultiple,
	isDisabled,
	placeholder,
	onSearchChange,
	isFilterLocally = true,
	isFormGroup = true,
	isErrorHidden,
	className,
}: FIGDropDownInputProps<T>) => {
	const [field, meta, helpers] = useField<T | T[] | undefined>({ name });

	// Construct the selected options from the field values and the options array
	const selected: SelectOption<T> | SelectOption<T>[] | null = (() => {
		if (Array.isArray(field.value)) {
			// If it is an array of values then construct an array out of the options
			return field.value.map(
				(value) =>
					options.find(
						(option) => JSON.stringify(option.value) === JSON.stringify(value),
					) as SelectOption<T>,
			);
		} else if (field.value) {
			return options.find(
				(option) =>
					JSON.stringify(option.value) === JSON.stringify(field.value),
			) as SelectOption<T>;
		} else {
			return null;
		}
	})();

	const error = meta.touched && meta.error;

	const Wrapper = isFormGroup
		? FormGroup
		: ({ children }: PropsWithChildren) => <div>{children}</div>;

	return (
		<Wrapper className={className}>
			{label && (
				<InputLabel htmlFor={id || name} text={label} required={required} />
			)}
			<StyledSelect
				className={isPlain ? "plain" : ""}
				inputId={id || name}
				value={selected}
				onChange={(
					newSelection:
						| Readonly<SelectOption<T>>
						| Readonly<SelectOption<T>[]>
						| null,
				) => {
					helpers.setValue(getValuesFromSelection(newSelection));
				}}
				placeholder={placeholder}
				options={options}
				isMulti={isMultiple}
				isDisabled={isDisabled}
				onInputChange={(newSearch: string) =>
					onSearchChange && onSearchChange(newSearch)
				}
				isSearchable={isFilterLocally || !!onSearchChange}
				// This is a hack to stop filtering (always true) when not filtering locally
				filterOption={isFilterLocally ? undefined : () => true}
				classNamePrefix="select"
			/>
			{!!error && !isErrorHidden && <Error>{error}</Error>}
		</Wrapper>
	);
};

export default FIGDropDownInput;
