import React from "react";

// Styles
import { Container } from "./styles";

// Types
import MaxCharacterHintProps from "./types";

const MaxCharacterHint: React.FC<MaxCharacterHintProps> = ({
	charactersRemaining,
	...props
}) => {
	return (
		<Container {...props} isError={(charactersRemaining as number) < 0}>
			{Math.abs(charactersRemaining as number)} characters{" "}
			{(charactersRemaining as number) < 0 ? "over limit" : "remaining"}
		</Container>
	);
};

export default MaxCharacterHint;
