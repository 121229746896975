import styled from "@emotion/styled";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

// Styles
export const Container = styled.div(({ theme }) => ({
	...theme.webFonts.h3,
	display: "inline-block",
	background: theme.gradientsColors.Standard,
	color: theme.colors.grey50,
	padding: "0.8rem 2.8rem",
	overflow: "hidden",
	borderBottomRightRadius: "1.6rem",
	marginLeft: "-1.5rem", // A little hacky... This is to offset the CCWContainer's padding
	[MediaQueries[0]]: {
		padding: "0.8rem 3.6rem",
		borderBottomRightRadius: "2rem",
		borderBottomLeftRadius: "2rem",
		marginLeft: "unset",
	},
}));
