import { useEffect, useState } from "react";

const useDebouncer = (interval: number) => {
	const [timerId, setTimerId] = useState<undefined | number>();

	useEffect(() => {
		return () => {
			timerId != null && clearTimeout(timerId);
		};
	});

	return (callback: () => void) => {
		if (timerId) clearTimeout(timerId);
		const id = window.setTimeout(() => callback(), interval);
		setTimerId(id);
	};
};

export default useDebouncer;
