import styled from "@emotion/styled";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Row = styled.div(() => ({
	[MediaQueries[0]]: {
		display: "flex",
		flexDirection: "row",
		gap: "30px",
		"> *": {
			width: "50%",
		},
	},
}));
export default Row;
