import React from "react";

// Styles
import { MainBar, InnerBar } from "./styles";

// Types
import { ProgressBarProps } from "./types";

const ProgressBar: React.FC<ProgressBarProps> = ({
	now,
	color = undefined,
	backgroundColor,
	isRoundedEdges,
	height,
	...props
}) => {
	return (
		<MainBar
			isRoundedEdges={isRoundedEdges}
			color={backgroundColor}
			height={height}
			role="progressbar"
			{...props}>
			<InnerBar
				progress={now}
				isRoundedEdges={isRoundedEdges}
				color={color}></InnerBar>
		</MainBar>
	);
};

ProgressBar.defaultProps = {
	text: "Some text",
};

export default ProgressBar;
