import styled from "@emotion/styled";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

// CC
import CCButton from "CCW-Components/CCW-CCButton";

// Styles
export const Container = styled.div(() => ({}));

export const Row = styled.div(({ theme }) => ({
	display: "flex",
	justifyContent: "flex-start",
	alignItems: "stretch",
	background: theme.colors.grey200,
	borderRadius: "0.8rem",
	border: `1px solid ${theme.colors.D5}`,
}));

export const RowHeading = styled.div<{ shortHeading?: boolean }>(
	({ theme, shortHeading }) => ({
		background: theme.colors.primary,
		display: "flex",
		flex: `0 1 ${shortHeading ? "14rem" : "20rem"}`,
		padding: "1.6rem 2rem",
		alignItems: "center",
		overflow: "hidden",
		borderRadius: "0.7rem 0 0 0.7rem",
		[MediaQueries[1]]: {
			flex: `0 1 ${shortHeading ? "14rem" : "26rem"}`,
		},
	}),
);

export const RowHeadingText = styled.div<{ isUpdated?: boolean }>(
	({ theme, isUpdated }) => ({
		...theme.webFonts.F5Bold,
		color: isUpdated ? theme.colors.White70 : theme.colors.White,
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		textDecoration: isUpdated ? "line-through" : "none",
	}),
);

export const RowContent = styled.div(() => ({
	display: "flex",
	flexWrap: "wrap",
	padding: "1rem",
	gap: "1.2rem",
	flexGrow: 1,
	justifyContent: "start",
	alignItems: "center",
}));

export const DataBox = styled.div<{ isUpdated?: boolean }>(
	({ theme, isUpdated }) => ({
		...theme.webFonts.F6,
		display: "none",
		justifyContent: "space-between",
		background: theme.colors.White,
		color: isUpdated ? theme.colors.Grape300 : theme.colors.D1,
		fontWeight: isUpdated ? "bold" : "normal",
		padding: "0.6rem 1.2rem",
		boxShadow: isUpdated ? `inset 0 0 0 1px ${theme.colors.Grape300}` : "none",
		borderRadius: "0.3rem",
		gap: "0.8rem",
		whiteSpace: "nowrap",
		userSelect: "none",
		"& > span": {
			...theme.webFonts.bold,
		},
		[MediaQueries[1]]: {
			...theme.webFonts.F6,
			display: "flex",
			fontWeight: isUpdated ? "bold" : "normal",
		},
	}),
);

export const ActionTag = styled(DataBox)<{ isUpdated?: boolean }>(
	({ theme, isUpdated }) => ({
		display: "flex",
		background: isUpdated ? theme.colors.D3 : theme.gradientsColors.Rotated90,
		color: theme.colors.White,
		gap: "1rem",
		boxShadow: "none",
	}),
);

export const EditButton = styled(CCButton)(({ theme }) => ({
	...theme.webFonts.F6Bold,
	display: "none",
	margin: "0 0 0 auto",
	padding: "0 3rem",
	minHeight: "3.8rem",
	[MediaQueries[2]]: {
		...theme.webFonts.F6Bold,
		display: "initial",
	},
}));

export const ActionTagPopupContainer = styled.div(() => ({
	position: "relative",
	cursor: "pointer",
	display: "inline-block",
}));

export const ActionTagPopup = styled.div<{ showPopup: boolean }>(
	({ theme, showPopup }) => ({
		...theme.webFonts.F6Bold,
		display: "flex",
		position: "absolute",
		zIndex: 1,
		flexDirection: "column",
		alignItems: "center",
		top: "3rem",
		left: "0.8rem",
		gap: "1rem",
		visibility: showPopup ? "visible" : "hidden",
		backgroundColor: theme.colors.White,
		color: theme.colors.D2,
		border: `2px solid ${theme.colors.D2}`,
		borderRadius: "0.4rem",
		padding: "1rem",
		boxShadow: theme.shadow,
		"::after": {
			content: "''",
			position: "absolute",
			top: "-1rem",
			left: "3rem",
			border: "5px solid",
			borderColor: "transparent transparent white transparent",
		},
	}),
);

export const PopupButtonContainer = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-start",
	gap: "1rem",
}));
