// Helpers
import patchReq from "Helpers/NetworkingHelpers/PatchReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import { JSONApi, DataHelperResponse, Update } from "Types/JSONApi";
import User, { UpdateUser } from "Types/User";
import Account from "Types/Account";

type Relationships = {
	accounts: {
		links: { related: string };
		data: { type: "accounts"; id: string }[];
	};
};

type Related = {
	accounts: Account[];
};

type Response = JSONApi<User, Relationships, undefined, Related>;

/**
 * @async
 * Updates a user's details
 * @param userID The ID of the user getting updated
 * @returns a DataHelperResponse tuple of User, Related, and the full response
 * @throws Networking error
 */
const updateUser = async (
	userID: string,
	updatedUserDetails: UpdateUser,
): Promise<DataHelperResponse<User, undefined, Response>> => {
	const requestBody: Update<Partial<UpdateUser>> = {
		data: {
			type: "users",
			id: userID,
			attributes: updatedUserDetails,
			relationships: undefined,
			meta: undefined,
		},
	};
	const response = await patchReq<Response>(
		`${import.meta.env.VITE_BASE_URL}executive/users/${userID}`,
		requestBody,
		3,
	);

	return transformAPIResponse(response, {});
};

export default updateUser;
