import React from "react";

// Types
import ContainerProps from "./types";

const Container: React.FC<ContainerProps> = ({ children, ...props }) => (
	<div
		className="ccw-container container mx-auto max-w-screen-xl px-6"
		{...props}>
		{children}
	</div>
);

export default Container;
