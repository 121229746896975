import React from "react";

// Components
import DefaultMissing404 from "./Components/DefaultMissing404";

const Missing404Page = () => {
	return <DefaultMissing404 />;
};

export default Missing404Page;
