import React from "react";

// Types
import type IconProps from "Types/IconProps";

const MeasureIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "measure-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "32px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M2 8C2 6.34315 3.34315 5 5 5H7C8.65685 5 10 6.34315 10 8V29C10 30.6569 8.65685 32 7 32H5C3.34315 32 2 30.6569 2 29V8Z"
				fill={fill != null ? fill : "#828282"}
			/>
			<path
				d="M12 16C12 14.3431 13.3431 13 15 13H17C18.6569 13 20 14.3431 20 16V29C20 30.6569 18.6569 32 17 32H15C13.3431 32 12 30.6569 12 29V16Z"
				fill={fill != null ? fill : "#828282"}
			/>
			<path
				d="M22 27C22 25.3431 23.3431 24 25 24H27C28.6569 24 30 25.3431 30 27V29C30 30.6569 28.6569 32 27 32H25C23.3431 32 22 30.6569 22 29V27Z"
				fill={fill != null ? fill : "#828282"}
			/>
		</svg>
	);
};

export default MeasureIcon;
