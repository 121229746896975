import styled from "@emotion/styled";

// CC
import CCButton from "../CCW-CCButton";

import { StyledProps } from "./types";

export const Button = styled(CCButton)<StyledProps>(
	({ load, theme, variant }) => {
		return {
			position: "relative",
			background:
				load && (variant === "primary" || variant === "washed")
					? `${theme.colors.White}`
					: variant,

			alignItems: "center",
			justifyContent: "center",
			display: "flex",
			minWidth: "10rem",
			padding: "1rem 2rem 1.1rem 2rem",
		};
	},
);

export const Spinner = styled.div(() => ({
	position: "absolute",
	height: "3.2rem",
	"@keyframes load-spin-button": {
		"0%": {
			transform: "rotate(0deg)",
		},
		"100%": {
			transform: "rotate(360deg)",
		},
	},
	animation: "load-spin-button 0.8s linear infinite",
}));

export const Text = styled.span<{ visible: boolean }>(({ visible }) => ({
	visibility: visible ? "visible" : "hidden",
}));
