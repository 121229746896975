// Helpers
import postReq from "Helpers/NetworkingHelpers/PostReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import type { JSONApi, Create, DataHelperResponse } from "Types/JSONApi";
import Utility, { CreateUtility } from "Types/Utility";

type Response = JSONApi<Utility, undefined, undefined, undefined, undefined>;

/**
 * @async
 * Create a new Utility provider in the admin panel
 * @param utilityToCreate Data required to create the new utility
 * @throws Networking error
 */

const createUtility = async (
	utilityToCreate: CreateUtility,
): Promise<DataHelperResponse<Utility, undefined, Response>> => {
	const requestBody: Create<Partial<CreateUtility>> = {
		data: {
			type: "utilities",
			attributes: utilityToCreate,
			relationships: undefined,
			meta: undefined,
		},
	};

	const response = await postReq<
		JSONApi<Utility, undefined, undefined, undefined, undefined>
	>(
		`${import.meta.env.VITE_BASE_URL}measure/utilities`,
		requestBody,
		1,
		undefined,
		undefined,
		"JSONAPI",
	);
	const [utility, related, fullResponse] = transformAPIResponse(response, {});
	return [utility, related, fullResponse];
};
export default createUtility;
