import styled from "@emotion/styled";
// CC
import CCButton from "../CCW-CCButton";

// Styles
export const Container = styled.div(({ theme }) => ({
	...theme.webFonts.F6,
	color: theme.colors.primary,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	overflow: "hidden",
	borderRadius: "0.8rem",
	padding: "1rem",
	gap: "1rem",
}));

export const PageNavButton = styled(CCButton)(({ theme, disabled }) => ({
	color: disabled ? theme.colors.grey200 : theme.colors.grey400,
	boxShadow: "none",
	margin: 0,
	padding: "0.2rem",
	minHeight: "unset",
	background: "transparent",
	":hover": !disabled
		? {
				color: theme.colors.grey800,
		  }
		: {},
}));

// Custom text-only button to fit and behave in low-profile pagination nav bar
export const ActionButton = styled.div(({ theme }) => ({
	...theme.webFonts.F5Bold,
	color: theme.colors.primary,
	display: "inline-flex",
	alignItems: "center",
	cursor: "pointer",
	userSelect: "none",
	padding: "0.3rem 1.4rem",
	borderRadius: "0.8rem",
	":hover, :focus": {
		backgroundColor: theme.colors.primary,
		color: theme.colors.White,
		border: "none",
	},
	"&:active": {
		transform: "translateY(2px) scale(0.98)",
	},
}));

export const PagePicker = styled.div(({ theme }) => ({
	display: "flex",
	alignItems: "center",
}));

export const PageNumber = styled.div<{
	isActive?: boolean;
	isClickable?: boolean;
}>(({ theme, isActive, isClickable }) => ({
	...(isActive ? theme.webFonts.F5Bold : theme.webFonts.F6),
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	color: isActive ? theme.colors.grey50 : theme.colors.grey500,
	background: isActive ? theme.colors.Blue400 : "none",
	width: "3.2rem",
	height: "3.2rem",
	borderRadius: "50%",
	cursor: isClickable ? "pointer" : "default",
}));

export const FlexColumn = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "stretch",
	gap: "0.2rem",
}));

export const PerPageBox = styled.div(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "stretch",
	gap: "1rem",
	...theme.webFonts.F7,
	color: theme.colors.grey500,
	alignSelf: "flex-end",
}));

export const PerPageOptions = styled.div(({ theme }) => ({
	display: "flex",
	overflow: "hidden",
	borderRadius: "0.6rem",
	border: `0.1rem solid ${theme.colors.grey200}`,
	gap: "0.1rem",
	background: theme.colors.grey200,
}));

export const PerPageOption = styled.button<{ isActive: boolean }>(
	({ theme, isActive }) => ({
		...(isActive && theme.webFonts.bold),
		color: isActive ? theme.colors.grey50 : theme.colors.grey500,
		background: isActive ? theme.colors.primary : theme.colors.grey50,
		border: "none",
		padding: "1rem",
	}),
);
