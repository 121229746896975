import styled from "@emotion/styled";
import ProfileImage from "../CCW-ProfileImage";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Padding = styled.div({
	padding: "0 0.5rem 0.5rem",
});

export const Menu = styled.div(({ theme }) => ({
	background: theme.colors.White,
	display: "block",
	borderRadius: "0 0 0 2rem",
	boxShadow: theme.shadow,
	minWidth: "33.5rem",
	overflow: "hidden",
	paddingBottom: "0.4rem",
	[MediaQueries[1]]: {
		borderRadius: "0 0 2rem 2rem",
	},
}));

export const MenuContainer = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	"& a": {
		textDecoration: "none",
	},
}));

export const EntityContainer = styled.div(({ theme }) => ({
	width: "100%",
	display: "flex",
	justifyContent: "start",
	alignItems: "center",
	gap: "1rem",
	padding: "1.6rem 2.4rem",
	marginBottom: "1rem",
	background: theme.colors.primary,
}));

export const Column = styled.div({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
});

export const EntityName = styled.span(({ theme }) => ({
	color: theme.colors.White70,
	...theme.webFonts.F6,
}));

export const SiteName = styled.span(({ theme }) => ({
	color: theme.colors.White,
	...theme.webFonts.F5,
	...theme.webFonts.bold,
}));

export const Divider = styled.div(({ theme }) => ({
	borderBottom: "1px solid",
	borderColor: theme.colors.Black10,
	width: "100%",
	height: "1px",
	margin: "1rem 0",
}));

/**
 * Sorry about this. The HTML is messy (no other way) so the CSS is a bit repetitive
 */
export const LinkStyles = styled.div<{ isLoud?: boolean }>(
	({ theme, isLoud }) => ({
		...theme.webFonts.F5,
		color: theme.colors.D3,
		"& a": {
			color: theme.colors.D3,
			"&:active": {
				...theme.webFonts.bold,
				color: theme.colors.Blue500,
				"& svg": {
					"& path, g > path": {
						fill: theme.colors.Blue500,
					},
				},
			},
		},
		"&:hover, :focus": {
			background: theme.colors.Blue500,
			color: theme.colors.White,
			"& a": {
				color: theme.colors.White,
			},
			"& svg": {
				"& path, g > path": {
					fill: theme.colors.White,
				},
			},
		},
		...(isLoud
			? {
					borderRadius: "0.4rem 0 0 0.4rem",
					overflow: "hidden",
					position: "relative",
					margin: "0.6rem 0 0.6rem 1.2rem",
					transition: "background-color 1s",
					background: theme.gradientsColors.Standard,
					"& a": {
						color: theme.colors.White,
						"& path, g > path": {
							fill: theme.colors.White,
						},
					},
					"&:hover, :focus": {
						background: theme.gradientsColors.Rotated180,
					},
					"& a div": {
						marginLeft: "-1.2rem",
					},
					":before, :after": {
						content: "''",
						width: "2rem",
						position: "absolute",
						top: 0,
						left: "-20%",
						height: "150%",
						transform: "skew(20deg)",
						opacity: 0.4,
						backgroundColor: "pink",
						margin: "-2rem 0",
						transition: "all ease-in-out 0.8s",
						animation: "shimmer 6s infinite",
						"@keyframes shimmer": {
							"40%": {
								transform: "skew(20deg) translate(-2rem) scaleX(1)",
							},
							"60%": {
								transform: "skew(25deg) translate(47rem) scaleX(1.5)",
							},
							"80%": {
								transform: "skew(25deg) translate(47rem) scaleX(1.5)",
							},
							"100%": {
								transform: "skew(20deg) translate(-2rem) scaleX(1)",
							},
						},
					},
					":after": {
						animationDelay: "0.2s",
						width: "5rem",
					},
			  }
			: {}),
	}),
);

export const MenuButton = styled.div({
	padding: "1rem 2.4rem",
	cursor: "pointer",
	display: "flex",
	alignItems: "center",
	gap: "1.2rem",
});

export const SmallProfileImage = styled(ProfileImage)({
	width: "4rem",
	height: "4rem",
});

export const SwitchSiteBox = styled.div({
	cursor: "pointer",
	marginLeft: "auto",
});
