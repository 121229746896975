import React from "react";

// CC
import Layout from "CCW-Components/CCW-Layout";

// Styles
import { Container, Welcome } from "./styles";

// Types
import type LoginHeaderProps from "./types";

const LoginHeader: React.FC<LoginHeaderProps> = ({ referrer }) => {
	return (
		<Container>
			<Layout
				referrerImages={
					referrer && {
						logoURL: referrer?.logoURL,
						bannerURL: referrer?.bannerURL,
					}
				}
				referrerName={referrer?.name}>
				<div className="mx-auto max-w-4xl">
					<Welcome isHoliday={import.meta.env.VITE_HOLIDAY === "halloween"}>
						<span className="start">Welcome back,</span> thank you for
						continuing your journey to helping
						<span className="end"> save the environment</span>
					</Welcome>
				</div>
			</Layout>
		</Container>
	);
};

export default LoginHeader;
