import styled from "@emotion/styled";

export const Layout = styled.div(({ theme }) => ({
	display: "grid",
	// Make a grid with two rows, first for the navbar that has auto
	// height, second for the main, which takes the remaining space
	gridTemplateRows: "auto 1fr",
	overflow: "hidden",
	maxHeight: "100%",
	height: "100%",
	background: theme.colors.Blueberry50,
	"> main": {
		overflow: "auto",
	},
}));
