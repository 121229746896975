import styled from "@emotion/styled";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

const FormSubHeading = styled.p<{ centerOnMobile?: boolean }>(
	({ theme, centerOnMobile }) => ({
		...theme.webFonts.F7,
		...theme.webFonts.italic,
		color: theme.colors.grey500,
		margin: "0 0 1.8rem",
		padding: 0,
		textAlign: centerOnMobile ? "center" : "unset",
		[MediaQueries[0]]: {
			textAlign: "unset",
		},
	}),
);

export default FormSubHeading;
