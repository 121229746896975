import { useLayoutEffect, useState } from "react";
// Helpers
import { breakpoints } from "Helpers/WebUIHelpers/Breakpoints";

/**
 * Listen to the window size and report updates
 */
const useBreakpoints = () => {
	// State
	const [width, setWidth] = useState(0);

	// Hooks
	useLayoutEffect(() => {
		const updateWidth = () => {
			const filterBreakpoints = breakpoints.filter(
				(breakpoint) => breakpoint <= window.innerWidth,
			);
			setWidth(
				filterBreakpoints.length === 0
					? 0
					: filterBreakpoints[filterBreakpoints.length - 1],
			);
		};
		window.addEventListener("resize", updateWidth);
		updateWidth();
		return () => window.removeEventListener("resize", updateWidth);
	}, []);

	return width;
};

export default useBreakpoints;
