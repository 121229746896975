import styled from "@emotion/styled";

import breakpoints from "Helpers/WebUIHelpers/Breakpoints/";

import { StyledProps } from "./types";

export const LinkContainer = styled.div<StyledProps>(({ theme, selected }) => ({
	height: "4rem",
	maxWidth: "15.1rem",
	display: "flex",
	alignItems: "center",
	"& a": {
		gap: "1rem",
		...theme.webFonts.F6Bold,
		color: selected ? theme.colors.secondary : theme.colors.D3,
		textDecoration: "none",
		display: "flex",
		alignItems: "center",
		width: "100%",
		height: "100%",
	},
	"& a > svg > path": {
		fill: selected ? theme.colors.secondary : theme.colors.D3,
	},
	"& a > svg > rect": {
		fill: selected ? theme.colors.primary : theme.colors.D3,
	},
	"&: hover, :focus": {
		"& a": {
			color: theme.colors.primary,
		},
		"& a > svg > path": {
			fill: theme.colors.primary,
		},
		"& a > svg > rect": {
			fill: theme.colors.primary,
		},
	},
	"&: active": {
		"& svg > path": {
			transform: "scale(0.90, 0.90)",
		},
		"& svg > rect": {
			transform: "scale(0.90, 0.90)",
		},
		[breakpoints[2]]: {
			transform: "scale(0.95, 0.95)",
			"& svg > path": {
				transform: "none",
			},
			"& svg > rect": {
				transform: "none",
			},
		},
	},
}));

export const Text = styled.span(() => ({
	display: "none",
	[breakpoints[2]]: {
		display: "block",
	},
}));

export const Item = styled.div(() => ({
	position: "relative",
	"& > svg": {
		animation: "fade 0.5s ease-in-out",
		"@keyframes fade": {
			"0%": {
				opacity: 0.6,
				transform: "scale(0.1)",
			},
			"100%": {
				opacity: 1,
				transform: "scale(1)",
			},
		},
		position: "absolute",
	},
	"#halloween": {
		left: "22px",
	},
	"#xmas": {
		zIndex: 0,
		left: "8px",
		top: "-10px",
	},
}));
