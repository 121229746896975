import React from "react";

// Types
import type IconProps from "Types/IconProps";

const WarningIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "warning-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "32px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM14.5137 17.8652L14.2881 7.48828H16.8984L16.6836 17.8652H14.5137ZM17.2529 21.6143C17.2529 22.5918 16.5977 23.2471 15.6094 23.2471C14.6104 23.2471 13.9443 22.5918 13.9443 21.6143C13.9443 20.6152 14.6104 19.96 15.6094 19.96C16.5977 19.96 17.2529 20.6152 17.2529 21.6143Z"
				fill={fill || "currentColor"}
			/>
		</svg>
	);
};

export default WarningIcon;
