import styled from "@emotion/styled";

export const LabelContainer = styled.div<{ defaultMargin?: boolean }>(
	({ defaultMargin }) => ({
		display: "flex",
		justifyContent: "space-between",
		marginBottom: defaultMargin ? "0.8rem" : "0",
		gap: "0.8rem",
	}),
);

export const ToolContainer = styled.div(() => ({
	display: "flex",
	alignItems: "center",
}));

export const Label = styled.label(({ theme }) => ({
	...theme.webFonts.F6Bold,
	color: theme.colors.D1,
}));

export const Required = styled.span(({ theme }) => ({
	...theme.webFonts.F6Bold,
	color: theme.colors.D1,
}));
