import React from "react";

// Styles
import {
	Container,
	CheckLabel,
	CheckInput,
	Box,
	BoxFill,
	Error,
} from "./styles";

// Types
import { CheckBoxProps } from "./types";

export const CheckBox: React.FC<CheckBoxProps> = ({
	id,
	label,
	name,
	error,
	style,
	className,
	checked = false,
	disabled,
	...props
}) => {
	return (
		<Container style={style} className={className}>
			<CheckLabel htmlFor={id}>
				<CheckInput
					id={id}
					type="checkbox"
					name={name}
					checked={checked}
					disabled={disabled}
					{...props}
				/>
				<Box>
					<BoxFill />
				</Box>
				{label}
			</CheckLabel>
			<Error error={error}>{error}</Error>
		</Container>
	);
};

export default CheckBox;
