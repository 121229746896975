import styled from "@emotion/styled";
import { animated } from "react-spring";

export const Animation = styled(animated.div)(({ theme }) => ({
	position: "fixed",
	zIndex: 99,
	bottom: "0",
	left: "0",
	right: "0",
	width: "100%",
	height: "10rem",
	background: theme.colors.White,
	boxShadow: theme.shadow,
}));

export const Bar = styled.div(() => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	height: "10rem",
	maxWidth: "100%",
}));
