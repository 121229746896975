import styled from "@emotion/styled";

// CC
import { DropDownInputConverter as CCDropDownInput } from "CCW-Components/CCW-FIGDropDownInputNew";
import mediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Flex = styled.div(() => ({
	display: "flex",
	gap: "1rem",
	justifyContent: "end",
	[mediaQueries[1]]: {
		gap: "3rem",
	},
}));

export const MiniFlex = styled.div(() => ({
	display: "flex",
	gap: "0.5rem",
	alignItems: "center",
}));

export const SortContainer = styled.div(() => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	[mediaQueries[1]]: {
		width: "16rem",
	},
}));

export const DropDownInput = styled(CCDropDownInput)(({ theme }) => ({
	".select__control": {
		backgroundColor: "transparent",
		background: "transparent",
		cursor: "pointer",
	},
})) as unknown as typeof CCDropDownInput;

export const Relative = styled.div({
	position: "relative",
	display: "flex",
	alignItems: "center",
});

export const FilterButton = styled.button<{ isActive: boolean }>(
	({ theme, isActive }) => ({
		...theme.webFonts.F6,
		...theme.webFonts.bold,
		display: "flex",
		gap: "1rem",
		alignItems: "center",
		color: isActive ? theme.colors.primary : theme.colors.grey500,
		background: "transparent",
		border: "none",
		":hover": {
			color: theme.colors.grey800,
		},
		"& > span": {
			display: "none",
			[mediaQueries[1]]: {
				display: "inline",
			},
		},
	}),
);

export const Position = styled.div(({ theme }) => ({
	position: "absolute",
	top: "100%",
	right: 0,
	zIndex: 1,
	width: "26rem",
	maxWidth: "100vw",
	background: theme.colors.grey50,
	boxShadow: `0 0 1rem ${theme.colors.Black20}`,
	borderRadius: "0.6rem",
	padding: "0.5rem 2rem",
}));

export const SelectedFilterOptions = styled.div(({ theme }) => ({
	display: "flex",
	justifyContent: "end",
	alignItems: "center",
	gap: "0.4rem",
	flexWrap: "wrap",
	marginTop: "0.5rem",
}));
