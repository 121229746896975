import styled from "@emotion/styled";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

// Styles
export const Relative = styled.div({
	position: "relative",
});

export const ToggleButton = styled.button(({ theme }) => ({
	transform: "rotate(0.25turn)",
	background: "transparent",
	border: "none",
	padding: "1.2rem 0",
	color: theme.colors.grey500,
	"&:hover": {
		color: theme.colors.grey800,
	},
	[MediaQueries[1]]: {
		display: "none",
	},
}));

export const Container = styled.div<{
	isShown: boolean;
}>(({ theme, isShown }) => ({
	backgroundColor: theme.colors.White,
	display: isShown ? "flex" : "none",
	flexDirection: "column",
	alignItems: "flex-start",
	borderRadius: "0.8rem",
	overflow: "hidden",
	boxShadow: theme.shadow,
	position: "absolute",
	top: "100%",
	zIndex: "1",
	padding: "1.5rem 1rem",
	minWidth: "max-content",
	"& > button": {
		padding: "0 !important",
	},
	// Breakpoint MUST match the breakpoint measure changes layout at
	[MediaQueries[1]]: {
		backgroundColor: "transparent",
		display: "flex",
		boxShadow: "none",
		position: "static",
	},
}));
