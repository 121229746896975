import { ReactNode } from "react";
import { useLocation, useParams } from "react-router-dom";

// CC
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import Container from "CCW-Components/CCW-Container";
import PageControls from "CCW-Components/CCW-PageControls";
import FilterBarProps from "CCW-Components/CCW-PageControls/Components/FilterBar/types";
import ActionPicker from "CCW-Components/CCW-ActionPicker";
import AppError from "CCW-Components/CCW-AppError";
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import { Action } from "CCW-Components/CCW-ActionPicker/types";

// Hooks
import useUsers from "Hooks/UseUsers";
import useAlert from "Hooks/UseAlert";
import usePartners from "Hooks/UsePartners";
import useEntities from "Hooks/UseEntities";
import useAccounts from "Hooks/UseAccounts";

// Helpers
import { states } from "Helpers/StringHelpers/Address";

// Styles
import {
	Cell,
	HeaderCell,
	Table,
	TopRow,
	OverflowScroll,
} from "CCW-Components/CCW-TableStyles/styles";

// Assets
import {
	CrossIcon,
	FileIcon,
	HouseIcon,
	SettingsIcon,
	TickIcon,
	UserIcon,
} from "Assets/SVG";

// Types
import Account, { AccountType } from "Types/Account";
import { Tier } from "Types/Tiers";
import Entity from "Types/Entity";
import Partner from "Types/Partner";
import { User } from "Types/User";

// Styles
import { HoverPosition, StyledRow as Row } from "./styles";

type Entry = User | Partner | Account | Entity;

export const AdminDashboard = () => {
	const location = useLocation();
	const [, reportAlert] = useAlert();

	const { type } = useParams<{ type?: string }>();

	const searchParams: URLSearchParams = new URLSearchParams(location.search);

	const searchValue =
		(searchParams.get("search")?.length || 0) > 3
			? (searchParams.get("search") as string)
			: undefined;

	const selectedPaginationParameters = {
		limit: parseInt(searchParams.get("perPage") || "") || 10,
		offset: parseInt(searchParams.get("page") || "") || 1,
	};

	const [users, usersError, , usersRelated, , userPagination] = useUsers(
		true,
		selectedPaginationParameters,
		{
			account: searchParams.get("account") || undefined,
			id: searchParams.get("id") || undefined,

			search: searchValue,
		},
		type !== "users",
	);

	const [partners, partnersError, , , , partnersPagination] = usePartners(
		false,
		selectedPaginationParameters,
		{
			account: searchParams.get("account") || undefined,
			state: searchParams.get("state") || undefined,
			type: searchParams.get("type") || undefined,
			tier: searchParams.get("tier") || undefined,
			search: searchValue,
		},
		type !== "partners" && type !== "entities",
	);

	const [entities, entitiesError, , entitiesRelated, , entitiesPagination] =
		useEntities(
			true,
			selectedPaginationParameters,
			{
				account: searchParams.get("account") || undefined,
				state: searchParams.get("state") || undefined,
				search: searchValue,
				lga: searchParams.get("lga") || undefined,
			},
			type !== "entities",
		);
	const [accounts, accountsError, accountsMutate, , , accountsPagination] =
		useAccounts(
			false,
			selectedPaginationParameters,
			type === "accounts"
				? {
						"account-type":
							(searchParams.get("accountType") as AccountType) || undefined,
						partner: searchParams.get("partner") || undefined,
						search: searchValue,
						tier: (searchParams.get("tier") as Tier) || undefined,
						id: searchParams.get("id") || undefined,
				  }
				: undefined,
		);

	const entries: Entry[] | undefined =
		users ||
		(type != "entities" && partners) ||
		entities ||
		(type === "accounts" ? accounts : undefined);

	const related =
		(Array.isArray(usersRelated) && usersRelated) ||
		(Array.isArray(entitiesRelated) && entitiesRelated);

	const entriesError: Error | undefined =
		usersError || partnersError || entitiesError || accountsError;

	// Error state
	if (entriesError) {
		return <AppError error={entriesError} />;
	}

	const hiddenColumns: string[] = ["permission", "img"];

	const columns = Array.from(
		entries?.reduce((columns, entry) => {
			Object.keys(entry).forEach((key) => columns.add(key));
			return columns;
		}, new Set<string>()) || [],
	)
		.filter((column) => !hiddenColumns.includes(column))
		.sort();

	const getBooleanIcon = (booleanToCheck: boolean) =>
		booleanToCheck ? (
			<TickIcon width="20px" height="20px" fill="green" />
		) : (
			<CrossIcon width="10px" height="10px" fill="red" />
		);

	const formatCell = (cell: unknown): ReactNode => {
		switch (typeof cell) {
			case "boolean":
				return getBooleanIcon(cell);
			case "string":
				return Date.parse(cell)
					? new Date(cell).toLocaleDateString()
					: cell.startsWith("https://")
					? getBooleanIcon(true)
					: cell;
			case "number":
				return cell;
			case "object":
				return JSON.stringify(cell);
			default:
				return (cell as ReactNode) || "-";
		}
	};

	const pagination =
		userPagination ||
		partnersPagination ||
		entitiesPagination ||
		accountsPagination;

	const filterOptions: FilterBarProps | undefined = (() => {
		switch (type) {
			case "users":
				return {
					displayNames: { account: "Account" } as { [name: string]: string },
					options: accounts?.map((account) => ({
						display: account._id,
						group: "account",
						isSelected: searchParams.get("account") === account._id,
						value: account._id,
					})),
				};
			case "entities":
				return {
					displayNames: { lga: "LGA" } as { [name: string]: string },
					options: partners
						?.filter((partner) => partner.type === "lga")
						.map((lga) => ({
							display: lga.name,
							group: "lga",
							isSelected: searchParams.get("lga") === lga._id,
							value: lga._id,
						})),
				};
			case "accounts":
				return {
					displayNames: { accountType: "Account Type", tier: "Tier" } as {
						[name: string]: string;
					},
					options: [
						...["home", "school", "business", "partner"].map((accountType) => ({
							display: accountType,
							group: "accountType",
							isSelected: searchParams.get("accountType") === accountType,
							value: accountType,
						})),
						...["basic", "standard", "pro"].map((tier) => ({
							display: tier,
							group: "tier",
							isSelected: searchParams.get("tier") === tier,
							value: tier,
						})),
					],
				};
			case "partners":
				return {
					displayNames: {
						type: "Type",
						state: "State",
					},
					options: [
						...["normal", "lga", "affiliate"].map((type) => ({
							display: type,
							group: "type",
							isSelected: searchParams.get("type") === type,
							value: type,
						})),
						...states.map((state) => ({
							display: state,
							group: "state",
							isSelected: searchParams.get("state") === state,
							value: state,
						})),
					],
				};

			default:
				return;
		}
	})();

	const makeActionsFromEntry = (
		entry: Entry,
		relatedAccountID?: string,
	): Action[] => {
		const copyIDAction = {
			icon: FileIcon,
			onClickOrLink: async () => {
				await navigator.clipboard.writeText(entry._id as string);
				reportAlert("ID copied to clipboard", "success");
			},
			title: "Copy ID",
		};

		const goToRelatedAccountAction = relatedAccountID
			? {
					icon: SettingsIcon,
					title: "Show Account",
					onClickOrLink: {
						pathname: "/dashboard/accounts",
						search: `?id=${relatedAccountID}`,
					},
			  }
			: undefined;

		if ("fName" in entry) {
			// entry: User
			return [
				copyIDAction,
				...(goToRelatedAccountAction ? [goToRelatedAccountAction] : []),
			];
		} else if ("discount" in entry) {
			// entry: Partner
			return [
				copyIDAction,
				{
					icon: SettingsIcon,
					title: "Show all Accounts",
					onClickOrLink: {
						pathname: "/dashboard/accounts",
						search: `?partner=${entry._id}`,
					},
				},
			];
		} else if ("tier" in entry) {
			// entry: Account
			return [
				copyIDAction,
				{
					icon: HouseIcon,
					title: "Show all Entities",
					onClickOrLink: {
						pathname: "/dashboard/entities",
						search: `?account=${entry._id}`,
					},
				},
				{
					icon: UserIcon,
					title: "Show all Users",
					onClickOrLink: {
						pathname: "/dashboard/users",
						search: `?account=${entry._id}`,
					},
				},
			];
		} else {
			// entry: Entity
			return [
				copyIDAction,
				...(goToRelatedAccountAction ? [goToRelatedAccountAction] : []),
			];
		}
	};

	// Normal state
	return (
		<DefaultPage>
			<Container>
				<PageControls
					header={"Dashboard"}
					showViewOptions={false}
					showSortDirection={false}
					showSearch={true}
					navLinks={[
						{
							description: "Accounts",
							link: { pathname: "/dashboard/accounts" },
						},
						{
							description: "Entities",
							link: { pathname: "/dashboard/entities" },
						},
						{
							description: "Partners",
							link: { pathname: "/dashboard/partners" },
						},
						{
							description: "Users",
							link: { pathname: "/dashboard/users" },
						},
					]}
					pagination={{
						limit: parseInt(searchParams.get("perPage") || "") || 10,
						self: parseInt(searchParams.get("page") || "") || 1,
						last: pagination?.last,
					}}
					filter={{
						...filterOptions,
						showSearch: true,
						showSortDirection: false,
					}}>
					{!entries ? (
						<BlockLoader />
					) : !columns.length ? (
						<TopRow>
							<HeaderCell>No results found</HeaderCell>
						</TopRow>
					) : (
						<OverflowScroll>
							<Table border={0}>
								<thead tabIndex={1}>
									<TopRow>
										{/* First cell left empty for the Action popup (this is a safari hack) */}
										<HeaderCell />
										{columns?.map((column, index) => (
											<HeaderCell key={column + index}>{column}</HeaderCell>
										))}
									</TopRow>
								</thead>
								<tbody>
									{entries?.map((entry, index) => {
										// These "related" types are annoying because they
										// are subtly different for users vs entities
										const relatedItem = Array.isArray(related)
											? related?.[index]
											: undefined;
										const relatedAccount =
											relatedItem && "account" in relatedItem
												? relatedItem.account
												: relatedItem?.accounts?.[0];
										return (
											<Row
												aria-label={`Entry ${index + 1}`}
												tabIndex={2}
												id={entry?._id as string}
												key={entry?._id as string}>
												<Cell key={index} style={{ position: "relative" }}>
													<HoverPosition className={"showOnHover"}>
														<ActionPicker
															actions={makeActionsFromEntry(
																entry,
																relatedAccount?._id,
															)}
															key={"Actions" + index}
														/>
													</HoverPosition>
												</Cell>
												{columns.map((column, index) => {
													// eslint-disable-next-line @typescript-eslint/ban-ts-comment
													// @ts-ignore
													const cell = formatCell(entry[column]);
													return <Cell key={index}>{cell}</Cell>;
												})}
											</Row>
										);
									})}
								</tbody>
							</Table>
						</OverflowScroll>
					)}
				</PageControls>
			</Container>
		</DefaultPage>
	);
};

export default AdminDashboard;
