import React, { PropsWithChildren } from "react";

// Styles
import Row from "./styles";

const ProfileInputRow: React.FC<PropsWithChildren<unknown>> = (props) => (
	<Row {...props} />
);

export default ProfileInputRow;
