// Helpers
import putReq from "Helpers/NetworkingHelpers/PutReq";

// Types
import type { Success } from "Types/JSONApi";

/**
 * @async
 * Replaces the image of the specific user and returns success
 * @param userID user ID of the user whose image we want to replace
 * @param image Image we want to upload for the user in jpeg format
 * @returns success
 * @throws Networking error
 */
const replaceUserImage = async (
	userId: string,
	image: Blob | Blob[],
): Promise<Success | undefined> => {
	const userImage = new FormData();
	userImage.append("image", Array.isArray(image) ? image[0] : image);

	const response = await putReq<Success | undefined>(
		`${import.meta.env.VITE_BASE_URL}executive/users/${userId}/image`,
		userImage,
		true,
		1,
	);

	return response;
};

export default replaceUserImage;
