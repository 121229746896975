import * as Yup from "yup";

export const InitialValues = (
	terms?: string,
	appendix?: string,
	climate?: string,
) => ({
	terms: terms ?? "",
	appendix: appendix ?? "",
	climate: climate ?? "",
});

export const validationSchema = Yup.object({
	terms: Yup.string(),
	appendix: Yup.string(),
	climate: Yup.string(),
});
