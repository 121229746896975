import React from "react";

// Local
import DefaultResetPasswordPage from "./Components/DefaultResetPasswordPage";

const ResetPasswordPage = () => {
	return <DefaultResetPasswordPage />;
};

export default ResetPasswordPage;
