import React from "react";
import { useSpring } from "react-spring";

// CC or Bit
import Container from "../CCW-Container";

// Styles
import { Animation, Bar } from "./styles";

// Types
import ActionAlertProps from "./types";

const ActionAlert: React.FC<ActionAlertProps> = ({ show, ...props }) => {
	// Helpers
	const animation = useSpring({
		opacity: show ? 1 : 0,
		bottom: show ? 0 : -100,
	});

	return (
		<Animation aria-hidden={!show} style={animation}>
			<Container>
				<Bar>{props.children}</Bar>
			</Container>
		</Animation>
	);
};

export default ActionAlert;
