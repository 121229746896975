const categoryColors = {
	/* Area Gradients */
	GreenGradient: "linear-gradient(351.52deg, #44bd99 11.05%, #d8a84b 88.95%)",
	BlueGradient: "linear-gradient(140.06deg, #007CBE 0%, #a264d3 100%)",
	PurpleGradient: "linear-gradient(140.19deg, #ae4bdc 0%, #dc994b 100%)",

	/* Category Gradients */
	Electricity: "linear-gradient(177.05deg, #FBB12C 0%, #F34340 100%)",
	Gas: "linear-gradient(173.77deg, #F24141 0%, #7A29CA 100%)",
	LPG: "linear-gradient(6.71deg, #BB3680 5.26%, #1FA786 94.74%)",
	Water: "linear-gradient(149.61deg, #007CBE 0%, #00c0c0 100%)",
	Waste: "linear-gradient(92.29deg, #82bc25 0%, #50bd7b 100%)",
	Travel: "linear-gradient(148.12deg, #007CBE 0%, #f34b40 100%)",
	Transport: "linear-gradient(148.12deg, #007CBE 0%, #f34b40 100%)",
	Garden: "linear-gradient(146.58deg, #7eca32 0%, #f89e17 100%)",
	Offsets: "linear-gradient(15deg, #2b761d 0%, #bcae34 100%)",
	Other: "linear-gradient(138.38deg, #888888 0%, #76a4c5 100%)",
	Flight: "linear-gradient(145.09deg, #007CBE 0%, #AC50DA 100%)",
	Accommodation: "linear-gradient(145.09deg, #AC50DA 0%, #007CBE 100%)",
	Paper: "linear-gradient(180deg, #63849C 0.01%, #828282 100%)",
	electricity: "linear-gradient(177.05deg, #FBB12C 0%, #F34340 100%)",
	gas: "linear-gradient(173.77deg, #F24141 0%, #7A29CA 100%)",
	lpg: "linear-gradient(6.71deg, #BB3680 5.26%, #1FA786 94.74%)",
	water: "linear-gradient(149.61deg, #007CBE 0%, #00c0c0 100%)",
	waste: "linear-gradient(92.29deg, #82bc25 0%, #50bd7b 100%)",
	travel: "linear-gradient(148.12deg, #007CBE 0%, #f34b40 100%)",
	transport: "linear-gradient(148.12deg, #007CBE 0%, #f34b40 100%)",
	garden: "linear-gradient(146.58deg, #7eca32 0%, #f89e17 100%)",
	offsets: "linear-gradient(146.58deg, #2b761d 0%, #bcae34 100%)",
	other: "linear-gradient(138.38deg, #888888 0%, #76a4c5 100%)",
	flight: "linear-gradient(145.09deg, #007CBE 0%, #AC50DA 100%)",
	paper: "linear-gradient(180deg, #63849C 0.01%, #828282 100%)",
	accommodation: "linear-gradient(145.09deg, #AC50DA 0%, #007CBE 100%)",

	/* Category Solid Colours */
	SElectricity: "#F67038",
	SGas: "#BB3680",
	SLPG: "#508484",
	SWater: "#007CBE",
	SWaste: "#5E9842",
	STravel: "#886B80",
	STransport: "#886B80",
	SGarden: "#AF9411",
	SOffsets: "#698E27",
	SOther: "#63849C",
	SFlight: "#6E68D8",
	SPaper: "#6A8496",
	selectricity: "#F67038",
	sgas: "#BB3680",
	slpg: "#508484",
	swater: "#007CBE",
	swaste: "#5E9842",
	stravel: "#886B80",
	stransport: "#886B80",
	sgarden: "#AF9411",
	soffsets: "#698E27",
	sother: "#63849C",
	sflight: "#6E68D8",
	spaper: "#6A8496",
};

export default categoryColors;
