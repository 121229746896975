export type ContentType = "JSONAPI" | "JSON" | "MULTIPART";

const getReqHeader = (contentType: ContentType) => {
	switch (contentType) {
		case "JSONAPI":
			return "application/vnd.api+json";
		case "JSON":
			return "application/json";
		case "MULTIPART":
			return "multipart/form-data";
		default:
			return "application/json";
	}
};

export default getReqHeader;
