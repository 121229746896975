import React from "react";
import { animated, config, useTransition } from "react-spring";

// CC
import CCAlert from "../CCW-Alert";
import CCContainer from "../CCW-Container";
import Portal from "CCW-Components/CCW-Portal";

// Hooks
import useAlert from "Hooks/UseAlert";
import useReduceMotion from "Hooks/UseReduceMotion";

// Styles
import { Position, AlertBox } from "./styles";

export const FullPageAlert: React.FC = () => {
	const [alerts, , clearAlert] = useAlert();
	const reduceMotion = useReduceMotion();
	const transitions = useTransition(alerts || [], {
		from: { opacity: 0, transform: "translate3d(0, -100%, 0)" },
		enter: { opacity: 1, transform: "translate3d(0, 0, 0)" },
		leave: { opacity: 0, transform: "translate3d(0, -100%, 0)" },
		config: config.stiff,
	});

	return (
		<Portal>
			<Position>
				<CCContainer>
					<AlertBox>
						{transitions((style, { id, message, type }) => (
							<animated.div style={style}>
								<CCAlert
									key={id}
									message={message}
									type={type}
									duration={reduceMotion ? 3000 : 5000}
									onFinish={() => clearAlert(id)}
								/>
							</animated.div>
						))}
					</AlertBox>
				</CCContainer>
			</Position>
		</Portal>
	);
};

export default FullPageAlert;
