import { OtherIcon } from "Assets/SVG";
import React, { useState } from "react";

// CC
import ButtonLink from "CCW-Components/CCW-ButtonLink";

// Styles
import { Relative, Container, ToggleButton } from "./styles";

// Types
import type ActionsProps from "./types";
import { useTheme } from "@emotion/react";

export const Actions: React.FC<ActionsProps> = ({ actions, ...props }) => {
	const theme = useTheme();
	// Note that is shown only applies for mobile; it's always shown in desktop
	const [isShown, setIsShown] = useState(false);
	return (
		<Relative
			onBlur={({ currentTarget, relatedTarget }) =>
				!currentTarget.contains(relatedTarget) && setIsShown(false)
			}>
			<ToggleButton onClick={() => setIsShown((oldIsShown) => !oldIsShown)}>
				<OtherIcon height="14" width="14" />
			</ToggleButton>
			<Container isShown={isShown} {...props}>
				{actions.map(({ description, icon, link, disabled }) => (
					<ButtonLink
						key={description}
						to={link}
						icon={icon}
						variant="linkWithIcon"
						disabled={disabled}
						customColor={{
							mainColor: theme.colors.grey500,
							hoverColor: theme.colors.Grape300,
							clickColor: theme.colors.Grape500,
						}}>
						{description}
					</ButtonLink>
				))}
			</Container>
		</Relative>
	);
};

export default Actions;
