import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Styles
import Container from "./styles";

// Types
import { DefaultPageProps } from "./types";

const DefaultPage = ({
	onNavigation = true,
	onMountOnly = false,
	defaultPadding = true,
	...props
}: DefaultPageProps) => {
	// Hooks
	const { pathname } = useLocation();

	// Helpers
	useEffect(() => {
		if (onNavigation) {
			window.scrollTo(0, 0);
		}
	}, [onNavigation, pathname]);

	useEffect(() => {
		if (onMountOnly) {
			window.scrollTo(0, 0);
		}
	}, [onMountOnly]);

	return <Container defaultPadding={defaultPadding} {...props} />;
};

export default DefaultPage;
