// Helpers
import postReq from "Helpers/NetworkingHelpers/PostReq";

// Types
import type { CreateResetUserPasswordEmail } from "Types/User";

/**
 * @async
 * Send email to reset a user's password
 * @param sendEmailDetails The email and the account type
 * @returns void
 */
const createResetPasswordEmail = async (
	sendEmailDetails: CreateResetUserPasswordEmail,
): Promise<void> => {
	await postReq(
		`${import.meta.env.VITE_BASE_URL}executive/auth/forgot-password`,
		sendEmailDetails,
		1,
		undefined,
		true,
	);
	return;
};

export default createResetPasswordEmail;
