import React from "react";
import { Helmet } from "react-helmet";
import { useTheme } from "@emotion/react";

// Styles

// Types
import type MetaHeaderProps from "./types";

const MetaHeader: React.FC<MetaHeaderProps> = ({
	title,
	favIconURL,
	description,
	imageURL,
	manifestURL,
	...props
}) => {
	// Hooks
	const theme = useTheme();

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{title}</title>
				<meta property="og:site_name" content={title}></meta>
				<link rel="shortcut icon" href={favIconURL} />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="theme-color" content={theme.colors.primary} />
				<meta property="og:type" content="website" />
				{description != null && (
					<meta name="description" content={description}></meta>
				)}
				{imageURL != null && <meta property="og:image" content={imageURL} />}
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>
			{props.children}
		</>
	);
};

export default MetaHeader;
