import styled from "@emotion/styled";

// Types
export const Footer = styled.div(() => ({
	display: "flex",
	justifyContent: "space-between",
	gap: "1.5rem",
	marginTop: "4.4rem",
}));

export const ButtonContainer = styled.div(() => ({
	display: "flex",
	gap: "1.5rem",
}));

export const TemplateLink = styled.a<{ isVisible: boolean }>(
	({ theme, isVisible }) => ({
		...theme.webFonts.F6,
		...theme.webFonts.bold,
		color: theme.colors.Grape400,
		visibility: isVisible ? "visible" : "hidden",
		pointerEvents: isVisible ? "inherit" : "none",
		display: "flex",
		alignItems: "center",
		gap: "1rem",
		textDecoration: "none",
		"&:active": {
			transform: "scale(0.98)",
		},
	}),
);

export const Circle = styled.div(({ theme }) => ({
	borderRadius: "50%",
	background: theme.colors.Grape400,
	"& > svg": {
		display: "block",
	},
}));
