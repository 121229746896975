import styled from "@emotion/styled";

// Helpers

// Types

// Styles
export const ImageBox = styled.div(() => ({
	position: "relative",
	paddingBottom: "100%",
	borderRadius: "50%",
	height: 0,
	width: "100%",
	overflow: "hidden",
}));

export const ImageElement = styled.img(() => ({
	position: "absolute",
	objectFit: "cover",
	width: "100%",
	height: "100%",
	left: 0,
}));
