import React from "react";
import { animated, config, useTransition } from "react-spring";

import type TransitionOpacityProps from "./types";

export const TransitionOpacity: React.FC<TransitionOpacityProps> = ({
	springConfig = { config: config.stiff },
	visible,
	onRest,
	children,
}) => {
	// Hooks
	const transitions = useTransition(visible, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		onRest,
		...springConfig,
	});

	return transitions(
		(styles, visible) =>
			visible && (
				<animated.div aria-label="transition-opacity" style={styles}>
					{children}
				</animated.div>
			),
	);
};

export default TransitionOpacity;
