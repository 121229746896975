import React from "react";
import { useNavigate } from "react-router-dom";

// CC
import Button from "../CCW-CCButton";

// Types
import { ButtonLinkProps } from "./types";

const ButtonLink: React.FC<ButtonLinkProps> = ({ to, onClick, ...rest }) => {
	// Hooks
	const navigate = useNavigate();

	return (
		<Button
			{...rest}
			onClick={(event) => {
				onClick && onClick(event);
				navigate(to);
			}}
		/>
	);
};

export default ButtonLink;
