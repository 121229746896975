// Types
import type MessageDialogProps from "./types";

export const MessageDialog: React.FC<MessageDialogProps> = ({
	icon: Icon,
	highlightClass = "text-blueberry-400",
	outlineClass = "outline-blueberry-400",
	backgroundClass = "bg-neutral-500",
	iconClass = "",
	hasOutline,
	message,
	title,
}) => {
	return (
		// Note that to use this you'll need to set the container you want to use it in to have a `position` of `relative`
		<div
			className={`absolute inset-0 flex items-center justify-center bg-opacity-30 p-4 ${backgroundClass}`}>
			<div
				className={
					"text-f6 animate-pop flex items-center gap-6 rounded-xl bg-neutral-50 py-10 px-11 " +
					(hasOutline ? `outline outline-1 ${outlineClass}` : "")
				}>
				<Icon className={"flex-none " + highlightClass + " " + iconClass} />
				<div className="text-neutral-600">
					{!!title && (
						<span className={`mb-2 font-bold ${highlightClass}`}>{title}</span>
					)}
					<p className="m-0">{message}</p>
				</div>
			</div>
		</div>
	);
};

export default MessageDialog;
