import React, { PropsWithChildren, useEffect, useState } from "react";
import ReactDOM from "react-dom";

export const Portal: React.FC<PropsWithChildren> = ({ children }) => {
	// Hooks
	const [topLayerElement, setTopLayerElement] = useState<HTMLElement | null>();

	useEffect(() => {
		setTopLayerElement(document.getElementById("top-layer"));
	}, []);

	if (!topLayerElement) {
		return null;
	}

	return ReactDOM.createPortal(children, topLayerElement);
};

export default Portal;
