import React from "react";

// Types
import type IconProps from "Types/IconProps";

const SearchIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "search-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "16px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#search-icon-clip)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7ZM11.2001 12.6004C10.0302 13.4792 8.57591 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 8.57591 13.4792 10.0302 12.6004 11.2001C12.6376 11.2281 12.6733 11.259 12.7071 11.2929L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L11.2929 12.7071C11.259 12.6733 11.2281 12.6376 11.2001 12.6004Z"
					fill={fill != null ? fill : "#888"}
				/>
			</g>
			<defs>
				<clipPath id="search-icon-clip">
					<path d="M0 0H16V16H0V0Z" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default SearchIcon;
