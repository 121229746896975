import { useContext } from "react";

// Context
import IntercomContext from "Contexts/IntercomContext";

/**
 * Get and set the intercom user hash
 */
const useIntercomHash = () => {
	const intercom = useContext(IntercomContext);

	return [intercom?.hash, intercom?.updateHash] as [
		string,
		(hash: string) => void,
	];
};

export default useIntercomHash;
