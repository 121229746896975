import React from "react";

// Component
import LoadingSun from "Assets/SVG/LoadingSunIcon";

// Styles
import { LoaderContainer, SVGContainer, LoaderText } from "./styles";

// Types
import { BlockLoaderProps } from "./types";

const BlockLoader: React.FC<BlockLoaderProps> = ({
	message = "Loading a new healthy world...",
	height = "20rem",
}) => {
	return (
		<LoaderContainer>
			<SVGContainer>
				<LoadingSun width="13rem" height={height} />
			</SVGContainer>
			<LoaderText>{message}</LoaderText>
		</LoaderContainer>
	);
};

export default BlockLoader;
