import styled from "@emotion/styled";

export const Column = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	"> *": {
		width: "100%",
	},
}));
export default Column;
