import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const OriginalLink = styled(Link)(({ theme }) => ({
	...theme.webFonts.F6SemiBold,
	color: theme.colors.primary,
	"&: hover, focus": {
		color: theme.colors.Blue600,
	},
	"&: visited": {
		color: theme.colors.Blue600,
	},
}));

export const VariantLink = styled(OriginalLink)(({ theme }) => ({
	color: theme.colors.secondary,
	"&: visited": {
		color: theme.colors.Grape500,
	},
}));
