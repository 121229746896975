import getReqHeader, { ContentType } from "../GetReqHeader";
import makeError from "../MakeError";

/**
 * Networking function for post requests
 * @param url The url for the request
 * @param reqBody Data to be send in the request body
 * @param version Optional version of the API endpoint
 * @param headers Optional override of the headers
 * @param voidResponse Option to void the response instead of parsing JSON
 * @param contentType Option to declare a specific content type
 */
const postReq = async <T>(
	url: string,
	reqBody: Record<string, unknown> | RequestInit["body"],
	version?: number,
	headers?: { name: string; value: string }[],
	voidResponse?: boolean, // Does the response contain JSON?
	contentType: ContentType = "JSON",
): Promise<T> => {
	const requestHeaders = new Headers();
	requestHeaders.set("Content-Type", getReqHeader(contentType));

	version &&
		requestHeaders.set("Accept", `application/vnd.api.v${version}+json`);
	headers?.forEach((header) => requestHeaders.set(header.name, header.value));
	try {
		const response = await fetch(url, {
			method: "POST",
			credentials: "include",
			...(contentType !== "MULTIPART" && { headers: requestHeaders }),
			body:
				contentType === "MULTIPART"
					? (reqBody as RequestInit["body"])
					: JSON.stringify(reqBody),
		});

		if (!response.ok) {
			throw makeError(
				response.headers.get("content-type")?.includes("application/json")
					? (await response.json()).error
					: "Unknown Error, please contact support",
			);
		}

		const responseData = voidResponse ? response : await response.json();

		return responseData;
	} catch (error) {
		throw makeError(error);
	}
};

export default postReq;
