import React from "react";
import { Form, Formik } from "formik";

// CC
import FileInput from "../CCW-FileInputNew";
import ButtonLoader from "../CCW-ButtonLoader";
import CSVRequirements from "../CCW-CSVRequirements";

// Helpers
import isError from "Helpers/TypeHelpers/IsError";

// Styles
import { ButtonContainer, TemplateLink, Footer, Circle } from "./styles";

// Types
import type UploadCSVFormProps from "./types";

// Form Data
import { validation, initialValues } from "./FormLogic";
import CCButton from "../CCW-CCButton";
import DownloadArrow from "Assets/SVG/DownloadArrowIcon";

export const UploadCSVForm: React.FC<UploadCSVFormProps> = ({
	requirements,
	className,
	templateLinkHRef,
	onSubmit,
	onCancel,
}) => {
	return (
		<div className={className} id="form-upload-csv">
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validation}
				validateOnChange
				onSubmit={async (
					{ CSVs },
					{ setSubmitting, resetForm, setFieldError },
				) => {
					if (!CSVs) return null;
					setSubmitting(true);

					try {
						// Upload CSV file
						await onSubmit(CSVs);
						resetForm();
					} catch (err) {
						setFieldError(
							"CSVs",
							isError(err)
								? err.message
								: "Action failed. Please contact support",
						);
					} finally {
						setSubmitting(false);
					}
				}}>
				{({ isSubmitting, values, errors }) => {
					return (
						<Form>
							<FileInput name="CSVs" accept="text/csv" multiple={true} />
							{requirements && <CSVRequirements requirements={requirements} />}
							<Footer>
								<TemplateLink
									download={"template.csv"}
									isVisible={!!templateLinkHRef}
									href={templateLinkHRef}>
									<Circle>
										<DownloadArrow width={"24"} height={"24"} fill="white" />
									</Circle>
									<span>Download CSV template</span>
								</TemplateLink>
								<ButtonContainer>
									<ButtonLoader
										loading={isSubmitting}
										type="submit"
										disabled={!values.CSVs?.length || !!errors.CSVs}>
										Upload
									</ButtonLoader>
									<CCButton variant="washed" onClick={onCancel}>
										Cancel
									</CCButton>
								</ButtonContainer>
							</Footer>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default UploadCSVForm;
