// Helpers
import postReq from "../PostReq";

// Types
import { MarkdownEntry, MarkdownEntryName } from "Types/Markdown";
import { APIResponseDeprecated } from "Types/JSONApi";

/**
 * @async
 * Create a new version of a markdown entry
 * @param name The name of the markdown entry you'd like to create / update
 * @param data Data needed to update the Something
 * @throws Networking error
 */
const createMarkdownEntry = async (
	name: MarkdownEntryName,
	data: { markdown: string; currentId?: string }, // Omit the currentId if you're creating the first version of an entry
): Promise<MarkdownEntry | undefined> => {
	const { data: createdMarkdownEntry } = await postReq<
		APIResponseDeprecated<MarkdownEntry>
	>(`${import.meta.env.VITE_BASE_URL}measure/markdowns/${name}`, data);

	return createdMarkdownEntry;
};
export default createMarkdownEntry;
