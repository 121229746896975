import React, { useEffect, useMemo } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { useParams } from "react-router-dom";

// CC
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import Container from "CCW-Components/CCW-Container";

// Hooks
import useAPICharts from "Hooks/UseAPICharts";
import useAlert from "Hooks/UseAlert";

// Locals
import ChartSelector from "../ChartSelector";

// Styles
import { Warning, Chart } from "./styles";

const DBChartsPage: React.FC = () => {
	// Hooks
	const [apiCharts, error, , , { meta } = { meta: undefined }] = useAPICharts();
	const [, reportAlert] = useAlert();
	const { chartId } = useParams();
	const chartsSDK = useMemo(
		() =>
			new ChartsEmbedSDK({
				baseUrl: meta?.baseURL,
			}),
		[meta?.baseURL],
	);

	// Helpers
	useEffect(() => {
		if (!chartId || !apiCharts || !meta) return;
		const chart = chartsSDK.createChart({
			chartId: chartId,
			getUserToken: () => meta.token,
			autoRefresh: false,
			showAttribution: false,
		});
		const container = document.getElementById("injected-chart");
		if (!container) return;
		chart
			.render(container)
			.catch(() => reportAlert(`Failed to load chart: ${chartId}`, "error"));
	}, [apiCharts, chartId, chartsSDK, meta, reportAlert]);

	if (error) {
		return (
			<Warning>
				{`Error loading the charts, please contact support and quote this message: \n ${error.message}`}
			</Warning>
		);
	}

	// Loading State
	if (!apiCharts) {
		return <BlockLoader />;
	}

	return (
		<DefaultPage>
			<Container>
				<Warning>
					We are still working on these charts so you may find issues
				</Warning>
				<ChartSelector charts={apiCharts} />
				<Chart id="injected-chart"></Chart>
			</Container>
		</DefaultPage>
	);
};

export default DBChartsPage;
