import React from "react";

// Types
import { InputProps } from "./types";

// Styles
import { Container, Input, Error } from "./styles";

/** Base input field */
const TextInput: React.FC<InputProps> = ({ id, name, error, ...props }) => (
	<Container>
		<Input id={id} name={name} error={error} {...props} />
		<Error error={error}>{error}</Error>
	</Container>
);

export default TextInput;
