import React from "react";
import MDEditor from "@uiw/react-md-editor";
import { useField } from "formik";

// CC
import InputLabel from "../CCW-InputLabel";
import FormGroup from "../CCW-FormGroup";

// Types
import type FIGMarkdownProps from "./types";
import MaxCharacterHint from "../CCW-MaxCharacterHint";

export const FIGMarkdown: React.FC<FIGMarkdownProps> = ({
	name,
	label,
	required,
	id,
	showRenderedMarkdown,
	autofocus = true,
	characterLimit,
	className,
}) => {
	// State
	const [field, , helpers] = useField({ name });
	const charactersRemaining = characterLimit
		? characterLimit - (field.value?.length || 0)
		: undefined;

	return (
		<FormGroup className={className} data-color-mode="light">
			{label && <InputLabel htmlFor={id} text={label} required={required} />}
			{!showRenderedMarkdown ? (
				<>
					<MDEditor
						value={field.value ?? ""}
						onChange={(value?: string) => helpers.setValue(value)}
						autoFocus={autofocus}
						textareaProps={{ maxLength: characterLimit || undefined }}
					/>
					{characterLimit && (
						<MaxCharacterHint
							charactersRemaining={charactersRemaining as number}
						/>
					)}
				</>
			) : (
				<MDEditor.Markdown source={field.value} />
			)}
		</FormGroup>
	);
};

export default FIGMarkdown;
