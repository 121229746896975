import styled from "@emotion/styled";

// CC
import CardTemplate from "CCW-Components/CCW-CardTemplate";
import ButtonLoader from "CCW-Components/CCW-ButtonLoader";

// Helpers
import breakpoints from "Helpers/WebUIHelpers/Breakpoints";

export const Card = styled(CardTemplate)(() => ({
	padding: "2.5rem",
	[breakpoints[0]]: {
		padding: "5rem",
	},
}));

export const Button = styled(ButtonLoader)(() => ({
	marginTop: "3rem",
}));
