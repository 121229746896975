// Styles
import React from "react";
import { Container, StyledButton, StyledLink } from "./styles";

// Types
import type ActionPickerProps from "./types";

export const ActionPicker: React.FC<ActionPickerProps> = ({
	actions,
	...props
}) => {
	return (
		<Container {...props}>
			{actions?.map(({ icon: Icon, title, onClickOrLink }) => (
				<React.Fragment key={title}>
					{typeof onClickOrLink === "function" ? (
						<StyledButton onClick={() => onClickOrLink()} title={title}>
							<Icon width="20px" height="20px" />
						</StyledButton>
					) : (
						<StyledLink to={onClickOrLink} title={title}>
							<Icon width="20px" height="20px" />
						</StyledLink>
					)}
				</React.Fragment>
			))}
		</Container>
	);
};

export default ActionPicker;
