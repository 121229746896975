import React from "react";

// Types
import type IconProps from "Types/IconProps";

export const PlayIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "play-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "16px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 16 16"
			fill={fill || "currentColor"}
			xmlns="http://www.w3.org/2000/svg">
			<path d="M2.5 2.25C2.5 1.83579 2.83579 1.5 3.25 1.5H15.25C15.6642 1.5 16 1.83579 16 2.25V2.25C16 2.66421 15.6642 3 15.25 3H3.25C2.83579 3 2.5 2.66421 2.5 2.25V2.25Z" />
			<path d="M2.5 7.75C2.5 7.33579 2.83579 7 3.25 7H15.25C15.6642 7 16 7.33579 16 7.75V7.75C16 8.16421 15.6642 8.5 15.25 8.5H3.25C2.83579 8.5 2.5 8.16421 2.5 7.75V7.75Z" />
			<path d="M0 7.5C0 7.22386 0.223858 7 0.5 7H1C1.27614 7 1.5 7.22386 1.5 7.5V8C1.5 8.27614 1.27614 8.5 1 8.5H0.5C0.223858 8.5 0 8.27614 0 8V7.5Z" />
			<path d="M2.5 13.25C2.5 12.8358 2.83579 12.5 3.25 12.5H15.25C15.6642 12.5 16 12.8358 16 13.25V13.25C16 13.6642 15.6642 14 15.25 14H3.25C2.83579 14 2.5 13.6642 2.5 13.25V13.25Z" />
			<path d="M0 13C0 12.7239 0.223858 12.5 0.5 12.5H1C1.27614 12.5 1.5 12.7239 1.5 13V13.5C1.5 13.7761 1.27614 14 1 14H0.5C0.223858 14 0 13.7761 0 13.5V13Z" />
			<path d="M0 2C0 1.72386 0.223858 1.5 0.5 1.5H1C1.27614 1.5 1.5 1.72386 1.5 2V2.5C1.5 2.77614 1.27614 3 1 3H0.5C0.223858 3 0 2.77614 0 2.5V2Z" />
		</svg>
	);
};

export default PlayIcon;
