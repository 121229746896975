import { formatNumToNumber } from "../FormatNumberForDisplay";

/**
 * @param value
 * @returns string
 *
 * This function takes a very large (but not too large) number and returns a shortened string with the appropriate suffix.
 * Should work for any number 0 < n < 1 * 10^30
 */

const addMagnitudeSuffix = (value: number, addSpace?: boolean): string => {
	const suffix = ["", "k", "M", "B", "T", "Qd", "Qn", "Sx", "Sp", "O", "N"];
	const valueForLog = Math.max(Math.abs(value), 1); // protect log from numbers less than 1

	const orderOfMagnitude = Math.floor(Math.log10(valueForLog) / 3);

	return `${formatNumToNumber(value / Math.pow(10, orderOfMagnitude * 3))}${
		addSpace ? " " : ""
	}${suffix[orderOfMagnitude]}`;
};

export default addMagnitudeSuffix;
