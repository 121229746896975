import styled from "@emotion/styled";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Heading = styled.h2(({ theme }) => ({
	...theme.webFonts.h4,
	color: theme.colors.grey600,
	margin: "0",
	textTransform: "capitalize",
	[MediaQueries[0]]: {
		textAlign: "unset",
	},
}));

export const CreateContainer = styled.div(() => ({
	display: "none",
	[MediaQueries[1]]: {
		display: "block",
	},
}));

export const MobileCreateContainer = styled.div(() => ({
	display: "block",
	position: "absolute",
	top: "0",
	bottom: "0",
	right: "0",
	left: "0",
	background: "white",
	zIndex: 2,
	height: "100vh",
	[MediaQueries[1]]: {
		display: "none",
	},
}));

export const PageContainer = styled.div(() => ({
	marginTop: "3rem",
}));

export const MarginBottom = styled.div({ marginBottom: "5rem" });

export const FlexRow = styled.div(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	gap: "2rem",
	"& strong": { color: theme.colors.Grape400, fontStyle: "italic" },
	"& button": { flexShrink: 0 },
}));

export const HeadingSection = styled.div({
	display: "flex",
	justifyContent: "space-between",
	marginBottom: "1.8rem",
});

export const ButtonBox = styled.div({
	display: "flex",
	gap: "1.2rem",
	alignItems: "start",
});
