import React from "react";

// Assets
import PlusIcon from "Assets/SVG/PlusIcon";

// Styles
import {
	PrimaryButton,
	SecondaryButton,
	WashedButton,
	DeleteButton,
	TextWashedLinkButton,
	ButtonLinkWithIcon,
	HolidayButton,
} from "./styles";

// Types
import { CCButtonProps } from "./types";

const CCButton: React.FC<CCButtonProps> = ({
	variant = "primary",
	block = false,
	name,
	type = "button",
	onClick,
	id,
	disabled = false,
	border = false,
	icon: Icon = PlusIcon,
	colorVariant = "blue",
	focusRef,
	...props
}) => {
	if (variant === "secondary") {
		return (
			<SecondaryButton
				colorVariant={colorVariant}
				name={name}
				type={type}
				id={id}
				onClick={disabled ? undefined : onClick}
				block={block}
				disabled={disabled}
				ref={focusRef}
				{...props}
			/>
		);
	}

	if (variant === "washed") {
		return (
			<WashedButton
				name={name}
				type={type}
				id={id}
				onClick={disabled ? undefined : onClick}
				block={block}
				disabled={disabled}
				{...props}
			/>
		);
	}

	if (variant === "delete" || variant === "deletePrimary") {
		return (
			<DeleteButton
				name={name}
				type={type}
				id={id}
				onClick={disabled ? undefined : onClick}
				block={block}
				disabled={disabled}
				isPrimary={variant === "deletePrimary"}
				{...props}
			/>
		);
	}

	if (variant === "linkWashed") {
		return (
			<TextWashedLinkButton
				name={name}
				type={type}
				id={id}
				onClick={disabled ? undefined : onClick}
				block={block}
				disabled={disabled}
				{...props}
			/>
		);
	}

	if (variant === "linkWithIcon") {
		return (
			<ButtonLinkWithIcon
				name={name}
				type={type}
				id={id}
				onClick={disabled ? undefined : onClick}
				block={block}
				disabled={disabled}
				colorVariant={colorVariant}
				{...props}>
				{Icon && <Icon width={"16"} height={"16"} />}
				{props.children}
			</ButtonLinkWithIcon>
		);
	}

	if (variant === "holiday") {
		return (
			<HolidayButton
				colorVariant={colorVariant}
				name={name}
				type={type}
				id={id}
				onClick={disabled ? undefined : onClick}
				block={block}
				disabled={disabled}
				ref={focusRef}
				{...props}
			/>
		);
	}

	return (
		<PrimaryButton
			colorVariant={colorVariant}
			border={border}
			name={name}
			type={type}
			id={id}
			onClick={disabled ? undefined : onClick}
			block={block}
			disabled={disabled}
			{...props}
		/>
	);
};

export default CCButton;
