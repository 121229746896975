import React from "react";

// Types
import type IconProps from "Types/IconProps";

export const PartnerIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "partner-icon",
	fill = "#828282",
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "64px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 32 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M31.6747 18.7195C30.5363 17.2658 29.385 15.8212 28.2502 14.363C28.1069 14.1635 27.9161 14.0034 27.6951 13.8973C27.4742 13.7912 27.2302 13.7426 26.9856 13.7558C25.8593 13.7766 24.7324 13.7861 23.6049 13.7842C23.438 13.7853 23.2739 13.7413 23.1298 13.6567C22.429 13.2092 21.7136 12.779 21.0557 12.2737C20.2453 11.65 19.4869 10.9584 18.7048 10.2972C18.131 9.81108 17.3516 9.84501 16.8591 10.3751C16.7388 10.5017 16.6451 10.6512 16.5834 10.8148C16.5217 10.9784 16.4934 11.1528 16.5 11.3276C16.5067 11.5024 16.5482 11.6741 16.6221 11.8325C16.696 11.9909 16.7862 12.1154 16.9304 12.2498C17.5206 12.8001 18.0944 13.3843 18.745 13.8594C19.9328 14.7243 21.1699 15.5158 22.3787 16.3495C22.5021 16.4348 22.6382 16.5935 22.6529 16.732C22.789 17.9004 22.9059 19.0707 23.0119 20.242C23.0544 20.6609 23.0663 21.0824 23.0476 21.5031C22.9562 23.1219 22.7872 24.7388 22.7652 26.3585C22.7461 27.8177 22.8703 29.2797 22.9553 30.7398C22.9845 31.2543 23.2705 31.6386 23.7402 31.8597C24.7452 32.3311 25.7832 31.5772 25.7174 30.4326C25.5721 27.9342 25.4141 25.4368 25.7393 22.9403C25.7521 22.8412 25.7658 22.7431 25.7777 22.6504H26.1898C26.2028 22.6778 26.2141 22.706 26.2236 22.7348C26.8065 25.3377 27.3803 27.9443 27.9761 30.5509C28.0372 30.8626 28.1599 31.1588 28.337 31.4222C28.7217 31.9468 29.4334 32.0697 30.0282 31.778C30.6231 31.4864 30.8935 30.8902 30.7327 30.1565C30.211 27.7719 29.6518 25.3955 29.1511 23.0026C28.877 21.7113 28.6431 20.0778 28.4201 18.7763C28.4201 18.7763 29.2772 19.9879 29.6609 20.4006C29.8848 20.6367 30.1158 20.7987 30.4833 20.8895C31.0214 21.0225 31.5605 20.7363 31.8227 20.2851C32.1005 19.8017 32.0548 19.2028 31.6747 18.7195Z"
				fill={fill}
			/>
			<circle cx="25.1" cy="10.1" r="3.1" fill={fill} />
			<path
				d="M0.325274 18.7195C1.46374 17.2658 2.615 15.8212 3.74982 14.363C3.89306 14.1635 4.08392 14.0034 4.30488 13.8973C4.52583 13.7912 4.7698 13.7426 5.01438 13.7558C6.14067 13.7766 7.26757 13.7861 8.39507 13.7842C8.56197 13.7853 8.72608 13.7413 8.87019 13.6567C9.571 13.2092 10.2864 12.779 10.9443 12.2737C11.7547 11.65 12.5131 10.9584 13.2952 10.2972C13.869 9.81108 14.6484 9.84501 15.1409 10.3751C15.2612 10.5017 15.3549 10.6512 15.4166 10.8148C15.4783 10.9784 15.5066 11.1528 15.5 11.3276C15.4933 11.5024 15.4518 11.6741 15.3779 11.8325C15.304 11.9909 15.1992 12.1328 15.0696 12.2498C14.4794 12.8001 13.9056 13.3843 13.255 13.8594C12.0672 14.7243 10.8301 15.5158 9.62125 16.3495C9.49791 16.4348 9.36176 16.5935 9.34714 16.732C9.211 17.9004 9.09405 19.0707 8.98806 20.242C8.9456 20.6609 8.93369 21.0824 8.95243 21.5031C9.0438 23.1219 9.21283 24.7388 9.23476 26.3585C9.25395 27.8177 9.12968 29.2797 9.04471 30.7398C9.01547 31.2543 8.72948 31.6386 8.25984 31.8597C7.25477 32.3311 6.21681 31.5772 6.28259 30.4326C6.42787 27.9342 6.58594 25.4368 6.26066 22.9403C6.24787 22.8412 6.23417 22.7431 6.22229 22.6504H5.81021C5.79717 22.6778 5.78587 22.706 5.7764 22.7348C5.19346 25.3377 4.61966 27.9443 4.02393 30.5509C3.96279 30.8626 3.84007 31.1588 3.66302 31.4222C3.27835 31.9468 2.56658 32.0697 1.97176 31.778C1.37694 31.4864 1.10649 30.8902 1.2673 30.1565C1.78902 27.7719 2.34821 25.3955 2.84891 23.0026C3.12302 21.7113 3.35693 20.0778 3.57987 18.7763C3.57987 18.7763 2.72282 19.9879 2.33907 20.4006C2.11522 20.6367 1.83058 20.8059 1.51674 20.8895C0.978568 21.0225 0.439487 20.7363 0.177256 20.2851C-0.100509 19.8017 -0.0548248 19.2028 0.325274 18.7195Z"
				fill={fill}
			/>
			<path
				d="M7.06931 13.2422C6.24466 13.2394 5.4549 12.9079 4.87376 12.3206C4.29262 11.7333 3.9677 10.9383 3.97049 10.1106C3.97328 9.2828 4.30354 8.49005 4.88862 7.90671C5.47371 7.32337 6.26568 6.99722 7.09032 7.00002C7.49864 7.0014 7.90269 7.0835 8.27941 7.24163C8.65612 7.39976 8.99811 7.63083 9.28586 7.92163C9.867 8.50892 10.1919 9.3039 10.1891 10.1317C10.1863 10.9594 9.85608 11.7522 9.271 12.3355C8.68592 12.9189 7.89395 13.245 7.06931 13.2422Z"
				fill={fill}
			/>
			<path
				d="M20.8926 2.26604C21.2476 1.84297 21.8783 1.78779 22.3014 2.14279C22.7245 2.49779 22.7796 3.12855 22.4246 3.55162L20.1749 6.23278C19.8199 6.65585 19.1891 6.71103 18.7661 6.35603C18.343 6.00103 18.2878 5.37027 18.6428 4.9472L20.8926 2.26604Z"
				fill={fill}
			/>
			<path
				d="M15 1C15 0.447715 15.4477 0 16 0C16.5523 0 17 0.447715 17 1V4.5C17 5.05228 16.5523 5.5 16 5.5C15.4477 5.5 15 5.05228 15 4.5V1Z"
				fill={fill}
			/>
			<path
				d="M9.57278 3.55163C9.21778 3.12855 9.27296 2.4978 9.69604 2.1428C10.1191 1.78779 10.7499 1.84298 11.1049 2.26605L13.3546 4.94721C13.7096 5.37028 13.6544 6.00104 13.2314 6.35604C12.8083 6.71104 12.1775 6.65586 11.8225 6.23278L9.57278 3.55163Z"
				fill={fill}
			/>
		</svg>
	);
};

export default PartnerIcon;
