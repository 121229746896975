import React from "react";

// Types
import type IconProps from "Types/IconProps";

const OtherIcon: React.FC<IconProps> = ({
	width,
	height,
	className = "other-icon",
	fill,
	id,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "60px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 60 12"
			fill={fill || "currentColor"}
			xmlns="http://www.w3.org/2000/svg">
			<circle cx="6" cy="6" r="6" />
			<circle cx="30" cy="6" r="6" />
			<circle cx="54" cy="6" r="6" />
		</svg>
	);
};

export default OtherIcon;
