import React from "react";

// Types
import { FileProps } from "./types";

// Styles
import { Container, UploadInput, Error } from "./styles";

/** Base input field */
const FileInput: React.FC<FileProps> = ({
	id,
	onChange,
	name,
	className,
	color,
	error,
	accept,
	border = true,
	disabled = false,
	...props
}) => {
	return (
		<Container id="upload-file-container" {...props}>
			<UploadInput
				className={className}
				accept={accept}
				id={id}
				name={name}
				type="file"
				onChange={onChange}
				color={color}
				border={border}
				error={error}
				disabled={disabled}
			/>
			<Error error={error}>{error}</Error>
		</Container>
	);
};

export default FileInput;
