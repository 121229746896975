import { useCallback, useState } from "react";
import confetti from "canvas-confetti";

// Styles
import colours from "Theme/Styles/Colors";

const useConfetti = (): [() => void, number] => {
	const [confettiClicks, setConfettiClicks] = useState(0);

	const popConfetti = useCallback(() => {
		setConfettiClicks((oldConfettiClicks) => oldConfettiClicks + 1);
		const count = 60;
		const defaults = {
			origin: { y: -0.5 },
			ticks: 300,
			angle: 270,
			colors: [
				colours.Yellow,
				colours.primary,
				colours.Teal,
				colours.Tree,
				colours.Purple,
				colours.Green,
				colours.Red,
			],
		};

		const fire = (
			particleRatio: number,
			options: {
				spread?: number;
				scalar?: number;
				startVelocity?: number;
				gravity?: number;
				decay?: number;
			},
		): void => {
			confetti(
				Object.assign({}, defaults, options, {
					particleCount: Math.floor(count * particleRatio),
				}),
			);
		};

		fire(0.3, {
			spread: 70,
			scalar: 0.4,
			startVelocity: 30,
			gravity: 0.6,
		});

		fire(0.4, {
			spread: 200,
			scalar: 0.6,
			decay: 0.8,
			gravity: 0.8,
		});

		fire(0.8, {
			spread: 600,
			decay: 0.91,
			scalar: 0.8,
			gravity: 1,
		});

		fire(1.2, {
			spread: 500,
			startVelocity: 50,
			decay: 0.91,
			scalar: 1.5,
		});

		fire(1, {
			spread: 700,
			startVelocity: 90,
			scalar: 3,
			decay: 0.92,
			gravity: 1.9,
		});

		fire(0.5, {
			spread: 120,
			startVelocity: 45,
			gravity: 0.4,
		});
	}, []);

	return [popConfetti, confettiClicks];
};

export default useConfetti;
