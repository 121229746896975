import React from "react";
import { animated, config, useTransition } from "react-spring";

import type TransitionHeightProps from "./types";

import useMeasure from "Hooks/UseMeasure";

export const TransitionHeight: React.FC<TransitionHeightProps> = ({
	visible,
	withOpacity = true,
	/** Delay must be greater than zero */
	springConfig = { delay: 50, config: config.stiff },
	transitionOptions,
	children,
	className,
}) => {
	// Hooks
	const [heightElement, { height }] = useMeasure<HTMLDivElement>();
	const transitions = useTransition(visible, {
		...((!transitionOptions || transitionOptions === "on-entry-only") && {
			from: { height: 0, opacity: withOpacity ? 0 : 1 },
		}),
		...(height && { update: { height, opacity: 1 } }),
		...((!transitionOptions || transitionOptions === "on-leave-only") && {
			leave: { height: 0, opacity: withOpacity ? 0 : 1 },
		}),
		...springConfig,
	});

	return transitions((styles, item) => {
		return (
			item && (
				<animated.div
					className={className}
					aria-label="transition-height"
					style={{ ...styles, overflow: "hidden" }}>
					<div
						ref={heightElement}
						// This style is a dirty hack to make sure that margins in the
						// children don't flow through this div
						style={{ paddingTop: "1px", marginTop: "-1px" }}>
						{children}
					</div>
				</animated.div>
			)
		);
	});
};

export default TransitionHeight;
