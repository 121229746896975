import React from "react";

// Types
import type IconProps from "Types/IconProps";

export const LoadingSunIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "loading-sun-icon",
	fill = "#FBB12A",
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "100px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 100 100"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2148_1019)">
				<path
					d="M49.999 76.3651C64.5089 76.3651 76.3399 64.5516 76.3633 50.042C76.3888 35.4933 64.5421 23.6289 49.9931 23.6289C35.4851 23.6289 23.6541 35.4424 23.6306 49.9501C23.6052 64.4968 35.4558 76.3671 49.999 76.3651Z"
					fill={fill}
				/>
				<path
					opacity="0.76"
					d="M14.5389 52.9262C16.4348 52.9262 17.7515 51.7229 17.7632 50.0169C17.775 48.3108 16.4563 47.0821 14.5741 47.0723C10.7745 47.0618 6.9743 47.0618 3.17342 47.0723C1.3069 47.0723 -0.0196213 48.3264 -5.6088e-05 50.0286C0.0175526 51.6956 1.32451 52.9125 3.13039 52.9262C5.04778 52.9399 6.96322 52.9262 8.88062 52.9262C10.7706 52.9282 12.6548 52.936 14.5389 52.9262Z"
					fill={fill}
				/>
				<path
					opacity="0.92"
					d="M29.8799 20.9877C30.0642 21.3244 30.3141 21.6208 30.6148 21.8594C30.9154 22.0981 31.2607 22.2742 31.6305 22.3774C32.0002 22.4806 32.3868 22.5087 32.7676 22.4603C33.1484 22.4118 33.5156 22.2876 33.8477 22.0951C34.1848 21.9117 34.4817 21.6625 34.7208 21.3623C34.9599 21.0622 35.1364 20.7171 35.2399 20.3475C35.3433 19.9779 35.3716 19.5914 35.323 19.2107C35.2744 18.83 35.15 18.4629 34.957 18.1312C33.0318 14.7073 31.044 11.3146 29.0758 7.91812C28.8339 7.49581 28.489 7.14159 28.0734 6.88844C27.6577 6.63528 27.1848 6.49144 26.6985 6.47029C24.3311 6.45464 22.9127 8.8416 24.0397 10.8607C25.9394 14.2573 27.9057 17.6284 29.8799 20.9877Z"
					fill={fill}
				/>
				<path
					opacity="0.2"
					d="M81.881 34.9496C85.3147 33.042 88.7093 31.0639 92.0862 29.0585C92.4204 28.8681 92.7128 28.6123 92.946 28.3064C93.1792 28.0006 93.3484 27.6509 93.4435 27.2782C93.5386 26.9055 93.5577 26.5175 93.4997 26.1373C93.4416 25.7571 93.3076 25.3925 93.1056 25.0652C92.9113 24.7347 92.6535 24.446 92.3471 24.2157C92.0406 23.9854 91.6915 23.8181 91.32 23.7235C90.9486 23.6289 90.562 23.6089 90.1828 23.6645C89.8035 23.7202 89.4389 23.8505 89.1103 24.0478C85.7236 25.973 82.3564 27.9315 78.9873 29.89C78.5786 30.1081 78.2314 30.4254 77.9774 30.8128C77.7234 31.2001 77.5708 31.645 77.5336 32.1067C77.5081 34.6365 79.852 36.0746 81.881 34.9496Z"
					fill={fill}
				/>
				<path
					opacity="0.44"
					d="M70.0635 78.926C69.8503 78.5362 69.5437 78.2054 69.1712 77.9633C68.7986 77.7212 68.3717 77.5753 67.9289 77.5388C65.3854 77.4899 63.9142 79.8397 65.0353 81.8588C66.9429 85.2945 68.9229 88.6871 70.9205 92.0699C71.1092 92.4049 71.3633 92.6986 71.6676 92.9336C71.9719 93.1686 72.3203 93.3402 72.6921 93.4382C73.0639 93.5361 73.4516 93.5585 73.8322 93.504C74.2128 93.4494 74.5786 93.3191 74.9079 93.1206C75.5672 92.7464 76.0516 92.1263 76.2551 91.396C76.4585 90.6656 76.3646 89.8845 75.9938 89.2232C74.0666 85.768 72.0612 82.3441 70.0635 78.926Z"
					fill={fill}
				/>
				<path
					opacity="0.68"
					d="M18.0822 65.0605C14.6857 66.9681 11.3205 68.9305 7.96113 70.9027C6.50156 71.7597 6.05938 73.4658 6.85177 74.8706C7.03609 75.2073 7.28601 75.5037 7.58676 75.7423C7.88751 75.9808 8.23299 76.1567 8.60283 76.2596C8.97267 76.3624 9.35937 76.3902 9.74011 76.3411C10.1208 76.2921 10.4879 76.1673 10.8196 75.974C14.2455 74.0508 17.6381 72.061 21.0307 70.0908C21.9698 69.5449 22.4296 68.6762 22.457 67.8877C22.4629 65.3227 20.1053 63.9257 18.0822 65.0605Z"
					fill={fill}
				/>
				<path
					opacity="0.52"
					d="M52.9333 85.5375C52.9333 83.5594 51.7457 82.229 49.9985 82.229C48.2514 82.229 47.0735 83.5614 47.0638 85.5375C47.0638 89.0592 47.1283 92.5614 47.0344 96.0714C46.9855 97.8871 47.5216 99.2234 49.2101 100H50.7753C52.4598 99.2175 52.9999 97.8871 52.9509 96.0714C52.859 92.5614 52.9333 89.0495 52.9333 85.5375Z"
					fill={fill}
				/>
				<path
					opacity="0.6"
					d="M32.1064 77.5273C31.6729 77.5485 31.2517 77.6793 30.8825 77.9075C30.5133 78.1358 30.208 78.454 29.9953 78.8323C27.9527 82.3032 25.9257 85.7819 23.9614 89.2958C23.2003 90.6654 23.7658 92.3363 25.0688 93.113C26.3718 93.8897 28.1366 93.5904 28.9446 92.2424C31.0303 88.7617 33.0866 85.2595 35.0255 81.6967C36.0566 79.8008 34.5932 77.5665 32.1064 77.5273Z"
					fill={fill}
				/>
				<path
					opacity="0.36"
					d="M91.7791 70.7383C88.5795 68.877 85.3721 67.0307 82.1569 65.1994C81.6365 64.904 81.0299 64.7553 80.4645 64.5361C79.8147 64.5827 79.1946 64.8262 78.6867 65.2341C78.1788 65.642 77.8073 66.195 77.6216 66.8194C77.2695 68.1107 77.7997 69.4098 79.0499 70.1455C82.3016 72.0459 85.5625 73.9307 88.8325 75.7999C90.4877 76.7488 92.2232 76.3868 93.0958 74.9566C93.9919 73.4696 93.4617 71.7166 91.7791 70.7383Z"
					fill={fill}
				/>
				<path
					opacity="0.84"
					d="M20.9954 29.8801C20.0778 29.3283 19.1426 28.804 18.2152 28.2679C15.7695 26.8553 13.3376 25.4172 10.8704 24.0418C10.5413 23.8449 10.1764 23.7153 9.79677 23.6605C9.41718 23.6058 9.03048 23.627 8.65916 23.7229C8.28783 23.8188 7.93926 23.9876 7.63367 24.2193C7.32809 24.4511 7.07161 24.7412 6.87908 25.0729C6.09647 26.3857 6.41342 28.1466 7.75364 28.9488C11.2343 31.0325 14.7365 33.0868 18.2974 35.0316C20.1874 36.0627 22.4256 34.5973 22.4609 32.1614C22.4472 31.3338 21.9619 30.4631 20.9954 29.8801Z"
					fill={fill}
				/>
				<path
					opacity="0.28"
					d="M96.0665 47.0426C92.5584 47.1346 89.0465 47.0661 85.5365 47.07C83.5624 47.07 82.228 48.2616 82.228 50.0048C82.228 51.7481 83.5604 52.9396 85.5346 52.9396C89.0563 52.9396 92.5584 52.875 96.0665 52.967C97.8822 53.0159 99.2185 52.4837 99.9952 50.7913V49.2261C99.2204 47.5181 97.8822 46.9937 96.0665 47.0426Z"
					fill={fill}
				/>
				<path
					opacity="0.12"
					d="M66.1329 22.0833C67.5377 22.8757 69.2125 22.4296 70.1105 20.9935C70.6251 20.1659 71.0888 19.3109 71.5799 18.4677C73.0257 15.9653 74.499 13.4785 75.9077 10.9566C77.0698 8.87481 75.712 6.45654 73.3094 6.45654C72.8219 6.47246 72.3466 6.61321 71.929 6.86534C71.5114 7.11746 71.1655 7.47252 70.9244 7.89654C68.9444 11.2892 66.9683 14.6857 65.0372 18.1077C64.8427 18.4393 64.7167 18.8065 64.6665 19.1877C64.6164 19.5689 64.6432 19.9563 64.7453 20.3269C64.8475 20.6975 65.0229 21.0439 65.2612 21.3456C65.4995 21.6473 65.7959 21.8982 66.1329 22.0833Z"
					fill={fill}
				/>
				<path
					d="M47.0638 14.631C47.0775 16.4466 48.2944 17.7516 49.9633 17.7614C51.6322 17.7712 52.9079 16.474 52.9137 14.6799C52.932 10.8138 52.932 6.94771 52.9137 3.08162C52.9137 1.28357 51.6401 -0.0175197 49.9672 -0.00578057C48.2944 0.00595858 47.0755 1.31292 47.0618 3.12466C47.0501 5.0088 47.0618 6.89293 47.0618 8.77706C47.0638 10.7355 47.0638 12.6803 47.0638 14.631Z"
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id="clip0_2148_1019">
					<rect width="100" height="100" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default LoadingSunIcon;
