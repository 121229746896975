import styled from "@emotion/styled";

// CC
import CCButton from "CCW-Components/CCW-CCButton";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const PageTitle = styled.h2(({ theme }) => ({
	...theme.webFonts.h2,
	color: theme.colors.D2,
}));

export const TargetsNavBar = styled.div(() => ({
	display: "flex",
	gap: "1rem",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	marginBottom: "3rem",
	flexDirection: "column",
	flexWrap: "wrap",
	[MediaQueries[1]]: {
		flexDirection: "row",
		alignItems: "center",
		gap: "2rem",
	},
}));

export const AddButton = styled(CCButton)(({ theme }) => ({
	...theme.webFonts.F6Bold,
	whiteSpace: "nowrap",
	padding: "0 2rem",
	marginLeft: "auto",
}));

export const RowContainer = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	gap: "1rem",
}));
