import React from "react";
import { useNavigate } from "react-router-dom";

// Types
import { CustomButtonLinkProps } from "./types";

const CustomButtonLink: React.FC<CustomButtonLinkProps> = ({
	button: Button,
	to,
	onClick,
	customProps,
	...rest
}) => {
	const navigate = useNavigate();

	return (
		<Button
			{...rest}
			{...customProps}
			onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
				onClick && onClick(event);
				navigate(to);
			}}
		/>
	);
};

export default CustomButtonLink;
