import React from "react";

// Types
import type IconProps from "Types/IconProps";

/** Customizable svg icon */
const ArrowUpDownIcon: React.FC<IconProps & { direction?: "down" | "up" }> = ({
	width,
	height,
	id,
	className = "up-down-direction-arrow",
	direction = "up",
	fill,

	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "7px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 7 9"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill={fill || "currentColor"}
				d={
					direction === "down"
						? "M0.138987 4.93601C0.315628 4.77011 0.592473 4.77972 0.757338 4.95747L3.0625 7.44282L3.0625 0.940252C3.0625 0.697108 3.25838 0.5 3.5 0.5C3.74162 0.5 3.9375 0.697108 3.9375 0.940252L3.9375 7.44282L6.24266 4.95747C6.40753 4.77972 6.68437 4.77011 6.86101 4.93601C7.03765 5.10191 7.0472 5.3805 6.88234 5.55825L3.81984 8.86014C3.73709 8.94935 3.62127 9 3.5 9C3.37873 9 3.26291 8.94935 3.18016 8.86014L0.117665 5.55825C-0.0471999 5.3805 -0.0376535 5.10191 0.138987 4.93601Z"
						: "M6.86102 4.56399C6.68438 4.72989 6.40754 4.72028 6.24267 4.54253L3.93751 2.05718L3.93751 8.55975C3.93751 8.80289 3.74164 9 3.50001 9C3.25839 9 3.06251 8.80289 3.06251 8.55975L3.06251 2.05718L0.757349 4.54253C0.592485 4.72028 0.315639 4.72989 0.138998 4.56399C-0.0376425 4.39809 -0.0471888 4.1195 0.117676 3.94175L3.18017 0.63986C3.26292 0.550649 3.37874 0.5 3.50001 0.5C3.62128 0.5 3.7371 0.550649 3.81985 0.63986L6.88235 3.94175C7.04721 4.1195 7.03766 4.39809 6.86102 4.56399Z"
				}
			/>
		</svg>
	);
};

export default ArrowUpDownIcon;
