export const breakpoints: number[] = [640, 768, 1024, 1280];
export const breakpointNames: string[] = [
	"extra-small",
	"small",
	"medium",
	"large",
];
export const screenWidthQueries = (queryType: "min-width" | "max-width") => {
	return {
		extraSmall: `@media (${queryType}: ${breakpoints[0]}px)`,
		small: `@media (${queryType}: ${breakpoints[1]}px)`,
		medium: `@media (${queryType}: ${breakpoints[2]}px)`,
		large: `@media (${queryType}: ${breakpoints[3]}px)`,
	};
};

export const mediaQueries: string[] = breakpoints.map(
	(bp) => `@media (min-width: ${bp}px)`,
);

export const minScreenWidth = screenWidthQueries("min-width");

export const maxScreenWidth = screenWidthQueries("max-width");

export const isDeviceMobile = (clientWidth: number) =>
	clientWidth < breakpoints[1];

export const isDeviceTablet = (clientWidth: number) =>
	clientWidth < breakpoints[2];

export default mediaQueries;
