import React from "react";
import { Route, Routes } from "react-router-dom";

// CC
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import Container from "CCW-Components/CCW-Container";

// Local CC
import ReportForm from "../ReportForm";

// Styles
import { Section, Page } from "./styles";

const DefaultReportsPage: React.FC = (props) => {
	return (
		<DefaultPage onNavigation={true} defaultPadding={false}>
			<Container>
				<Section id="reports-admin" {...props}>
					<Routes>
						<Route
							path="/"
							element={
								<Page>
									<ReportForm />
								</Page>
							}
						/>
					</Routes>
				</Section>
			</Container>
		</DefaultPage>
	);
};

export default DefaultReportsPage;
